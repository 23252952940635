import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up_report extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filter_datas: [],
            sums: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_follow_up_report;
        this.setState({
            datas: [],
            filter_datas: [],
            sums: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filter_datas: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnexport_report_1 = (event) => {
        event.preventDefault();
        let wb=XLSX.utils.book_new();
        wb.SheetNames.push("Report 1");
        let wb2= XLSX.utils.table_to_sheet(document.getElementById('report_1'));
        wb.Sheets["Report 1"]=wb2;
        XLSX.writeFile(wb,"report 1.xlsx");
    }

    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filter_datas.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.plate_no}</td>
                <td>{value.type}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_1}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table id="report_1" className="table table-head-fixed text-wrap">
                                            <thead>
                                            <tr>
                                                <th className="col-md-2">#</th>
                                                <th className="col-md-5">Plate #</th>
                                                <th className="col-md-5">Type</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tdata}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()

        return (
            <>
                <Nav type="follow_up_report"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Follow-up </h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_report;
