import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up_stoppage extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            rows_1: [],
            rows_2: [],
            count: 0,
            filtered: [],
            txtsearch: '',

            wiz: 1,
            iscreate: false,
            id: -1,
            msg_status: '',

            all_movs: [],
            sum_movs: [],
            sums: [],
            transit_for_loading: [],
            transit_for_unloading: [],
            transit_for_heading: [],
            isexe: false,

            filter_datas: [],
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicles_for_follow_up_stoppage;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                }, () => {
                    this.fetchdata2()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    fetchdata2 = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_reports_for_follow_up_stoppage;
        let msg_status = this.state.msg_status
        this.setState({
            isexe: true,
        })
        msg_status = msg_status + "Total of "+this.state.datas.length+" vehicle found. \n"
        this.setState({
            msg_status: msg_status,
        })
        let len=this.state.datas.length
        for (let i = 0; i < len; i++) {
            console.log(this.state.datas[i])
            msg_status = msg_status + "Executing "+(i+1)+" of "+len+"\n"
            this.setState({
                msg_status: msg_status,
            })
            msg_status = msg_status + "Executing vehicle " + this.state.datas[i].plate_no + "....\n"
            this.setState({
                msg_status: msg_status,
            })
            await axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                eid: this.state.eid,
                i: this.state.count,
                st: this.state.datas[i].st,
                ed: this.state.datas[i].ed,
                rr: this.state.datas[i].rou,
                assign_own_id: this.state.datas[i].assign_own_id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let rows_1 = this.state.rows_1
                    let rows_2 = this.state.rows_2
                    res.data.rows_1.map(v=>{
                        rows_1.push(
                            {
                                id:v.id
                            }
                        )
                    })
                    res.data.rows_2.map(v=>{
                        rows_2.push(
                            {
                                id:v.id,
                                type:v.type,
                                data:v.data,
                            }
                        )
                    })
                    res.data.rows_1.push()
                    msg_status = msg_status + "Execute finished for vehicle " + this.state.datas[i].plate_no + "\n"
                    this.setState({
                        rows_1: rows_1,
                        rows_2: rows_2,
                        msg_status: msg_status,
                        eid: res.data.eid,
                        count: res.data.i,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
        this.setState({
            isexe: false,
        })
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnmore = (event, vehicle_id) => {
        event.preventDefault();
        let temp = this.state.datas.filter(v => {
            if (v.vehicle_id === vehicle_id)
                return v;
        })
        if (temp.length > 0) {
            let transit_for_loading = this.state.sum_movs.filter(v1 => {
                if (v1.vehicle_id === vehicle_id && v1.type === "loading")
                    return v1;
            })
            let transit_for_unloading = this.state.sum_movs.filter(v1 => {
                if (v1.vehicle_id === vehicle_id && v1.type === "unloading")
                    return v1;
            })
            let transit_for_heading = this.state.sum_movs.filter(v1 => {
                if (v1.vehicle_id === vehicle_id && v1.type === "heading")
                    return v1;
            })

            this.setState({
                wiz: 2,
                transit_for_loading: transit_for_loading,
                transit_for_unloading: transit_for_unloading,
                transit_for_heading: transit_for_heading,
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btndelete = (event, vehicle_id) => {
        event.preventDefault();
        if (this.state.isexe) {
            Swal.fire({
                title: 'Error!',
                text: "You can delete currently. The report is being executing",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let all_movs = this.state.all_movs;
            let sum_movs = this.state.sum_movs;
            let sums = this.state.sums;
            let datas = this.state.datas;

            datas = datas.filter(v => {
                if (v.vehicle_id !== vehicle_id)
                    return v;
            })
            all_movs = all_movs.filter(v => {
                if (v.vehicle_id !== vehicle_id)
                    return v;
            })
            sum_movs = sum_movs.filter(v => {
                if (v.vehicle_id !== vehicle_id)
                    return v;
            })
            sums = sums.filter(v => {
                if (v.vehicle_id !== vehicle_id)
                    return v;
            })
            /**/
            this.setState({
                transit_for_loading: [],
                transit_for_unloading: [],
                transit_for_heading: [],
                all_movs: all_movs,
                sum_movs: sum_movs,
                sums: sums,
                datas: datas,
                filtered: datas,
            })
        }
    }
    btnfilter = (event, filter_by) => {
        event.preventDefault();
        this.setState({
            filter_datas: [],
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.fileter_follow_up_stoppage;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            hr: this.state.hr,
            st: this.state.date_time,
            filter_by: filter_by,
            sum_movs: this.state.sum_movs,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        filter_datas: res.data.datas,
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.rows_1.map(v => {
            let temps = this.state.rows_2.filter(v2 => {
                if (v2.id === v.id)
                    return v2;
            })
            let rows = temps.map(v3 => {
                if (v3.type == 1)
                    return (<th>{v3.data}</th>)
                else {
                    return (<td>{v3.data}</td>)
                }
            })
            return (<tr>
                {rows}
            </tr>)
        })
        return (<>
            <div className="col-12 px-3 py-1">
                <div className="table" id="section_to_print">
                    <table id="report" className="table table-sm table-bordered table-responsive">
                        {tdata}
                    </table>
                </div>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="follow_up_stoppage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Follow-up Stoppage</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4  px-3 py-1">
                                                <div className="form-group">
                                                    <label>Information Status</label>
                                                    <textarea rows={7} className="form-control form-control-sm" name="msg_status"
                                                              value={this.state.msg_status} onChange={this.inputmgt}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                            </div>
                                            <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                                           onClick={this.btnexport_report}>Download .xlsx</a></div>

                                            {dis}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_stoppage;
