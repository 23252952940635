import {Component} from 'react';
import axios from "axios"
import Nav from "./Nav";
import Swal from "sweetalert2";
import myconfig from "../myconfig";
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap';
import Select from "react-select";

let source = axios.CancelToken.source();

class User extends Component {

    constructor() {
        super();
        this.state = {
            stock: 'nav-item',
            obj: [],
            objload: false,

            //modal
            cushow:false,
            iscreate:true,
            id: '-1',
            username:'',
            password:'',
            name:'',
            email:'',
            isadmin:false,
            isactive:true,

            //modal 2
            pwdshow: false,
            newpwd: '',
            pwdid: '-1',

            user_templates: [],
            user_templates_selected: null,
            role_dis:true,
        }
    }
    handelchange_user_template = (user_templates_selected) => {
        this.setState({
            user_templates_selected:user_templates_selected,
        })
    }

    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let baseurl = myconfig.getusers;
        let redirecttologin = myconfig.redirecttologin;
        this.setState({
            objs: [],
            objsload: false,
        })
        axios.post(baseurl, {
            'sid': window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                  window.localStorage.setItem("logmgtsys_username", null);
                  window.localStorage.setItem("logmgtsys_name", null);
                  var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    obj: res.data,
                    user_templates: res.data.user_templates,
                    objload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    modalchangepwd=()=> {
        return (<Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.pwdshow}
                onHide={this.hidechangepwd}>
                <Modal.Header><h4>User Password Change</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>New password</label>
                                                <input  className="form-control" type="password" name="newpwd" onChange={this.inputmgt} value={this.state.newpwd}/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitchangepwd}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidechangepwd}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitchangepwd = (event) => {
        event.preventDefault()
        let baseurl = myconfig.changepwduser;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            'sid': window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.pwdid,
            newpwd: this.state.newpwd,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                  window.localStorage.setItem("logmgtsys_username", null);
                  window.localStorage.setItem("logmgtsys_name", null);
                  var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.successumit()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    showchangepwd = (event,id=-1) => {
        event.preventDefault()
        this.setState({
            pwdshow: true,
            newpwd: '',
            pwdid: id,
        })
    }
    hidechangepwd = () => {
        this.setState({
            pwdshow:false,
        })
    }


    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgtchk = (event) => {
        var name = event.target.name;
        var value = event.target.checked;

        this.setState({
            [name]: value,
            role_dis:!value,
        })
    }
    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        if(iscreate) {
            this.setState({
                cushow: true,
                iscreate: iscreate,
                id: '-1',
                username:'',
                password:'',
                name:'',
                email:'',
                isadmin:false,
                isactive:true,
                role_dis:true,
            })
        }
        else {
            let baseurl = myconfig.getuser;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                'sid': window.localStorage.getItem("logmgtsys_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                      window.localStorage.setItem("logmgtsys_sid", null);
                      window.localStorage.setItem("logmgtsys_username", null);
                      window.localStorage.setItem("logmgtsys_name", null);
                      var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        let role_dis=res.data.data.isadmin?false:true;
                        let user_templates_selected=null;
                        if(res.data.data.user_template_id!=null)
                            user_templates_selected={value:res.data.data.user_template_id,label:res.data.data.template_name}
                        this.setState({
                            cushow: true,
                            iscreate: iscreate,
                            id: id,
                            name: res.data.data.name,
                            email: res.data.data.email,
                            isadmin: res.data.data.isadmin,
                            isactive: res.data.data.isactive,
                            role_dis: role_dis,
                            user_templates_selected: user_templates_selected,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objload) {
            let count = 0;
            retdata = this.state.obj.datas.map(value => {
                count=count+1
                let isactive="Yes";
                let isadmin="No";
                if(value.isactive=="0")
                    isactive="No";
                if(value.isadmin=="1")
                    isadmin="Yes";

                return (<tr>
                    <td>{count}</td>
                    <td>
                        <a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a> |
                        <a href="" onClick={(event => {this.showchangepwd(event,value.id)})}>Password</a> |
                        <a href="#">Delete</a></td>
                    <td>{value.username}</td>
                    <td>{value.name}</td>
                    <td>{value.user_role}</td>
                    <td>{isadmin}</td>
                    <td>{isactive}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }
    modalcu=()=> {
        let display=null;
        if(this.state.iscreate) {
            display = <>
                <div className="col-12 px-3">
                    <div className="form-group">
                        <label>Username</label>
                        <input className="form-control" type="text" name="username" onChange={this.inputmgt}
                               value={this.state.username}/>
                    </div>
                </div>
                <div className="col-12 px-3">
                    <div className="form-group">
                        <label>Password</label>
                        <input className="form-control" type="password" name="password" onChange={this.inputmgt}
                               value={this.state.password}/>
                    </div>
                </div>
            </>
        }
        let user_role=null;
        if(this.state.role_dis)
            user_role= <div className="col-12 px-3">
                <div className="form-group">
                    <label>User Role</label>
                    <Select
                        value={this.state.user_templates_selected}
                        onChange={this.handelchange_user_template}
                        options={this.state.user_templates}/>
                </div>
            </div>
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Header><h4>User</h4></Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row">
                                        {display}
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Full Name</label>
                                                <input  className="form-control" type="text" name="name" onChange={this.inputmgt} value={this.state.name}/>
                                            </div>
                                        </div>
                                        <div className="col-12 px-3">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input  className="form-control" type="text" name="email" onChange={this.inputmgt} value={this.state.email}/>
                                            </div>
                                        </div>
                                        {user_role}
                                        <div className="col-md-6 px-3 pb-3">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" checked={this.state.isadmin} name="isadmin" onChange={this.inputmgtchk}/>
                                                    <label className="form-check-label">Is Admin</label>
                                            </div>
                                        </div>

                                        <div className="col-md-6 px-3 pb-3">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" checked={this.state.isactive} name="isactive" onChange={this.inputmgtchk}/>
                                                    <label className="form-check-label">Is Active</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let baseurl = myconfig.updateuser;
        let redirecttologin = myconfig.redirecttologin;
        if (this.state.iscreate)
            baseurl = myconfig.createuser
        let user_template_id=this.state.user_templates_selected==null?null:this.state.user_templates_selected.value;
        axios.post(baseurl, {
            'sid': window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            username: this.state.username,
            password: this.state.password,
            name: this.state.name,
            email: this.state.email,
            isadmin: this.state.isadmin,
            isactive: this.state.isactive,
            user_template_id: user_template_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                 window.localStorage.setItem("logmgtsys_sid", null);
                  window.localStorage.setItem("logmgtsys_username", null);
                  window.localStorage.setItem("logmgtsys_name", null);
                  var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.successumit()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    successumit=()=> {
        this.setState({
            siunit: '',
            cushow: false,
            pwdshow: false,
        }, () => this.fetcheddata())
    }
    render() {
        return (
            <>
                <Nav type="user"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">User Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">User</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="table_search"
                                                           className="form-control float-right" placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block" onClick={(event => this.showcu(event,true))}>New User</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th width="5.5%">#</th>
                                                    <th width="11.1%">Action</th>
                                                    <th>Username</th>
                                                    <th>Full Name</th>
                                                    <th>User Role</th>
                                                    <th>Is Admin</th>
                                                    <th>Is Active</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
                {this.modalchangepwd()}
            </>
        )
    }
}

export default User;
