import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up_wfo extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',


            wiz: 1,
            iscreate: false,
            id: -1,
            msg_status: "",

            grand_sums: [],
            sums: [],
            filter_sums: [],
            labels: [],
            details: [],
            orders: [],
            filter_orders: [],
            isload: false,
            isallexe: false,

            allocation: '',
            arivalatloading: '',
            loading: '',
            departure: '',
            arivalatunloading: '',
            unloading: '',
            finished: '',


            type: '',
            time: '',
            time_con: '',
            short: '',
            long: '',
            millage: '',
            msg: null,

            client: '',
            loading_at: '',
            unloading_at: '',
            starting_at: '',
            ending_at: '',
            plate_no: '',
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_follow_up_wfo;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                }, () => {
                    this.fetchdata2()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_mov_for_follow_up_wfo;

        for (let i = 0; i < this.state.datas.length; i++) {
            let msg_status = this.state.msg_status
            msg_status = msg_status + "Executing vehicle " + this.state.datas[i].plate_no + "....\n"
            this.setState({
                msg_status: msg_status,
            })
            await axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                eid: this.state.eid,
                starttime: this.state.datas[i].st,
                endtime: this.state.datas[i].ed,
                id: this.state.datas[i].id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.sums.length === 0) {
                        let msg_status = this.state.msg_status;
                        msg_status = msg_status + "No data found for vehicle " + this.state.datas[i].plate_no + "\n"
                        let temp = this.state.datas;
                        temp = temp.filter(v => {
                            if (v.id === this.state.datas[i].id)
                                v.isfound = null;
                            return v;
                        })
                        this.setState({
                            datas: temp,
                            filtered: temp,
                            msg_status: msg_status,
                            eid: res.data.eid,
                        })
                    } else {
                        let msg_status = this.state.msg_status;
                        msg_status = msg_status + "Report found for vehicle " + this.state.datas[i].plate_no + "\n"
                        let temp = this.state.grand_sums;
                        res.data.sums.map(v => {
                            temp.push({
                                assign_id: v.assign_id,
                                ed_long: v.ed_long,
                                ed_short: v.ed_short,
                                ed_time: v.ed_time,
                                ed_time_con: v.ed_time_con,
                                millage: v.millage,
                                st_long: v.st_long,
                                st_short: v.st_short,
                                st_time: v.st_time,
                                st_time_con: v.st_time_con,
                                c: v.c,
                            })
                        })
                        let temp2 = this.state.datas;
                        temp2 = temp2.filter(v => {
                            if (v.id === this.state.datas[i].id)
                                v.isfound = true;
                            return v;
                        })
                        let temp3 = this.state.details;
                        temp3.push({
                            assign_id: this.state.datas[i].id,
                            client: res.data.client,
                            plate_no: res.data.plate_no,
                            loading_at: res.data.loading_at,
                            unloading_at: res.data.unloading_at,
                            starting_at: res.data.starting_at,
                            ending_at: res.data.ending_at,
                            date_obj: res.data.date_obj,
                            labels: res.data.labels,
                            dt: res.data.dt,
                        })
                        let temp4 = this.state.orders;
                        res.data.orders.map(v => {
                            temp4.push(
                                {
                                    id: v.id,
                                    client: v.client,
                                    org: v.org,
                                    des: v.des,
                                    iscurrent: v.iscurrent,
                                }
                            )
                        })
                        this.setState({
                            grand_sums: temp,
                            datas: temp2,
                            filtered: temp2,
                            msg_status: msg_status,
                            details: temp3,
                            eid: res.data.eid,
                            orders: temp4,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.driver.toUpperCase().includes(value.toUpperCase()) ||
                    v.vehicle.toUpperCase().includes(value.toUpperCase()) ||
                    v.route.toUpperCase().includes(value.toUpperCase()) ||
                    v.status.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    filter = (isfilter) => {
        //-1 is all item to be displayed
        let temp = this.state.sums;
        if (isfilter) {
            let fi = -1;
            console.log(this.state.sums)
            this.state.sums.filter(v => {
                if (
                    (v.st_time === this.state.allocation || v.ed_time === this.state.allocation) ||
                    (v.st_time === this.state.arivalatloading || v.ed_time === this.state.arivalatloading) ||
                    (v.st_time === this.state.loading || v.ed_time === this.state.loading) ||
                    (v.st_time === this.state.departure || v.ed_time === this.state.departure) ||
                    (v.st_time === this.state.arivalatunloading || v.ed_time === this.state.arivalatunloading) ||
                    (v.st_time === this.state.unloading || v.ed_time === this.state.unloading) ||
                    (v.st_time === this.state.finished || v.ed_time === this.state.finished)
                ) {
                    if (v.c > fi)
                        fi = v.c
                }
            })
            temp = this.state.sums.filter(v => {
                console.log(v.c)
                console.log(fi)
                if (v.c >= fi)
                    return v;
            })
        }
        this.setState({
            filter_sums: temp,
        })
    }
    btn_manage = (event, id) => {
        event.preventDefault();
        let temp = this.state.grand_sums.filter(v => {
            if (v.assign_id === id)
                return v;
        })
        this.setState({
            id: id,
            isload: true,
            time: '',
            time_con: '',
            short: '',
            long: '',
            millage: '',
            msg: null,
            wiz: 2,
            filter_sums: temp
        })

    }
    btn_history = (event, id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_follow_up_hisotry;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    follow_ups: res.data.datas,
                    wiz: 3,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btn_remove = (event, id) => {
        event.preventDefault();
        let datas = this.state.datas.filter(v => {
            if (v.id === id)
                v.isfound = null;
            return v;
        })
        this.setState({
            wiz: 1,
            datas: datas,
            filtered: datas,
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.assign_date;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            allocation: this.state.allocation,
            arivalatloading: this.state.arivalatloading,
            loading: this.state.loading,
            departure: this.state.departure,
            arivalatunloading: this.state.arivalatunloading,
            unloading: this.state.unloading,
            finished: this.state.finished,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    let datas = this.state.datas;
                    datas = this.state.datas.filter(v => {
                        if (v.id === this.state.id)
                            v.isfound = null;
                        return v;
                    })
                    this.setState({
                        wiz: 1,
                        datas: datas,
                        filtered: datas,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_get_report = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_mov_for_follow_up_2_1;
        this.setState({
            isload: false,
            allocation: '',
            arivalatloading: '',
            loading: '',
            departure: '',
            arivalatunloading: '',
            unloading: '',
            finished: '',
            type: '',
            time: '',
            time_con: '',
            short: '',
            long: '',
            millage: '',
            msg: null,
            filter_sums: [],
            labels: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            dt: this.state.dt,
            eid: this.state.eid,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    eid: res.data.eid,
                    filter_sums: res.data.sums,
                    sums: res.data.sums,
                    labels: res.data.labels,
                    isload: true,
                    type: "allocation",
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filtered.map(value => {
            let btn = null;
            if (value.isfound)
                btn = <>
                    <a href="" onClick={event => this.btn_manage(event, value.id)}>Manage</a>
                    <hr/>
                    <a href="" onClick={event => this.btn_history(event, value.id)}>History</a>
                    <hr/>
                    <a href="" onClick={event => this.btn_remove(event, value.id)}>Remove</a>
                </>
            if (value.isfound != null) {
                count = count + 1;
                return (<tr>
                    <td style={{verticalAlign: "middle"}}>{count}</td>
                    <td style={{verticalAlign: "middle"}}>{btn}</td>
                    <td style={{verticalAlign: "middle"}}>{value.plate_no}</td>
                    <td style={{verticalAlign: "middle"}}>{value.type}</td>
                </tr>)
            }

        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Information Status</label>
                            <textarea rows={7} className="form-control form-control-sm" name="msg_status"
                                      value={this.state.msg_status} onChange={this.inputmgt}></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <table id="report" className="table table-head-fixed">
                            <thead>
                            <tr>
                                <th className="col-md-2">#</th>
                                <th className="col-md-2">Action</th>
                                <th className="col-md-4">Vehicle</th>
                                <th className="col-md-4">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata = this.state.filter_sums.map(v => {
            return (<tr>
                <td>{v.st_time}</td>
                <td><p title={v.st_long}>{v.st_short}</p></td>
                <td>{v.millage}</td>
                <td>{v.ed_time}</td>
                <td><p title={v.ed_long}>{v.ed_short}</p></td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header bg-fuchsia ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Reports</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <table id="report" className="table table-head-fixed table-wrap">
                                        <thead>
                                        <tr>
                                            <th className="col-md-2">Start Time</th>
                                            <th className="col-md-3">Start Location</th>
                                            <th className="col-md-2">Millage</th>
                                            <th className="col-md-2">End Time</th>
                                            <th className="col-md-3">End Location</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tdata}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer clearfix">
                                <button type="button" className="btn btn-danger float-right" onClick={event => {
                                    event.preventDefault()
                                    this.setState({
                                        wiz: 1,
                                    })
                                }}><i
                                    className="fas fa-arrow-left"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>)
    }
    wiz_3 = () => {
        let count = 0;
        let tdata = this.state.follow_ups.map(v => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td>{v.driver}</td>
                <td>{v.vehicle}</td>
                <td>{v.route}</td>
                <td>{v.clientname}</td>
                <td>{v.allocation}</td>
                <td>{v.arivalatloading}</td>
                <td>{v.loading}</td>
                <td>{v.departure}</td>
                <td>{v.arivalatunloading}</td>
                <td>{v.unloading}</td>
                <td>{v.finished}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed table-responsive">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Driver</th>
                            <th>Vehicle</th>
                            <th>Route</th>
                            <th>Company</th>
                            <th>Allocation</th>
                            <th>Arrive @ load</th>
                            <th>Loading</th>
                            <th>Departure</th>
                            <th>Arrive @ offload</th>
                            <th>Unloading</th>
                            <th>Finished</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="follow_up_wfo"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-fuchsia ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Vehicle Follow_up WFO {this.state.url}</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_wfo;
