import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Cement_so extends Component {

    constructor() {
        super();
        this.state = {
            agreement_no:'',
            data:{},
            wiz:0,
            urls:[],
            products:[],
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    get_cement = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_cement_so;
        this.setState({
            wiz: 0,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            no:this.state.agreement_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                        id: res.data.data.id,
                        order_date: res.data.data.order_date,
                        purchase_no: res.data.data.purchase_no,
                        client: res.data.data.client,
                        cargo: res.data.data.cargo,
                        so_no: res.data.data.so_no=="null"?null:res.data.data.so_no,
                        agreement_no: res.data.data.agreement_no,
                        cement_rate_s: res.data.data.cement_rate_s,
                        weight: res.data.data.weight,
                        bank_tt: res.data.data.bank_tt,
                        t_volume: res.data.data.t_volume,
                        urls:res.data.urls,
                        products:res.data.products
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_so;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            so_no: this.state.so_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 0,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let imgs=this.state.urls.map(v=>{
            return (<div className="col-md-12 text-center my-2 border">
                <img src={v.url}/>
            </div> )
        })
        let tdata=this.state.products.map(v=>{
            return (<tr>
                <td>{v.product_type}</td>
                <td>{v.volume}</td>
                <td>{v.unit_price}</td>
                <td>{v.po_no}</td>
                <td>{v.fs_no}</td>
            </tr> )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.client}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order Date</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.order_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cement Type</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sale Rate</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.cement_rate_s}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Bank TT #</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.bank_tt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.t_volume}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Agreement #</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.agreement_no}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-12 pb-3">
                                    <h4>Product Data</h4>
                                    <table className="table table-bordered table-sm">
                                        <tr>
                                            <th className="col-md-3">Product Type</th>
                                            <th className="col-md-3">Weight (q)</th>
                                            <th className="col-md-2">Unit Price</th>
                                            <th className="col-md-2">PO #</th>
                                            <th className="col-md-2">FS #</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                                {imgs}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>SO #</label>
                                        <input type="text" className="form-control form-control-sm" name="so_no" onChange={this.inputmgt}
                                               value={this.state.so_no} placeholder="SO #"/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                onClick={this.btnupdate}> Update SO
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz: 0,
                                                    })
                                                }}> Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="cement_so"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Sale Order</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Agreement #</label>
                                                        <input type="text" className="form-control form-control-sm" name="agreement_no"
                                                               value={this.state.agreement_no} onChange={this.inputmgt}
                                                               placeholder="Agreement #"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.get_cement()
                                                                }}> Get Cement
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cement_so;
