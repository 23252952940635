import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Cancel_allocate extends Component {

    constructor() {
        super();
        this.state = {
            road_routes: [],
            fuel_datas: [],
            othercost_datas: [],
            re_fund_datas: [],
            assign_data:{},

            wiz: 1,
            iscreate:true,
            given_fuel:'0',
            given_parking:'0',
            given_predium:'0',
            given_other:'0',
            remark:'',
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnget_fright = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_cancel_allocate;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no:this.state.fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {

                this.setState({
                    wiz: 2,
                    assign_data:res.data.assign_data,
                    id:res.data.assign_id,
                    fuel_datas:res.data.fuel_datas,
                    given_fuel:res.data.given_fuel,
                    given_other:res.data.given_other,
                    given_parking:res.data.given_parking,
                    given_predium:res.data.given_predium,
                    iscreate:res.data.iscreate,
                    othercost_datas:res.data.othercost_datas,
                    re_fund_datas:res.data.re_fund_datas,
                    remark:res.data.remark,
                    road_routes:res.data.road_routes,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                coc: temp[0].coc,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cancel_allocate;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            given_fuel:this.state.given_fuel,
            given_predium:this.state.given_predium,
            given_parking:this.state.given_parking,
            given_other:this.state.given_other,
            remark:this.state.remark,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btndelete = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.delete_cancel_allocate;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header bg-green ui-sortable-handle"
                                 style={{cursor: 'move'}}>
                                <h3 className="card-title">Fright Information</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Fright #</label>
                                                <input className="form-control" type="text"
                                                       name="fright_no" value={this.state.fright_no}
                                                       onChange={this.inputmgt}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer clearfix">
                                <button type="button" className="btn btn-primary float-right"
                                        onClick={this.btnget_fright}><i className="fas fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">

                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let isdisabled_customer = true;
        if (this.state.ini_selected != null)
            isdisabled_customer = false;

        let isdisabled_final = true;
        let detail = null;
        let detail_2 = null;
        if (this.state.order_selected != null) {
            isdisabled_final = false;
            detail = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.order_no}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Orgin</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.orgin}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Destination</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.destination}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Millage</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.distance}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Cargo</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.cargo_name}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Description of Good</label>
                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                  onChange={this.inputmgt} value={this.state.order_data.des ?? "NA"}></textarea>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Types of Load</label>
                        <input disabled={true} className="form-control form-control-sm text-capitalize"
                               value={this.state.order_data.tol}/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </>
            if (this.state.order_data.tol === "container") {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label># Container to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.no_container}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_1</label>
                            <Select
                                value={this.state.container_selected}
                                onChange={this.handelchange_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size"
                                   value={this.state.size}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm"
                                   name="actual_weight"
                                   value={this.state.actual_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_2</label>
                            <Select
                                value={this.state.container_2_selected}
                                onChange={this.handelchange_2_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size_2"
                                   value={this.state.size_2}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="volume_2"
                                   value={this.state.volume_2}/>
                        </div>
                    </div>
                </>
            } else {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Left Weight (q) to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.avl_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Actual Weight (q)</label>
                            <input type="text" className="form-control form-control-sm" name="actual_weight"
                                   value={this.state.actual_weight} onChange={this.inputmgt}
                                   placeholder="Weight"/>
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">

                    </div>
                </>
            }
        }

        let km = 0;
        let allowence = 0;
        let fuel = 0;
        let parking = 0;
        let other = 0;
        let tdata = this.state.road_routes.map(v => {
            let bg = "bg-white"
            if (!v.is_found)
                bg = "bg-danger"
            if (v.is_found) {
                km = km + v.km
                allowence = allowence + v.allownce
                fuel = fuel + v.fuel
                parking = parking + v.parking
                other = other + v.other
            }
            return (<tr className={bg}>
                <td>{v.route}</td>
                <td>{v.status}</td>
                <td>{v.km}</td>
                <td>{v.allownce}</td>
                <td>{v.fuel}</td>
                <td>{v.parking}</td>
                <td>{v.other}</td>
            </tr>)
        })
        let tdata2 = null;
        if (this.state.road_routes.length > 0) {
            let total = km + allowence + fuel + parking + other
            tdata2 =
                <>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{km}</td>
                        <td>{allowence}</td>
                        <td>{fuel}</td>
                        <td>{parking}</td>
                        <td>{other}</td>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total</th>
                        <th>{total}</th>
                    </tr>
                </>
        }


        let det_fuel = this.state.fuel_datas.map(v => {
            return (<>
                <div className="col-md-2 text-sm"></div>
                <div className="col-md-3 text-sm">{v.station}</div>
                <div className="col-md-3 text-sm">{v.fuel_rate}</div>
                <div className="col-md-2 text-sm">{(v.total_fuel_l * 1).toFixed(2)}</div>
                <div className="col-md-2 text-sm">{(v.total_fuel_etb * 1).toFixed(2)}</div>
            </>)
        })

        let total_fuel_l = 0
        let total_fuel_etb = 0
        this.state.fuel_datas.map(v => {
            total_fuel_l = ((total_fuel_l * 1) + (v.total_fuel_l * 1)).toFixed(2)
            total_fuel_etb = ((total_fuel_etb * 1) + (v.total_fuel_etb * 1)).toFixed(2)
        })

        let det_other = this.state.othercost_datas.map(v => {
            return (<>
                <div className="col-md-2 text-sm"></div>
                <div className="col-md-5 text-sm">{v.othercost}</div>
                <div className="col-md-5 text-sm">{(v.cost * 1).toFixed(2)}</div>
            </>)
        })
        let total_other_cost = 0
        this.state.othercost_datas.map(v => {
            total_other_cost = ((total_other_cost * 1) + (v.cost * 1) * 1).toFixed(2)
        })

        let det_re_fund = this.state.re_fund_datas.map(v => {
            return (<>
                <div className="col-md-2 text-sm"></div>
                <div className="col-md-5 text-sm">{v.re_fund_reason}</div>
                <div className="col-md-5 text-sm">{(v.re_fund_cost * 1).toFixed(2)}</div>
            </>)
        })
        let total_re_fund_cost = 0
        this.state.re_fund_datas.map(v => {
            total_re_fund_cost = ((total_re_fund_cost * 1) + (v.re_fund_cost * 1) * 1).toFixed(2)
        })

        let expected_total_fuel_l = 0
        this.state.road_routes.map(v => {
            if (!isNaN(v.fuel))
                expected_total_fuel_l = (expected_total_fuel_l + v.fuel) * 1
        })

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cancel Assign</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.assign_data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.assign_data.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.assign_data.trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.assign_data.fright_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.assign_data.start_loc}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.assign_data.order}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.assign_data.end_loc}/>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-2">
                                    <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Fuel Data </strong>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-3 border"><strong>Station</strong></div>
                                        <div className="col-md-3 border"><strong>Fuel Rate</strong></div>
                                        <div className="col-md-2 border"><strong>Total Fuel(l)</strong></div>
                                        <div className="col-md-2 border"><strong>Total Fuel(ETB)</strong></div>
                                        {det_fuel}
                                        <div className="col-md-12 pt-3"><strong>Total
                                            Fuel(ETB): </strong>{total_fuel_etb}</div>
                                        <div className="col-md-12 pt-3"><strong>Total Fuel(L): </strong>{total_fuel_l}
                                        </div>
                                        <div className="col-md-12 pt-3"><strong>Expected
                                            Fuel(L): </strong>{expected_total_fuel_l}</div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-2">
                                    <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Other Cost </strong>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5 border"><strong>Other Cost</strong></div>
                                        <div className="col-md-5 border"><strong>Cost</strong></div>
                                        {det_other}
                                        <div className="col-md-12 pt-3"><strong>Total Other
                                            Cost(ETB): </strong>{total_other_cost}</div>

                                    </div>
                                </div>
                                <div className="col-md-6 pb-2">
                                </div>
                                <div className="col-md-6 pb-2">
                                    <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Re-fund </strong>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5 border"><strong>Re-fund Reason</strong></div>
                                        <div className="col-md-5 border"><strong>Cost</strong></div>
                                        {det_re_fund}
                                        <div className="col-md-12 pt-3"><strong>Total Refund
                                            Cost(ETB): </strong>{total_re_fund_cost}</div>

                                    </div>
                                </div>
                                {detail}
                                {detail_2}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Given Fuel (l)</label>
                                        <input type="text" className="form-control form-control-sm"
                                               onChange={this.inputmgt}
                                               name="given_fuel"
                                               value={this.state.given_fuel}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Given Pre-diem (ETB)</label>
                                        <input type="text" className="form-control form-control-sm"
                                               onChange={this.inputmgt}
                                               name="given_predium"
                                               value={this.state.given_predium}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Given Handling fee (ETB)</label>
                                        <input type="text" className="form-control form-control-sm"
                                               onChange={this.inputmgt}
                                               name="given_parking"
                                               value={this.state.given_parking}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Given Other Cost</label>
                                        <input type="text" className="form-control form-control-sm"
                                               onChange={this.inputmgt}
                                               name="given_other"
                                               value={this.state.given_other}/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={3} className="form-control form-control-sm"
                                                  onChange={this.inputmgt}
                                                  name="remark"
                                                  value={this.state.remark}/>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4"></div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btncreate}> Create
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-warning btn-sm"
                                                onClick={this.btndelete}> Delete
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-danger ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Mewecha Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr>
                                            <th className="col-md-2">Route</th>
                                            <th className="col-md-2">Status</th>
                                            <th className="col-md-1">KM</th>
                                            <th className="col-md-2">Allowance</th>
                                            <th className="col-md-2">Fuel</th>
                                            <th className="col-md-2">Parking</th>
                                            <th className="col-md-2">Others</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tdata}
                                        {tdata2}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="cancel_allocate"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cancel Allcoation</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cancel_allocate;
