import {Component} from 'react';
import axios from "axios"
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import myconfig from "../../myconfig";
import nt from "../../img/ntlog.png";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { StickyContainer, Sticky } from 'react-sticky';
import Select from "react-select";
import style2 from "../../style2.css"

let source = axios.CancelToken.source();

class Fright_document extends Component {
    constructor() {
        super();
        this.state = {
            wiz: 0,
            data: {}
        };
    }

    componentDidMount() {
        this.fetchdata()
    }

    fetchdata = () => {
        let fright_no = new URLSearchParams(this.props.location.search).get("fright_no");
        let type = new URLSearchParams(this.props.location.search).get("type");
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_fright_for_fright_document;
        if(type==="os")
            baseurl=myconfig.fright_document_sub_contractor
        else if(type==="su")
            baseurl=myconfig.fright_document_suppliers
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no:fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(res.data.data==null){
                    this.setState({
                        data: {},
                        wiz:2,
                    })
                }
                else
                {
                    this.setState({
                        data: res.data.data,
                        wiz:1,
                    })
                }

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    wiz_0 = () => {
        return (
            <div style={{minHeight: "100vh"}}>
                <p>Loading...</p>
            </div>
        )
    }
    wiz_1 = () => {
        return (
            <>
                <br/>
                <br/>
                <br/>
                <div className="row no-print">
                    <a href="" onClick={event => {
                        event.preventDefault();
                        window.print()
                    }}>Print</a>
                </div>
                <div className="row" id="section_to_print_3">
                    <div className="col-2 d-flex align-items-center justify-content-center" style={{
                        width: "100%",
                        borderWidth: "1px 0px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <img className="img w-75" src={nt}></img>
                    </div>
                    <div className="col-8" style={{
                        width: "100%",
                        borderWidth: "1px 0px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <p>Company Name:</p>
                        <h4 className="text-center">National Transport PLC Cross Boarder Level 1 Freight Private
                            Transport</h4>
                        <p className="text-left  p-0 m-0"><strong>Tel:</strong> +251 11 440 1495, +251-11 440 4134</p>
                        <p className="text-left p-0 m-0"><strong>Fax:</strong> +251 11 440 4137, +251-11 440 4145,+251-11
                            442 1890,+251-11 442 1890</p>
                        <p className="text-left  p-0 m-0"><strong>Email:</strong> nationaltransprotplceth@gmail.com</p>
                        <p className="text-left  p-0 m-0"><strong>P.O Box:</strong> 1793 Addis Ababa, Ethiopia</p>
                    </div>
                    <div className="col-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                    </div>


                    <div className="col-2" style={{
                        width: "100%",
                        borderWidth: "1px 0px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                    </div>
                    <div className="col-8" style={{
                        width: "100%",
                        borderWidth: "1px 0px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <p>Document Title:</p>
                        <br/>
                        <h5 className="text-center">የጭነት ማዘዣ ውል </h5>
                        <h5 className="text-center">FREIGHT ORDER AGREEMENT</h5>
                        <br/>
                    </div>
                    <div className="col-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                    </div>

                    <div className="col-2 py-3 text-right">
                        <strong>VAT Registeration No:</strong>
                    </div>
                    <div className="col-1 py-3">
                        000055242634
                    </div>

                    <div className="col-2  py-3 text-right">
                        <strong>Date:</strong>
                    </div>
                    <div className="col-1 py-3">
                        {this.state.data.dt}
                    </div>
                    <div className="col-2 py-3 text-right">
                        <strong>Time:</strong>
                    </div>
                    <div className="col-1 py-3">
                        {this.state.data.ti}
                    </div>
                    <div className="col-2 py-3 text-right">
                        <strong>VAT:</strong>
                    </div>
                    <div className="col-1 py-3">
                    </div>

                    <div className="col-12 py-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-2">የሹፌር ስም</div>
                            <div className="col-2">የሰሌዳ ቁጥር</div>
                            <div className="col-2">የተሳቢ ቁጥር</div>
                            <div className="col-2">የጎን ቁጥር</div>
                            <div className="col-2">የመጫን አቅም</div>
                            <div className="col-2">የጭነት መዝገብ ቁጥር</div>
                            <div className="col-2"><strong>Driver Name:</strong> {this.state.data.driver_name}</div>
                            <div className="col-2"><strong>Truck No:</strong> {this.state.data.plate_no}</div>
                            <div className="col-2"><strong>Trailer No:</strong> {this.state.data.trailer_no}</div>
                            <div className="col-2"><strong>Fleet No:</strong></div>
                            <div className="col-2"><strong>Capacity:</strong> 40</div>
                            <div className="col-2"><strong>Freight Reg No.</strong> {this.state.data.fright_no}</div>
                        </div>
                    </div>

                    <div className="col-12 py-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-6" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6">የአስጫኝ ስም</div>
                                    <div className="col-6">ስልክ ቁጥር</div>
                                    <div className="col-6"><strong>Shipper Name:</strong> {this.state.data.shipper_name}</div>
                                    <div className="col-6"><strong>Phone #:</strong>{this.state.data.phone_no}</div>
                                    <div className="col-6 pt-3">አድራሻ</div>
                                    <div className="col-6"></div>
                                    <div className="col-6"><strong>Address:</strong> {this.state.data.address   }</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">የተረካቢ ስም</div>
                                    <div className="col-6">ስልክ ቁጥር</div>
                                    <div className="col-6"><strong>Consignee's Name:</strong>{this.state.data.client_name}</div>
                                    <div className="col-6"><strong>Phone #:</strong>{this.state.data.client_phone}</div>
                                    <div className="col-6  pt-3">አድራሻ</div>
                                    <div className="col-6"></div>

                                    <div className="col-6"><strong>Address:</strong> {this.state.data.client_loc}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 py-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-3">የስምሪት መነሻ ከተማ</div>
                            <div className="col-3" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>የስምሪት መድረሻ ከተማ
                            </div>
                            <div className="col-3">የጭነት መነሻ ከተማ</div>
                            <div className="col-3">የጭነት መድረሻ ከተማ</div>

                            <div className="col-3"><strong>Empty Origin:</strong>{this.state.data.empty_org}</div>
                            <div className="col-3" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}><strong>Empty Destination:</strong>{this.state.data.empty_des}
                            </div>
                            <div className="col-3"><strong>Loaded Origin:</strong> {this.state.data.load_org}</div>
                            <div className="col-3"><strong>Loaded Destination:</strong>{this.state.data.load_des}</div>
                        </div>
                    </div>

                    <div className="col-12  py-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-3">የጭነት ዓይነት</div>
                            <div className="col-3">በኩንታል</div>
                            <div className="col-2">ኪ.ሜ</div>
                            <div className="col-2">ታሪፍ</div>
                            <div className="col-2">የሚሰበሰብ ብር</div>
                            <div className="col-3"><strong>Commodity:</strong> {this.state.data.cargo_name}</div>
                            <div className="col-3"><strong>Quintal:</strong> {this.state.data.volume}</div>
                            <div className="col-2"><strong>K.M:</strong> {this.state.data.distance}</div>
                            <div className="col-2"><strong>Tariff:</strong>{this.state.data.tariff}</div>
                            <div className="col-2"><strong>Freight Charged:</strong>{this.state.data.fright_charged}</div>
                        </div>
                    </div>

                    <div className="col-12  py-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>ወደ አስጫኝ የደረሰበት
                            </div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>ጭኖ የጨረሰበት
                            </div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>ጉሙሩክ የደረሰበት
                            </div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>ጉሙሩክ የጨረሰበት
                            </div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>ወደ ተረካቢ የደረሰበት
                            </div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>አራግፎ የጨረሰበት
                            </div>

                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}><strong>Arrival at Shipper</strong></div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}><strong>Loading Completed</strong></div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}><strong>Arrival at Customs</strong></div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}><strong>Customs Completed </strong></div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}><strong>Arrival at Customer </strong></div>
                            <div className="col-2 text-center" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}><strong>Unloading Completed </strong></div>

                            <div className="col-2" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6">ቀን</div>
                                    <div className="col-6">ሰአት</div>
                                    <div className="col-6 pb-4"><strong>Date</strong></div>
                                    <div className="col-6 pb-4"><strong>Time</strong></div>
                                </div>
                            </div>
                            <div className="col-2" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6">ቀን</div>
                                    <div className="col-6">ሰአት</div>
                                    <div className="col-6 pb-4"><strong>Date</strong></div>
                                    <div className="col-6 pb-4"><strong>Time</strong></div>
                                </div>
                            </div>
                            <div className="col-2" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6">ቀን</div>
                                    <div className="col-6">ሰአት</div>
                                    <div className="col-6 pb-4"><strong>Date</strong></div>
                                    <div className="col-6 pb-4"><strong>Time</strong></div>
                                </div>
                            </div>
                            <div className="col-2" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6">ቀን</div>
                                    <div className="col-6">ሰአት</div>
                                    <div className="col-6 pb-4"><strong>Date</strong></div>
                                    <div className="col-6 pb-4"><strong>Time</strong></div>
                                </div>
                            </div>
                            <div className="col-2" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6">ቀን</div>
                                    <div className="col-6">ሰአት</div>
                                    <div className="col-6 pb-4"><strong>Date</strong></div>
                                    <div className="col-6 pb-4"><strong>Time</strong></div>
                                </div>
                            </div>
                            <div className="col-2" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6">ቀን</div>
                                    <div className="col-6">ሰአት</div>
                                    <div className="col-6 pb-4"><strong>Date</strong></div>
                                    <div className="col-6 pb-4"><strong>Time</strong></div>
                                </div>
                            </div>

                            <div className="col-12" style={{
                                width: "100%",
                                borderWidth: "1px 1px 1px 1px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-6" style={{
                                        width: "100%",
                                        borderWidth: "0px 1px 0px 0px",
                                        borderStyle: "ridge",
                                        borderColor: "black"
                                    }}>
                                        <div className="row">
                                            <div className="col-12"><strong>አስጫኝ /Shipper</strong></div>
                                            <div className="col-4">ስም</div>
                                            <div className="col-4">ፊርማ</div>
                                            <div className="col-4">ማህተም</div>
                                            <div className="col-4 pb-3"><strong>Name</strong> National Transport</div>
                                            <div className="col-4 pb-3"><strong>Signature</strong></div>
                                            <div className="col-4 pb-3"><strong>Stamp</strong></div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-12"><strong>ተረካቢ /Consignee</strong></div>
                                            <div className="col-4">ስም</div>
                                            <div className="col-4">ፊርማ</div>
                                            <div className="col-4">ማህተም</div>
                                            <div className="col-4 pb-3"><strong>Name</strong></div>
                                            <div className="col-4 pb-3"><strong>Signature</strong></div>
                                            <div className="col-4 pb-3"><strong>Stamp</strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-12  py-2" style={{
                        width: "100%",
                        borderWidth: "0px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-3" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-12">የባለንብረት ስምና ፊርማ</div>
                                    <div className="col-12"><strong>Truck Owner Name & Sig.</strong></div>
                                    <div className="col-12 pb-4" style={{
                                        width: "100%",
                                        borderWidth: "0px 0px 1px 0px",
                                        borderStyle: "ridge",
                                        borderColor: "black"
                                    }}>National Transporter
                                    </div>
                                    <div className="col-12">የሹፌር ስምና ፊርማ</div>
                                    <div className="col-12"><strong>Driver Name & Sig.</strong></div>
                                    <div className="col-12 pb-4">{this.state.data.driver_name}</div>
                                </div>
                            </div>
                            <div className="col-3" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-12">የኮሚሽን ደረሰኝ ቁጥር</div>
                                    <div className="col-12"><strong>Commission Recite No.</strong></div>
                                    <div className="col-12 pb-4" style={{
                                        width: "100%",
                                        borderWidth: "0px 0px 1px 0px",
                                        borderStyle: "ridge",
                                        borderColor: "black"
                                    }}><br/></div>
                                </div>
                            </div>
                            <div className="col-3" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-12">መግለጫ</div>
                                    <div className="col-12"><strong>Remark</strong></div>
                                    <div className="col-12 pb-4"></div>
                                </div>
                            </div>
                            <div className="col-3" style={{
                                width: "100%",
                                borderWidth: "0px 1px 0px 0px",
                                borderStyle: "ridge",
                                borderColor: "black"
                            }}>
                                <div className="row">
                                    <div className="col-12 text-center" style={{
                                        width: "100%",
                                        borderWidth: "0px 0px 1px 0px",
                                        borderStyle: "ridge",
                                        borderColor: "black"
                                    }}><strong>ልዩነት/Discrepancy</strong></div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6 text-center" style={{
                                                borderWidth: "0px 1px 0px 0px",
                                                borderStyle: "ridge",
                                                borderColor: "black"
                                            }}>በመብለጥ
                                            </div>
                                            <div className="col-6 text-center">በማነስ</div>
                                            <div className="col-6 text-center" style={{
                                                borderWidth: "0px 1px 1px 0px",
                                                borderStyle: "ridge",
                                                borderColor: "black"
                                            }}><strong>Overage</strong></div>
                                            <div className="col-6 text-center" style={{
                                                borderWidth: "0px 0px 1px 0px",
                                                borderStyle: "ridge",
                                                borderColor: "black"
                                            }}><strong>Shortage</strong></div>
                                            <div className="col-6 text-center" style={{
                                                borderWidth: "0px 1px 1px 0px",
                                                borderStyle: "ridge",
                                                borderColor: "black"
                                            }}><br/><br/><br/><br/></div>
                                            <div className="col-6 text-center" style={{
                                                borderWidth: "0px 0px 1px 0px",
                                                borderStyle: "ridge",
                                                borderColor: "black"
                                            }}><br/><br/><br/><br/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12  py-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-4">የስምሪት ፀሃፊ ስም</div>
                            <div className="col-4">ፊርማ</div>
                            <div className="col-4">ስምሪቱ የተጠናቀቀበት ቀንና ሰዓት</div>
                            <div className="col-4"><strong>Dispatcher Name</strong></div>
                            <div className="col-4"><strong>Signature</strong></div>
                            <div className="col-4"><strong>Dispatched Date & Time</strong></div>
                            <div className="col-4"><strong>{this.state.data.pre_by}</strong></div>
                            <div className="col-4"><strong><br/></strong></div>
                            <div className="col-4"><strong><br/></strong></div>
                        </div>
                    </div>
                    <div className="col-12  py-2" style={{
                        width: "100%",
                        borderWidth: "1px 1px 1px 1px",
                        borderStyle: "ridge",
                        borderColor: "black"
                    }}>
                        <div className="row">
                            <div className="col-2">ስርጨት</div>
                            <div className="col-2">ዋናው ለባለንብረት</div>
                            <div className="col-2">1ኛ ኮፒ - ለሂሳብ ክፍል</div>
                            <div className="col-2">2ኛ ኮፒ - ለአስጫኝ</div>
                            <div className="col-2">3ኛ ኮፒ - ለተረካቢ</div>
                            <div className="col-2">4ኛ ኮፒ - ለጥራዝ</div>

                            <div className="col-2">Distribution</div>
                            <div className="col-2">Original - Consigner</div>
                            <div className="col-2">1<sup>st</sup> Copy - Accounts</div>
                            <div className="col-2">2<sup>nd</sup> Copy - Shipper</div>
                            <div className="col-2">3<sup>rd</sup> Copy - Consigner</div>
                            <div className="col-2">4<sup>rd</sup> Copy - Pad</div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
    wiz_2 = () => {
        return (
            <div style={{minHeight: "100vh"}}>
                <p>No freight order found.</p>
            </div>
        )
    }

    render() {
        let dis = null;
        if (this.state.wiz === 0)
            dis = this.wiz_0()
        else if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <div className="container-fluid">
                    {dis}
                </div>
            </>
        )
    }
}

export default Fright_document;
