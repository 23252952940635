import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Order_detail extends Component {

    constructor() {
        super();
        this.state = {
            is_exe:false,
            datas: [],
            details:[],
            data: {},
            urls: [],
            datas_q:[],
            datas_no_truck:[],
            len:0,
            display: false,
            order_no:'',

        }
    }

    componentDidMount() {
        let order_no = new URLSearchParams(this.props.location.search).get("order_no");
        console.log(order_no)
        if (order_no!=null){
            this.setState({
                order_no:order_no,
            },()=>this.get_order())
        }
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    get_order = () => {
        this.setState({
            display: false,
            data: {},
            urls: [],
            datas_q:[],
            datas_no_truck:[],
        }, () => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.order_detail;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                order_no: this.state.order_no
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        display: true,
                        data: res.data.data,
                        urls: res.data.urls,
                        details: res.data.details,
                        datas_q: res.data.datas_q,
                        datas_no_truck: res.data.datas_no_truck,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })
    }
    wiz_2 = () => {
        let len=this.state.len;
        let temp=[];
        for(let i=1; i<=len;i++){
            let va="img_"+i.toString();
            temp.push({
                state_name:va
            })
        }
        let imgs=this.state.urls.map(v=>{
            return (<div className="col-md-11 text-center">
                <img src={v.url}/>
            </div> )
        })
        let count=0;
        let tdata=this.state.details.map(v=>{
            count=count+1;
            let bg="bg-success";
            if (v.t_type==="SUB-CONTRACTOR")
                bg="bg-primary"
            else if (v.t_type==="SUPPLIER")
                bg="bg-warning"
            return (<tr className={bg}>
                <td>{count}</td>
                <td>{v.fo_date}</td>
                <td>{v.fright_no}</td>
                <td>{v.t_name}</td>
                <td>{v.t_type}</td>
                <td>{v.plate_no}</td>
                <td>{v.driver_name}</td>
                <td>{v.tra}</td>
                <td>{v.volume}</td>
            </tr>)
        })

        count=0;
        let tdata_2 = this.state.datas_q.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.order_no}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.t_weight}</td>
                <td>{value.total_loaded}</td>
                <td>{value.left_over_order_q}</td>
                <td>{value.l_own}</td>
                <td>{value.l_sub}</td>
            </tr>)
        })

        count=0;
        let tdata_3 = this.state.datas_no_truck.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.order_no}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.t_weight}</td>
                <td>{value.total_loaded}</td>
                <td>{value.left_over_order_q}</td>
                <td>{value.l_own}</td>
                <td>{value.l_sub}</td>
            </tr>)
        })
        return (<>
            <div className="card">
                <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                    <h3 className="card-title">Order Information</h3>
                    <div className="card-tools">
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Order #</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.order_no}/>
                            </div>
                        </div>
                        <div className="col-md-9">

                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Company Name</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.client}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Origin</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.org}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Destination</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.des}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Millage</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.distance}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Description of Good</label>
                                <textarea rows={5} className="form-control form-control-sm"
                                          onChange={this.inputmgt} readOnly={true}>{this.state.data.des_good}</textarea>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Cargo</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.cargo}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Modal Of Transport</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.mode_of_transport}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>PO #</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.purchase_no}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Start Time</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.st_date}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>End Date</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.ed_date}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Transport Rate</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.transport_rate}/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Weight (q)</label>
                                <input readOnly={true}
                                       className="form-control form-control-sm"
                                       value={this.state.data.weight}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                        {imgs}
                        <div className="col-md-12">
                            <h4>Order Summary by Quintal</h4>
                            <table className="table table-head-fixed table-bordered table-responsive">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Order #</th>
                                    <th>Client</th>
                                    <th>Origin</th>
                                    <th>Destination</th>
                                    <th>Weight (q)</th>
                                    <th>Total Loaded (q)</th>
                                    <th>Left Over (q)</th>
                                    <th>Loaded by Own Truck (q)</th>
                                    <th>Loaded by Outsource (q)</th>
                                </tr>
                                </thead>
                                <tbody>{tdata_2}</tbody>
                            </table>
                        </div>
                        <div className="col-md-12">
                            <h4>Order Summary by Number of Vehicles</h4>
                            <table className="table table-head-fixed table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Order #</th>
                                    <th>Client</th>
                                    <th>Origin</th>
                                    <th>Destination</th>
                                    <th># of Trucks</th>
                                    <th>Total Loaded (Truck)</th>
                                    <th>Left Over (Truck)</th>
                                    <th>Loaded by Own Truck (Truck)</th>
                                    <th>Loaded by Outsource (Truck)</th>
                                </tr>
                                </thead>
                                <tbody>{tdata_3}</tbody>
                            </table>
                        </div>
                        <div className="col-md-12">
                            <h4>Order Detail</h4>
                            <table className="table table-head-fixed table-bordered">
                                <thead>
                                <tr>
                                    <th className="col-md-1">#</th>
                                    <th className="col-md-1">FO DATE</th>
                                    <th className="col-md-1">FO #</th>
                                    <th className="col-md-2">TRANSPORTER NAME</th>
                                    <th className="col-md-2">TRANSPORTER TYPE</th>
                                    <th className="col-md-1">TRUCK #</th>
                                    <th className="col-md-1">DRIVER NAME</th>
                                    <th className="col-md-1">TRAILER #</th>
                                    <th className="col-md-2">LOADED WEIGHT</th>
                                </tr>
                                </thead>
                                <tbody>{tdata}</tbody>
                            </table>
                        </div>
                        <div className="col-md-3 my-3">
                            <div className="form-group">
                                <button className="btn btn-block btn-outline-danger btn-sm"
                                        onClick={event => {
                                            event.preventDefault()
                                            this.setState({
                                                display:false,
                                            })
                                        }}> Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.display)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="order_detail"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Order Data</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Fright #</label>
                                                        <input type="text" className="form-control form-control-sm" name="order_no"
                                                               value={this.state.order_no} onChange={this.inputmgt}
                                                               placeholder="Order #"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.get_order()
                                                                }}> Get Order
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_detail;
