import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import {Modal} from "react-bootstrap";
import nt from "../../img/ntlog.png";
import style from "../../style2.css"


let source = axios.CancelToken.source();

class Cement_open extends Component {

    constructor() {
        super();
        this.state = {
            no_img:1,
            imgs:[],

            order_date:new Date().toISOString().slice(0, 10),
            purchase_no:'',
            cement_type:'',
            weight:'',
            cement_rate_b:'',
            cement_rate_s:'',
            bank_tt:'',
            fs_no:'',
            so_no:'',
            agreement_no:'',

            is_exe:false,
            btn_text:"Create",

            datas: [],
            txtsearch: '',

            clients: [],
            clients_main: [],
            cargos: [],

            wiz: 1,
            is_create:true,
            client_selected: null,
            cargo_selected: null,
            iscreate: true,
            id: -1,
            modal_type: 1,

            list_purchaes: [],
            products: [],
            purchaes: [],
            purchaes_selected: null,

            data:{},
        }
    }

    onfilechange = (event,id) => {
        event.preventDefault()
        if(event.target.files[0]!==undefined){
            let isfound=false;
            let imgs = this.state.imgs.filter(v=>{
                if(v.name==="img_" + id){
                    isfound=true;
                    v.img=event.target.files[0]
                }
                return v;
            })
            if(!isfound)
                imgs.push({
                    name: "img_" + id,
                    img: event.target.files[0],
                })
            this.setState({
                imgs: imgs,
            });
        }

    }

    handelchange_client = (client_selected) => {
        this.setState({
            client_selected:client_selected,
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            is_sis: -1,
        },()=>{
            let temp=this.state.clients_main.filter(v=>{
                console.log(client_selected.value)
                console.log(v.id)
                if(v.id===client_selected.value)
                    return v;
            })
            if(temp.length>0) {
                this.setState({
                    company_name: temp[0].company_name==null?"NA":temp[0].company_name,
                    client_type: temp[0].type==null?"NA":temp[0].type,
                    contact_person: temp[0].contact_person==null?"NA":temp[0].contact_person,
                    tin: temp[0].tin==null?"NA":temp[0].tin,
                    city: temp[0].city==null?"NA":temp[0].city,
                    address: temp[0].address==null?"NA":temp[0].address,
                    phone: temp[0].phone==null?"NA":temp[0].phone,
                    email: temp[0].email==null?"NA":temp[0].email,
                    is_sis: temp[0].is_sis==null?"NA":temp[0].is_sis,
                })
            }
        })
    }
    handelchange_cargo = (cargo_selected) => {
        this.setState({
            cargo_selected:cargo_selected,
        })
    }
    handelchange_purchaes = (purchaes_selected) => {
        let temp=this.state.purchaes.filter(v=>{
            console.log(v.value)
            console.log(purchaes_selected.value)
            if(v.value===purchaes_selected.value)
                return v;
        })
        console.log(temp)
        if(temp.length>0){
            this.setState({
                purchaes_id:purchaes_selected.value,
                purchaes_selected:purchaes_selected,
                label:temp[0].label,
                p_fs_no:temp[0].fs_no,
                p_po_no:temp[0].po_no,
                p_product_type:temp[0].product_type,
                p_unit_price:temp[0].unit_price,
                p_volume:temp[0].avl_volume,
            })
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl =myconfig.get_open_cement_for_cement_open;
        this.setState({
            datas: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    clients: res.data.clients,
                    clients_main: res.data.clients_main,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    add_product = (event) => {
        event.preventDefault()
        if (this.state.cement_type !== "ppc" && this.state.cement_type !== "opc") {
            Swal.fire({
                title: 'Error!',
                text: "Select Cement type first",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_product_for_cement_open;
            this.setState({
                datas: [],
                txtsearch: '',
            })
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                product_type: this.state.cement_type,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        cushow: true,
                        modal_type: 1,
                        sale_volume:'',
                        p_fs_no:'',
                        p_po_no:'',
                        p_product_type:'',
                        p_unit_price:'',
                        p_volume:'',
                        purchaes: res.data.purchaes,
                        purchaes_selected: null
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });

        }
    }
    add_product_to_list = (event) => {
        event.preventDefault()
        if (this.state.purchaes_selected==null) {
            Swal.fire({
                title: 'Error!',
                text: "Select product",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (isNaN(this.state.sale_volume) ||this.state.sale_volume==="" ) {
            Swal.fire({
                title: 'Error!',
                text: "Sale weight must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let purchase=this.state.purchaes.filter(v=>{
                if(v.value===this.state.purchaes_selected.value)
                    return v;
            })
            let sale_volume=this.state.sale_volume*1;
            let volume=purchase[0].volume*1;
            if(sale_volume<=0){
                Swal.fire({
                    title: 'Error!',
                    text: "Sale weight must be greater than zero",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else if(sale_volume>volume){
                Swal.fire({
                    title: 'Error!',
                    text: "Sale weight can not exceed more than available weight",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else
            {
                let temp=this.state.list_purchaes;
                let id=temp.length+1;
                temp.push({
                    id:id,
                    purchase_id:purchase[0].value,
                    label:purchase[0].label,
                    product_type:purchase[0].product_type,
                    volume:purchase[0].volume,
                    sale_volume:this.state.sale_volume,
                    po_no:purchase[0].po_no,
                    unit_price:purchase[0].unit_price,
                    fs_no:purchase[0].fs_no,
                })
                this.setState({
                    cushow:false,
                    list_purchaes:temp,
                })
            }
        }
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.company_name.toUpperCase().includes(value.toUpperCase()
                    )
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_cement_type = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            list_purchaes: [],
            purchaes: [],
            purchaes_selected: null,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            no_img:1,
            is_create:true,
            imgs:[],
            order_date:new Date().toISOString().slice(0, 10),
            purchase_no:'',
            cement_type:'',
            weight:'',
            cement_rate_b:'',
            cement_rate_s:'',
            so_no:'',
            agreement_no:'',
            bank_tt:'',
            fs_no:'',
            wiz: 2,
            client_selected: null,
            cargo_selected: null,
            iscreate: true,
            id: -1,
        })
    }
    btnshow = (event,id) => {
        event.preventDefault();
        this.getorder(id,4)
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_eidt_for_cement_open;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    is_create:false,
                    wiz: 2,
                    btn_text:"Edit",
                    id: id,
                    client_selected:res.data.data.client_selected,
                    client_type:res.data.data.client.client_type,
                    company_name:res.data.data.client.company_name,
                    contact_person:res.data.data.client.contact_person,
                    tin:res.data.data.client.tin,
                    city:res.data.data.client.city,
                    address:res.data.data.client.address,
                    phone:res.data.data.client.phone,
                    email:res.data.data.client.email,
                    order_date: res.data.data.order_date,
                    client: res.data.data.client,
                    cargo: res.data.data.cargo,
                    cement_type: res.data.data.cement_type,
                    cement_rate_s: res.data.data.cement_rate_s,
                    so_no: res.data.data.so_no,
                    agreement_no: res.data.data.agreement_no,
                    bank_tt: res.data.data.bank_tt,
                    urls:res.data.urls,
                    list_purchaes:res.data.products
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btnremoveattachment = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_image_for_remove_for_cement_open;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 5,
                    urls:res.data.urls,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btnaddattachment = (event,id) => {
        event.preventDefault();
        this.setState({
            is_create: false,
            id:id,
            wiz: 4,
        });
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            client_selected: null,
            iscreate: true,
            id:-1,
            des:'',
            weight:'',
            mode_of_transport:'',
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            tol: '-1',
            container_no:'',
            volume:'',
            transport_rate:'',
            st_date:'',
            ed_date:'',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if (!this.state.is_exe) {
            if (this.state.client_selected === null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select client.",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else if (this.state.is_sis === 1) {
                Swal.fire({
                    title: 'Selected client are sister company. The order will be approved automatically. Are you sure you want to open the order? You can not undone the operation',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.create()
                    }
                })
            }
            else {
                this.create();
            }
        }
    }
    create=()=>{
        this.setState({
            is_exe: true,
            btn_text: "Working...",
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_open;
        const formData = new FormData();
        formData.append(
            "sid", window.localStorage.getItem("logmgtsys_sid")
        );
        formData.append(
            "client_id", this.state.client_selected.value
        );
        formData.append(
            "order_date", this.state.order_date
        );
        formData.append(
            "purchase_no", this.state.purchase_no
        );
        formData.append(
            "cement_type", this.state.cement_type
        );
        formData.append(
            "weight", this.state.weight
        );
        formData.append(
            "cement_rate_b", this.state.cement_rate_b
        );
        formData.append(
            "cement_rate_s", this.state.cement_rate_s
        );
        formData.append(
            "so_no", this.state.so_no
        );
        formData.append(
            "agreement_no", this.state.agreement_no
        );
        formData.append(
            "bank_tt", this.state.bank_tt
        );
        formData.append(
            "fs_no", this.state.fs_no
        );
        /*    for (var i = 0; i < this.state.list_purchaes.length; i++) {
                formData.append('list_purchaes[]', [i]);
            }*/
        formData.append(
            "list_purchaes", JSON.stringify(this.state.list_purchaes)
        );

        let len = this.state.imgs.length
        formData.append(
            "img_length",
            len,
        );
        for (let i = 1; i <= len; i++) {
            formData.append(
                "img_" + i.toString(),
                this.state.imgs[i - 1].img,
            );
        }
        axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    is_exe: false,
                    btn_text: "Create",
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                        is_exe: false,
                        btn_text: "Create",
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnupdate = (event) => {
        event.preventDefault();
        if (!this.state.is_exe) {
            if (this.state.client_selected === null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select client.",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else if (this.state.is_sis === 1) {
                Swal.fire({
                    title: 'Selected client are sister company. The order will be approved automatically. Are you sure you want to open the order? You can not undone the operation',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.update()
                    }
                })
            }
            else {
                this.update();
            }
        }
    }
    update=()=>{
        this.setState({
            is_exe: true,
            btn_text: "Working...",
        })
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_open_edit;
        const formData = new FormData();
        formData.append(
            "sid", window.localStorage.getItem("logmgtsys_sid")
        );
        formData.append(
            "id", this.state.id
        );
        formData.append(
            "client_id", this.state.client_selected.value
        );
        formData.append(
            "order_date", this.state.order_date
        );
        formData.append(
            "purchase_no", this.state.purchase_no
        );
        formData.append(
            "cement_type", this.state.cement_type
        );
        formData.append(
            "cement_rate_s", this.state.cement_rate_s
        );
        formData.append(
            "bank_tt", this.state.bank_tt
        );
        formData.append(
            "list_purchaes",  JSON.stringify(this.state.list_purchaes)
        );
        formData.append(
            "so_no", this.state.so_no
        );
        formData.append(
            "agreement_no", this.state.agreement_no
        );
        axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    is_exe: false,
                    btn_text: "Edit",
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                        is_exe: false,
                        btn_text: "Create",
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    b_attached_document = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_open_add_attachment;
        const formData = new FormData();
        formData.append(
            "sid", window.localStorage.getItem("logmgtsys_sid")
        );
        formData.append(
            "id", this.state.id
        );
        let len = this.state.imgs.length
        formData.append(
            "img_length",
            len,
        );
        for (let i = 1; i <= len; i++) {
            formData.append(
                "img_" + i.toString(),
                this.state.imgs[i - 1].img,
            );
        }
        axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    is_exe: false,
                    btn_text: "Edit",
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                        is_exe: false,
                        btn_text: "Create",
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    getorder = (id,wiz) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_cement_open;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                let cement_type=res.data.data.cargo==="PPC"?"ppc":"opc";
                this.setState({
                    wiz: wiz,
                    client_selected: {value:res.data.data.client_id,label:res.data.data.client},
                    iscreate: false,
                    btn_text:"Edit",
                    id: id,
                    order_date: res.data.data.order_date,
                    purchase_no: res.data.data.purchase_no,
                    client: res.data.data.client,
                    cargo: res.data.data.cargo,
                    cement_type: cement_type,
                    cement_rate_b: res.data.data.cement_rate_b,
                    cement_rate_s: res.data.data.cement_rate_s,
                    so_no: res.data.data.so_no,
                    agreement_no: res.data.data.agreement_no,
                    weight: res.data.data.weight,
                    bank_tt: res.data.data.bank_tt,
                    fs_no: res.data.data.fs_no,
                    t_volume: res.data.data.t_volume,
                    urls:res.data.urls,
                    products:res.data.products
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_show = (event,id) => {
        event.preventDefault();
        this.getorder(id,3)
    }
    btn_delete = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove this order? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    is_exe:true,
                })
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.cement_open_delete;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:1,
                                is_exe:false,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btn_delete_attachment = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove this attachment? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    is_exe:true,
                })
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.cement_open_remove_attachment;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:1,
                                is_exe:false,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btn_get_aggrement = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_aggrement_obj_for_cement_open;
        let t_weight=0;
        this.state.list_purchaes.map(v=> {
            t_weight = t_weight + (v.sale_volume * 1);
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    is_exe:false,
                })
            } else {
                this.setState({
                    wiz:6,
                    data:res.data.data,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.datas.map(value => {
            return (<tr>
                <td style={{verticalAlign:"middle"}}>
                    <a href="" onClick={event =>{
                        this.btn_show(event,value.id)
                    }}>Show</a> <hr/>

                    <a href="" onClick={event =>{
                        this.btnedit(event,value.id)
                    }}>Edit</a> <hr/>

                    <a href="" onClick={event =>{
                        this.btn_get_aggrement(event,value.id)
                    }}>Get Agreement </a> <hr/>

                    <a href="" onClick={event =>{
                        this.btnaddattachment(event,value.id)
                    }}>Add Attachment</a> <hr/>

                    <a href="" onClick={event =>{
                        this.btnremoveattachment(event,value.id)
                    }}>Remove Attachment</a> <hr/>

                    <a href="" onClick={event =>{
                        this.btn_delete(event,value.id)
                    }}>Remove</a> <hr/>
                </td>
                <td style={{verticalAlign:"middle"}}>{value.order_date}</td>
                <td style={{verticalAlign:"middle"}}>{value.purchase_no}</td>
                <td style={{verticalAlign:"middle"}}>{value.client}</td>
                <td style={{verticalAlign:"middle"}}>{value.cargo}</td>
                <td style={{verticalAlign:"middle"}}>{value.cement_rate_b}</td>
                <td style={{verticalAlign:"middle"}}>{value.cement_rate_s}</td>
                <td style={{verticalAlign:"middle"}}>{value.weight}</td>
                <td style={{verticalAlign:"middle"}}>{value.bank_tt}</td>
                <td style={{verticalAlign:"middle"}}>{value.fs_no}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Order</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Date</th>
                            <th className="col-md-1">PO #</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-1">Cargo</th>
                            <th className="col-md-2">Sale Rate</th>
                            <th className="col-md-1">Weight</th>
                            <th className="col-md-1">Bank TT</th>
                            <th className="col-md-1">FS #</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let files=null;
        let btn= <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> {this.state.btn_text} </button>
        if(this.state.is_create) {
            btn= <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> {this.state.btn_text} </button>
            let no_img = [];
            for (let i = 1; i <= this.state.no_img; i++) {
                no_img.push(i)
            }
            let t = no_img.map(v => {
                return (
                    <div className="col-md-12">
                        <input className="m-2" type="file" onChange={event => {
                            this.onfilechange(event, v)
                        }}/>
                    </div>
                )
            });
            files =<>
                <div className="col-md-6">
                    <label htmlFor="formFileSm" className="form-label">Agreements</label><p>(Agreement and other
                    file must be in image format)</p>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            no_img: this.state.no_img + 1,
                        })
                    }}>(Add Additional File) <br/></a>
                </div>
                <div className="col-md-12">
                </div>
                {t}
            </>
        }
        let t_weight=0;
        let tdata=this.state.list_purchaes.map(v=>{
            t_weight=t_weight+(v.sale_volume*1);
            return (
                <>
                    <div className="col-md-2"><a href="" onClick={event => {
                        event.preventDefault();
                        let temp=this.state.list_purchaes.filter(v2=>{
                            if(v.id!==v2.id){
                                return v;
                            }
                        });
                        this.setState({
                            list_purchaes:temp,
                        })
                    }}>X</a></div>
                    <div className="col-md-2 border">{v.product_type}</div>
                    <div className="col-md-2 border">{v.sale_volume}</div>
                    <div className="col-md-2 border">{v.unit_price}</div>
                    <div className="col-md-2 border">{v.po_no}</div>
                    <div className="col-md-2 border">{v.fs_no}</div>
                </>
            )
        })


        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-md-3">
                                    <div className="form-group">
                                        <label></label>
                                        <a href="" onClick={this.btn_get_aggrement}>Get Agreement Here</a>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                </div>*/}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <Select
                                            value={this.state.client_selected}
                                            onChange={this.handelchange_client}
                                            options={this.state.clients}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Type</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="client_type"
                                               value={this.state.client_type} onChange={this.inputmgt}
                                               placeholder="Client Type"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}
                                               placeholder="Company name"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div disabled={true} className="form-group">
                                        <label>Contact person</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="contact_person" value={this.state.contact_person}
                                               onChange={this.inputmgt} placeholder="Contact person"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>TIN</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="tin"
                                               value={this.state.tin} onChange={this.inputmgt} placeholder="TIN"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="city"
                                               value={this.state.city} onChange={this.inputmgt} placeholder="City"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="address"
                                               value={this.state.address} onChange={this.inputmgt}
                                               placeholder="Address"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Phone #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="phone"
                                               value={this.state.phone} onChange={this.inputmgt} placeholder="Phone #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input disabled={true} type="email" className="form-control form-control-sm" name="email"
                                               value={this.state.email} onChange={this.inputmgt} placeholder="Email"/>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order Date</label>
                                        <input type="date" className="form-control form-control-sm" name="order_date"
                                               value={this.state.order_date} onChange={this.inputmgt} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cement Type</label>
                                        <select className="form-control form-control-sm" name="cement_type"
                                                value={this.state.cement_type} onChange={this.inputmgt_cement_type}>
                                            <option value="-1">-- Select Cement Type --</option>
                                            <option value="ppc">PPC</option>
                                            <option value="opc">OPC</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Sale Rate</label>
                                        <input type="text" className="form-control form-control-sm" name="cement_rate_s"
                                               value={this.state.cement_rate_s} onChange={this.inputmgt} placeholder="Sale Rate" />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Bank TT #</label>
                                        <input type="text" className="form-control form-control-sm" name="bank_tt"
                                               value={this.state.bank_tt} onChange={this.inputmgt} placeholder="Bank TT #" />
                                    </div>
                                </div>
                                {/*<div className="col-md-3">
                                    <div className="form-group">
                                        <label>SO #</label>
                                        <input type="text" className="form-control form-control-sm" name="so_no"
                                               value={this.state.so_no} onChange={this.inputmgt} placeholder="SO #" />
                                    </div>
                                </div>*/}
                                {/*   <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Agreement #</label>
                                        <input type="text" className="form-control form-control-sm" name="agreement_no"
                                               value={this.state.agreement_no} onChange={this.inputmgt} placeholder="Agreement #" />
                                    </div>
                                </div>*/}
                                {/*   <div className="col-md-6">
                                </div>*/}
                                <div className="col-md-8 pb-2">
                                    <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Product Data </strong>
                                            <a className="text-sm" href=""
                                               onClick={event => {
                                                   this.add_product(event)
                                               }}>(Add Product)
                                            </a>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-2 border"><strong>Product Type</strong></div>
                                        <div className="col-md-2 border"><strong>Weight (q)</strong></div>
                                        <div className="col-md-2 border"><strong>Unit Price</strong></div>
                                        <div className="col-md-2 border"><strong>PO #</strong></div>
                                        <div className="col-md-2 border"><strong>FS #</strong></div>
                                        {tdata}
                                        <div className="col-md-12 pt-3"><strong>Total Sale Weight </strong>{t_weight} (q)</div>
                                    </div>
                                </div>
                                <div className="col-md-4 pb-2">
                                </div>
                                {files}
                                <div className="col-md-3 my-3">
                                    <div className="form-group">
                                        {btn}
                                    </div>
                                </div>
                                <div className="col-md-3 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let imgs=this.state.urls.map(v=>{
            let sty="rotate("+v.deg+"deg)"
            return (<>
                    <div className="col-md-12 text-center my-2 border">
                        <img style={{transform:sty}} src={v.url}/>
                    </div>
                    <div className="col-md-12 text-center my-2 border">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let urls=this.state.urls.map(v2=>{
                                if(v2.id===v.id){
                                    v2.deg=v2.deg+90
                                    if(v2.deg>270)
                                        v2.deg=0
                                }
                                return v2
                            });
                            this.setState({
                                urls:urls
                            })
                        }}>Image Rotate</a>
                    </div>
                </>
            )
        })
        let tdata=this.state.products.map(v=>{
            return (<tr>
                <td>{v.product_type}</td>
                <td>{v.volume}</td>
                <td>{v.unit_price}</td>
                <td>{v.po_no}</td>
                <td>{v.fs_no}</td>
            </tr> )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.client}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order Date</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.order_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cement Type</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sale Rate</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.cement_rate_s}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Bank TT #</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.bank_tt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.t_volume}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Agreement #</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.agreement_no}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-12 pb-3">
                                    <h4>Product Data</h4>
                                    <table className="table table-bordered table-sm">
                                        <tr>
                                            <th className="col-md-3">Product Type</th>
                                            <th className="col-md-3">Weight (q)</th>
                                            <th className="col-md-2">Unit Price</th>
                                            <th className="col-md-2">PO #</th>
                                            <th className="col-md-2">FS #</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                                {imgs}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        let no_img = [];
        for (let i = 1; i <= this.state.no_img; i++) {
            no_img.push(i)
        }
        let t = no_img.map(v => {
            return (
                <div className="col-md-12">
                    <input className="m-2" type="file" onChange={event => {
                        this.onfilechange(event, v)
                    }}/>
                </div>
            )
        });
        let files =<>
            <div className="col-md-6">
                <label htmlFor="formFileSm" className="form-label">Agreements</label><p>(Agreement and other
                file must be in image format)</p>
                <a href="" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        no_img: this.state.no_img + 1,
                    })
                }}>(Add Additional File) <br/></a>
            </div>
            <div className="col-md-6">
            </div>
            {t}
        </>
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order Add Attachment</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {files}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                onClick={this.b_attached_document}> Add Attachment
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_5 = () => {
        let imgs=this.state.urls.map(v=>{
            return (<>
                <div className="col-md-12 text-center my-2 border">
                    <img src={v.url}/>
                </div>
                <div className="col-md-12 text-center my-2 border">
                    <a href="" onClick={event => this.btn_delete_attachment(event,v.id)}>Remove</a>
                </div>
            </> )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order Remove Attachment</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {imgs}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}> Close
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-8 my-3">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_6 = () => {
        return (<>
            <div className="card-body">
                <div id="section_to_print_1" className="row" style={{fontSize:"16px"}}>
                    <div className="col-12 no-print">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            window.print()
                        }}>Print</a>
                    </div>
                    <div className="col-3 pb-5 my-3">
                        <img className="img w-50" src={nt}></img>
                    </div>
                    <div className="col-9">
                    </div>
                    <div className="col-12 text-right">
                        <p><strong>ውል ቁጥር: {this.state.data.agreement_no}</strong></p>
                    </div>
                    <div className="col-12 text-center">
                        <strong>የሲሚንቶ ሽያጭ  ውል</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        <p>ይህ ዉል <strong>ናሽናል ትራንስፖርት ኃ/የተ/የግል ማህበር </strong>አድራሻ <strong>አዲስ አበባ </strong> ስ/ቁ <strong>0988-06-02-38/0930-01-44-04/ </strong> የቤት ቁጥር <strong>አዲስ/301</strong> የግብር ከፋይ መለያ ቁጥር <strong>0043696904</strong> ኢሜል  <strong>info@nationaltransportplc.com</strong> ዉል ተቀባይ /ሻጭ /እየተባለ በሚጠራው</p>
                        <p className="text-center">እና</p>
                        <p>በ <strong>{this.state.data.am_company_name}</strong> አድራሻ <strong>{this.state.data.am_address} </strong>ስልክ ቁጥር <strong>{this.state.data.phone}</strong> የግብር ከፋይ መለያ ቁጥር <strong>{this.state.data.tin}</strong> ዉል ሰጪ/ገዢ እየተባለ በሚጠራው መካከል የተደረገ የሲሚንቶ ሽያጭ ዉል ነው፡፡</p>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 1</strong><br/>
                        <strong>የዉሉ አላማ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        <p>1.1	ሻጭ /ዉል ተቀባይ /ድርጅት የሚያከፋፍለዉን ሲሚንቶ ገዢ ለመግዛት በመፈለጉ  ሻጭም ከታች በተዘረዘሩት መስፈርቶች ለመሸጥ በመስማማቱ የተደረገ የሽያጭ  ዉል ነዉ፡፡ </p>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 2</strong><br/>
                        <strong>ገዢ ከ ሻጭ የሚገዛው የሲሚንቶ መጠን፣የመሸጫ ዋጋ እና የማሰረከቢያ ሁኔታ  </strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        2.1 ገዢ ከ ሻጭ በዚሁ ውል መሰረት የሚገዛዉን ሲሚንቶ መጠን {this.state.data.weight} ኩንታል ፒፒሲ ሲሚንቶ ነው፤የአንድ ኩንታል ዋጋ ተ.እ.ታ ን ጨምሮ {this.state.data.sale_rate} ብር፤ በድምሩ {this.state.data.total} ብር ነው፡፡<br/>
                        2.2 100% ቅድመ ክፍያ በናሽናል ትራንስፖርት የባንክ ሂሳብ ቁጥር 1000443970103 የኢትዮጵያ ንግድ ባንክ ድሬዳዋ፣ ከዚራ ቅርንጫፍ ወይም በአዋሽ የባንክ ሂሳብ ቁጥር 01304683783900 ማስገባት ይኖርበታል፡፡<br/>
                        2.3	ማስረከቢያ ድሬዳዋ ነዉ፡፡<br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 3</strong><br/>
                        <strong>የሻጭ /ዉል ተቀባይ /ግዴታ </strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        3.1	ገዢ ክፍያዉ ሙሉ  መክፈሉን አረጋግጦ ሻጭ ሲሚንቶዉን የማስረከብ ግዴታ አለበት፡፡<br/>
                        3.2	ሻጭ ጥራቱን የጠበቀ ሲሚንቶ ለገዢ የማስረከብ ግዴታ አለበት፡፡<br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 4</strong><br/>
                        <strong>የገዢ/የዉል ሰጪ/ግዴታ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        4.1	በዉሉ መሰረት የሲሚንቶዉሂሳብ  ዉሉ የተፈረመ ቀን በዉል ተቀባይ ሂሳብ ቁጥር 100% ቅድመ ክፍያ ይከፍላል፡፡<br/>
                        4.2	የማስጫኛ  ክፍያዎችን የመሸፈን ግዴታ አለበት ፡፡<br/>
                        4.3	ዉሉ ከተፈረመ ሻጭ ለገዢው ሲሚንቶውን በሚቀርብለት ጊዜ ተረክቦ መጨረስ አለበት፡፡ ይህ ሳይሆን ቀርቶ ገዢ በራሱ ምክንያት ሲሚንቶዉን ሳይረከብ ቀርቶ የ ሲሚንቶ ዋጋ ቢጨምር ገዢ ጭማሬዉን ክፍያ የመክፈል ግዴታ አለበት፡፡<br/>
                        4.4	ገዢ በራሱ ምክንያት ሲሚንቶዉን ቀርቦለት ሳይረከብ ከቀረ ከ 05 ቀን ብኃላ 50.00 ብር በኩንታል የመጋዘን ክራይ በየቀኑ የሚታሰብበት ይሆናል፡፡<br/>
                        4.5	ጭነቱ ተራግፎ ሲጠናቀቅ ውል ሰጪው በድርጅቱ ማህተም ወይም የተረከበው አካል/ተወካይ ፈርሞ መቀበሉን ያረጋግጣል፡፡<br/>
                        4.6	ሲሚንቶ የሚያከፋፍልበት ከተማ/ክልል ፡_________________________<br/>
                    </div>
                    <div className="col-3 pb-5 my-3">
                        <img className="img w-50" src={nt}></img>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 5</strong><br/>
                        <strong>ዋጋ መጨመር እና መቀነስን በተመለከተ </strong><br/>
                    </div>
                    <div className="col-12 text-justify">
                        5.1	ሲሚንቶዉ ተጓጉዞ ሳያልቅ ድርጅቱ የዋጋ ማስተካከያ ካደረገ እና ሲሚንቶዉ ሂሳብ የሚቀንስ ከሆነ ባልተጫነዉ/ ላልተጫነዉ/ ጭነት ብቻ ገዢ የቅናሹ ተጠቃሚ የሆናል፡፡ ይህም ድጋሜ በሚወስደዉ ጭነት ላይ ታሳቢ ሆኖ የሚቀነስ ይሆናል ፡፡<br/>
                        5.2	ሲሚንቶዉ ተጓጉዞ ሳያልቅ ድርጅቱ የዋጋ ጭማሪ  ካደረገ እና የሲሚንቶ ሂሳብ የሚጨምር ከሆነ  ላልተጫነዉ ጭነት ብቻ ገዢ ጭማሪዉን የመክፈል ግዴታ ይኖርበታል ገዢ ተጨማሪዉን ክፍያ ለመክፈል የማይስማማ ከሆነ ሻጭ በቀሪዉ ሂሳብ መሰረት አዲሱን ዋጋ መሰረት አድርጎ ተመጣጣኝ የሆነ ሲሚንቶ  ይስጠዋል፡፡<br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 6</strong><br/>
                        <strong>አለመግባባትን ስለመፍታት</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        6.1 ተዋዋይ ወገኖች ከዚህ ውል ጋር ተያይዞ የሚነሳውን አለመግባባት በመግባባት ይፈታሉ፡፡<br/>
                        6.2	 ጉዳያቸዉን በመግባባት መፍታት ካልቻሉ ስልጣን ወዳለዉ ፍርድ ቤት ጉዳያቸዉን መዉሰድ ይችላሉ፡፡<br/>
                    </div>
                    <div className="col-12 text-center">
                        <strong>አንቀጽ 7</strong><br/>
                        <strong>ዉሉን ስለማፍረስ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        7.1	ከላይ የተቀመጡት ግዴታዎች ያልተፈጸሙ እንደሆነ በጉዳዩ ቅር የተሰኘዉ አካል የ 03 ቀን ማስጠንቀቂያ በመስጠት ዉሉን ማፍረስ ይችላል፡፡<br/>
                    </div>

                    <div className="col-12 text-center">
                        <strong>አንቀጽ 8</strong><br/>
                        <strong>ከአቅም በላይ ሁኔታ</strong><br/><br/>
                    </div>
                    <div className="col-12 text-justify">
                        8.1	በኢትዮጵያ ፍትኃብሄር ህግ ቁጥር አንቀጽ 1792 እና 1793 በተገለጸው መሰረት ከቁጥጥር ውጭ በሆነ ሁኔታ ለሚደርስ ችግር ተዋዋይ ወገኖች አንዱ በሌላው ላይ የሚያቀርበው የይገባኛል ጥያቄ አይኖርም፡፡ይህ  ውል ከዛሬ <strong>{this.state.data.dt}</strong> ጀምሮ የፀና ይሆናል፡፡ የውሉ ቅጂም በሁለቱ ወገኖች እጅ ይገኛል፡፡<br/><br/>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6">
                                        <strong>ስለ ሻጭ</strong><br/><br/>
                                    </div>
                                    <div className="col-6">
                                        <strong>ስለ ገዢ</strong><br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6">
                                        ስም: _____________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        ስም: _____________________<br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-6">
                                        ፊርማ :_____________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        ፊርማ :_____________________<br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8 text-center">
                                <strong>ምስክሮች</strong><br/>
                            </div>
                            <div className="col-2"></div>

                            <div className="col-2"></div>
                            <div className="col-8 text-center">
                                <div className="row">
                                    <div className="col-6">
                                        1. ________________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        __________________________<br/><br/>
                                    </div>

                                    <div className="col-6">
                                        2. ________________________<br/><br/>
                                    </div>
                                    <div className="col-6">
                                        __________________________<br/><br/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix no-print">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }

    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        let dis=null;
        if(this.state.modal_type===1)
        {
            dis= <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Product Data</h3>
                            <div className="card-tools">
                                <button type="button" onClick={this.hidecu} className="btn btn-tool">
                                    <i className="fas fa-times"/>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Product</label>
                                            <Select
                                                value={this.state.purchaes_selected}
                                                onChange={this.handelchange_purchaes}
                                                options={this.state.purchaes}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Product Type</label>
                                            <input readOnly={true} className="form-control" type="text"
                                                   value={this.state.p_product_type}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Available Weight</label>
                                            <input readOnly={true} className="form-control" type="text"
                                                   value={this.state.p_volume}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>PO #</label>
                                            <input readOnly={true} className="form-control" type="text"
                                                   value={this.state.p_po_no}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>FS #</label>
                                            <input readOnly={true} className="form-control" type="text"
                                                   value={this.state.p_fs_no}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Unit Price</label>
                                            <input readOnly={true} className="form-control" type="text"
                                                   value={this.state.p_unit_price} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Sale Weight (q)</label>
                                            <input className="form-control" type="text"
                                                   name="sale_volume" onChange={this.inputmgt}
                                                   value={this.state.sale_volume} placeholder={"Sale Weight (q)"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <button className="btn-primary form-control" onClick={this.add_product_to_list}>Add Product</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Body>
                    {dis}
                </Modal.Body>
            </Modal>
        )
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        else if (this.state.wiz === 5)
            dis = this.wiz_5()
        else if (this.state.wiz === 6)
            dis = this.wiz_6()
        return (
            <>
                <Nav type="cement_open"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle no-print"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Open</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Cement_open;
