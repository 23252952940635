import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Sync_item extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            filtered:[],
            objsload: false,

            txtsearch:'',
            isexe:false,
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_items;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    txtsearch:'',
                    objs: res.data,
                    filtered:res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    btnsync = (event) => {
        event.preventDefault()
        if(this.state.isexe===false){
            this.setState({
                isexe:true,
                objsload: false,
                objs: [],
                filtered:[],
            },()=>{
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.sync_items;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("ordermgt_sid"),
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("ordermgt_sid", null);
                        window.localStorage.setItem("ordermgt_username", null);
                        window.localStorage.setItem("ordermgt_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(value => {
                            this.setState({
                                txtsearch:'',
                                objs: res.data,
                                filtered:res.data.datas,
                                objsload: true,
                                isexe:false,
                            })
                            this.fetcheddata()
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            })
        }

    }

    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            retdata = this.state.filtered.map(value => {
                return (<tr>
                    <td>{value.name}</td>
                    <td>{value.number_of_filed}</td>
                    <td>{value.siunit}</td>
                    <td>{value.cateory}</td>
                    <td>{value.spec}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }

    render() {
        return (
            <>
                <Nav type="sync_item"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Sync Item</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Sync</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block"
                                                            onClick={(event => this.btnsync(event))}>Sync Item
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '400px'}}>
                                            <table className="table table-head-fixed">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-3">Name</th>
                                                    <th className="col-md-2"># of Fields</th>
                                                    <th className="col-md-2">UOM</th>
                                                    <th className="col-md-3">Category</th>
                                                    <th className="col-md-2">Spec</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Sync_item;
