import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Vehicle_status extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,

            status_types: [],
            status_type_selected: null,

            vehicles: [],
            vehicle_selected: null,
        }
    }
    handelchange_status_type = (status_type_selected) => {
        this.setState({
            status_type_selected:status_type_selected,
        })
    }
    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected:vehicle_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
        this.fetchdata2()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_status_type_for_vehicle_status;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    status_types: res.data.datas,
                    vehicles: res.data.datas2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_status;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.status_type.toUpperCase().includes(value.toUpperCase())||
                    v.vehicle.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            vehicle_selected: null,
        })
    }
    btnremove = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove from the list? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.remove_vehicle_status;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata2()
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        if(this.state.vehicle_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select Vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.status_type_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select status type.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            event.preventDefault();
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.create_vehicle_status;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id:this.state.vehicle_selected.value,
                status_type_id:this.state.status_type_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata2()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }

    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => {this.btnremove(event,value.id)}}><i className="fas fa-trash px-1 text-danger"></i></a>
                </td>
                <td>{value.vehicle}</td>
                <td>{value.status_type}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>Add To List</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-3">Action</th>
                            <th className="col-md-4">Vehicle</th>
                            <th className="col-md-4">Status Type</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Manage Vehicle Status</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <Select
                                            value={this.state.vehicle_selected}
                                            onChange={this.handelchange_vehicle}
                                            options={this.state.vehicles}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Status Type</label>
                                        <Select
                                            value={this.state.status_type_selected}
                                            onChange={this.handelchange_status_type}
                                            options={this.state.status_types}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Add To List </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="vehicle_status"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Vehicle Status</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Vehicle_status;
