import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Order_loading_adv_req extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            order_data:{},
            orders: [],
            sums: [],
            order_selected: null,

            wiz: 1,
            iscreate: false,
        }
    }
    handelchange_order = (order_selected) => {
        if (order_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let temp=this.state.orders.filter(v=>{
                if(v.value===order_selected.value)
                    return v;
            })
            if(temp.length>0){
                this.setState({
                    order_data: temp[0],
                    order_selected:order_selected,
                })
            }
        }
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_datas_for_order_loading_adv_req;
        this.setState({
            datas: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    orders: res.data.orders,
                    datas: res.data.loading_ads,
                    sums: res.data.sums,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            coc: '',
            order_selected:null,
            no_truck:'',
            order_data: {},
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.order_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select order.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.order_loading_adv_req;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id:this.state.order_selected.value,
                no_truck:this.state.no_truck,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    wiz_1 = () => {
        let count=0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            let td_class="col-md-1 bg-yellow";
            if(value.status==="APPROVED")
                td_class="col-md-1 bg-green";
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => {this.btn_remove(event,value.id)}}><i className="fas fa-trash px-1 text-danger"></i></a>
                </td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.driver_phone}</td>
                <td>{value.type}</td>
                <td className={td_class}>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>Add Allocation</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            wiz:3,
                        })
                    }}>Show Summary</a>
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">S/N</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-3">CUSTOMER NAME</th>
                            <th className="col-md-1">ORIGIN</th>
                            <th className="col-md-1">DESTINATION</th>
                            <th className="col-md-1">TRUCK PLATE NO</th>
                            <th className="col-md-1">DRIVER NAME</th>
                            <th className="col-md-1">DRIVER phone</th>
                            <th className="col-md-1">TRANSPORTER TYPE</th>
                            <th className="col-md-1">AGREEMENT STATUS</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Loading Advice Open</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label># of Trucks</label>
                                        <input className="form-control form-control-sm" name="no_truck"
                                               value={this.state.no_truck} onChange={this.inputmgt} placeholder="# of Trucks"/>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Open Order</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.total_weight_truck}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Own Truck</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.assign_owns_truck}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Subcontractor Truck</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.assign_sub_contractor_truck}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Available Order</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.weight_truck}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.order_data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let count=0;
        let tdata = this.state.sums.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.count}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>Add Allocation</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            wiz:1,
                        })
                    }}>Show Detail</a>
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">S/N</th>
                            <th className="col-md-3">CUSTOMER NAME</th>
                            <th className="col-md-3">ORIGIN</th>
                            <th className="col-md-3">DESTINATION</th>
                            <th className="col-md-2">Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }

    btn_remove = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove the allocation?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.order_loading_adv_req_delete;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            }, () => {
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="order_loading_adv_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Loading Advice Open</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_loading_adv_req;
