import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Cement_delivery_cancel_app extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            urls: [],
            wiz: 1,

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_cement_delivery_cancel_app;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    show = (event,value) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_delivery_cancel_app_imgs;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            cement_delivery_cancel_id:value.id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz:2,
                    cement_delivery_cancel_id:value.id,
                    client_name:value.label,
                    allocate_date:value.allocate_date,
                    cement_no:value.cement_no,
                    fs_no:value.fs_no,
                    po_no:value.po_no,
                    weight:value.active_weight,
                    cancel_weight:value.cancel_weight,
                    urls:res.data.urls,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_accept = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to accept this cancel request? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.cement_delivery_cancel_app;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    cement_delivery_cancel_id:this.state.cement_delivery_cancel_id,
                }, {cancelToken: source.token}).then(res => {
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:1,
                                is_exe:false,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    wiz_1 = () => {
        let tdata = this.state.datas.map(value => {
            return (<tr>
                <td>
                    <a href="" onClick={event => this.show(event,value)}>Manage</a>
                </td>
                <td>{value.label}</td>
                <td>{value.cement_no}</td>
                <td>{value.po_no}</td>
                <td>{value.fs_no}</td>
                <td>{value.allocate_date}</td>
                <td>{value.active_weight}</td>
                <td>{value.cancel_weight}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-1">Cement #</th>
                            <th className="col-md-1">PO #</th>
                            <th className="col-md-1">FS #</th>
                            <th className="col-md-2">Allocated Date</th>
                            <th className="col-md-2">Allocated Weight (q)</th>
                            <th className="col-md-2">Cancel Weight (q)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let imgs=this.state.urls.map(v=>{
            return (<div className="col-md-12 text-center my-2 border">
                <img src={v.url}/>
            </div> )
        })
        return (<>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle no-print"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Cement Allocation Cancel</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Allocate Date</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.allocate_date}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cement #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.cement_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>PO #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.po_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>FS #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fs_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Weight (q)</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.weight}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Cancel Weight (q)</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm" name="cancel_weight"
                                           value={this.state.cancel_weight} onChange={this.inputmgt} placeholder="Cancel Weight (q)"/>
                                </div>
                            </div>
                            <div className="col-md-8">
                            </div>
                            {imgs}
                            <div className="col-md-4">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-primary btn-sm" onClick={this.btn_accept}> Accept </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-danger btn-sm" onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            wiz:1,
                                        })
                                    }}> Cancel </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="cement_delivery_cancel_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Allocation Cancel Accept</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cement_delivery_cancel_app;
