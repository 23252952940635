import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Cement_delivery_edit extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',


            dt: new Date().toISOString().slice(0, 10),
            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',
            actual_weight:0,
            data: {},
            cement_selected: null,
            cements:[],

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_datas_for_cement_delivery_edit;
        this.setState({
            datas: [],
            cements: [],
            cement_selected: null,
            filtered: [],
            txtsearch:'',
            t_total_volume:0,
            t_delivered_volume:0,
            t_remain_volume:0,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            dt:this.state.dt,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 1,
                    datas: res.data.datas,
                    cements: res.data.cement_datas,
                    filtered: res.data.datas,
                    actual_weight: res.data.active_no_truck,
                    t_total_volume: res.data.t_total_volume,
                    t_delivered_volume: res.data.t_delivered_volume,
                    t_remain_volume: res.data.t_remain_volume,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.label.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_no_truck = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let active_no_truck="";
        let active_weight="";
        if(value!=="" && !isNaN(value)){
            active_weight=(value*1)*400;
            active_no_truck=value;
        }
        this.setState({
            [name]:value,
            active_weight:active_weight,
            active_no_truck:active_no_truck,
        })
    }
    inputmgt_weight = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let active_no_truck="";
        let active_weight="";
        if(value!=="" && !isNaN(value)){
            active_no_truck=(value*1)/400;
            active_weight=value;
        }
        this.setState({
            [name]:value,
            active_weight:active_weight,
            active_no_truck:active_no_truck,
        })
    }

    btncreate = (event) => {
        event.preventDefault();
        if(this.state.cement_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Select cement order first",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.cement_delivery_edit_create;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                active_weight:this.state.active_weight,
                cement_id:this.state.cement_selected.value,
                dt:this.state.dt,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_delivery_edit;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            active_weight:this.state.active_weight,
            cement_delivery_id:this.state.data.cement_delivery_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    handelchange_cement = (cement_selected) => {
        let cements = this.state.cements.filter(v => {
            if (v.value === cement_selected.value)
                return v;
        })
        if (cements.length > 0) {
            this.setState({
                cement_selected: cement_selected,
                data: cement_selected,
                active_no_truck:'',
                active_weight:'',
            })
        }
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td><a href="" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        data:value,
                        active_no_truck:value.active_no_truck,
                        active_weight:value.active_weight,
                        wiz:2,
                        iscreate:false,
                    })
                }}>Edit</a></td>
                <td>{value.label}</td>
                <td>{value.po_no}</td>
                <td>{value.total_weight}</td>
                <td>{value.delivered_volume}</td>
                <td>{value.remaining}</td>
                <td>{value.active_no_truck}</td>
                <td>{value.active_weight}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={event => {
                            event.preventDefault()
                        this.setState({
                            wiz: 2,
                            iscreate: true,
                            id:-1,
                            actual_weight:0,
                            data: {},
                            cement_selected: null,
                            active_no_truck:'',
                            active_weight:'',
                        })
                    }}>Add Allocation</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 table-responsive" style={{height: '500px'}}>
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-4">Client</th>
                            <th className="col-md-2">PO #</th>
                            <th className="col-md-1">Total Weight (q)</th>
                            <th className="col-md-1">Delivered Weight (q)</th>
                            <th className="col-md-1">Remaining Weight (q)</th>
                            <th className="col-md-1"># of Truck</th>
                            <th className="col-md-1">Weight (q)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1">Total Weight (q)</th>
                            <th className="col-md-1">{this.state.t_total_volume}</th>
                        </tr>
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1">Total Delivered Weight (q)</th>
                            <th className="col-md-1">{this.state.t_delivered_volume}</th>
                        </tr>
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1">Total Remaining Volume (q)</th>
                            <th className="col-md-1">{this.state.t_remain_volume}</th>
                        </tr>
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1">Current Allocation (# of Truck)</th>
                            <th className="col-md-1">{this.state.actual_weight}</th>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let btn=  <button type="button" className="btn btn-primary float-left m-3" onClick={this.btncreate}>Create
        </button>
        let cmt=  <div className="form-group">
            <label>Cement Orders</label>
            <Select
                value={this.state.cement_selected}
                onChange={this.handelchange_cement}
                options={this.state.cements}
            />
        </div>
        if(!this.state.iscreate) {
            btn = <button type="button" className="btn btn-primary float-left m-3" onClick={this.btnupdate}>Update
            </button>
            cmt= <div className="form-group">
                <label>Client</label>
                <input readOnly={true} className="form-control form-control-sm"
                       value={this.state.data.label}/>
            </div>
        }
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        {cmt}
                    </div>
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>PO #</label>
                            <input readOnly={true} className="form-control form-control-sm"
                                   value={this.state.data.po_no}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Total Weight (q)</label>
                            <input readOnly={true} className="form-control form-control-sm"
                                   value={this.state.data.total_weight}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Delivered Volume (q)</label>
                            <input readOnly={true} className="form-control form-control-sm"
                                   value={this.state.data.delivered_volume}/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Remaining Weight (q)</label>
                            <input readOnly={true} className="form-control form-control-sm"
                                   value={this.state.data.remaining}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label># of Truck</label>
                            <input className="form-control form-control-sm"
                                  name="active_no_truck" value={this.state.active_no_truck} onChange={this.inputmgt_no_truck} placeholder="# of Truck"/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Weight (q)</label>
                            <input className="form-control form-control-sm"
                                   name="active_weight" value={this.state.active_weight} onChange={this.inputmgt_weight} placeholder="Weight (q)"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix">
                {btn}
                <button type="button" className="btn btn-danger float-left m-3" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz:1,
                    })
                }}>Cancel
                </button>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="cement_delivery_edit"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Date Selection</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Date</label>
                                                        <input type="date" className="form-control form-control-sm" name="dt"
                                                               value={this.state.dt} onChange={this.inputmgt}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.fetchdata()
                                                                }}> Get Allocation
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Allocated Cement Update</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cement_delivery_edit;
