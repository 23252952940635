import {Component} from 'react';
import axios from "axios"
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import myconfig from "../../myconfig";
import logimg from "../../img/logo.png";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { StickyContainer, Sticky } from 'react-sticky';
import Select from "react-select";

let source = axios.CancelToken.source();

class Public_dashboard extends Component {
    constructor() {
        super();
        this.state = {
            dashboard_type: "1",

            wiz: 0,
            status: [],
            vehicles: [],
            vehicles_fitler: [],
            series: [],
            sum_datas: [],
            datas: [],
            det_datas: [],
            options: {},

            sums: [],
            stoppages: [],
            filter_stoppages: [],

            cus_datas: [],
            cus_filter_datas: [],
            cus_sums: [],

            filter_status: [],
            filter_customers: [],
            filter_cargos: [],
            filter_orgs: [],
            filter_dess: [],
            location_selected:null,
            locations: [],
            aud:0,
            wfo:0,
            itlp:0,
            wtl:0,
            itop:0,
            wto:0,
            hts:0,

            aud_datas:[],
            wfo_datas:[],
            itlp_datas:[],
            wtl_datas:[],
            itop_datas:[],
            wto_datas:[],
            hts_datas:[],
        }
    }
    handelchange_location = (location_selected) => {
        this.setState({
            location_selected: location_selected,
        }, () => {
            if (!location_selected !== null) {
                let baseurl = myconfig.pub_dashboard_3;
                let token = new URLSearchParams(this.props.location.search).get("token");
                axios.post(baseurl, {
                    token: token,
                    id: location_selected.value,
                }, {cancelToken: source.token}).then(res => {
                    if (!res.data.auth) {
                        this.setState({
                            wiz: 1,
                        })
                    } else if (!res.data.success) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        let aud=0
                        let wfo=0
                        let itlp=0
                        let wtl=0
                        let itop=0
                        let wto=0
                        let hts=0
                        let aud_datas=[]
                        let wfo_datas=[]
                        let itlp_datas=[]
                        let wtl_datas=[]
                        let itop_datas=[]
                        let wto_datas=[]
                        let hts_datas=[]

                        res.data.datas.map(v=>{
                            if(v.type==="WAITING_FOR_ORDER") {
                                wfo = wfo + 1
                                wfo_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            else if(v.type==="ALLOCATED_UNDER_DISPATCH") {
                                aud = aud + 1
                                aud_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            else if(v.type==="IN_TRANSIT_TO_LOAD_POINT"){
                                itlp=itlp+1
                                itlp_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            else if(v.type==="WAITING_TO_LOAD") {
                                wtl = wtl + 1
                                wtl_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            else if(v.type==="WAITING_TO_TRANSIT") {
                                wtl = wtl + 1
                                wtl_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            else if(v.type==="IN_TRANSIT_TO_OFFLOAD_POINT") {
                                itop = itop + 1
                                itop_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            else if(v.type==="WAITING_TO_OFFLOAD") {
                                wto = wto + 1
                                wto_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            else if(v.type==="HEADING_TO_STATION"){
                                hts=hts+1
                                hts_datas.push({
                                    plate_no:v.plate_no,
                                    customer:v.customer,
                                    cargo:v.cargo,
                                    org:v.org,
                                    des:v.des,
                                })
                            }
                            this.setState({
                                wfo: wfo,
                                itlp: itlp,
                                wtl: wtl,
                                itop: itop,
                                wto: wto,
                                hts: hts,
                                wfo_datas: wfo_datas,
                                itlp_datas: itlp_datas,
                                wtl_datas: wtl_datas,
                                itop_datas: itop_datas,
                                wto_datas: wto_datas,
                                hts_datas: hts_datas,

                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    componentDidMount() {
        this.fetchdata_dashboard_1()
    }
    fetchdata_dashboard_1 = () => {
        let baseurl = myconfig.pub_dashboard_1;
        let token = new URLSearchParams(this.props.location.search).get("token");
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ntfms_sid"),
            token: token,
        }, {cancelToken: source.token}).then(res => {
            if (!res.data.auth) {
                this.setState({
                    wiz: 1,
                })
            } else if (!res.data.success) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    status: res.data.status,
                    vehicles: res.data.vehicles,
                    series: res.data.series,
                    sum_datas: res.data.sum_datas,
                    datas: res.data.datas,

                    sums: res.data.sums,
                    stoppages: res.data.stoppages,
                    filter_stoppages: [],


                    options: {
                        chart: {
                            width: 350,
                            type: 'pie',
                        },
                        legend: {
                            position: 'bottom',
                            show: true,
                        },
                        labels: res.data.lables,
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                            }
                        }]
                    },
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata_dashboard_2 = () => {
        let baseurl = myconfig.pub_dashboard_2;
        let token = new URLSearchParams(this.props.location.search).get("token");
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ntfms_sid"),
            token: token,
        }, {cancelToken: source.token}).then(res => {
            if (!res.data.auth) {
                this.setState({
                    wiz: 1,
                })
            } else if (!res.data.success) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                let filter_customers=[];
                let filter_status=[];
                let filter_cargos=[];
                let filter_orgs=[];
                let filter_dess=[];
                let var_count=0;
                res.data.datas.map(v=>{
                    let isfound=false;
                    var_count=var_count+1;
                    for(let i=0; i< filter_customers.length;i++){
                        if(v.customer===filter_customers[i].customer){
                            isfound=true
                            break;
                        }
                    }
                    if(!isfound) {
                        filter_customers.push({
                            customer: v.customer,
                            var_name: "chk_customer_" + var_count,
                            ischeck:true,
                        })
                    }
                    filter_customers=filter_customers.sort(function(a, b) {
                        return a.customer.localeCompare(b.customer)
                    });

                    isfound=false;
                    for(let i=0; i< filter_status.length;i++){
                        if(v.status===filter_status[i].status){
                            isfound=true
                            break;
                        }
                    }
                    if(!isfound) {
                        filter_status.push({
                            status: v.status,
                            var_name: "chk_status_" + var_count,
                            ischeck:true,
                        })
                    }
                    filter_status=filter_status.sort(function(a, b) {
                        return a.status.localeCompare(b.status)
                    });

                    isfound=false;
                    for(let i=0; i< filter_cargos.length;i++){
                        if(v.cargo===filter_cargos[i].cargo){
                            isfound=true
                            break;
                        }
                    }
                    if(!isfound) {
                        filter_cargos.push({
                            cargo: v.cargo,
                            var_name: "chk_cargo_" + var_count,
                            ischeck:true,
                        })
                    }
                    filter_cargos=filter_cargos.sort(function(a, b) {
                        return a.cargo.localeCompare(b.cargo)
                    });

                    isfound=false;
                    for(let i=0; i< filter_orgs.length;i++){
                        if(v.org===filter_orgs[i].org){
                            isfound=true
                            break;
                        }
                    }
                    if(!isfound) {
                        filter_orgs.push({
                            org: v.org,
                            var_name: "chk_org_" + var_count,
                            ischeck:true,
                        })
                    }
                    filter_orgs=filter_orgs.sort(function(a, b) {
                        return a.org.localeCompare(b.org)
                    });

                    isfound=false;
                    for(let i=0; i< filter_dess.length;i++){
                        if(v.des===filter_dess[i].des){
                            isfound=true
                            break;
                        }
                    }
                    if(!isfound) {
                        filter_dess.push({
                            des: v.des,
                            var_name: "chk_des_" + var_count,
                            ischeck:true,
                        })
                    }
                    filter_dess=filter_dess.sort(function(a, b) {
                        return a.des.localeCompare(b.des)
                    });

                })
                this.setState({
                    wiz: 3,
                    cus_datas: res.data.datas,
                    cus_filter_datas: res.data.datas,
                    cus_sums: res.data.sums,
                    filter_customers: filter_customers,
                    filter_status: filter_status,
                    filter_cargos: filter_cargos,
                    filter_orgs: filter_orgs,
                    filter_dess: filter_dess,
                    chk_customer_all:true,
                    chk_status_all:true,
                    chk_cargo_all:true,
                    chk_org_all:true,
                    chk_des_all:true,
                },()=>{
                    console.log(this.state)
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata_dashboard_3_loc = () => {
        let baseurl = myconfig.pub_dashboard_3_loc;
        let token = new URLSearchParams(this.props.location.search).get("token");
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ntfms_sid"),
            token: token,
        }, {cancelToken: source.token}).then(res => {
            if (!res.data.auth) {
                this.setState({
                    wiz: 1,
                })
            } else if (!res.data.success) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 4,
                    locations:res.data.datas,
                },()=>{
                    console.log(this.state)
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    dashboard_type_change = (event) => {
        event.preventDefault();
        if (this.state.wiz !== 0) {
            var value = event.target.value;
            this.setState({
                dashboard_type: value,
                wiz: 0,
                status: [],
                vehicles: [],
                vehicles_fitler: [],
                series: [],
                sum_datas: [],
                datas: [],
                det_datas: [],
                options: {},

                sums: [],
                stoppages: [],
                filter_stoppages: [],

                cus_datas: [],
                cus_filter_datas: [],
                cus_sums: [],
            }, () => {
                if (value === "1")
                    this.fetchdata_dashboard_1()
                else if (value === "2")
                    this.fetchdata_dashboard_2()
                else if (value === "3")
                    this.fetchdata_dashboard_3_loc()
            })
        }
    }
    more_status = (event, ty) => {
        event.preventDefault();
        let temp = this.state.vehicles;
        if (ty !== "All") {
            temp = this.state.vehicles.filter(v => {
                if (v.status === ty)
                    return v;
            })
        }
        this.setState({
            vehicles_fitler: temp
        })
    }
    more_datas = (event, ty, value) => {
        event.preventDefault();
        let temp = this.state.stoppages.filter(v => {
            if (v.status === ty && v.type === value)
                return v;
        })
        this.setState({
            filter_stoppages: temp
        })
    }

    wiz_0 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Loading...</h5>
            </div>
        )
    }
    wiz_1 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Invalid session token. Please contact your admin.</h5>
            </div>
        )
    }
    wiz_2_1 = () => {
        let t_data_status_sum = this.state.status.map(v => {
            return (<tr>
                <td>{v.type}</td>
                <td><a href="" onClick={event => this.more_status(event, v.type)}>{v.count}</a></td>
            </tr>)
        })
        let t_data_status_det = this.state.vehicles_fitler.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.status}</td>
            </tr>)
        })
        let t_data_sum_datas_sum = this.state.sums.map(v => {
            return (<tr>
                <td>{v.type}</td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 1)}>{v.type_1}</a></td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 2)}>{v.type_2}</a></td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 3)}>{v.type_3}</a></td>
            </tr>)
        })
        let t_data_sum_datas_det = this.state.filter_stoppages.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.status}</td>
                <td>{v.client}</td>
                <td>{v.st_time}</td>
                <td>{v.dif_time_con}</td>
            </tr>)
        })

        return (
            <div className="row parent">
                <div className="con_1 col-12 table-responsive">
                    <div className="row mx-auto" style={{width:"97%"}}>
                        <div className="col-12">
                            <div className="input-group input-group-sm m-2 " style={{width: '150px'}}>
                                <select name="dashboard_type"
                                        className="form-control float-right"
                                        value={this.state.dashboard_type} onChange={this.dashboard_type_change}>
                                    <option value="1">Status Overview</option>
                                    <option value="2">Customer Overview</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Vehicles Status Pi Chart </h3>
                                </div>
                                <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                    <div id="chart">
                                        <Chart options={this.state.options} series={this.state.series} type="pie"
                                               width={350}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Vehicles Status Summary</h3>
                                </div>
                                <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                    <table className="table table-head-fixed">
                                        <thead>
                                        <tr>
                                            <th className="col-md-6">Status</th>
                                            <th className="col-md-5">Count</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {t_data_status_sum}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Vehicles Status Detail</h3>
                                </div>
                                <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                    <table className="table table-head-fixed">
                                        <thead>
                                        <tr>
                                            <th className="col-md-6">Plate #</th>
                                            <th className="col-md-5">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {t_data_status_det}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-warning">
                                <div className="card-header">
                                    <h3 className="card-title">Vehicle Stoppage Summary</h3>
                                </div>
                                <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                    <table className="table table-head-fixed">
                                        <thead>
                                        <tr>
                                            <th className="col-md-4">Status</th>
                                            <th className="col-md-2">Green</th>
                                            <th className="col-md-2">Yellow</th>
                                            <th className="col-md-2">Red</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {t_data_sum_datas_sum}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-warning">
                                <div className="card-header">
                                    <h3 className="card-title">Vehicle Stoppage Detail</h3>
                                </div>
                                <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                    <table className="table table-head-fixed">
                                        <thead>
                                        <tr>
                                            <th className="col-md-3">Plate #</th>
                                            <th className="col-md-3">Status</th>
                                            <th className="col-md-2">Client</th>
                                            <th className="col-md-2">Start Time</th>
                                            <th className="col-md-2">Stoppage</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {t_data_sum_datas_det}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    wiz_2 = () => {
        let t_data_status_sum = this.state.status.map(v => {
            return (<tr>
                <td>{v.type}</td>
                <td><a href="" onClick={event => this.more_status(event, v.type)}>{v.count}</a></td>
            </tr>)
        })
        let t_data_status_det = this.state.vehicles_fitler.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.status}</td>
            </tr>)
        })
        let t_data_sum_datas_sum = this.state.sums.map(v => {
            return (<tr>
                <td>{v.type}</td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 1)}>{v.type_1}</a></td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 2)}>{v.type_2}</a></td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 3)}>{v.type_3}</a></td>
            </tr>)
        })
        let t_data_sum_datas_det = this.state.filter_stoppages.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.status}</td>
                <td>{v.client}</td>
                <td>{v.st_time}</td>
                <td>{v.dif_time_con}</td>
            </tr>)
        })

        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Vehicles Status Pi Chart </h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <div id="chart">
                                    <Chart options={this.state.options} series={this.state.series} type="pie"
                                           width={350}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Vehicles Status Summary</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Status</th>
                                        <th className="col-md-5">Count</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_status_sum}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Vehicles Status Detail</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Plate #</th>
                                        <th className="col-md-5">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_status_det}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">Vehicle Stoppage Summary</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-4">Status</th>
                                        <th className="col-md-2">Green</th>
                                        <th className="col-md-2">Yellow</th>
                                        <th className="col-md-2">Red</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_sum_datas_sum}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">Vehicle Stoppage Detail</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Status</th>
                                        <th className="col-md-2">Client</th>
                                        <th className="col-md-2">Start Time</th>
                                        <th className="col-md-2">Stoppage</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_sum_datas_det}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    wiz_3 = () => {
        let filter_customers = null;
        if (this.state.filter_customers.length > 0) {
            let opt = this.state.filter_customers.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_customer}/>
                        <label className="form-check-label p-0">{v.customer}</label>
                    </div>
                )
            })
            filter_customers = <div className="card">
                <div className="card-header bg-primary ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Customers</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input name="chk_customer_all" checked={this.state.chk_customer_all}
                                                   className="form-check-input" onChange={this.inputmgt_chk_customer}
                                                   type="checkbox"/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_status = null;
        if (this.state.filter_status.length > 0) {
            let opt = this.state.filter_status.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_status}/>
                        <label className="form-check-label p-0">{v.status}</label>
                    </div>
                )
            })
            filter_status = <div className="card p-0">
                <div className="card-header bg-primary ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Status</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_status_all"
                                                   checked={this.state.chk_status_all}
                                                   onChange={this.inputmgt_chk_status}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_cargos = null;
        if (this.state.filter_cargos.length > 0) {
            let opt = this.state.filter_cargos.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_cargo}/>
                        <label className="form-check-label p-0">{v.cargo}</label>
                    </div>
                )
            })
            filter_cargos = <div className="card p-0">
                <div className="card-header bg-primary ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Cargos</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_cargo_all"
                                                   checked={this.state.chk_cargo_all}
                                                   onChange={this.inputmgt_chk_cargo}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_orgs = null;
        if (this.state.filter_orgs.length > 0) {
            let opt = this.state.filter_orgs.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_org}/>
                        <label className="form-check-label p-0">{v.org}</label>
                    </div>
                )
            })
            filter_orgs = <div className="card p-0">
                <div className="card-header bg-primary ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Origins</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_org_all"
                                                   checked={this.state.chk_org_all} onChange={this.inputmgt_chk_org}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_dess = null;
        if (this.state.filter_dess.length > 0) {
            let opt = this.state.filter_dess.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_des}/>
                        <label className="form-check-label p-0">{v.des}</label>
                    </div>
                )
            })
            filter_dess = <div className="card p-0">
                <div className="card-header bg-primary ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Destinations</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_des_all"
                                                   checked={this.state.chk_des_all} onChange={this.inputmgt_chk_des}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let cus_filter_datas = this.state.cus_datas.filter(v => {
            let cus_found = false;
            let sta_found = false;
            let car_found = false;
            let org_found = false;
            let des_found = false;
            for (let i = 0; i < this.state.filter_customers.length; i++) {
                if (v.customer === this.state.filter_customers[i].customer) {
                    cus_found = this.state.filter_customers[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_status.length; i++) {
                if (v.status === this.state.filter_status[i].status) {
                    sta_found = this.state.filter_status[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_cargos.length; i++) {
                if (v.cargo === this.state.filter_cargos[i].cargo) {
                    car_found = this.state.filter_cargos[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_orgs.length; i++) {
                if (v.org === this.state.filter_orgs[i].org) {
                    org_found = this.state.filter_orgs[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_dess.length; i++) {
                if (v.des === this.state.filter_dess[i].des) {
                    des_found = this.state.filter_dess[i].ischeck;
                    break;
                }
            }

            if (cus_found && sta_found && car_found && org_found && des_found) {
                return v;
            }
        })
        let count = 0;
        let tdata_2 = cus_filter_datas.map(value => {
            count = count + 1
            return (<tr>
                <td>{count}</td>
                <td>{value.cargo}</td>
                <td>{value.vehicle}</td>
                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.status}</td>
            </tr>)
        })

        let cus_sums = [];
        this.state.filter_customers.map(value => {
            let a = 0
            let itlp = 0
            let wtl = 0
            let wtt = 0
            let itop = 0
            let wto = 0
            let hts = 0
            let org = ''
            let des = ''
            cus_filter_datas.map(value2 => {
                if (value2.customer === value.customer) {
                    if (value2.status === "ALLOCATED_UNDER_DISPATCH")
                        a = a + 1;
                    else if (value2.status === "IN_TRANSIT_TO_LOAD_POINT")
                        itlp = itlp + 1;
                    else if (value2.status === "WAITING_TO_LOAD")
                        wtl = wtl + 1;
                    else if (value2.status === "WAITING_TO_TRANSIT")
                        wtt = wtt + 1;
                    else if (value2.status === "IN_TRANSIT_TO_OFFLOAD_POINT")
                        itop = itop + 1;
                    else if (value2.status === "WAITING_TO_OFFLOAD")
                        wto = wto + 1;
                    else if (value2.status === "HEADING_TO_STATION")
                        hts = hts + 1;
                    org = value2.org;
                    des = value2.des;
                }
            })

            let total = a + itlp + wtl + wtt + itop + wto + hts
            console.log("fffffff")
            console.log(total)
            if (total > 0) {
                cus_sums.push({
                    customer: value.customer,
                    org: org,
                    des: des,
                    a: a,
                    itlp: itlp,
                    wtl: wtl,
                    wtt: wtt,
                    itop: itop,
                    wto: wto,
                    hts: hts,
                    total: total,
                })
            }
        })
        let tdata = cus_sums.map(value => {
            return (<tr>
                {/*   <td>< a href="" onClick={event => {
                    this.filter_data(event, "by_cus", value.customer)
                }}>{value.customer}</a></td>*/}

                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.a}</td>
                <td>{value.itlp}</td>
                <td>{value.wtl}</td>
                <td>{value.wtt}</td>
                <td>{value.itop}</td>
                <td>{value.wto}</td>
                <td>{value.hts}</td>
                <td>{value.total}</td>
            </tr>)
        })

        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-3">
                        {filter_customers}
                    </div>
                    <div className="col-md-3">
                        {filter_status}
                    </div>
                    <div className="col-md-2">
                        {filter_cargos}
                    </div>
                    <div className="col-md-2">
                        {filter_orgs}
                    </div>
                    <div className="col-md-2">
                        {filter_dess}
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-warning ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Summary Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_1}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_1"
                                                   className="table table-head-fixed text-wrap table-responsive">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Orgin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-1">Allocated</th>
                                                    <th className="col-md-1">Transit (load)</th>
                                                    <th className="col-md-1">Loading</th>
                                                    <th className="col-md-1">Departure</th>
                                                    <th className="col-md-1">Transit (offload)</th>
                                                    <th className="col-md-1">Unloading</th>
                                                    <th className="col-md-1">Heading</th>
                                                    <th className="col-md-1">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-warning ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Detail Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_2}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_2" className="table table-head-fixed">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-1">#</th>
                                                    <th className="col-md-1">Cargo</th>
                                                    <th className="col-md-2">Plate #</th>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Origin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-2">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata_2}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    wiz_3_3 = () => {
        let tdata = this.state.cus_sums.map(value => {
            return (<tr>
                <td>< a href="" onClick={event => {
                    this.filter_data(event, "by_cus", value.customer)
                }}>{value.customer}</a></td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.a}</td>
                <td>{value.itlp}</td>
                <td>{value.wtl}</td>
                <td>{value.wtt}</td>
                <td>{value.itop}</td>
                <td>{value.wto}</td>
                <td>{value.hts}</td>
                <td>{value.total}</td>
            </tr>)
        })
        let tdata_2 = this.state.cus_filter_datas.map(value => {
            return (<tr>
                <td>{value.cargo}</td>
                <td>{value.vehicle}</td>
                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <iframe>
                    <div className="row pb-2 justify-content-center">
                        <div className="col-md-3 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Status</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Origin</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Destination</h5>
                        </div>
                        <div className="col-md-2 mr-3 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Customer</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Cargo</h5>
                        </div>
                    </div>
                </iframe>

                <StickyContainer>
                    <Sticky>
                        {({
                              style,

                              // the following are also available but unused in this example
                              isSticky,
                              wasSticky,
                              distanceFromTop,
                              distanceFromBottom,
                              calculatedHeight
                          }) => (
                            <header style={style}>

                            </header>

                        )}
                    </Sticky>
                    <div className="row pb-2 justify-content-center">
                        <div className="col-md-3 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Status</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Origin</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Destination</h5>
                        </div>
                        <div className="col-md-2 mr-3 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Customer</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Cargo</h5>
                        </div>
                    </div>
                    <div className="row pb-2 justify-content-center">
                        <div className="col-md-3 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Status</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Origin</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Destination</h5>
                        </div>
                        <div className="col-md-2 mr-3 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Customer</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Cargo</h5>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Summary Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_1}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_1"
                                                   className="table table-head-fixed text-wrap table-responsive">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Orgin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-1">Allocated</th>
                                                    <th className="col-md-1">Transit (load)</th>
                                                    <th className="col-md-1">Loading</th>
                                                    <th className="col-md-1">Departure</th>
                                                    <th className="col-md-1">Transit (offload)</th>
                                                    <th className="col-md-1">Unloading</th>
                                                    <th className="col-md-1">Heading</th>
                                                    <th className="col-md-1">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Detail Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_2}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_2" className="table table-head-fixed">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Cargo</th>
                                                    <th className="col-md-2">Plate #</th>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Origin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-2">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata_2}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </StickyContainer>
            </div>
        </>)
    }
    wiz_3_2 = () => {
        let tdata = this.state.cus_sums.map(value => {
            return (<tr>
                <td>< a href="" onClick={event => {
                    this.filter_data(event, "by_cus", value.customer)
                }}>{value.customer}</a></td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.a}</td>
                <td>{value.itlp}</td>
                <td>{value.wtl}</td>
                <td>{value.wtt}</td>
                <td>{value.itop}</td>
                <td>{value.wto}</td>
                <td>{value.hts}</td>
                <td>{value.total}</td>
            </tr>)
        })
        let tdata_2 = this.state.cus_filter_datas.map(value => {
            return (<tr>
                <td>{value.cargo}</td>
                <td>{value.vehicle}</td>
                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <StickyContainer>
                    <Sticky>
                        {({
                              style,

                              // the following are also available but unused in this example
                              isSticky,
                              wasSticky,
                              distanceFromTop,
                              distanceFromBottom,
                              calculatedHeight
                          }) => (
                            <header style={style}>
                                <div className="row pb-2 justify-content-center">
                                    <div className="col-md-3 mr-2 table-responsive"
                                         style={{border: "double", height: '200px'}}>
                                        <h5 className="bg-dark py-1 m-2 text-center">Status</h5>
                                    </div>
                                    <div className="col-md-2 mr-2 table-responsive"
                                         style={{border: "double", height: '200px'}}>
                                        <h5 className="bg-dark py-1 m-2 text-center">Origin</h5>
                                    </div>
                                    <div className="col-md-2 mr-2 table-responsive"
                                         style={{border: "double", height: '200px'}}>
                                        <h5 className="bg-dark py-1 m-2 text-center">Destination</h5>
                                    </div>
                                    <div className="col-md-2 mr-3 table-responsive"
                                         style={{border: "double", height: '200px'}}>
                                        <h5 className="bg-dark py-1 m-2 text-center">Customer</h5>
                                    </div>
                                    <div className="col-md-2 mr-2 table-responsive"
                                         style={{border: "double", height: '200px'}}>
                                        <h5 className="bg-dark py-1 m-2 text-center">Cargo</h5>
                                    </div>
                                </div>
                            </header>

                        )}
                    </Sticky>
                    <div className="row pb-2 justify-content-center">
                        <div className="col-md-3 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Status</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Origin</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Destination</h5>
                        </div>
                        <div className="col-md-2 mr-3 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Customer</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Cargo</h5>
                        </div>
                    </div>
                    <div className="row pb-2 justify-content-center">
                        <div className="col-md-3 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Status</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Origin</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Destination</h5>
                        </div>
                        <div className="col-md-2 mr-3 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Customer</h5>
                        </div>
                        <div className="col-md-2 mr-2 table-responsive" style={{border: "double", height: '200px'}}>
                            <h5 className="bg-dark py-1 m-2 text-center">Cargo</h5>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Summary Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_1}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_1"
                                                   className="table table-head-fixed text-wrap table-responsive">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Orgin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-1">Allocated</th>
                                                    <th className="col-md-1">Transit (load)</th>
                                                    <th className="col-md-1">Loading</th>
                                                    <th className="col-md-1">Departure</th>
                                                    <th className="col-md-1">Transit (offload)</th>
                                                    <th className="col-md-1">Unloading</th>
                                                    <th className="col-md-1">Heading</th>
                                                    <th className="col-md-1">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Detail Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_2}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_2" className="table table-head-fixed">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Cargo</th>
                                                    <th className="col-md-2">Plate #</th>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Origin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-2">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata_2}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </StickyContainer>
            </div>
        </>)
    }
    wiz_3_1 = () => {
        let tdata = this.state.cus_sums.map(value => {
            return (<tr>
                <td>< a href="" onClick={event => {
                    this.filter_data(event, "by_cus", value.customer)
                }}>{value.customer}</a></td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.a}</td>
                <td>{value.itlp}</td>
                <td>{value.wtl}</td>
                <td>{value.wtt}</td>
                <td>{value.itop}</td>
                <td>{value.wto}</td>
                <td>{value.hts}</td>
                <td>{value.total}</td>
            </tr>)
        })
        let tdata_2 = this.state.cus_filter_datas.map(value => {
            return (<tr>
                <td>{value.cargo}</td>
                <td>{value.vehicle}</td>
                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <StickyContainer>
                    <Sticky>
                        {({
                              style,

                              // the following are also available but unused in this example
                              isSticky,
                              wasSticky,
                              distanceFromTop,
                              distanceFromBottom,
                              calculatedHeight
                          }) => (
                            <header style={style}>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header bg-dark ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Filter</h3>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                <div className="card">
                                                                    <div
                                                                        className="card-header bg-dark ui-sortable-handle"
                                                                        style={{cursor: 'move'}}>
                                                                        <h3 className="card-title">Status</h3>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div
                                                                                    className="card-body table-responsive p-0"
                                                                                    style={{height: '100px'}}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="card">
                                                                    <div
                                                                        className="card-header bg-dark ui-sortable-handle"
                                                                        style={{cursor: 'move'}}>
                                                                        <h3 className="card-title">Status</h3>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div
                                                                                    className="card-body table-responsive p-0"
                                                                                    style={{height: '100px'}}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="card">
                                                                    <div
                                                                        className="card-header bg-dark ui-sortable-handle"
                                                                        style={{cursor: 'move'}}>
                                                                        <h3 className="card-title">Status</h3>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div
                                                                                    className="card-body table-responsive p-0"
                                                                                    style={{height: '100px'}}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="card">
                                                                    <div
                                                                        className="card-header bg-dark ui-sortable-handle"
                                                                        style={{cursor: 'move'}}>
                                                                        <h3 className="card-title">Status</h3>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div
                                                                                    className="card-body table-responsive p-0"
                                                                                    style={{height: '100px'}}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <div className="card">
                                                                    <div
                                                                        className="card-header bg-dark ui-sortable-handle"
                                                                        style={{cursor: 'move'}}>
                                                                        <h3 className="card-title">Status</h3>
                                                                    </div>
                                                                    <div className="card-body p-0">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div
                                                                                    className="card-body table-responsive p-0"
                                                                                    style={{height: '100px'}}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>

                        )}
                    </Sticky>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Summary Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_1}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_1"
                                                   className="table table-head-fixed text-wrap table-responsive">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Orgin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-1">Allocated</th>
                                                    <th className="col-md-1">Transit (load)</th>
                                                    <th className="col-md-1">Loading</th>
                                                    <th className="col-md-1">Departure</th>
                                                    <th className="col-md-1">Transit (offload)</th>
                                                    <th className="col-md-1">Unloading</th>
                                                    <th className="col-md-1">Heading</th>
                                                    <th className="col-md-1">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Detail Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_2}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_2" className="table table-head-fixed">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Cargo</th>
                                                    <th className="col-md-2">Plate #</th>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Origin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-2">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata_2}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </StickyContainer>
            </div>
        </>)
    }
    wiz_3_4 = () => {
        let filter_customers = null;
        if (this.state.filter_customers.length > 0) {
            let opt = this.state.filter_customers.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_customer}/>
                        <label className="form-check-label p-0">{v.customer}</label>
                    </div>
                )
            })
            filter_customers = <div className="card p-0">
                <div className="card-header bg-dark ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Customers</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input name="chk_customer_all" checked={this.state.chk_customer_all}
                                                   className="form-check-input" onChange={this.inputmgt_chk_customer}
                                                   type="checkbox"/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_status = null;
        if (this.state.filter_status.length > 0) {
            let opt = this.state.filter_status.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_status}/>
                        <label className="form-check-label p-0">{v.status}</label>
                    </div>
                )
            })
            filter_status = <div className="card p-0">
                <div className="card-header bg-dark ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Status</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_status_all"
                                                   checked={this.state.chk_status_all}
                                                   onChange={this.inputmgt_chk_status}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_cargos = null;
        if (this.state.filter_cargos.length > 0) {
            let opt = this.state.filter_cargos.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_cargo}/>
                        <label className="form-check-label p-0">{v.cargo}</label>
                    </div>
                )
            })
            filter_cargos = <div className="card p-0">
                <div className="card-header bg-dark ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Cargos</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_cargo_all"
                                                   checked={this.state.chk_cargo_all}
                                                   onChange={this.inputmgt_chk_cargo}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_orgs = null;
        if (this.state.filter_orgs.length > 0) {
            let opt = this.state.filter_orgs.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_org}/>
                        <label className="form-check-label p-0">{v.org}</label>
                    </div>
                )
            })
            filter_orgs = <div className="card p-0">
                <div className="card-header bg-dark ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Origins</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_org_all"
                                                   checked={this.state.chk_org_all} onChange={this.inputmgt_chk_org}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let filter_dess = null;
        if (this.state.filter_dess.length > 0) {
            let opt = this.state.filter_dess.map(v => {
                return (
                    <div className="form-check  mx-3">
                        <input className="form-check-input p-0" type="checkbox" name={v.var_name} checked={v.ischeck}
                               onChange={this.inputmgt_chk_des}/>
                        <label className="form-check-label p-0">{v.des}</label>
                    </div>
                )
            })
            filter_dess = <div className="card p-0">
                <div className="card-header bg-dark ui-sortable-handle"
                     style={{cursor: 'move'}}>
                    <h3 className="card-title">Destinations</h3>
                </div>
                <div className="card-body p-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body table-responsive p-0"
                                 style={{height: '200px'}}>
                                <div className="row">
                                    <div className="col-11">
                                        <div className="form-check mx-3">
                                            <input className="form-check-input" type="checkbox" name="chk_des_all"
                                                   checked={this.state.chk_des_all} onChange={this.inputmgt_chk_des}/>
                                            <label className="form-check-label">All</label>
                                        </div>
                                        {opt}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        let cus_filter_datas = this.state.cus_datas.filter(v => {
            let cus_found = false;
            let sta_found = false;
            let car_found = false;
            let org_found = false;
            let des_found = false;
            for (let i = 0; i < this.state.filter_customers.length; i++) {
                if (v.customer === this.state.filter_customers[i].customer) {
                    cus_found = this.state.filter_customers[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_status.length; i++) {
                if (v.status === this.state.filter_status[i].status) {
                    sta_found = this.state.filter_status[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_cargos.length; i++) {
                if (v.cargo === this.state.filter_cargos[i].cargo) {
                    car_found = this.state.filter_cargos[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_orgs.length; i++) {
                if (v.org === this.state.filter_orgs[i].org) {
                    org_found = this.state.filter_orgs[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_dess.length; i++) {
                if (v.des === this.state.filter_dess[i].des) {
                    des_found = this.state.filter_dess[i].ischeck;
                    break;
                }
            }

            if (cus_found && sta_found && car_found && org_found && des_found) {
                return v;
            }
        })
        let count = 0;
        let tdata_2 = cus_filter_datas.map(value => {
            count = count + 1
            return (<tr>
                <td>{count}</td>
                <td>{value.cargo}</td>
                <td>{value.vehicle}</td>
                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.status}</td>
            </tr>)
        })

        let cus_sums = [];
        this.state.filter_customers.map(value => {
            let a = 0
            let itlp = 0
            let wtl = 0
            let wtt = 0
            let itop = 0
            let wto = 0
            let hts = 0
            let org=''
            let des=''
            cus_filter_datas.map(value2 => {
                if (value2.customer === value.customer) {
                    if (value2.status === "ALLOCATED_UNDER_DISPATCH")
                        a = a + 1;
                    else if (value2.status === "IN_TRANSIT_TO_LOAD_POINT")
                        itlp = itlp + 1;
                    else if (value2.status === "WAITING_TO_LOAD")
                        wtl = wtl + 1;
                    else if (value2.status === "WAITING_TO_TRANSIT")
                        wtt = wtt + 1;
                    else if (value2.status === "IN_TRANSIT_TO_OFFLOAD_POINT")
                        itop = itop + 1;
                    else if (value2.status === "WAITING_TO_OFFLOAD")
                        wto = wto + 1;
                    else if (value2.status === "HEADING_TO_STATION")
                        hts = hts + 1;
                    org = value2.org;
                    des = value2.des;
                }
            })

            let total = a + itlp + wtl + wtt + itop + wto + hts
            console.log("fffffff")
            console.log(total)
            if (total > 0) {
                cus_sums.push({
                    customer: value.customer,
                    org: org,
                    des: des,
                    a: a,
                    itlp: itlp,
                    wtl: wtl,
                    wtt: wtt,
                    itop: itop,
                    wto: wto,
                    hts: hts,
                    total: total,
                })
            }
        })
        let tdata = cus_sums.map(value => {
            return (<tr>
             {/*   <td>< a href="" onClick={event => {
                    this.filter_data(event, "by_cus", value.customer)
                }}>{value.customer}</a></td>*/}

                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.a}</td>
                <td>{value.itlp}</td>
                <td>{value.wtl}</td>
                <td>{value.wtt}</td>
                <td>{value.itop}</td>
                <td>{value.wto}</td>
                <td>{value.hts}</td>
                <td>{value.total}</td>
            </tr>)
        })
        return (<>
            <div className="row parent">
                <div className="con_1 col-md-2 table-responsive">
                    <div className="row">
                        <div className="col-12">
                            <div className="input-group input-group-sm m-2 " style={{width: '150px'}}>
                                <select name="dashboard_type"
                                        className="form-control float-right"
                                        value={this.state.dashboard_type} onChange={this.dashboard_type_change}>
                                    <option value="1">Status Overview</option>
                                    <option value="2">Customer Overview</option>
                                    <option value="3">Heading Status</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                    <h3 className="card-title">Filter</h3>
                                </div>
                                <div className="card-body p-2">
                                    <div className="row">
                                        <div className="col-12">
                                            {filter_customers}
                                        </div>
                                        <div className="col-12">
                                            {filter_status}
                                        </div>
                                        <div className="col-12">
                                            {filter_cargos}
                                        </div>
                                        <div className="col-12">
                                            {filter_orgs}
                                        </div>
                                        <div className="col-12">
                                            {filter_dess}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="con_2 col-md-10 table-responsive">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Summary Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_1}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_1"
                                                   className="table table-head-fixed text-wrap table-responsive">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Orgin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-1">Allocated</th>
                                                    <th className="col-md-1">Transit (load)</th>
                                                    <th className="col-md-1">Loading</th>
                                                    <th className="col-md-1">Departure</th>
                                                    <th className="col-md-1">Transit (offload)</th>
                                                    <th className="col-md-1">Unloading</th>
                                                    <th className="col-md-1">Heading</th>
                                                    <th className="col-md-1">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Detail Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_2}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                            <table id="report_2" className="table table-head-fixed">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-1">#</th>
                                                    <th className="col-md-1">Cargo</th>
                                                    <th className="col-md-2">Plate #</th>
                                                    <th className="col-md-2">Customer</th>
                                                    <th className="col-md-1">Origin</th>
                                                    <th className="col-md-1">Destination</th>
                                                    <th className="col-md-2">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {tdata_2}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        let wfo_datas=this.state.wfo_datas.map(v=>{
            return(<tr>
                <td>{v.plate_no}</td>
                <td>{v.customer}</td>
                <td>{v.cargo}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
            </tr>)
        })
        let aud_datas=this.state.aud_datas.map(v=>{
            return(<tr>
                <td>{v.plate_no}</td>
                <td>{v.customer}</td>
                <td>{v.cargo}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
            </tr>)
        })
        let wtl_datas=this.state.wtl_datas.map(v=>{
            return(<tr>
                <td>{v.plate_no}</td>
                <td>{v.customer}</td>
                <td>{v.cargo}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
            </tr>)
        })
        let wto_datas=this.state.wto_datas.map(v=>{
            return(<tr>
                <td>{v.plate_no}</td>
                <td>{v.customer}</td>
                <td>{v.cargo}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
            </tr>)
        })
        let itlp_datas=this.state.itlp_datas.map(v=>{
            return(<tr>
                <td>{v.plate_no}</td>
                <td>{v.customer}</td>
                <td>{v.cargo}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
            </tr>)
        })
        let itop_datas=this.state.itop_datas.map(v=>{
            return(<tr>
                <td>{v.plate_no}</td>
                <td>{v.customer}</td>
                <td>{v.cargo}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
            </tr>)
        })
        let hts_datas=this.state.hts_datas.map(v=>{
            return(<tr>
                <td>{v.plate_no}</td>
                <td>{v.customer}</td>
                <td>{v.cargo}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
            </tr>)
        })
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Place of interest</label>
                            <Select
                                value={this.state.location_selected}
                                onChange={this.handelchange_location}
                                options={this.state.locations}
                            />
                        </div>
                    </div>
                    <div className="col-md-1">
                    </div>
                    <div className="col-md-4">
                        <table className="table table-head-fixed">
                            <tr>
                                <th colSpan={2}>Summary Report (DD)</th>
                            </tr>
                            <tr>
                                <td className="col-md-6">WAITING_FOR_ORDER</td>
                                <td className="col-md-6">{this.state.wfo}</td>
                            </tr>
                            <tr>
                                <td className="col-md-6">ALLOCATED_UNDER_DISPATCH</td>
                                <td className="col-md-6">{this.state.aud}</td>
                            </tr>
                            <tr>
                                <td className="col-md-6">IN_TRANSIT_TO_LOAD_POINT</td>
                                <td className="col-md-6">{this.state.itlp}</td>
                            </tr>
                            <tr>
                                <td className="col-md-6">WAITING_TO_LOAD</td>
                                <td className="col-md-6">{this.state.wtl}</td>
                            </tr>
                            <tr>
                                <td className="col-md-6">IN_TRANSIT_TO_OFFLOAD_POINT</td>
                                <td className="col-md-6">{this.state.itop}</td>
                            </tr>
                            <tr>
                                <td className="col-md-6">WAITING_TO_OFFLOAD</td>
                                <td className="col-md-6">{this.state.wto}</td>
                            </tr>
                            <tr>
                                <td className="col-md-6">HEADING_TO_STATION</td>
                                <td className="col-md-6">{this.state.hts}</td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Trucks Ready for dispatch</h3>
                            </div>

                            <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Customer</th>
                                        <th className="col-md-2">Cargo</th>
                                        <th className="col-md-2">Org</th>
                                        <th className="col-md-2">Des</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {wfo_datas}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Trucks dispatched</h3>
                            </div>

                            <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Customer</th>
                                        <th className="col-md-2">Cargo</th>
                                        <th className="col-md-2">Org</th>
                                        <th className="col-md-2">Des</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {aud_datas}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Waiting for loading </h3>
                            </div>

                            <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Customer</th>
                                        <th className="col-md-2">Cargo</th>
                                        <th className="col-md-2">Org</th>
                                        <th className="col-md-2">Des</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {wtl_datas}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Waiting for unloading</h3>
                            </div>

                            <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Customer</th>
                                        <th className="col-md-2">Cargo</th>
                                        <th className="col-md-2">Org</th>
                                        <th className="col-md-2">Des</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {wto_datas}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">In-transit to loading point</h3>
                            </div>

                            <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Customer</th>
                                        <th className="col-md-2">Cargo</th>
                                        <th className="col-md-2">Org</th>
                                        <th className="col-md-2">Des</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {itlp_datas}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">In-transit to unloading point</h3>
                            </div>

                            <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Customer</th>
                                        <th className="col-md-2">Cargo</th>
                                        <th className="col-md-2">Org</th>
                                        <th className="col-md-2">Des</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {itop_datas}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">In-transit to Depot</h3>
                            </div>

                            <div className="card-body table-responsive p-0" style={{height: '300px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Customer</th>
                                        <th className="col-md-2">Cargo</th>
                                        <th className="col-md-2">Org</th>
                                        <th className="col-md-2">Des</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {hts_datas}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    btnexport_report_1 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Report 1");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report_1'));
        wb.Sheets["Report 1"] = wb2;
        XLSX.writeFile(wb, "report 1.xlsx");
    }
    btnexport_report_2 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Report 2");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report_2'));
        wb.Sheets["Report 2"] = wb2;
        XLSX.writeFile(wb, "report 2.xlsx");
    }
    filter_data = (event, type, customer, data = -1) => {
        event.preventDefault()
        let cus_filter_datas = this.state.cus_datas.filter(v => {
            let cus_found = false;
            let sta_found = false;
            let car_found = false;
            let org_found = false;
            let des_found = false;
            for (let i = 0; i < this.state.filter_customers.length; i++) {
                if (v.customer === this.state.filter_customers[i].customer) {
                    cus_found = this.state.filter_customers[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_status.length; i++) {
                if (v.status === this.state.filter_status[i].status) {
                    sta_found = this.state.filter_status[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_cargos.length; i++) {
                if (v.cargo === this.state.filter_cargos[i].cargo) {
                    car_found = this.state.filter_cargos[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_orgs.length; i++) {
                if (v.org === this.state.filter_orgs[i].org) {
                    org_found = this.state.filter_orgs[i].ischeck;
                    break;
                }
            }
            for (let i = 0; i < this.state.filter_dess.length; i++) {
                if (v.des === this.state.filter_dess[i].des) {
                    des_found = this.state.filter_dess[i].ischeck;
                    break;
                }
            }

            if (cus_found && sta_found && car_found && org_found && des_found) {
                return v;
            }
        })
        if (type === "by_cus") {
            cus_filter_datas = cus_filter_datas.filter(v => {
                if (v.customer === customer)
                    return v;
            })

        }
        this.setState({
            cus_filter_datas: cus_filter_datas,
        })
    }

    inputmgt_chk_customer = (event) => {
        var name = event.target.name;
        var is_check = event.target.checked;
        let filter_customers = this.state.filter_customers;
        let chk_customer_all = false;
        if (name === "chk_customer_all") {
            if (is_check) {
                filter_customers = filter_customers.map(v => {
                    v.ischeck = true;
                    return v;
                })
                chk_customer_all = true;
            } else {
                filter_customers = filter_customers.map(v => {
                    v.ischeck = false;
                    return v;
                })
            }
        } else {
            filter_customers = filter_customers.map(v => {
                if (v.var_name === name)
                    v.ischeck = is_check;
                return v;
            })
        }
        this.setState({
            filter_customers: filter_customers,
            chk_customer_all: chk_customer_all,
        })
    }
    inputmgt_chk_status = (event) => {
        var name = event.target.name;
        var is_check = event.target.checked;
        let filter_status = this.state.filter_status;
        let chk_status_all = false;
        if (name === "chk_status_all") {
            if (is_check) {
                chk_status_all = filter_status.map(v => {
                    v.ischeck = true;
                    return v;
                })
                chk_status_all = true;
            } else {
                filter_status = filter_status.map(v => {
                    v.ischeck = false;
                    return v;
                })
            }
        } else {
            filter_status = filter_status.map(v => {
                if (v.var_name === name)
                    v.ischeck = is_check;
                return v;
            })
        }
        this.setState({
            filter_status: filter_status,
            chk_status_all: chk_status_all,
        })
    }
    inputmgt_chk_cargo = (event) => {
        var name = event.target.name;
        var is_check = event.target.checked;
        let filter_cargos = this.state.filter_cargos;
        let chk_cargo_all = false;
        if (name === "chk_cargo_all") {
            if (is_check) {
                chk_cargo_all = filter_cargos.map(v => {
                    v.ischeck = true;
                    return v;
                })
                chk_cargo_all = true;
            } else {
                filter_cargos = filter_cargos.map(v => {
                    v.ischeck = false;
                    return v;
                })
            }
        } else {
            filter_cargos = filter_cargos.map(v => {
                if (v.var_name === name)
                    v.ischeck = is_check;
                return v;
            })
        }
        this.setState({
            filter_cargos: filter_cargos,
            chk_cargo_all: chk_cargo_all,
        })
    }
    inputmgt_chk_org = (event) => {
        var name = event.target.name;
        var is_check = event.target.checked;
        let filter_orgs = this.state.filter_orgs;
        let chk_org_all = false;
        if (name === "chk_org_all") {
            if (is_check) {
                chk_org_all = filter_orgs.map(v => {
                    v.ischeck = true;
                    return v;
                })
                chk_org_all = true;
            } else {
                filter_orgs = filter_orgs.map(v => {
                    v.ischeck = false;
                    return v;
                })
            }
        } else {
            filter_orgs = filter_orgs.map(v => {
                if (v.var_name === name)
                    v.ischeck = is_check;
                return v;
            })
        }
        this.setState({
            filter_orgs: filter_orgs,
            chk_org_all: chk_org_all,
        })
    }
    inputmgt_chk_des = (event) => {
        var name = event.target.name;
        var is_check = event.target.checked;
        let filter_dess = this.state.filter_dess;
        let chk_des_all = false;
        if (name === "chk_des_all") {
            if (is_check) {
                chk_des_all = filter_dess.map(v => {
                    v.ischeck = true;
                    return v;
                })
                chk_des_all = true;
            } else {
                filter_dess = filter_dess.map(v => {
                    v.ischeck = false;
                    return v;
                })
            }
        } else {
            filter_dess = filter_dess.map(v => {
                if (v.var_name === name)
                    v.ischeck = is_check;
                return v;
            })
        }
        this.setState({
            filter_dess: filter_dess,
            chk_des_all: chk_des_all,
        })
    }


    /*render() {
        let dis = null;
        if (this.state.wiz === 0)
            dis = this.wiz_0();
        else if (this.state.wiz === 1)
            dis = this.wiz_1();
        else if (this.state.wiz === 2)
            dis = this.wiz_2();
        else if (this.state.wiz === 3)
            dis = this.wiz_3();
        return (
            <>
                {dis}
            </>
        )
    }*/

    render() {
         let dis=null;
         if(this.state.wiz===0)
             dis=this.wiz_0();
         else if(this.state.wiz===1)
             dis=this.wiz_1();
         else if(this.state.wiz===2)
             dis=this.wiz_2();
         else if(this.state.wiz===3)
             dis=this.wiz_3();
         else if(this.state.wiz===4)
             dis=this.wiz_4();
         return (
             <>
                 <div className="container">
                     <div className="row">
                         <div className="col-12 m-2" style={{width: "100%"}}>
                             <div className="card">
                                 <div className="card-header bg-green">
                                     <h2 className="card-title">Dashboard</h2>
                                     <div className="card-tools">
                                         <div className="input-group input-group-sm" style={{width: '150px'}}>
                                             <select name="dashboard_type"
                                                     className="form-control float-right"
                                                     value={this.state.dashboard_type} onChange={this.dashboard_type_change}>
                                                 <option value="1">Status Overview</option>
                                                 <option value="2">Customer Overview</option>
                                                 <option value="3">Place of Interest</option>
                                             </select>
                                         </div>
                                     </div>
                                 </div>
                                 {dis}
                             </div>
                         </div>
                     </div>
                 </div>
             </>
         )
     }
    /*   render() {
           let dis=null;
           if(this.state.wiz===0)
               dis=this.wiz_0();
           else if(this.state.wiz===1)
               dis=this.wiz_1();
           else if(this.state.wiz===2)
               dis=this.wiz_2();
           else if(this.state.wiz===3)
               dis=this.wiz_3();
           return (

           )
       }*/
}

export default Public_dashboard;
