import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Assign_sub_contractor_app extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            txtsearch: '',

            wiz: 1,
            id:-1,

        }
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_assign_sub_contractor_app;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
            wiz: 1,
            id:-1,

        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas:res.data.datas,
                    filtered:res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btnmanage = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                coc: temp[0].coc,
                company_name: temp[0].company_name,
                plate_no: temp[0].plate_no,
                driver_name: temp[0].driver_name,
                trailer_no: temp[0].trailer_no,
                order_label: temp[0].order_label,
                fright_no: temp[0].fright_no,
                loaded_weight: temp[0].loaded_weight,
                transport_rate: temp[0].transport_rate,
                allocate_date: temp[0].allocate_date,
                order: temp[0].order,
                order_no: temp[0].order_no,
                remark: temp[0].remark,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btn_app = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to approve the allocation? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.assign_sub_contractor_app_app;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: this.state.id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata()
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btn_rej = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to reject the allocation? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.assign_sub_contractor_app_rej;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: this.state.id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata()
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }

    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnmanage(event,value.id)}>Manage</a>
                </td>
                <td>{value.fright_no}</td>
                <td>{value.order_no}</td>
                <td>{value.order_label}</td>
                <td>{value.company_name}</td>
                <td>{value.plate_no}</td>
                <td>{value.allocate_date}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Fright # </th>
                            <th className="col-md-1">Order # </th>
                            <th className="col-md-3">Order </th>
                            <th className="col-md-2">Sub-contractor</th>
                            <th className="col-md-2">Truck # </th>
                            <th className="col-md-1">Allocated Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Sub-Contractor Allocation REQUEST</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Company</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Vehicle</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Driver</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Trailer</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.fright_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.allocate_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order_label}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea readOnly={true} rows={5} className="form-control form-control-sm"
                                                  value={this.state.order.des_good}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Types of Load</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.tol}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Available Weight</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.avl_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.loaded_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>


                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order Rate</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.order_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea readOnly={true} rows={5} className="form-control form-control-sm"
                                                  name="remark" value={this.state.remark}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_app}> APPROVE </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btn_rej}> REJECT
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-primary btn-sm"
                                                onClick={this.btncancel}> CANCEL
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="assign_sub_contractor_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Allocate Sub-Contractor Vehicle APP</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Assign_sub_contractor_app;
