import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Opex_cost_app extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            datas2:[],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_opex_cost_app;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = (id) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_driver_single_for_opex_cost_app;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas2: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 3,
        })
    }
    btn_manage = (event,value) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_sum_opex_for_opex_cost_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:value.id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    vehicle_id:value.id,
                    plate_no:value.plate_no,
                    actual:res.data.actual,
                    budgeted:res.data.budgeted,
                    varicance:res.data.varicance,
                },()=>this.fetchdata2(value.id))
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.opex_cost_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            amount:this.state.amount,
            cheque_no:this.state.cheque_no,
            bank:this.state.bank,
            remark:this.state.remark,
            vehicle_id:this.state.vehicle_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_manage(event,value)}>Approve Cost</a>
                </td>
                <td>{value.plate_no}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-9">Truck No</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let count=0;
        let tdata = this.state.datas2.map(value => {
            count=count+1;
            return (
                <tr>
                    <td>{count}</td>
                    <td>
                        <a href="" onClick={event => this.btnapprove(event,value.finance_cost_id)}>Approve Cost</a>
                    </td>
                    <td>{value.plate_no}</td>
                    <td>{value.amount}</td>
                    <td>{value.cheque_no}</td>
                    <td>{value.bank}</td>
                    <td>{value.remark}</td>
                    <td>{value.req_by}</td>
                    <td>{value.req_at}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="col-md-3">Truck No</th>
                        <th className="col-md-3">Budgeted</th>
                        <th className="col-md-3">Actual</th>
                        <th className="col-md-3">Variance (Actual-Budgeted)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{this.state.plate_no}</td>
                        <td>{this.state.budgeted}</td>
                        <td>{this.state.actual}</td>
                        <td>{this.state.varicance}</td>
                    </tr>
                    </tbody>
                </table>
                <table id="report" className="table table-head-fixed">
                    <thead>
                    <tr>
                        <th className="col-md-1">#</th>
                        <th className="col-md-1">Action</th>
                        <th className="col-md-2">Truck No</th>
                        <th className="col-md-1">Amount</th>
                        <th className="col-md-1">Cheque #</th>
                        <th className="col-md-1">Bank</th>
                        <th className="col-md-1">Remark</th>
                        <th className="col-md-2">REQ BY</th>
                        <th className="col-md-2">REQ AT</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tdata}
                    </tbody>
                </table>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_3 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Request Cost</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck No</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="text" className="form-control form-control-sm" name="amount"
                                               value={this.state.amount} onChange={this.inputmgt}
                                               placeholder="Amount"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Bank Name</label>
                                        <input type="text" className="form-control form-control-sm" name="bank"
                                               value={this.state.bank} onChange={this.inputmgt}
                                               placeholder="Bank"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cheque #</label>
                                        <input type="text" className="form-control form-control-sm" name="cheque_no"
                                               value={this.state.cheque_no} onChange={this.inputmgt}
                                               placeholder="Cheque #"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Request </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz: 2,
                                                    })
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    btnapprove = (event,finance_cost_id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to approve the record? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.opex_cost_app;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    finance_cost_id: finance_cost_id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata2(this.state.vehicle_id)
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="opex_cost_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">OPEX TRUCK COST APP</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_cost_app;
