import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import style2 from "../../style2.css"
import nt from "../../img/ntlog.png";
import ntfooter from "../../img/ntfooter.png";

let source = axios.CancelToken.source();

class Opex_cost_payment_all extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',
            costs:[],
            reports:[],
            ret_datas:[],
            drivers:[],
            fright_data:{},
            total_amount:0,
            money_to_birr:'',
            report_date:'',
            letter_no:'',
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_opex_cost_payment_all;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
            wiz:1,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                console.log(v.fright_no)
                if (
                    v.plate_no.toUpperCase().includes(value.toUpperCase()) ||
                    v.ref_no.toUpperCase().includes(value.toUpperCase()) ||
                    v.fright_no.toString().toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btn_next = (event) => {
        event.preventDefault()
        let selected_list = this.state.datas.filter(v => {
            if (v.type === 2)
                return v;
        })
        if (selected_list.length <= 0) {
            Swal.fire({
                title: 'Error!',
                text: "Select at leaset one payment before proceed",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_vehicle_for_opex_cost_payment_all_2;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                datas:selected_list,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        wiz:3,
                        reports:res.data.reports,
                        ret_datas:res.data.ret_datas,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btn_next_2 = (event) => {
        event.preventDefault()
        Swal.fire({
            title: 'Are you sure you want to pay all the drivers? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.get_vehicle_for_opex_cost_payment_all_3;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    datas: this.state.ret_datas,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            wiz: 4,
                            drivers: res.data.drivers,
                            total_amount: res.data.total_amount,
                            money_to_birr: res.data.money_to_birr,
                            report_date: res.data.report_date,
                            letter_no: res.data.letter_no,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btn_toogle = (event,ref_no,type) => {
        event.preventDefault();
        let temp = this.state.filtered.map(v => {
            if (v.ref_no === ref_no)
                v.type = type
            return v;
        })
        let temp2 = this.state.datas.map(v => {
            if (v.ref_no === ref_no)
                v.type = type
            return v;
        })
        this.setState({
            filtered: temp,
            datas: temp2,
        })
    }
    btn_manage = (event,value) => {
        event.preventDefault();
        this.setState({
            costs:[],
            fright_data:{},
        },()=> {
            let costs = [];
            let fright_data = {};
            let temp=this.state.filtered.filter(v=>{
                if(v.finance_cost_id===value.finance_cost_id)
                    return v;
            })
            if(temp.length>0){
                costs=temp[0].fright_data.costs;
                fright_data=temp[0].fright_data;
                this.setState({
                    wiz: 2,
                    finance_cost_id:value.finance_cost_id,
                    vehicle_id:value.id,
                    plate_no:value.plate_no,
                    amount:value.amount,
                    amount_con:value.amount_con,
                    cheque_no:value.cheque_no,
                    bank:value.bank,
                    remark:value.remark,
                    costs:costs,
                    fright_data:fright_data,
                })
            }
        })

        //get the history of the vehicle with requested and fine
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let active_list=this.state.filtered.filter(v=>{
            if(v.type===1)
                return v;
        })
        let selected_list=this.state.filtered.filter(v=>{
            if(v.type===2)
                return v;
        })
        let td_active_list = active_list.map(value => {
            count=count+1;
            return (
                <tr>
                    <td>
                        <a href="" onClick={event => this.btn_toogle(event,value.ref_no,2)}>Add</a> |
                        <a href="" onClick={event => this.btn_manage(event,value)}>Info</a>
                    </td>
                    <td>{value.plate_no}</td>
                    <td>{value.fright_no}</td>
                    <td>{value.amount_con}</td>
                    <td>{value.req_by}</td>
                    <td>{value.req_at}</td>
                    <td>{value.app_by}</td>
                    <td>{value.app_at}</td>
                    <td>{value.ref_no}</td>
                </tr>
            )
        })
        let td_selected_list = selected_list.map(value => {
            count=count+1;
            return (
                <tr>
                    <td>
                        <a href="" onClick={event => this.btn_toogle(event,value.ref_no,1)}>Remove</a> |
                        <a href="" onClick={event => this.btn_manage(event,value)}>Info</a>
                    </td>
                    <td>{value.plate_no}</td>
                    <td>{value.fright_no}</td>
                    <td>{value.amount_con}</td>
                    <td>{value.req_by}</td>
                    <td>{value.req_at}</td>
                    <td>{value.app_by}</td>
                    <td>{value.app_at}</td>
                    <td>{value.ref_no}</td>
                </tr>
            )
        })
        let c_selected=td_selected_list.length;
        let c_all=this.state.filtered.length;
        return (<>
            <div className="col-md-12">
                <div className="card"  >
                    <div className="card-header bg-green ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">PAYMENT ALL IN ONE ACTIVE LIST</h3>
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}>
                                <input type="text" name="txtsearch"
                                       className="form-control float-right"
                                       value={this.state.txtsearch} onChange={this.inputsearch}
                                       placeholder="Search"/>
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body table-responsive" >
                        <div className="row">
                            <p>({c_selected} selected out of {c_all} item)
                                <a id="active_list" href="" onClick={event => {
                                    event.preventDefault()
                                    const element = document.getElementById('selected_list');
                                    element.focus();
                                }}>(Show Selected List)</a>
                            </p>

                            <table  id="report" className="table table-head-fixed">
                                <thead>
                                <tr>
                                    <th className="col-md-2">Action</th>
                                    <th className="col-md-2">Truck No</th>
                                    <th className="col-md-2">Fright #</th>
                                    <th className="col-md-1">Amount</th>
                                    <th className="col-md-1">REQ BY</th>
                                    <th className="col-md-1">REQ AT</th>
                                    <th className="col-md-1">APP BY</th>
                                    <th className="col-md-1">APP AT</th>
                                    <th className="col-md-1">Ref_No</th>
                                </tr>
                                </thead>
                                <tbody>
                                {td_active_list}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div className="card">
                    <div className="card-header bg-warning ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">PAYMENT ALL IN ONE SELECTED LIST</h3>
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}>

                            </div>
                        </div>
                    </div>
                    <div className="card-body" >
                        <div className="row">
                            <p>({c_selected} selected out of {c_all} item)
                                <a id="selected_list" href="" onClick={event => {
                                    event.preventDefault()
                                    const element = document.getElementById('active_list');
                                    element.focus();
                                }}>
                                    (Show Active List)
                                </a>
                            </p>
                            <table id="report" className="table table-head-fixed table-responsive">
                                <thead>
                                <tr>
                                    <th className="col-md-2">Action</th>
                                    <th className="col-md-2">Truck No</th>
                                    <th className="col-md-2">Fright #</th>
                                    <th className="col-md-1">Amount</th>
                                    <th className="col-md-1">REQ BY</th>
                                    <th className="col-md-1">REQ AT</th>
                                    <th className="col-md-1">APP BY</th>
                                    <th className="col-md-1">APP AT</th>
                                    <th className="col-md-1">Ref_No</th>
                                </tr>
                                </thead>
                                <tbody>
                                {td_selected_list}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-primary float-right" onClick={this.btn_next}><i
                            className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>

        </>)
    }
    wiz_2 = () => {
        let ls=this.state.costs.map(v=>{
            return (<li>{v.type}: ({v.amount_con})</li>)
        })
        return (<>
            <div className="col-md-12 px-5 py-3">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                        <h3 className="card-title">Payment Information</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Reference #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fright_data.ref_no}/>
                                </div>
                            </div>
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Plate No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fright_data.plate_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Driver Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fright_data.driver_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Trailer No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fright_data.trailer_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fright_data.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>ORG</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fright_data.org}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>DES</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fright_data.des}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Expected Cost (ETB)</label>
                                    <ul>
                                        {ls}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Cost from PRV trip (ETB)</label>
                                    <p>{this.state.fright_data.dif}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Cost to be given (ETB)</label>
                                    <p>{this.state.amount_con}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Remark</label>
                                    <textarea disabled={true} rows={5} className="form-control form-control-sm" name="remark"
                                              value={this.state.remark} onChange={this.inputmgt}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-danger float-right" onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 1,
                            })
                        }}><i
                            className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let tdata=this.state.reports.map(v=>{
            if(v.d0===1){
                return(
                    <tr>
                        <th>{v.d1}</th>
                        <th>{v.d2}</th>
                        <th>{v.d3}</th>
                        <th>{v.d4}</th>
                        <th>{v.d5}</th>
                    </tr>
                )
            }
            else {
                return (
                    <tr>
                        <td>{v.d1}</td>
                        <td>{v.d2}</td>
                        <td>{v.d3}</td>
                        <td>{v.d4}</td>
                        <td>{v.d5}</td>
                    </tr>
                )
            }
        })
        return (<>
            <div className="col-12">
                <div className="card" >
                    <div className="card-header bg-green ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">SELECTED DATA LIST</h3>
                        <div className="card-tools">
                            <div className="input-group input-group-sm">
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="col-12">
                            <table className="table table-sm table-striped table-bordered">
                                {tdata}
                            </table>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-primary float-right mx-2" onClick={this.btn_next_2}><i
                            className="fas fa-arrow-right"></i>
                        </button>
                        <button type="button" className="btn btn-danger float-right mx-2" onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz:1,
                            })
                        }}><i
                            className="fas fa-arrow-left"></i>
                        </button>

                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        let count=0;
        let tdata=this.state.drivers.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.driver_name}</td>
                <td>{value.acct_no}</td>
                <td>{value.amount_con}</td>
            </tr>)
        })
       let url_1=myconfig.redirecttoreport_generator+"?id=2&filled_1="+this.state.letter_no
       let url_2=myconfig.redirecttoreport_generator+"?id=3&filled_1="+this.state.letter_no
       let url_3=myconfig.redirecttofinance_document+"?ref_no="+this.state.ref_nos;
        return (<>
            <div className="col-md-12" >
                <div className="row no-print">
                    <a href="" onClick={event => {
                        event.preventDefault();
                        window.print()
                    }}>Print</a>
                </div>
                <div className="row no-print">
                    <a href={url_1} target="_blank">Get Payment Detail</a>
                </div>
                <div className="row no-print">
                    <a href={url_2} target="_blank">Get Payment Detail</a>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-4 d-flex align-items-center justify-content-center">
                                <img className="img w-100" src={nt}></img>
                            </div>
                            <div className="col-8">
                            </div>
                            <div className="col-6">
                                <br/>
                                <br/>
                                <p style={{fontSize:"25px"}}><strong>Date: </strong>{this.state.report_date}</p>
                            </div>
                            <div className="col-6 text-right">
                                <br/>
                                <br/>
                                <p style={{fontSize:"25px"}}><strong>Letter #: </strong>{this.state.letter_no}</p>
                            </div>
                            <div className="col-1">
                            </div>
                            <div className="col-11">
                                <br/>
                                <br/>
                                <br/>
                                <div className="row">
                                    <div className="col-1 text-left">
                                        <p style={{fontSize:"26px"}}><strong>To :- </strong></p>
                                    </div>
                                    <div className="col-11 text-left">
                                        <p style={{fontSize:"26px"}}>Commercial Bank of Ethiopia</p>
                                    </div>
                                    <div className="col-1 text-left">
                                    </div>
                                    <div className="col-11 text-left">
                                        <p style={{fontSize:"26px"}}>Dire-Dawa Main Branch</p>
                                    </div>
                                    <div className="col-1 text-left">
                                    </div>
                                    <div className="col-11 text-left">
                                        <p style={{fontSize:"26px"}}>Dire-Dawa</p>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div className="col-1 text-left">
                                    </div>
                                    <div className="col-11 text-left">
                                        <p style={{fontSize:"26px"}}><strong>Subject: </strong>Driver Perdium Payment</p>
                                    </div>
                                    <br/>
                                    <br/>
                                    <div className="col-12 text-justify">
                                        <p style={{fontSize:"24px"}}>Please transfer the following payment from our account Number 1000443970103 for a total of birr {this.state.total_amount} ({this.state.money_to_birr})with your bank as per the attached accounts of our payee in your bank. You are also authorized to deduct the necessary bank service charge from our account.</p>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        ------------------------------------------------- <br/><br/><br/>
                                        ------------------------------------------------- <br/><br/><br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>

                                    </div>

                                </div>
                            </div>
                            <div className="col-12">
                                <img className="img w-100" src={ntfooter}></img>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th className="col-md-1">S/N</th>
                                <th className="col-md-5">Name</th>
                                <th className="col-md-5">ACCT N0</th>
                                <th className="col-md-2">Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        return (
            <>
                <Nav type="opex_cost_payment_all"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_cost_payment_all;
