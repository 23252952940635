import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Report_generator extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),

            report_templates:[],
            vehicles_all:[],
            vehicles:[],
            vehicles_datas:[],

            report_template_selected:null,
            vehicles_data_selected:null,

            is_vehicle:false,
            is_st:false,
            is_ed:false,
            is_single_based:false,
            is_filled_1:false,
            filled_label:"",
            filled_1:"",

            report_exe:false,
            rows_1:[],
            rows_2:[],
        }
    }

    handelchange_report_template = (report_template_selected) => {
        let temp = this.state.report_templates.filter(v => {
            if (v.value === report_template_selected.value)
                return v;
        })
        console.log(temp)
        if (temp.length > 0) {
            let vehicles_datas=this.state.vehicles_all;
            if(temp[0].is_single_based)
                vehicles_datas=this.state.vehicles
            //report operation master trip
            let url="";
            if(temp[0].value===1)
                url=myconfig.report_op_master_trip
            else if(temp[0].value===2)
                url=myconfig.report_fin_detail_payment
            else if(temp[0].value===3)
                url=myconfig.report_fin_detail_bank
            else if(temp[0].value===4)
                url=myconfig.report_op_master_tracking
            else if(temp[0].value===5)
                url=myconfig.report_op_tat
            else if(temp[0].value===6)
                url=myconfig.report_fin_gp_by_letter
            else if(temp[0].value===7)
                url=myconfig.report_fin_gp_by_fo_date
            else if(temp[0].value===8)
                url=myconfig.report_master_sale
            else if(temp[0].value===9)
                url=myconfig.report_master_transport
            else if(temp[0].value===10)
                url=myconfig.report_cement_allocation
            else if(temp[0].value===11)
                url=myconfig.report_truck_allocation
            else if(temp[0].value===12)
                url=myconfig.report_master_cement_allocation
            this.setState({
                report_template_selected: report_template_selected,
                is_st: temp[0].is_st,
                is_ed: temp[0].is_ed,
                is_vehicle: temp[0].is_vehicle,
                is_filled_1: temp[0].is_filled_1,
                filled_label: temp[0].filled_label,
                vehicles_datas: vehicles_datas,
                url: url,
            })
        }
    }
    handelchange_vehicles_data = (vehicles_data_selected) => {
        this.setState({
            vehicles_data_selected: vehicles_data_selected,
        })
    }

    componentDidMount() {
        this.fetchdata();
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.report_generator;
        let id = new URLSearchParams(this.props.location.search).get("id");
        let vehicle_id = new URLSearchParams(this.props.location.search).get("vehicle_id");
        let st = new URLSearchParams(this.props.location.search).get("st");
        let ed = new URLSearchParams(this.props.location.search).get("ed");
        let filled_1 = new URLSearchParams(this.props.location.search).get("filled_1");

        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    report_templates: res.data.report_templates,
                    vehicles_all: res.data.vehicles_all,
                    vehicles: res.data.vehicles,
                },()=>{
                    let test=res.data.report_templates.filter(v=>{
                        if(v.value==id){
                            return v;
                        }
                    })
                    if(test.length>0){
                        let report_template_selected=null;
                        let vehicles_data_selected=null;
                        report_template_selected={value:test[0].value,label:test[0].label}
                        let vehicles_datas=this.state.vehicles_all;
                        if(test[0].is_single_based)
                            vehicles_datas=this.state.vehicles
                        //report operation master trip
                        let url="";
                        if(test[0].value===1)
                            url=myconfig.report_op_master_trip
                        else if(test[0].value===2)
                            url=myconfig.report_fin_detail_payment

                       let test2=res.data.vehicles_all.filter(v=>{
                            if(v.value==vehicle_id){
                                return v;
                            }
                        })
                        if(test2.length>0){
                            vehicles_data_selected={value:test2[0].value,label:test2[0].label}
                        }

                        this.setState({
                            report_template_selected: report_template_selected,
                            vehicles_data_selected: vehicles_data_selected,
                            is_st: test[0].is_st,
                            is_ed: test[0].is_ed,
                            is_vehicle: test[0].is_vehicle,
                            is_filled_1: test[0].is_filled_1,
                            filled_label: test[0].filled_label,
                            vehicles_datas: vehicles_datas,
                            url: url,
                            st:st??new Date().toISOString().slice(0, 10),
                            ed:ed??new Date().toISOString().slice(0, 10),
                            filled_1:filled_1??"",
                        },()=>{
                            this.get_report();
                        })
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btnget_report = (event) => {
        event.preventDefault();
        this.get_report()
    }
    get_report = () => {
        if(this.state.report_template_selected==null){
            Swal.fire({
                title: 'Error!',
                text: "Please select report template",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.url===""){
            Swal.fire({
                title: 'Error!',
                text: "Please select report template",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let redirecttologin = myconfig.redirecttologin;
            let vehicle_id=this.state.vehicles_data_selected==null?-1:this.state.vehicles_data_selected.value
            this.setState({
                report_exe:false,
            })
            axios.post(this.state.url, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                st:this.state.st,
                ed:this.state.ed,
                vehicle_id:vehicle_id,
                filled_1:this.state.filled_1,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: "Report Fetched successfully",
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v=>{
                        this.setState({
                            rows_1:res.data.rows_1,
                            rows_2:res.data.rows_2,
                            report_exe:true,
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    render() {
        let is_st=null;
        if(this.state.is_st)
            is_st=<div className="form-group">
                <label>Start Date</label>
                <input className="form-control form-control-sm" type="date"
                       name="st" value={this.state.st}
                       onChange={this.inputmgt}/>
            </div>

        let is_ed=null;
        if(this.state.is_ed)
            is_ed=  <div className="form-group">
                <label>End Date</label>
                <input className="form-control form-control-sm" type="date"
                       name="ed" value={this.state.ed}
                       onChange={this.inputmgt}/>
            </div>

        let is_vehicle=null;
        if(this.state.is_vehicle)
            is_vehicle=  <div className="form-group">
                <label>Vehicle</label>
                <Select
                    value={this.state.vehicles_data_selected}
                    onChange={this.handelchange_vehicles_data}
                    options={this.state.vehicles_datas}
                />
            </div>

        let is_filled_1=null;
        if(this.state.is_filled_1)
            is_filled_1=<div className="form-group">
                <label>{this.state.filled_label}</label>
                <input className="form-control form-control-sm" type="text"
                       name="filled_1" value={this.state.filled_1}
                       onChange={this.inputmgt} placeholder={this.state.filled_label}/>
            </div>
        let report=null;
        if(this.state.report_exe){
            let tdata=this.state.rows_1.map(v=>{
                let temps=this.state.rows_2.filter(v2=>{
                    if(v2.id===v.id)
                        return v2;
                })
                let rows=temps.map(v3=>{
                    if(v3.type==1)
                        return(<th>{v3.data}</th>)
                    else
                    {
                        return(<td>{v3.data}</td>)
                    }
                })
                return (<tr>
                    {rows}
                </tr>)
            })
            report=  <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Report Result</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                           onClick={this.btnexport_report}>Download .xlsx</a></div>
                            <div className="table" id="section_to_print">
                                <table id="report" className="table table-sm table-bordered table-responsive">
                                    {tdata}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return (
            <>
                <Nav type="report_generator"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Template</label>
                                                        <Select
                                                            value={this.state.report_template_selected}
                                                            onChange={this.handelchange_report_template}
                                                            options={this.state.report_templates}
                                                        />
                                                    </div>
                                                    {is_st}
                                                    {is_ed}
                                                    {is_vehicle}
                                                    {is_filled_1}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <button type="button" className="btn btn-primary float-right btn-sm"
                                                    onClick={event => this.btnget_report(event,this.state.st,this.state.ed,false)}>Execute
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                </div>
                                {report}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Report_generator;
