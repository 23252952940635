import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import {Modal} from "react-bootstrap";


let source = axios.CancelToken.source();

class Update_allocate extends Component {

    constructor() {
        super();
        this.state = {
            vehicles: [],
            filtered: [],
            txtsearch: '',
            wiz: 1,

            assign_owns:[],

            road_routes: [],
            fuel_datas: [],
            drivers: [],
            trailers: [],
            locations: [],
            orders: [],


            driver_selected: null,
            trailer_selected: null,
            ini_selected: null,
            final_selected: null,
            order_selected: null,

            ret_data: {},

            cushow: false,
            modal_type: -1,
            assign_own_id: -1,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    handelchange_driver = (driver_selected) => {
        this.setState({
            driver_selected: driver_selected,
        })
    }
    handelchange_trailer = (trailer_selected) => {
        this.setState({
            trailer_selected: trailer_selected,
        })
    }
    handelchange_ini = (ini_selected) => {
        this.setState({
            ini_selected: ini_selected,
            road_routes: [],
            final_selected: null,
            order_selected: null,

        })
    }
    handelchange_final = (final_selected) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_final_route;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            order_id: this.state.order_selected.value,
            final_loc_id: final_selected.value,
            vehicle_id: this.state.vehicle_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if (res.data.road_routes.length > 0) {
                    let temp = this.state.road_routes;
                    if (temp.length >= 2) {
                        temp.length = 2
                        temp.push({
                            route: res.data.road_routes[0].route,
                            status: res.data.road_routes[0].status,
                            km: res.data.road_routes[0].km,
                            allownce: res.data.road_routes[0].allownce,
                            fuel: res.data.road_routes[0].fuel,
                            parking: res.data.road_routes[0].parking,
                            other: res.data.road_routes[0].other,
                            is_found: res.data.road_routes[0].is_found,
                        })
                        this.setState({
                            road_routes: temp,
                            final_selected: final_selected,
                        })
                    }

                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    handelchange_order = (order_selected) => {
        if (order_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.ini_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select initial location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.client_attach_for_update_allocate;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                ini_id: this.state.ini_selected.value,
                order_id: order_selected.value,
                id: this.state.assign_own_id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {

                    this.setState({
                        road_routes: res.data.road_routes,
                        order_data: res.data.order_data,
                        containers: res.data.containers,
                        order_selected: order_selected,
                        final_selected: null,
                        container_selected: null,
                        container_2_selected: null,
                        size: '',
                        actual_weight:400,
                        size_2: '',
                        volume_2: 400,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    handelchange_container = (container_selected) => {
        let temp = this.state.containers.filter(v=>  {
            if (v.value === container_selected.value)
                return v;
        })
        if (temp.length > 0)
            this.setState({
                container_selected: container_selected,
                size:temp[0].size,
                volume:temp[0].volume,
            })
    }
    handelchange_2_container = (container_2_selected) => {
        let temp = this.state.containers.filter(v=>  {
            if (v.value === container_2_selected.value)
                return v;
        })
        if (temp.length > 0)
            this.setState({
                container_2_selected: container_2_selected,
                size_2:temp[0].size,
                volume_2:temp[0].volume,
            })
    }
    handelfuel_rates = (fuel_rate_selected) => {
        let temp=0;
        if(fuel_rate_selected!==null) {
            temp = this.state.fuel_rates.filter(v => {
                if (v.value == fuel_rate_selected.value) {
                    return v;
                }
            });
            if(temp.length>0)
                temp=temp[0].fuel_rate
            else
                temp=0;
        }

        this.setState({
            fuel_rate_selected: fuel_rate_selected,
            fuel_rate: temp,
        })
    }
    fetchdata = () => {
        let fright_no = new URLSearchParams(this.props.location.search).get("fright_no");
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_update_allocate;
        this.setState({
            vehicles: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no:fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.vehicles,
                    filtered: res.data.vehicles,
                },()=>{
                    if(res.data.isfound){
                        this.fetchdata2(res.data.assign_own_id,res.data.vehicle_id)
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = (assign_own_id,vehicle_id) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_assign_own_for_update_allocate;
        this.setState({})
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: assign_own_id,
            vehicle_id: vehicle_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz:3,
                    assign_own_id:assign_own_id,
                    vehicle_id:vehicle_id,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                    plate_no: res.data.plate_no,
                    driver_selected: res.data.driver_selected,
                    trailer_selected: res.data.trailer_selected,
                    fright_no: res.data.fright_no,
                    locations: res.data.locations,
                    orders: res.data.orders,
                    order_data: res.data.order_data,
                    ini_selected: res.data.ini_selected,
                    final_selected: res.data.final_selected,
                    order_selected: res.data.order_selected,
                    fuel_datas: res.data.fuel_datas,
                    road_routes: res.data.road_routes,
                    actual_weight: res.data.actual_weight,
                    fuel_rates: res.data.fuel_rates,
                    allocated_date: res.data.allocated_date,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    btn_manage = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_assign_own_for_update_allocate;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id: id,
                    assign_owns: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_change = (event,assign_own_id,vehicle_id) => {
        event.preventDefault();
        this.fetchdata2(assign_own_id,vehicle_id)
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.vehicles;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.plate_no.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt2 = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            re_fund_reason: value,
        })
    }

    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filtered.map(value => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_manage(event, value.id)}>Manage</a>
                </td>
                <td>{value.plate_no}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <form onSubmit={event => {
                            if(this.state.fright_no!=""){
                                let redirecttoupdate_allocate=myconfig.redirecttoupdate_allocate+"?fright_no="+this.state.fright_no
                                window.open(redirecttoupdate_allocate, "_self");
                            }

                        }}>
                            <div className="form-group">
                                <label>Fright No</label>
                                <input className="form-control" type="text"
                                       name="fright_no" value={this.state.fright_no}
                                       onChange={this.inputmgt}/>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-12">
                        <table id="report" className="table table-head-fixed">
                            <thead>
                            <tr>
                                <th className="col-md-1">#</th>
                                <th className="col-md-2">Action</th>
                                <th className="col-md-9">Truck No.</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let count = 0;
        let tdata = this.state.assign_owns.map(value => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_change(event, value.id,value.vehicle_id)}>Change</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.fright_no}</td>
                <td>{value.clinet}</td>
                <td>{value.cargo}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-2">Truck No.</th>
                            <th className="col-md-1">Fright #</th>
                            <th className="col-md-2">Customer</th>
                            <th className="col-md-1">Cargo</th>
                            <th className="col-md-2">Origin</th>
                            <th className="col-md-2">Destination</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_3 = () => {
        let isdisabled_customer = true;
        if (this.state.ini_selected != null)
            isdisabled_customer = false;

        let isdisabled_final = true;
        let detail = null;
        let detail_2 = null;
        if (this.state.order_selected != null) {
            isdisabled_final = false;
            detail = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.order_no}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Orgin</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.org}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Destination</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.des}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Millage</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.distance}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Cargo</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.cargo}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Description of Good</label>
                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                  onChange={this.inputmgt} value={this.state.order_data.des_good ?? "NA"}></textarea>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Types of Load</label>
                        <input disabled={true} className="form-control form-control-sm text-capitalize"
                               value={this.state.order_data.tol}/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </>
            if (this.state.order_data.tol === "container") {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label># Container to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.no_container}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_1</label>
                            <Select
                                value={this.state.container_selected}
                                onChange={this.handelchange_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size"
                                   value={this.state.size}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm"
                                   name="actual_weight"
                                   value={this.state.actual_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_2</label>
                            <Select
                                value={this.state.container_2_selected}
                                onChange={this.handelchange_2_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size_2"
                                   value={this.state.size_2}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="volume_2"
                                   value={this.state.volume_2}/>
                        </div>
                    </div>
                </>
            } else {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Left Weight (q) to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.avl_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Actual Weight (q)</label>
                            <input type="text" className="form-control form-control-sm" name="actual_weight"
                                   value={this.state.actual_weight} onChange={this.inputmgt}
                                   placeholder="Weight"/>
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">

                    </div>
                </>
            }
        }

        let km = 0;
        let allowence = 0;
        let fuel = 0;
        let parking = 0;
        let other = 0;
        let tdata = this.state.road_routes.map(v => {
            let bg = "bg-white"
            if (!v.is_found)
                bg = "bg-danger"
            if (v.is_found) {
                km = km + v.km
                allowence = allowence + v.allownce
                fuel = fuel + v.fuel
                parking = parking + v.parking
                other = other + v.other
            }
            return (<tr className={bg}>
                <td>{v.route}</td>
                <td>{v.status}</td>
                <td>{v.km}</td>
                <td>{v.allownce}</td>
                <td>{v.fuel}</td>
                <td>{v.parking}</td>
                <td>{v.other}</td>
            </tr>)
        })

        let det_fuel = this.state.fuel_datas.map(v => {
            return (<>
                <div className="col-md-2 text-sm"><a href=""
                                                     onClick={event => this.modal_remove_fuel(event, v.id)}>X</a></div>
                <div className="col-md-3 text-sm">{v.station}</div>
                <div className="col-md-3 text-sm">{v.fuel_rate}</div>
                <div className="col-md-2 text-sm">{(v.total_fuel_l * 1).toFixed(2)}</div>
                <div className="col-md-2 text-sm">{(v.total_fuel_etb * 1).toFixed(2)}</div>
            </>)
        })

        let total_fuel_l = 0
        let total_fuel_etb = 0
        this.state.fuel_datas.map(v => {
            total_fuel_l = ((total_fuel_l * 1) + (v.total_fuel_l * 1)).toFixed(2)
            total_fuel_etb = ((total_fuel_etb * 1) + (v.total_fuel_etb * 1)).toFixed(2)
        })

        let expected_total_fuel_l = 0
        this.state.road_routes.map(v => {
            if (!isNaN(v.fuel))
                expected_total_fuel_l = ((expected_total_fuel_l*1) + (v.fuel*1)) * 1
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Assign Vehicle</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}
                                               placeholder="Plate #"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.fright_no}
                                               name="fright_no"
                                               placeholder="Fright #"
                                               onChange={this.inputmgt}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.allocated_date}
                                               name="allocated_date"
                                               onChange={this.inputmgt}
                                               type="date"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.ini_selected}
                                            onChange={this.handelchange_ini}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            isDisabled={isdisabled_customer}
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <Select
                                            isDisabled={isdisabled_final}
                                            value={this.state.final_selected}
                                            onChange={this.handelchange_final}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 pb-2">
                                    <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Fuel Data </strong>
                                            <a className="text-sm" href=""
                                               onClick={event => {
                                                   event.preventDefault();
                                                   this.setState({
                                                       cushow: true,
                                                       modal_type: 1,
                                                       fuel_rate_selected: null,
                                                       fuel_rate: '',
                                                       fuel: '',
                                                   })
                                               }}>(Add Fuel)
                                            </a>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-3 border"><strong>Station</strong></div>
                                        <div className="col-md-3 border"><strong>Fuel Rate</strong></div>
                                        <div className="col-md-2 border"><strong>Total Fuel(l)</strong></div>
                                        <div className="col-md-2 border"><strong>Total Fuel(ETB)</strong></div>
                                        {det_fuel}
                                        <div className="col-md-12 pt-3"><strong>Total
                                            Fuel(ETB): </strong>{total_fuel_etb}</div>
                                        <div className="col-md-12 pt-3"><strong>Total Fuel(L): </strong>{total_fuel_l}
                                        </div>
                                        <div className="col-md-12 pt-3"><strong>Expected
                                            Fuel(L): </strong>{expected_total_fuel_l}</div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-2"></div>
                                {detail}
                                {detail_2}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btnassign}> Assign
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-danger ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Mewecha Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr>
                                            <th className="col-md-2">Route</th>
                                            <th className="col-md-2">Status</th>
                                            <th className="col-md-1">KM</th>
                                            <th className="col-md-2">Allowance</th>
                                            <th className="col-md-2">Fuel</th>
                                            <th className="col-md-2">Parking</th>
                                            <th className="col-md-2">Others</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tdata}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        let dis=null;
        if(this.state.modal_type===1)
        {
            dis= <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Fuel Data</h3>
                            <div className="card-tools">
                                <button type="button" onClick={this.hidecu} className="btn btn-tool">
                                    <i className="fas fa-times"/>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Station</label>
                                            <Select
                                                value={this.state.fuel_rate_selected}
                                                onChange={this.handelfuel_rates}
                                                options={this.state.fuel_rates}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Fuel Rate</label>
                                            <input disabled={true} className="form-control" type="text"
                                                   name="fuel_rate" onChange={this.inputmgt}
                                                   value={this.state.fuel_rate} placeholder={"Rate"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Fuel</label>
                                            <input className="form-control" type="text"
                                                   name="fuel" onChange={this.inputmgt}
                                                   value={this.state.fuel} placeholder={"Fuel"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <button className="btn-primary form-control" onClick={this.modal_add_fuel}>Add Fuel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Body>
                    {dis}
                </Modal.Body>
            </Modal>
        )
    }

    modal_add_fuel=(event)=> {
        event.preventDefault()
        if (this.state.fuel_rate_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select fuel station",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.fuel === "") {
            Swal.fire({
                title: 'Error!',
                text: "Fuel rate is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (isNaN(this.state.fuel)) {
            Swal.fire({
                title: 'Error!',
                text: "Fuel rate must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let fuel_datas = this.state.fuel_datas;
            let total_fuel_etb = 0;
            let total_fuel_l = 0;
            let temp = this.state.fuel_rates.filter(v => {
                if (v.value == this.state.fuel_rate_selected.value) {
                    return v;
                }
            });
            let id=fuel_datas.length+1
            if (temp.length > 0) {
                fuel_datas.push({
                    id: id,
                    fuel_rate_id: temp[0].value,
                    station: temp[0].label,
                    fuel_rate: temp[0].fuel_rate,
                    total_fuel_l: this.state.fuel,
                    total_fuel_etb: this.state.fuel*temp[0].fuel_rate,
                })
            }

            fuel_datas.map(v => {
                total_fuel_l = (total_fuel_l*1) + (v.total_fuel_l*1)
                total_fuel_etb = total_fuel_etb + v.total_fuel_etb
            })

            this.setState({
                fuel_datas: fuel_datas,
                total_fuel_l: total_fuel_l,
                total_fuel_etb: total_fuel_etb,
                cushow:false,
            })

        }
    }
    modal_remove_fuel=(event,id)=> {
        event.preventDefault()
        let fuel_datas=this.state.fuel_datas;
        fuel_datas=fuel_datas.filter(v=>{
            if(v.id!==id)
                return v;
        })
        this.setState({
            fuel_datas: fuel_datas,
        })
    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
        })
    }
    btnassign = (event) => {
        event.preventDefault();

        if (this.state.driver_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select driver",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.ini_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select initial location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.order_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.final_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select final location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let trailer = this.state.trailer_selected == null ? null : this.state.trailer_selected.value;
            let fin_loc = this.state.final_selected == null ? null : this.state.final_selected.value;
            let container_1 = this.state.container_selected == null ? null : this.state.container_selected.value;
            let container_2 = this.state.container_2_selected == null ? null : this.state.container_2_selected.value;
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.vehicle_assign_own_update;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id: this.state.id,
                id: this.state.assign_own_id,
                driver_id: this.state.driver_selected.value,
                trailer_id: trailer,
                ini_loc_id: this.state.ini_selected.value,
                fin_loc_id: fin_loc,
                order_id: this.state.order_selected.value,
                volume: this.state.actual_weight,
                fright_no: this.state.fright_no,
                fuel_datas: this.state.fuel_datas,
                container_1: container_1,
                container_2: container_2,
                allocated_date: this.state.allocated_date,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.warning==true)
                        Swal.fire({
                            title: 'Warning!',
                            text: res.data.msg,
                            icon: 'warning',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            })
                        })
                    else
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            })
                        })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="update_allocate"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Update Allocation</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Update_allocate;
