import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Finance_fo_by_plate_no extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            reports: [],
            vehicles:[],
            vehicle_selected: null,
        }
    }
    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected: vehicle_selected,
        })
    }
    componentDidMount() {
        this.fetchdata();
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    btnget_report = (event) => {
        event.preventDefault();
        this.getreport();
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_finance_fo_by_plate_no;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st: this.state.st,
            ed: this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    getreport_by_plate_no = (event) => {
        event.preventDefault();
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.finance_fo_by_plate_no;
            this.setState({
                reports: [],
                data:"",
                fright_no:"",
            })
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id:this.state.vehicle_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        reports: res.data.reports,
                        data:res.data.data,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });

        }

    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    tabledata=()=> {
        let retdata = this.state.reports.map(value => {
            return (<tr>
                <td>{value.fo_date}</td>
                <td>{value.fright_no}</td>
                <td>{value.plate_no}</td>
                <td>{value.trailer_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.company_name}</td>
                <td>{value.loading}</td>
                <td>{value.unloading}</td>
                <td>{value.name}</td>
                <td>{value.volume}</td>
                <td>{value.distance}</td>
                <td>{value.transport_rate}</td>
                <td>{value.revenue}</td>
                <td>{value.f_qty_1}</td>
                <td>{value.f_staton_1}</td>
                <td>{value.f_cost_1}</td>
                <td>{value.f_qty_2}</td>
                <td>{value.f_staton_2}</td>
                <td>{value.f_cost_2}</td>
                <td>{value.f_qty_3}</td>
                <td>{value.f_staton_3}</td>
                <td>{value.f_cost_3}</td>
                <td>{value.f_qty_4}</td>
                <td>{value.f_staton_4}</td>
                <td>{value.f_cost_4}</td>
                <td>{value.t_fuel_cost}</td>
                <td>{value.allowance-value.re_fund}</td>
                <td>{value.parking}</td>
                <td>{value.boarder_charge}</td>
                <td>{value.other_expence}</td>
                <td>{value.total_expence}</td>
                <td>{value.gp}</td>
            </tr>)
        });
        return (<>
            {retdata}
        </>)
    }

    render() {
        return (
            <>
                <Nav type="finance_fo_by_plate_no"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">FO By Plate #</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Truck No</label>
                                                            <Select
                                                                value={this.state.vehicle_selected}
                                                                onChange={this.handelchange_vehicle}
                                                                options={this.state.vehicles}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <button type="button" className="btn btn-primary float-right"
                                                    onClick={this.getreport_by_plate_no}><i className="fas fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Result</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                                               onClick={this.btnexport_report}>Download .xlsx</a></div>
                                                <div className="table" id="section_to_print">
                                                    <table id="report" className="table table-responsive">
                                                        <thead>
                                                        <tr>
                                                            <th>FO DATE</th>
                                                            <th>FREIGHT ORDER #</th>
                                                            <th>TRUCK No.</th>
                                                            <th>TRAILER NO.</th>
                                                            <th>DRIVER NAME</th>
                                                            <th>CUSTOMER</th>
                                                            <th>ORIGIN</th>
                                                            <th>DESTINATION</th>
                                                            <th>CARGO</th>
                                                            <th>LOADED WEIGHT</th>
                                                            <th>TOTAL KMS (ONE WAY)</th>
                                                            <th>TRANSPORT RATE PER QUINTAL</th>
                                                            <th>REVENUE BIRR</th>
                                                            <th>FUEL QTY ISSUED AT ORIGIN</th>
                                                            <th>FILLING STATION</th>
                                                            <th>FUEL COST AT ORIGIN</th>
                                                            <th>FUEL QTY POINT 1</th>
                                                            <th>FILLING STATION</th>
                                                            <th>FUEL COST POINT 1</th>
                                                            <th>FUEL QTY POINT 2</th>
                                                            <th>FILLING STATION</th>
                                                            <th>FUEL COST POINT 2</th>
                                                            <th>FUEL QTY POINT 3</th>
                                                            <th>FILLING STATION</th>
                                                            <th>FUEL COST POINT 3</th>
                                                            <th>TOTAL FUEL COST</th>
                                                            <th>PER DIEM</th>
                                                            <th>HANDLING</th>
                                                            <th>BORDER CHARGES</th>
                                                            <th>ANY OTHER EXPENSE</th>
                                                            <th>TOTAL EXPENSE</th>
                                                            <th>GP</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.tabledata()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Finance_fo_by_plate_no;
