import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Follow_up_movement extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            reports: [],
            vehicles:[],
            vehicle_selected: null,
        }
    }
    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected: vehicle_selected,
        })
    }
    componentDidMount() {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_r_movement;
        this.setState({
            reports: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st: this.state.st,
            ed: this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.datas,
                },()=>{
                    let vehicle_id = new URLSearchParams(this.props.location.search).get("vehicle_id");
                    let st = new URLSearchParams(this.props.location.search).get("st");
                    let ed = new URLSearchParams(this.props.location.search).get("ed");
                    if (
                        (vehicle_id !== null && vehicle_id !== "") &&
                        (st !== null && st !== "") &&
                        (ed !== null && ed !== "")

                    ) {
                        this.getreport(vehicle_id,st,ed)
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetch_veh_obj = (vehicle_id) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_obj_for_r_movement;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id: vehicle_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(res.data.isfound) {
                    this.setState({
                        st: res.data.st_interface,
                        ed: res.data.ed_interface,
                        vehicle_selected: res.data.vehicle_selected,
                    }, () => {
                        this.getreport(res.data.st, res.data.ed, true)
                    })
                }
               /* Swal.fire({
                    title: 'Success!',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
               */
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btnget_report = (event,st,ed,isdatetime) => {
        event.preventDefault();
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let vehicle_id=this.state.vehicle_selected.value;
            let st=this.state.st+" 00:00:00";
            let ed=this.state.ed+" 23:59:59";
            this.getreport(vehicle_id,st,ed);
        }

    }
    getreport = (vehicle_id,st,ed) => {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.r_movement;
            this.setState({
                reports: [],
            })
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                st: st,
                ed: ed,
                vehicle_id: vehicle_id,
                eid: this.state.eid,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: "Report fetched successfully",
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    })
                    this.setState({
                        reports: res.data.reports,
                        eid:res.data.eid,
                        st:res.data.st,
                        ed:res.data.ed,
                        vehicle_selected:res.data.vehicle_selected,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    tabledata=()=> {
        let retdata = this.state.reports.map(value => {
            return (<tr>
                <td>{value.plateno}</td>
                <td>{value.sttime}</td>
                <td>{value.stloc}</td>
                <td>{value.edtime}</td>
                <td>{value.edloc}</td>
                <td>{value.km}</td>
                <td>{value.tatloc}</td>
            </tr>)
        });
        return (<>
            {retdata}
        </>)
    }
    render() {
        return (
            <>
                <Nav type="follow_up_movement"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Movement Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Truck No</label>
                                                            <Select
                                                                value={this.state.vehicle_selected}
                                                                onChange={this.handelchange_vehicle}
                                                                options={this.state.vehicles}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="st" value={this.state.st}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="ed" value={this.state.ed}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <button type="button" className="btn btn-primary float-right"
                                                    onClick={event => this.btnget_report(event,this.state.st,this.state.ed,false)}><i className="fas fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Result</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                                               onClick={this.btnexport_report}>Download .xlsx</a></div>
                                                <div className="table" id="section_to_print">
                                                    <table id="report" className="table table-responsive">
                                                        <thead>
                                                        <tr>
                                                            <th>TRUCK No.</th>
                                                            <th>Start Time</th>
                                                            <th>Start Loc</th>
                                                            <th>End Time</th>
                                                            <th>End Loc</th>
                                                            <th>Millage</th>
                                                            <th>Time At Loc</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.tabledata()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_movement;
