import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Order extends Component {

    constructor() {
        super();
        this.state = {
            is_exe:false,
            btn_text:"Create",

            datas: [],
            filtered: [],

            txtsearch: '',

            clients: [],
            clients_main: [],
            cargos: [],
            locations: [],

            wiz: 1,
            client_selected: null,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            iscreate: true,
            id: -1,
            isdoc: -1,
            des: '',
            weight: '',
            mode_of_transport: '',
            company_name: '',
            client_type: '',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            tol: '-1',
            container_no: '',
            transport_rate: '',
            st_date: '',
            ed_date: '',
            containers: [],

        }
    }
    handelchange_client = (client_selected) => {
        this.setState({
            client_selected:client_selected,
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
        },()=>{
            let temp=this.state.clients_main.filter(v=>{
                console.log(client_selected.value)
                console.log(v.id)
                if(v.id===client_selected.value)
                    return v;
            })
            if(temp.length>0) {
                this.setState({
                    company_name: temp[0].company_name==null?"NA":temp[0].company_name,
                    client_type: temp[0].type==null?"NA":temp[0].type,
                    contact_person: temp[0].contact_person==null?"NA":temp[0].contact_person,
                    tin: temp[0].tin==null?"NA":temp[0].tin,
                    city: temp[0].city==null?"NA":temp[0].city,
                    address: temp[0].address==null?"NA":temp[0].address,
                    phone: temp[0].phone==null?"NA":temp[0].phone,
                    email: temp[0].email==null?"NA":temp[0].email,
                })
            }
        })
    }
    handelchange_cargo = (cargo_selected) => {
        this.setState({
            cargo_selected:cargo_selected,
        })
    }

    handelchange_orgin = (org_selected) => {
        if (!this.state.isexecuting) {
            if (this.state.des_selected != null) {
                this.setState({
                    org_selected: org_selected,
                },()=>this.check_route())

            } else {
                this.setState({
                    org_selected: org_selected,
                    isexecuting: false,
                })
            }
        }
    }
    handelchange_des = (des_selected) => {
        if (!this.state.isexecuting) {
            if (this.state.org_selected != null) {
                this.setState({
                    des_selected: des_selected,
                },()=>this.check_route())
            } else {
                this.setState({
                    des_selected: des_selected,
                    isexecuting: false,
                })
            }
        }
    }
    check_route = () => {
        if(this.state.org_selected!=null && this.state.des_selected!=null){
            this.setState({
                isexecuting: true,
            },()=>{
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.check_route;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    org: this.state.org_selected.value,
                    des: this.state.des_selected.value,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        this.setState({
                            millage:res.data.millage,
                            isexecuting: false,
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            })
        }
        else
        {
            this.setState({
                isexecuting: false,
            })
        }
    }
    componentDidMount() {
        this.fetchdata()
        this.fetchdata2()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_orders;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_order;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    clients: res.data.clients,
                    cargos: res.data.cargos,
                    clients_main: res.data.clients_main,
                    cocs: res.data.cocs,
                    locations: res.data.locations,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.company_name.toUpperCase().includes(value.toUpperCase()
                    )
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            btn_text:"Create",
            wiz: 2,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            client_selected: null,
            iscreate: true,
            id:-1,
            des:'',
            weight:'',
            mode_of_transport:'',
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            tol: '-1',
            container_no:'',
            transport_rate:'',
            st_date:'',
            ed_date:'',
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        this.getorder(id,2)
    }
    btnshow = (event,id) => {
        event.preventDefault();
        this.getorder(id,4)
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            client_selected: null,
            iscreate: true,
            id:-1,
            des:'',
            weight:'',
            mode_of_transport:'',
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            tol: '-1',
            container_no:'',
            volume:'',
            transport_rate:'',
            st_date:'',
            ed_date:'',
        })
    }
    btnback = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if (this.state.client_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select client.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.org_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select origin.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.des_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select destination.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.millage === "NA") {
            Swal.fire({
                title: 'Error!',
                text: "Root is not found",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.cargo_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select cargo.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.mode_of_transport !== "Uni Modal" && this.state.mode_of_transport !== "Multi Modal") {
            Swal.fire({
                title: 'Error!',
                text: "Select mode of transport",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.tol !== "normal" && this.state.tol !== "container") {
            Swal.fire({
                title: 'Error!',
                text: "Select type of load",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let noerror = true;
            if (this.state.tol === "container") {
                if (this.state.container_no == "") {
                    Swal.fire({
                        title: 'Error!',
                        text: "Container # must be numeric",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    noerror = false;
                } else if (isNaN(this.state.container_no)) {
                    Swal.fire({
                        title: 'Error!',
                        text: "Container # must be numeric",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    noerror = false;
                } else if (parseInt(this.state.container_no) < 1) {
                    Swal.fire({
                        title: 'Error!',
                        text: "Container # must be greater than 1",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    noerror = false;
                }
            }
            if (noerror) {
                if (this.state.tol === "container") {
                    this.setState({
                        wiz: 3,
                    })
                } else {
                    this.setState({
                        containers: [],
                    }, () => {
                        this.create()
                    });
                }
            }
        }
    }
    btncreate_withcontainer = (event) => {
        event.preventDefault();
        let temp = [];
        for (let i = 1; i <= this.state.container_no; i++) {
            temp.push({
                container: this.state["container_" + i],
                cost: this.state["cost_" + i],
                size: this.state["size_" + i],
                volume: this.state["volume_" + i],
                price: this.state["price_" + i],
            })
        }
        this.setState({
            containers: temp,
        }, () => this.create())
    }
    create=()=>{
        if(!this.state.is_exe) {
            this.setState({
                is_exe:true,
                btn_text:"Working...",
            })
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.create_order;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                client_id: this.state.client_selected.value,
                org_id: this.state.org_selected.value,
                des_id: this.state.des_selected.value,
                des: this.state.des,
                cargo_id: this.state.cargo_selected.value,
                mode_of_transport: this.state.mode_of_transport,
                tol: this.state.tol,
                no_container: this.state.container_no,
                weight: this.state.weight,
                transport_rate: this.state.transport_rate,
                st_date: this.state.st_date,
                ed_date: this.state.ed_date,
                containers: this.state.containers,
                isdoc: this.state.isdoc,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    this.setState({
                        is_exe:false,
                        btn_text:"Create",
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                            is_exe:false,
                            btn_text:"Create",
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    update=()=>{
        if(!this.state.is_exe) {
            this.setState({
                is_exe:true,
                btn_text:"Working...",
            })
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.edit_order;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                client_id: this.state.client_selected.value,
                org_id: this.state.org_selected.value,
                des_id: this.state.des_selected.value,
                des: this.state.des,
                cargo_id: this.state.cargo_selected.value,
                mode_of_transport: this.state.mode_of_transport,
                tol: this.state.tol,
                no_container: this.state.container_no,
                weight: this.state.weight,
                transport_rate: this.state.transport_rate,
                st_date: this.state.st_date,
                ed_date: this.state.ed_date,
                isdoc: this.state.isdoc,
                containers: this.state.containers,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                            is_exe:false,
                            btn_text:"Edit",
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnupdate = (event) => {
        event.preventDefault();
        if (this.state.client_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select client.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.org_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select origin.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.des_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select destination.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.millage === "NA") {
            Swal.fire({
                title: 'Error!',
                text: "Root is not found",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.cargo_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select cargo.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.mode_of_transport !== "Uni Modal" && this.state.mode_of_transport !== "Multi Modal") {
            Swal.fire({
                title: 'Error!',
                text: "Select mode of transport",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.tol !== "normal" && this.state.tol !== "container") {
            Swal.fire({
                title: 'Error!',
                text: "Select type of load",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let noerror = true;
            if (this.state.tol === "container") {
                if (this.state.container_no === "") {
                    Swal.fire({
                        title: 'Error!',
                        text: "Container # must be numeric",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    noerror = false;
                } else if (isNaN(this.state.container_no)) {
                    Swal.fire({
                        title: 'Error!',
                        text: "Container # must be numeric",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    noerror = false;
                } else if (parseInt(this.state.container_no) < 1) {
                    Swal.fire({
                        title: 'Error!',
                        text: "Container # must be greater than 1",
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    noerror = false;
                }
            }
            if (noerror) {
                if (this.state.tol === "container") {
                    this.setState({
                        wiz: 3,
                    })
                } else {
                    this.setState({
                        containers: [],
                    }, () => {
                        this.update()
                    });
                }
            }
        }
    }
    btnupdate_withcontainer = (event) => {
        event.preventDefault();
        let temp = [];
        for (let i = 1; i <= this.state.container_no; i++) {
            temp.push({
                container: this.state["container_" + i],
                cost: this.state["cost_" + i],
                size: this.state["size_" + i],
                volume: this.state["volume_" + i],
                price: this.state["price_" + i],
            })
        }
        this.setState({
            containers: temp,
        }, () => this.update())
    }
    getorder = (id,wiz) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_order;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: wiz,
                    client_selected: {value:res.data.data.client_id,label:res.data.data.company_name},
                    cargo_selected: {value:res.data.data.cargo_id,label:res.data.data.name},
                    org_selected: {value:res.data.data.org_id,label:res.data.data.org},
                    des_selected: {value:res.data.data.destination_id,label:res.data.data.destination},
                    iscreate: false,
                    btn_text:"Edit",
                    id: id,
                    order_no: res.data.data.order_no,
                    des: res.data.data.des,
                    weight: res.data.data.weight,
                    transport_rate: res.data.data.transport_rate,
                    mode_of_transport: res.data.data.mode_of_transport,
                    client_type: res.data.data.type,
                    tol: res.data.data.tol,
                    st_date: res.data.data.st_date,
                    ed_date: res.data.data.ed_date,
                    isdoc: res.data.data.isdoc,
                    company_name: res.data.data.company_name==null?"NA":res.data.data.company_name,
                    contact_person: res.data.data.contact_person==null?"NA":res.data.data.contact_person,
                    tin: res.data.data.tin==null?"NA":res.data.data.tin,
                    city: res.data.data.city==null?"NA":res.data.data.city,
                    address: res.data.data.address==null?"NA":res.data.data.address,
                    phone: res.data.data.phone==null?"NA":res.data.data.phone,
                    email: res.data.data.email==null?"NA":res.data.data.email,
                    millage: res.data.data.millage,
                    container_no: res.data.data.container_no,
                    containers: res.data.order_containers,
                })
                for(let i=0; i<res.data.order_containers.length; i++){
                    let v_1="container_"+(i+1);
                    let v_2="cost_"+(i+1);
                    let v_3="size_"+(i+1);
                    let v_4="volume_"+(i+1);
                    let v_5="price_"+(i+1);

                    this.setState({
                        [v_1]:res.data.order_containers[i].container_no,
                        [v_2]:res.data.order_containers[i].cost,
                        [v_3]:res.data.order_containers[i].size,
                        [v_4]:res.data.order_containers[i].volume,
                        [v_5]:res.data.order_containers[i].price,
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value.id)}><i className="fas fa-edit px-1"></i></a>
                    <a href=""><i className="fas fa-trash px-1 text-danger"></i></a>
                    <a href="" onClick={event => this.btnshow(event,value.id)}><i className="fas fa-eye px-1 text-warning"></i></a>
                </td>
                <td>{value.order_no}</td>
                <td>{value.company_name}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.name}</td>
                <td>{value.mode_of_transport}</td>
                <td className="text-capitalize">{value.tol}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Order</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-responsive">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Order #</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-2">Org</th>
                            <th className="col-md-2">Des</th>
                            <th className="col-md-1">Cargo</th>
                            <th className="col-md-1">MOT</th>
                            <th className="col-md-1">TOL</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let header_1 = "Add Order"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> {this.state.btn_text} </button>
        let o_no=null;
        if (!this.state.iscreate) {
            header_1 = "Edit Order"
            btn_1 =
                <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> {this.state.btn_text} </button>
            o_no = <>
                <
                    div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <input disabled={true} type="text" className="form-control form-control-sm" name="order_no"
                               value={this.state.order_no} onChange={this.inputmgt}
                               placeholder="Order #"/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                </div>
            </>
        }
        let dis=null;
        if(this.state.tol==="normal") {
            dis = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Volume</label>
                        <input type="text" className="form-control form-control-sm" name="weight"
                               value={this.state.weight} onChange={this.inputmgt}
                               placeholder="Volume"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Transport Rate</label>
                        <input type="text" className="form-control form-control-sm" name="transport_rate"
                               value={this.state.transport_rate} onChange={this.inputmgt}
                               placeholder="Transport Rate"/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                </div>
            </>
        }
        else if(this.state.tol==="container") {
            dis = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label># Of Container</label>
                        <input type="text" className="form-control form-control-sm" name="container_no"
                               value={this.state.container_no} onChange={this.inputmgt}
                               placeholder="# Of Container"/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                </div>
            </>
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {o_no}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <Select
                                            value={this.state.client_selected}
                                            onChange={this.handelchange_client}
                                            options={this.state.clients}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Type</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="client_type"
                                               value={this.state.client_type} onChange={this.inputmgt}
                                               placeholder="Client Type"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}
                                               placeholder="Company name"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div disabled={true} className="form-group">
                                        <label>Contact person</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="contact_person" value={this.state.contact_person}
                                               onChange={this.inputmgt} placeholder="Contact person"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>TIN</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="tin"
                                               value={this.state.tin} onChange={this.inputmgt} placeholder="TIN"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="city"
                                               value={this.state.city} onChange={this.inputmgt} placeholder="City"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="address"
                                               value={this.state.address} onChange={this.inputmgt}
                                               placeholder="Address"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Phone #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="phone"
                                               value={this.state.phone} onChange={this.inputmgt} placeholder="Phone #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input disabled={true} type="email" className="form-control form-control-sm" name="email"
                                               value={this.state.email} onChange={this.inputmgt} placeholder="Email"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <Select
                                            value={this.state.org_selected}
                                            onChange={this.handelchange_orgin}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <Select
                                            value={this.state.des_selected}
                                            onChange={this.handelchange_des}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="millage"
                                               value={this.state.millage} onChange={this.inputmgt} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="des"
                                                  onChange={this.inputmgt} placeholder="Description of Good">{this.state.des}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <Select
                                            value={this.state.cargo_selected}
                                            onChange={this.handelchange_cargo}
                                            options={this.state.cargos}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Modal Of Transport</label>
                                        <select className="form-control form-control-sm" name="mode_of_transport"
                                                value={this.state.mode_of_transport} onChange={this.inputmgt}>
                                            <option value="-1">-- Select Modal --</option>
                                            <option value="Uni Modal">Uni Modal</option>
                                            <option value="Multi Modal">Multi Modal</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Types of Load</label>
                                        <select className="form-control form-control-sm" name="tol"
                                                value={this.state.tol} onChange={this.inputmgt}>
                                            <option value="-1">-- Select Type of Load --</option>
                                            <option value="normal">Normal</option>
                                            <option value="container">Container</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Document Status</label>
                                        <select className="form-control form-control-sm" name="isdoc"
                                                value={this.state.isdoc} onChange={this.inputmgt}>
                                            <option value={-1}>--Select Doc Status--</option>
                                            <option value={1}>With Document</option>
                                            <option value={0}>Without Document</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <input type="date" className="form-control form-control-sm" name="st_date"
                                               value={this.state.st_date} onChange={this.inputmgt} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input type="date" className="form-control form-control-sm" name="ed_date"
                                               value={this.state.ed_date} onChange={this.inputmgt} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                {dis}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let header_1 = "Add Container"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate_withcontainer}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Container"
            btn_1 =
                <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate_withcontainer}> Edit </button>
        }
        let temp=[];
        for(let i=1; i<=this.state.container_no;i++)
        {
            temp.push(i)
        }
        let main_display=temp.map(v=>{
            let lable_container="Container "+v+" #";
            let name_container="container_"+v;

            let lable_cost="Cost "+v;
            let name_cost="cost_"+v;

            let lable_size="Size "+v;
            let name_size="size_"+v;

            let lable_volume="Volume "+v;
            let name_volume="volume_"+v;

            let lable_price="Price "+v;
            let name_price="price_"+v;

            return(<>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>{lable_container}</label>
                        <input type="text" className="form-control form-control-sm" name={name_container}
                               value={this.state[name_container]} onChange={this.inputmgt}
                               placeholder={lable_container}/>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>{lable_cost}</label>
                        <input type="text" className="form-control form-control-sm" name={name_cost}
                               value={this.state[name_cost]} onChange={this.inputmgt}
                               placeholder={lable_cost}/>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>{lable_size}</label>
                        <input type="text" className="form-control form-control-sm" name={name_size}
                               value={this.state[name_size]} onChange={this.inputmgt}
                               placeholder={lable_size}/>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>{lable_volume}</label>
                        <input type="text" className="form-control form-control-sm" name={name_volume}
                               value={this.state[name_volume]} onChange={this.inputmgt}
                               placeholder={lable_volume}/>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label>{lable_price}</label>
                        <input type="text" className="form-control form-control-sm" name={name_price}
                               value={this.state[name_price]} onChange={this.inputmgt}
                               placeholder={lable_price}/>
                    </div>
                </div>
            </>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {main_display}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btnback}> Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        let dis=null;
        if(this.state.tol==="normal") {
            dis = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Volume</label>
                        <input disabled={true} type="text" className="form-control form-control-sm" name="weight"
                               value={this.state.weight} onChange={this.inputmgt}
                               placeholder="Volume"/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Transport Rate</label>
                        <input disabled={true} type="text" className="form-control form-control-sm" name="transport_rate"
                               value={this.state.transport_rate} onChange={this.inputmgt}
                               placeholder="Transport Rate"/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                </div>
            </>
        }
        else if(this.state.tol==="container") {
            let count=0;
            dis=this.state.containers.map(v=>{
                count=count+1;
                let lable_container="Container "+count+" #";
                let lable_cost="Cost "+count;
                let lable_size="Size "+count;
                let lable_volume="Volume "+count;
                let lable_price="Price "+count;
                return(<>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>{lable_container}</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={v.container_no}/>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>{lable_cost}</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={v.cost}/>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>{lable_size}</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={v.size}/>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="form-group">
                            <label>{lable_volume}</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={v.volume}/>
                        </div>
                    </div><div className="col-md-2">
                    <div className="form-group">
                        <label>{lable_price}</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={v.price}/>
                    </div>
                </div>
                </>)
            })
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Show Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="order_no"
                                               value={this.state.order_no} onChange={this.inputmgt}
                                               placeholder="Order #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.client_selected.label}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Type</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="client_type"
                                               value={this.state.client_type} onChange={this.inputmgt}
                                               placeholder="Client Type"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}
                                               placeholder="Company name"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div disabled={true} className="form-group">
                                        <label>Contact person</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="contact_person" value={this.state.contact_person}
                                               onChange={this.inputmgt} placeholder="Contact person"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>TIN</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="tin"
                                               value={this.state.tin} onChange={this.inputmgt} placeholder="TIN"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="city"
                                               value={this.state.city} onChange={this.inputmgt} placeholder="City"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="address"
                                               value={this.state.address} onChange={this.inputmgt}
                                               placeholder="Address"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Phone #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="phone"
                                               value={this.state.phone} onChange={this.inputmgt} placeholder="Phone #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input disabled={true} type="email" className="form-control form-control-sm" name="email"
                                               value={this.state.email} onChange={this.inputmgt} placeholder="Email"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} className="form-control form-control-sm" name="email"
                                               value={this.state.org_selected.label}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm" name="email"
                                               value={this.state.des_selected.label}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="millage"
                                               value={this.state.millage} onChange={this.inputmgt} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea disabled={true} rows={5} className="form-control form-control-sm" name="des"
                                                  onChange={this.inputmgt} placeholder="Description of Good">{this.state.des}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input disabled={true} className="form-control form-control-sm" name="email"
                                               value={this.state.cargo_selected.value}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Types of Load</label>
                                        <input disabled={true} className="form-control form-control-sm" name="email"
                                               value={this.state.mode_of_transport}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <input disabled={true} className="form-control form-control-sm" name="email"
                                               value={this.state.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input disabled={true} className="form-control form-control-sm" name="email"
                                               value={this.state.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                {dis}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={(event=>{
                                                    this.setState({
                                                        wiz: 1,
                                                    })
                                                })}> Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        return (
            <>
                <Nav type="order"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Order Management</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order;
