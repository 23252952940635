import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Finance_canceled_fo extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            reports: [],
        }
    }

    componentDidMount() {
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    btnget_report = (event) => {
        event.preventDefault();
        this.getreport();
    }
    getreport = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.finance_canceled_fo;
        this.setState({
            reports: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st: this.state.st,
            ed: this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    reports: res.data.reports,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    tabledata=()=> {
        let retdata = this.state.reports.map(value => {
            return (<tr>
                <td>{value.fo_date}</td>
                <td>{value.fright_no}</td>
                <td>{value.plate_no}</td>
                <td>{value.trailer_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.company_name}</td>
                <td>{value.loading}</td>
                <td>{value.unloading}</td>
                <td>{value.prv_fuel}</td>
                <td>{value.prv_allowance}</td>
                <td>{value.prv_parking}</td>
                <td>{value.prv_other_expence}</td>
                <td>{value.prv_boarder}</td>
                <td>{value.cur_fuel}</td>
                <td>{value.cur_allowance}</td>
                <td>{value.cur_parking}</td>
                <td>{value.cur_other_expence}</td>
                <td>{value.remark}</td>
                <td>{value.ret_fuel}</td>
                <td>{value.ret_cost}</td>
            </tr>)
        });
        return (<>
            {retdata}
        </>)
    }
    render() {
        return (
            <>
                <Nav type="finance_canceled_fo"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Canceled FO</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="st" value={this.state.st}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="ed" value={this.state.ed}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <button type="button" className="btn btn-primary float-right"
                                                    onClick={this.btnget_report}><i className="fas fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Result</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                                               onClick={this.btnexport_report}>Download .xlsx</a></div>
                                                <div className="table" id="section_to_print">
                                                    <table id="report" className="table table-responsive">
                                                        <thead>
                                                        <tr>
                                                            <th>FO DATE</th>
                                                            <th>FREIGHT ORDER #</th>
                                                            <th>TRUCK No.</th>
                                                            <th>TRAILER NO.</th>
                                                            <th>DRIVER NAME</th>
                                                            <th>CUSTOMER</th>
                                                            <th>ORIGIN</th>
                                                            <th>DESTINATION</th>
                                                            <th>PRV FUEL</th>
                                                            <th>PRV PER DIEM</th>
                                                            <th>PRV HANDLING</th>
                                                            <th>PRV OTHER COST</th>
                                                            <th>PRV BORDER CHARGES</th>
                                                            <th>CUR FUEL</th>
                                                            <th>CUR PER DIEM</th>
                                                            <th>CUR HANDLING</th>
                                                            <th>CUR OTHER COST</th>
                                                            <th>REMARK</th>
                                                            <th>RETURN FUEL</th>
                                                            <th>RETURN COST</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.tabledata()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Finance_canceled_fo;
