import './App.css';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Welcome from "./Componet/Welcome";
import Login from "./Componet/Login";
import User from "./Componet/User";
import ObjUser from "./Componet/ObjUser";
import UserAccess1 from "./Componet/UserAccess1";
import UserAccess2 from "./Componet/UserAccess2";
import Menu from "./Componet/Menu/Menu";
import Order from "./Componet/Operation/Order";
import D_served from "./Componet/Daily/D_served";
import D_not_served from "./Componet/Daily/D_not_served";
import D_sale from "./Componet/Daily/D_sale";
import D_sale_detail from "./Componet/Daily/D_sale_detail";
import Sync_item from "./Componet/Menu/Sync_item";
import Sync_si_unit from "./Componet/Menu/Sync_si_unit";
import Waiter from "./Componet/Menu/Waiter";
import Recipe_mgt from "./Componet/Menu/Recipe_mgt";
import D_sale_per_user from "./Componet/Daily/D_sale_per_user";
import Client_mgt from "./Componet/Management/Client_mgt";
import Client_type from "./Componet/Management/Client_type";
import Coc_mgt from "./Componet/Management/Coc_mgt";
import Cargo_type from "./Componet/Management/Cargo_type";
import Vehicle from "./Componet/Assets/Vehicle";
import Driver from "./Componet/Assets/Driver";
import Trailer from "./Componet/Assets/Trailer";
import Location from "./Componet/Assets/Location";
import Road_route from "./Componet/Assets/Road_route";
import Insurance_type from "./Componet/Insurance/Insurance_type";
import Assign_own from "./Componet/Operation/Assign_own";
import Update_own_1 from "./Componet/Operation/Update_own";
import Other_cost from "./Componet/Assets/Other_cost";
import Payment_req from "./Componet/Operation/Payment_req";
import Payment_app from "./Componet/Operation/Payment_app";
import Payment_pay from "./Componet/Operation/Payment_pay";
import Follow_up from "./Componet/Controal/Follow_up";
import Status_type from "./Componet/Assets/Status_type";
import Vehicle_status from "./Componet/Controal/Vehicle_status";
import Follow_up_2 from "./Componet/Controal/Follow_up_2";
import Follow_up_hisotry from "./Componet/Controal/Follow_up_hisotry";
import Follow_up_eta from "./Componet/Controal/Follow_up_eta";
import Public_dashboard from "./Componet/Public_Dashboard/Public_dashboard";
import Follow_up_customer from "./Componet/Controal/Follow_up_customer";
import Delete_own from "./Componet/Operation/Delete_own";
import Follow_up_report from "./Componet/Controal/Follow_up_report";
import Document_mgt from "./Componet/Operation/Document_mgt";
import Follow_up_loading_unloading from "./Componet/Controal/Follow_up_loading_unloading";
import Follow_up_transit from "./Componet/Controal/Follow_up_transit";
import Follow_up_wfo from "./Componet/Controal/Follow_up_wfo";
import Follow_up_stoppage from "./Componet/Controal/Follow_up_stoppage";
import Fuel_rate from "./Componet/Assets/Fuel_rate";
import R_trip_master from "./Componet/Report/R_trip_master";
import R_load_factor from "./Componet/Report/R_load_factor";
import Update_allocate from "./Componet/Operation/Update_allocate";
import G_in_req from "./Componet/Maintance/G_in_req";
import G_in_app from "./Componet/Maintance/G_in_app";
import G_out_req from "./Componet/Maintance/G_out_req";
import G_out_app from "./Componet/Maintance/G_out_app";
import G_status from "./Componet/Maintance/G_status";
import Follow_up_manual from "./Componet/Controal/Follow_up_manual";
import R_maintance from "./Componet/Report/R_maintance";
import R_ava from "./Componet/Report/R_ava";
import D_status from "./Componet/Public_Dashboard/D_status";
import R_tat from "./Componet/Report/R_tat";
import R_fright_order from "./Componet/Report/R_fright_order";
import R_daily_stoppage from "./Componet/Report/R_daily_stoppage";
import R_movement from "./Componet/Report/R_movement";
import R_tac from "./Componet/Report/R_tac";
import R_tac_current from "./Componet/Report/R_tac_current";
import R_time_to_load from "./Componet/Report/R_time_to_load";
import R_time_to_load_open from "./Componet/Report/R_time_to_load_open";
import Cancel_allocate from "./Componet/Operation/Cancel_allocate";
import Finance_fo_by_date from "./Componet/Finance/Finance_fo_by_date";
import Finance_fo_by_plate_no from "./Componet/Finance/Finance_fo_by_plate_no";
import Finance_canceled_fo from "./Componet/Finance/Finance_canceled_fo";
import Admin_script from "./Componet/Management/Admin_script";
import R_detail_tat from "./Componet/Report/R_detail_tat";
import Finance_doc_rec from "./Componet/Finance/Finance_doc_rec";
import Finance_not_doc_rec from "./Componet/Finance/Finance_not_doc_rec";
import Opex_cost_req from "./Componet/Opex/Opex_cost_req";
import Opex_cost_app from "./Componet/Opex/Opex_cost_app";
import Opex_cost_payment from "./Componet/Opex/Opex_cost_payment";
import Opex_fuel_req from "./Componet/Opex/Opex_fuel_req";
import Opex_fuel_app from "./Componet/Opex/Opex_fuel_app";
import Opex_fuel_giver from "./Componet/Opex/Opex_fuel_giver";
import Opex_cost_refund from "./Componet/Opex/Opex_cost_refund";
import Opex_fuel_refund from "./Componet/Opex/Opex_fuel_refund";
import Fright_document from "./Componet/Operation/Fright_document";
import Op_refund_req from "./Componet/OP_Refund/Op_refund_req";
import Op_refund_app from "./Componet/OP_Refund/Op_refund_app";
import Opex_summuary from "./Componet/Opex/Opex_summuary";
import Opex_detail from "./Componet/Opex/Opex_detail";
import Opex_cost_payment_all from "./Componet/Opex/Opex_cost_payment_all";
import Op_trip_cancel_req from "./Componet/OP_Refund/Op_trip_cancel_req";
import Op_trip_cancel_app from "./Componet/OP_Refund/Op_trip_cancel_app";
import Op_trip_extend_req from "./Componet/OP_Refund/Op_trip_extend_req";
import Op_trip_extend_app from "./Componet/OP_Refund/Op_trip_extend_app";
import Finance_document from "./Componet/Operation/Finance_document";
import Opex_cost_payment_validator from "./Componet/Opex/Opex_cost_payment_validator";
import Report_generator from "./Componet/ReportGenerator/Report_generator";
import Void_fo from "./Componet/Operation/Void_fo";
import Follow_up_movement from "./Componet/Controal/Follow_up_movement";
import Order_open from "./Componet/Order/Order_open";
import Opex_cost_bank_tt from "./Componet/Opex/Opex_cost_bank_tt";
import Order_accept from "./Componet/Order/Order_accept";
import Order_accept_fin from "./Componet/Order/Order_accept_fin";
import Os_company from "./Componet/Outsource/Os_company";
import Os_vehicle from "./Componet/Outsource/Os_vehicle";
import Os_driver from "./Componet/Outsource/Os_driver";
import Os_trailer from "./Componet/Outsource/Os_trailer";
import Assign_sub_contractor_req from "./Componet/Outsource/Assign_sub_contractor_req";
import Assign_sub_contractor_app from "./Componet/Outsource/Assign_sub_contractor_app";
import Sub_contractors from "./Componet/Outsource/Sub_contractors";
import Supplier_company from "./Componet/Outsource/Supplier_company";
import Assign_supplier_company_req from "./Componet/Outsource/Assign_supplier_company_req";
import Assign_supplier_company_app from "./Componet/Outsource/Assign_supplier_company_app";
import Suppliers from "./Componet/Outsource/Suppliers";
import Order_status from "./Componet/Order/Order_status";
import Order_detail from "./Componet/Order/Order_detail";
import Op_reconcile from "./Componet/Opex/Op_reconcile";
import Order_update from "./Componet/Order/Order_update";
import Order_op_update_app from "./Componet/Order/Order_op_update_app";
import Order_fin_update_app from "./Componet/Order/Order_fin_update_app";
import Cement_open from "./Componet/Order/Cement_open";
import Cement_app from "./Componet/Order/Cement_app";
import Cement_update from "./Componet/Order/Cement_update";
import Cement_update_app from "./Componet/Order/Cement_update_app";
import Cement_status from "./Componet/Order/Cement_status";
import Order_loading_adv_req from "./Componet/Loading_ad/Order_loading_adv_req";
import Order_loading_adv_app from "./Componet/Loading_ad/Order_loading_adv_app";
import Pr_prepare from "./Componet/Purchase/Pr_prepare";
import Pr_app from "./Componet/Purchase/Pr_app";
import Po from "./Componet/Purchase/Po";
import Purchase_fin from "./Componet/Purchase/Purchase_fin";
import Purchase_status from "./Componet/Purchase/Purchase_status";
import Status_purchase from "./Componet/Status/Status_purchase";
import Status_cement from "./Componet/Status/Status_cement";
import Status_delivery from "./Componet/Status/Status_delivery";
import Status_purchase_detail from "./Componet/Status/Status_purchase_detail";
import Status_delivery_detail from "./Componet/Status/Status_delivery_detail";
import User_template from "./Componet/User_template";
import Veh_ava from "./Componet/Operation/Veh_ava";
import Cement_current_status from "./Componet/Order/Cement_current_status";
import Order_current_status from "./Componet/Order/Order_current_status";
import Order_loading_adv from "./Componet/Loading_ad/Order_loading_adv";
import Pr_check from "./Componet/Purchase/Pr_check";
import Po_prepare from "./Componet/Purchase/Po_prepare";
import Po_chk from "./Componet/Purchase/Po_chk";
import Po_app from "./Componet/Purchase/Po_app";
import Purchase from "./Componet/Purchase/Purchase";
import Status_cement_detail from "./Componet/Status/Status_cement_detail";
import Cement_delivery from "./Componet/Loading_ad/Cement_delivery";
import Cement_delivery_report from "./Componet/Loading_ad/Cement_delivery_report";
import Cement_delivery_cancel_req from "./Componet/Loading_ad/Cement_delivery_cancel_req";
import Cement_delivery_cancel_app from "./Componet/Loading_ad/Cement_delivery_cancel_app";
import Cement_so from "./Componet/Order/Cement_so";
import Cement_delivery_edit from "./Componet/Loading_ad/Cement_delivery_edit";
import Status_cement_allocation from "./Componet/Status/Status_cement_allocation";
import Road_route_open from "./Componet/Assets/Road_route_open";
import Kpi_umo from "./Componet/Kpiplan/Kpi_umo";
import Kpi_description from "./Componet/Kpiplan/Kpi_description";
import Kpi_data from "./Componet/Kpiplan/Kpi_data";
import Main_temp from "./Componet/Operation/Main_temp";
import D_operation from "./Componet/Public_Dashboard/D_operation";
import D_marketing from "./Componet/Public_Dashboard/D_marketing";
import D_operation_2 from "./Componet/Public_Dashboard/D_operation_2";
import Follow_up_distance_calc from "./Componet/Controal/Follow_up_distance_calc";
import follow_up_wizard from "./Componet/Controal/Follow_up_wizard";
import Follow_up_wizard from "./Componet/Controal/Follow_up_wizard";

function App() {
    return (
        <>
            <BrowserRouter basename='/'>
                <Switch>
                    <Route path="/" exact component={Welcome}/>
                    <Route path="/login" exact component={Login}/>
                    <Route path="/user" exact component={User}/>
                    <Route path="/objuser" exact component={ObjUser}/>
                    <Route path="/useraccess1" exact component={UserAccess1}/>
                    <Route path="/useraccess2" exact component={UserAccess2}/>
                    <Route path="/menu" exact component={Menu}/>

                    <Route path="/order" exact component={Order}/>

                    <Route path="/d_served" exact component={D_served}/>
                    <Route path="/d_not_served" exact component={D_not_served}/>
                    <Route path="/d_sale" exact component={D_sale}/>
                    <Route path="/d_sale_detail" exact component={D_sale_detail}/>
                    <Route path="/sync_item" exact component={Sync_item}/>
                    <Route path="/sync_si_unit" exact component={Sync_si_unit}/>
                    <Route path="/waiter" exact component={Waiter}/>
                    <Route path="/recipe_mgt" exact component={Recipe_mgt}/>
                    <Route path="/d_sale_per_user" exact component={D_sale_per_user}/>

                    <Route path="/client_mgt" exact component={Client_mgt}/>
                    <Route path="/client_type" exact component={Client_type}/>
                    <Route path="/coc_mgt" exact component={Coc_mgt}/>
                    <Route path="/cargo_type" exact component={Cargo_type}/>
                    <Route path="/vehicle" exact component={Vehicle}/>
                    <Route path="/driver" exact component={Driver}/>
                    <Route path="/trailer" exact component={Trailer}/>
                    <Route path="/location" exact component={Location}/>
                    <Route path="/road_route" exact component={Road_route}/>
                    <Route path="/insurance_type" exact component={Insurance_type}/>
                    <Route path="/assign_own" exact component={Assign_own}/>
                    <Route path="/update_own" exact component={Update_own_1}/>
                    <Route path="/delete_own" exact component={Delete_own}/>
                    <Route path="/other_cost" exact component={Other_cost}/>
                    <Route path="/status_type" exact component={Status_type}/>
                    <Route path="/payment_req" exact component={Payment_req}/>
                    <Route path="/payment_app" exact component={Payment_app}/>
                    <Route path="/payment_pay" exact component={Payment_pay}/>

                    <Route path="/follow_up" exact component={Follow_up}/>
                    <Route path="/vehicle_status" exact component={Vehicle_status}/>
                    <Route path="/follow_up_2" exact component={Follow_up_2}/>
                    <Route path="/follow_up_hisotry" exact component={Follow_up_hisotry}/>
                    <Route path="/follow_up_eta" exact component={Follow_up_eta}/>


                    <Route path="/follow_up_eta" exact component={Follow_up_eta}/>
                    <Route path="/follow_up_customer" exact component={Follow_up_customer}/>
                    <Route path="/follow_up_report" exact component={Follow_up_report}/>

                    <Route path="/public_dashboard" exact component={Public_dashboard}/>
                    <Route path="/document_mgt" exact component={Document_mgt}/>

                    <Route path="/follow_up_loading_unloading" exact component={Follow_up_loading_unloading}/>
                    <Route path="/follow_up_transit" exact component={Follow_up_transit}/>
                    <Route path="/follow_up_wfo" exact component={Follow_up_wfo}/>
                    <Route path="/follow_up_stoppage" exact component={Follow_up_stoppage}/>
                    <Route path="/fuel_rate" exact component={Fuel_rate}/>
                    <Route path="/r_trip_master" exact component={R_trip_master}/>
                    <Route path="/r_load_factor" exact component={R_load_factor}/>
                    <Route path="/update_allocate" exact component={Update_allocate}/>
                    <Route path="/g_in_req" exact component={G_in_req}/>
                    <Route path="/g_in_app" exact component={G_in_app}/>
                    <Route path="/g_out_req" exact component={G_out_req}/>
                    <Route path="/g_out_app" exact component={G_out_app}/>
                    <Route path="/g_status" exact component={G_status}/>
                    <Route path="/follow_up_manual" exact component={Follow_up_manual}/>
                    <Route path="/r_maintance" exact component={R_maintance}/>
                    <Route path="/r_ava" exact component={R_ava}/>
                    <Route path="/d_status" exact component={D_status}/>
                    <Route path="/r_tat" exact component={R_tat}/>
                    <Route path="/r_fright_order" exact component={R_fright_order}/>
                    <Route path="/r_daily_stoppage" exact component={R_daily_stoppage}/>
                    <Route path="/r_movement" exact component={R_movement}/>
                    <Route path="/r_tac" exact component={R_tac}/>
                    <Route path="/r_tac_current" exact component={R_tac_current}/>
                    <Route path="/r_time_to_load" exact component={R_time_to_load}/>
                    <Route path="/r_time_to_load_open" exact component={R_time_to_load_open}/>
                    <Route path="/cancel_allocate" exact component={Cancel_allocate}/>
                    <Route path="/finance_fo_by_date" exact component={Finance_fo_by_date}/>
                    <Route path="/finance_fo_by_plate_no" exact component={Finance_fo_by_plate_no}/>
                    <Route path="/finance_canceled_fo" exact component={Finance_canceled_fo}/>
                    <Route path="/admin_script" exact component={Admin_script}/>
                    <Route path="/r_detail_tat" exact component={R_detail_tat}/>
                    <Route path="/finance_doc_rec" exact component={Finance_doc_rec}/>
                    <Route path="/finance_not_doc_rec" exact component={Finance_not_doc_rec}/>
                    <Route path="/opex_cost_req" exact component={Opex_cost_req}/>
                    <Route path="/opex_cost_app" exact component={Opex_cost_app}/>
                    <Route path="/opex_cost_payment" exact component={Opex_cost_payment}/>
                    <Route path="/opex_fuel_req" exact component={Opex_fuel_req}/>
                    <Route path="/opex_fuel_app" exact component={Opex_fuel_app}/>
                    <Route path="/opex_fuel_giver" exact component={Opex_fuel_giver}/>
                    <Route path="/opex_cost_refund" exact component={Opex_cost_refund}/>
                    <Route path="/opex_fuel_refund" exact component={Opex_fuel_refund}/>
                    <Route path="/fright_document" exact component={Fright_document}/>
                    <Route path="/finance_document" exact component={Finance_document}/>
                    <Route path="/op_refund_req" exact component={Op_refund_req}/>
                    <Route path="/op_refund_app" exact component={Op_refund_app}/>
                    <Route path="/opex_summuary" exact component={Opex_summuary}/>
                    <Route path="/opex_detail" exact component={Opex_detail}/>
                    <Route path="/opex_cost_payment_all" exact component={Opex_cost_payment_all}/>
                    <Route path="/op_trip_cancel_req" exact component={Op_trip_cancel_req}/>
                    <Route path="/op_trip_cancel_app" exact component={Op_trip_cancel_app}/>
                    <Route path="/op_trip_extend_req" exact component={Op_trip_extend_req}/>
                    <Route path="/op_trip_extend_app" exact component={Op_trip_extend_app}/>
                    <Route path="/opex_cost_payment_validator" exact component={Opex_cost_payment_validator}/>
                    <Route path="/report_generator" exact component={Report_generator}/>
                    <Route path="/void_fo" exact component={Void_fo}/>
                    <Route path="/follow_up_movement" exact component={Follow_up_movement}/>
                    <Route path="/order_open" exact component={Order_open}/>
                    <Route path="/opex_cost_bank_tt" exact component={Opex_cost_bank_tt}/>
                  {/*  <Route path="/order_accept" exact component={Order_accept}/>*/}
                    <Route path="/order_accept_fin" exact component={Order_accept_fin}/>
                    <Route path="/os_company" exact component={Os_company}/>
                    <Route path="/os_vehicle" exact component={Os_vehicle}/>
                    <Route path="/os_driver" exact component={Os_driver}/>
                    <Route path="/os_trailer" exact component={Os_trailer}/>
                    <Route path="/assign_sub_contractor_req" exact component={Assign_sub_contractor_req}/>
                    <Route path="/assign_sub_contractor_app" exact component={Assign_sub_contractor_app}/>
                    <Route path="/sub_contractors" exact component={Sub_contractors}/>
                    <Route path="/supplier_company" exact component={Supplier_company}/>
                    <Route path="/assign_supplier_company_req" exact component={Assign_supplier_company_req}/>
                    <Route path="/assign_supplier_company_app" exact component={Assign_supplier_company_app}/>
                    <Route path="/suppliers" exact component={Suppliers}/>
                    <Route path="/order_status" exact component={Order_status}/>
                    <Route path="/order_detail" exact component={Order_detail}/>
                    <Route path="/op_reconcile" exact component={Op_reconcile}/>
                    <Route path="/order_update" exact component={Order_update}/>
                    <Route path="/order_op_update_app" exact component={Order_op_update_app}/>
                    <Route path="/order_fin_update_app" exact component={Order_fin_update_app}/>
                    <Route path="/cement_open" exact component={Cement_open}/>
                    <Route path="/cement_app" exact component={Cement_app}/>
                    <Route path="/cement_update" exact component={Cement_update}/>
                    <Route path="/cement_update_app" exact component={Cement_update_app}/>
                    <Route path="/cement_status" exact component={Cement_status}/>
                    <Route path="/order_loading_adv_req" exact component={Order_loading_adv_req}/>
                    <Route path="/order_loading_adv_app" exact component={Order_loading_adv_app}/>
                    <Route path="/order_loading_adv" exact component={Order_loading_adv}/>
                    <Route path="/pr_prepare" exact component={Pr_prepare}/>
                    <Route path="/pr_check" exact component={Pr_check}/>
                    <Route path="/pr_app" exact component={Pr_app}/>
                    <Route path="/po" exact component={Po}/>
                    <Route path="/po_prepare" exact component={Po_prepare}/>
                    <Route path="/purchase_fin" exact component={Purchase_fin}/>
                    <Route path="/purchase_status" exact component={Purchase_status}/>
                    <Route path="/status_purchase" exact component={Status_purchase}/>
                    <Route path="/status_cement" exact component={Status_cement}/>
                    <Route path="/status_delivery" exact component={Status_delivery}/>
                    <Route path="/status_purchase_detail" exact component={Status_purchase_detail}/>
                    <Route path="/status_delivery_detail" exact component={Status_delivery_detail}/>
                    <Route path="/user_template" exact component={User_template}/>
                    <Route path="/veh_ava" exact component={Veh_ava}/>
                    <Route path="/cement_current_status" exact component={Cement_current_status}/>
                    <Route path="/order_current_status" exact component={Order_current_status}/>
                    <Route path="/po_chk" exact component={Po_chk}/>
                    <Route path="/po_app" exact component={Po_app}/>
                    <Route path="/purchase" exact component={Purchase}/>
                    <Route path="/status_cement_detail" exact component={Status_cement_detail}/>
                    <Route path="/cement_delivery" exact component={Cement_delivery}/>
                    <Route path="/cement_delivery_report" exact component={Cement_delivery_report}/>
                    <Route path="/cement_delivery_cancel_req" exact component={Cement_delivery_cancel_req}/>
                    <Route path="/cement_delivery_cancel_app" exact component={Cement_delivery_cancel_app}/>
                    <Route path="/cement_so" exact component={Cement_so}/>
                    <Route path="/cement_delivery_edit" exact component={Cement_delivery_edit}/>
                    <Route path="/status_cement_allocation" exact component={Status_cement_allocation}/>
                    <Route path="/road_route_open" exact component={Road_route_open}/>
                    <Route path="/kpi_umo" exact component={Kpi_umo}/>
                    <Route path="/kpi_description" exact component={Kpi_description}/>
                    <Route path="/kpi_data" exact component={Kpi_data}/>
                    <Route path="/main_temp" exact component={Main_temp}/>
                    <Route path="/d_operation" exact component={D_operation}/>
                    <Route path="/d_marketing" exact component={D_marketing}/>
                    <Route path="/d_operation_2" exact component={D_operation_2}/>
                    <Route path="/follow_up_distance_calc" exact component={Follow_up_distance_calc}/>
                    <Route path="/follow_up_wizard" exact component={Follow_up_wizard}/>

                </Switch>
            </BrowserRouter>
        </>
    );
}
export default App;
