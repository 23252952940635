import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up_customer extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filter_datas: [],
            sums: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',

        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_follow_up_customer;
        this.setState({
            datas: [],
            filter_datas: [],
            sums: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filter_datas: res.data.datas,
                    sums: res.data.sums,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnexport_report_1 = (event) => {
        event.preventDefault();
        let wb=XLSX.utils.book_new();
        wb.SheetNames.push("Report 1");
        let wb2= XLSX.utils.table_to_sheet(document.getElementById('report_1'));
        wb.Sheets["Report 1"]=wb2;
        XLSX.writeFile(wb,"report 1.xlsx");
    }
    btnexport_report_2 = (event) => {
        event.preventDefault();
        let wb=XLSX.utils.book_new();
        wb.SheetNames.push("Report 2");
        let wb2= XLSX.utils.table_to_sheet(document.getElementById('report_2'));
        wb.Sheets["Report 2"]=wb2;
        XLSX.writeFile(wb,"report 2.xlsx");
    }

    wiz_1 = () => {
        let tdata = this.state.sums.map(value => {
            return (<tr>
                <td>< a href="" onClick={event => {this.filter_data(event,"by_cus",value.customer)}}>{value.customer}</a></td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.a}</td>
                <td>{value.itlp}</td>
                <td>{value.wtl}</td>
                <td>{value.wtt}</td>
                <td>{value.itop}</td>
                <td>{value.wto}</td>
                <td>{value.hts}</td>
                <td>{value.total}</td>
            </tr>)
        })
        let tdata_2 = this.state.filter_datas.map(value => {
            return (<tr>
                <td>{value.customer}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-dark ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Summary Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_1}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table id="report_1" className="table table-head-fixed text-wrap">
                                            <thead>
                                            <tr>
                                                <th className="col-md-2">Customer</th>
                                                <th className="col-md-1">Orgin</th>
                                                <th className="col-md-1">Destination</th>
                                                <th className="col-md-1">Allocated</th>
                                                <th className="col-md-1">Transit (load)</th>
                                                <th className="col-md-1">Loading</th>
                                                <th className="col-md-1">Departure</th>
                                                <th className="col-md-1">Transit (offload)</th>
                                                <th className="col-md-1">Unloading</th>
                                                <th className="col-md-1">Heading</th>
                                                <th className="col-md-1">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tdata}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-white ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Detail Report</h3>
                                <div className="card-tools">
                                    <div className="input-group input-group-sm">
                                        <a href="" onClick={this.btnexport_report_2}>Download .xlsx</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <table id="report_2" className="table table-head-fixed">
                                            <thead>
                                            <tr>
                                                <th className="col-md-3">Customer</th>
                                                <th className="col-md-3">Origin</th>
                                                <th className="col-md-3">Destination</th>
                                                <th className="col-md-3">Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tdata_2}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let header_1 = "Add Code"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Code"
            btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Edit </button>
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Customs Office Code</label>
                                        <input type="text" className="form-control form-control-sm" name="coc"
                                               value={this.state.coc} onChange={this.inputmgt}
                                               placeholder="COC"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    filter_data=(event,type,customer,data=-1)=>{
        event.preventDefault()
        let temp=this.state.datas;
        if(type==="by_cus")
        {
            temp=temp.filter(v=>{
                if(v.customer===customer)
                    return v;
            })

        }
        this.setState({
            filter_datas: temp,
        })
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()

        return (
            <>
                <Nav type="follow_up_customer"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Follow-up </h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_customer;
