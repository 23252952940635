import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Payment_app extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',


            wiz: 1,
            own_assigns: [],
            id: -1,
            assign_id: -1,
            isdisplay: false,
            iscreate: true,
            amount: '',


            budjeted_datas_det: [],
            budjeted_datas_sum: [],
            actual_datas_det: [],
            balance: {},

            othercosts: [],
            othercost_selected: null,
            aops: [],
            aopcs: [],
        }
    }
    handelchange_othercost = (othercost_selected) => {
        this.setState({
            othercost_selected:othercost_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_for_payment_app;
        this.setState({
            wiz: 1,
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btn_manage_vehicle = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_assign_own_for_payment_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    own_assigns: res.data.datas,
                    id:id,
                    wiz:2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_cost_mgt = (event,id) => {
        event.preventDefault();
        this.fetchdata2(id)
    }
    btn_approve = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to approve the payment? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.payment_app;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id:this.state.assign_id,
                    approve_lists:this.state.aops,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata()
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btn_approve_cash = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to approve the payment? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.payment_app_for_cash;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id:this.state.assign_id,
                    cash_id:id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata()
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    fetchdata2=(id)=>{
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_assign_own_cost_for_payment_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    budjeted_datas_det: res.data.budjeted_datas_det,
                    budjeted_datas_sum: res.data.budjeted_datas_sum,
                    actual_datas_det: res.data.actual_datas_det,
                    balance: res.data.balance,
                    othercosts: res.data.othercosts,
                    aops: res.data.aops,
                    aopcs: res.data.aopcs,
                    assign_id:id,
                    wiz:3,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.othercost_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select cost",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.payment_req;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id:this.state.assign_id,
                amount:this.state.amount,
                othercost_id:this.state.othercost_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.fetchdata2(this.state.assign_id)
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_manage_vehicle(event,value.id)}>Manage</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.budgeted}</td>
                <td>{value.actual}</td>
                <td>{value.dif}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-3">Plate #</th>
                            <th className="col-md-2">Budget</th>
                            <th className="col-md-2">Actual</th>
                            <th className="col-md-2">(Budget-Actual)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let count=0;
        let tdata = this.state.own_assigns.map(value => {
            count=count+1;
            let bg="bg-white"
            let app= null;
            if(value.isfound){
                bg="bg-warning";
                app= <a href="" onClick={event => this.btn_cost_mgt(event,value.id)}>Approve</a>
            }

            return (<tr className={bg}>
                <td>{count}</td>
                <td>
                    {app}
                </td>
                <td>{value.driver}</td>
                <td>{value.vehicle}</td>
                <td>{value.company}</td>
                <td>{value.route}</td>
                <td>{value.actual}</td>
                <td>{value.budgeted}</td>
                <td>{value.dif}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Driver</th>
                            <th className="col-md-2">Plate #</th>
                            <th className="col-md-2">Company</th>
                            <th className="col-md-2">Route</th>
                            <th className="col-md-1">Actual</th>
                            <th className="col-md-1">Budget</th>
                            <th className="col-md-1">(Budget-Actual)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_3 = () => {
        let t=0;
        let tdata=this.state.budjeted_datas_sum.map(v=>{
            t=t+v.cost;
            return(<tr>
                <td>{v.type}</td>
                <td>{Number(v.cost).toFixed(2)}</td>
            </tr>)
        })
        let tdata_2=null
        if(this.state.budjeted_datas_sum.length>0) {
            tdata_2 = <tr>
                <th>Total</th>
                <td>{Number(t).toFixed(2)}</td>
            </tr>
        }

        t = 0;
        let tdata_5 = this.state.actual_datas_det.map(v => {
            t = t + v.amount;
            return (<tr>
                <td>{Number(v.amount).toFixed(2)}</td>
                <td>{v.pay_at}</td>
            </tr>)
        })
        let tdata_6 = null
        if (this.state.actual_datas_det.length > 0) {
            tdata_6 = <tr>
                <th>Total</th>
                <td>{Number(t).toFixed(2)}</td>
            </tr>
        }

        let tdata_3=this.state.budjeted_datas_det.map(v=>{
            t=t+v.cost;
            return(<tr>
                <td>{v.type}</td>
                <td>{v.ini_loc}</td>
                <td>{v.fin_loc}</td>
                <td>{Number(v.allowance).toFixed(2)}</td>
                <td>{Number(v.fuel).toFixed(2)}</td>
                <td>{Number(v.parking).toFixed(2)}</td>
                <td>{Number(v.other).toFixed(2)}</td>
            </tr>)
        })
        t=0;
        let tdata_4=this.state.aops.map(v=>{
            t=t+v.amount;
            return(<tr>
                <td></td>
                <td>{v.reason}</td>
                <td>{v.amount}</td>
                <td>{v.req_by}</td>
                <td>{v.req_at}</td>
                <td>{v.app_by}</td>
                <td>{v.app_at}</td>
                <td>{v.status}</td>
            </tr>)
        })
        let tdata_4_2 = null
        let dis_2=null;
        if (this.state.aops.length > 0) {
            tdata_4_2 = <tr>
                <td></td>
                <th>Total</th>
                <td>{t}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            dis_2=  <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}><a
                                href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btn_approve}>Approve Payment</a></div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <table id="report" className="table table-head-fixed">
                                <thead>
                                <tr>
                                    <th className="col-md-1">Action</th>
                                    <th className="col-md-2">Reason of Payment</th>
                                    <th className="col-md-2">Amount</th>
                                    <th className="col-md-2">Req By</th>
                                    <th className="col-md-1">Req At</th>
                                    <th className="col-md-2">App By</th>
                                    <th className="col-md-1">App At</th>
                                    <th className="col-md-2">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tdata_4}
                                {tdata_4_2}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        }

        t=0;
        let tdata_7=this.state.aopcs.map(v=>{
            t=t+v.amount;
            return(<tr>
                <td><a href="" onClick={event => {this.btn_approve_cash(event,v.id)}}>Approve</a></td>
                <td>{v.amount}</td>
                <td>{v.req_by}</td>
                <td>{v.req_at}</td>
                <td>{v.app_by}</td>
                <td>{v.app_at}</td>
                <td>{v.status}</td>
            </tr>)
        })
        let tdata_7_2 = null
        let dis_3=null
        if (this.state.aopcs.length > 0) {
            tdata_7_2 = <tr>
                <td></td>
                <th>Total</th>
                <td>{t}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            dis_3 = <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <table id="report" className="table table-head-fixed">
                                <thead>
                                <tr>
                                    <th className="col-md-1">Action</th>
                                    <th className="col-md-1">Amount</th>
                                    <th className="col-md-2">Req By</th>
                                    <th className="col-md-2">Req At</th>
                                    <th className="col-md-2">App By</th>
                                    <th className="col-md-2">App At</th>
                                    <th className="col-md-2">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tdata_7}
                                {tdata_7_2}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        }

        let dis=null;
        if(this.state.isdisplay) {
            dis = <div className="col-12">
                <span><strong>Budgeted cost detail</strong></span> <small><a href="" onClick={event => {
                event.preventDefault()
                this.setState({
                    isdisplay: false,
                })
            }}>(Less)</a></small>
                <table id="report" className="table table-head-fixed">
                    <thead>
                    <tr>
                        <th>Type</th>
                        <th>Inital Location</th>
                        <th>Final Location</th>
                        <th>Allowance</th>
                        <th>Fuel</th>
                        <th>Parking</th>
                        <th>Other</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tdata_3}
                    </tbody>
                </table>
            </div>
        }



        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <div className="invoice p-3 mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <i className="fas fa-globe"/> <strong>Budgeted Cost</strong>
                                </div>
                            </div>
                            <div className="col-12 table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Amount</th>
                                        <th className="col-md-6">Pay At</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata_5}
                                    {tdata_6}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {dis}
                    <div className="col-12">
                        <div className="invoice p-3 mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <i className="fas fa-globe" /><strong>Actual Cost</strong> <small><a href="" onClick={event => {
                                    event.preventDefault()
                                    this.setState({
                                        isdisplay: true,
                                    })
                                }}>(More)</a></small>
                                </div>
                            </div>
                            <div className="col-12 table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Cost Name</th>
                                        <th className="col-md-6">Cost</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata}
                                    {tdata_2}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="invoice p-3 mb-3">
                            <div className="row">
                                <div className="col-12">
                                    <i className="fas fa-globe"/> <strong>Balance</strong>
                                </div>
                            </div>
                            <div className="col-12 table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Previous Trip</th>
                                        <th className="col-md-3">Budget</th>
                                        <th className="col-md-3">Actual</th>
                                        <th className="col-md-3">Variance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{this.state.balance.dif}</td>
                                        <td>{this.state.balance.budget}</td>
                                        <td>{this.state.balance.actual}</td>
                                        <td>{this.state.balance.vari}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {dis_2}
                    {dis_3}
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 2,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_4 = () => {
        let header_1 = "Add Payment"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Payment"
            btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Edit </button>
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Costs</label>
                                        <Select
                                            value={this.state.othercost_selected}
                                            onChange={this.handelchange_othercost}
                                            options={this.state.othercosts}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="text" className="form-control form-control-sm" name="amount"
                                               value={this.state.amount} onChange={this.inputmgt}
                                               placeholder="Amount"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz: 3,
                                                    })
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2_1 = () => {
        let header_1 = "Add Code"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Code"
            btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Edit </button>
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Customs Office Code</label>
                                        <input type="text" className="form-control form-control-sm" name="coc"
                                               value={this.state.coc} onChange={this.inputmgt}
                                               placeholder="COC"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        return (
            <>
                <Nav type="payment_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Payment Approve</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Payment_app;
