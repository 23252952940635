import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
import style2 from "../../style2.css"
let source = axios.CancelToken.source();

class D_sale extends Component {

    constructor() {
        super();
        this.state = {
            dt: '',
            time:'',
            sale: '',
            casher: '',
            name: 'all',
            users:[],
        }
    }

    componentDidMount() {
        this.fetcheddata()
        this.fetcheddata2()
    }

    fetcheddata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_user_for_d_sale;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    users: res.data.users,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetcheddata2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.d_sale;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
            u_id:this.state.name,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    dt: res.data.dt,
                    sale: res.data.sale,
                    time: res.data.time,
                    casher: res.data.casher,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        },()=>this.fetcheddata2())
    }
    btnzreport = (event) => {
        event.preventDefault();
        if (this.state.orderd_lists.length === 0) {
            Swal.fire({
                title: 'Error!',
                text: "At least one order is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            this.setState({
                wiz: 2,
            })
        }
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name_en.toUpperCase().includes(value.toUpperCase()) ||
                    v.name_am.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }

    render() {
        let dislay = null;
        let options=this.state.users.map(v=>{
            return <option value={v.id}>{v.name}</option>
        })
        if (this.state.dt !== '')
            dislay = <div className="row">
                <div className="col-12">
                    <br/>
                    <br/>
                    Report Date:<br/>
                    <strong>{this.state.dt}</strong><br/>
                    Report Time:<br/>
                    <strong>{this.state.time}</strong><br/>
                    Cashier Name:<br/>
                    <strong>{this.state.casher}</strong><br/>
                    Total Sale:<br/>
                    <strong>{this.state.sale}</strong><br/>
                    <br/>
                </div>
            </div>
        return (
            <>
                <Nav type="d_sale"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Daily Sales</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Sales</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cashier</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Name</label>
                                                            <select className="form-control"
                                                                    name="name" value={this.state.name} onChange={this.inputmgt}>
                                                                <option value="all">All</option>
                                                                {options}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="invoice p-3 mb-3">
                                        <div id="section_to_print">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <h3>
                                                        GUANSA MAED
                                                    </h3>
                                                    <h5>
                                                        Z-Report
                                                    </h5>
                                                </div>
                                            </div>
                                            {dislay}
                                        </div>
                                        <div className="row no-print">
                                            <div className="col-12">
                                                <a href="" rel="noopener" onClick={event => {
                                                    event.preventDefault();
                                                    window.print()
                                                }} className="btn btn-default"><i className="fas fa-print"/> Print</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default D_sale;
