import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Void_fo extends Component {

    constructor() {
        super();
        this.state = {
            fright_no:'',
            data:{},
            is_exe:false,
            btn_text:"Request",

            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 0,
            iscreate: false,
            id:-1,
            coc:'',

            stations: [],
            station_selected: null,

            is_dis:false,
            txt_rate:'',
        }
    }
    handelstation = (station_selected) => {
        let txt_rate='';
        if(station_selected!=null){
            let temp=this.state.stations.filter(v=>{
                if(v.value===station_selected.value)
                    return v;
            })
            if(temp.length>0)
                txt_rate=temp[0].fuel_rate;
        }
        this.setState({
            station_selected: station_selected,
            txt_rate: txt_rate,
        })
    }
    componentDidMount() {

    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_cbo = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let is_dis = false
        if (value == "2")
            is_dis = true;
        this.setState({
            [name]: value,
            is_dis: is_dis,
        })
    }

    get_fright = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_fright_for_void_fo;
        this.setState({
            wiz: 0,
            data:{},
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no:this.state.fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                        id:res.data.data.id,
                        data:res.data.data,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            refund_type: '-1',
            refund_remark: '',
            refund_amount: '',
        })
    }
    btn_void = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to void this allocation? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    is_exe:true,
                })
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.void_fo;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: this.state.id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:0,
                                is_exe:false,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }



    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                coc: temp[0].coc,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }

    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.data.assing_own_op_refunds.map(value => {
            let status="bg-yellow"
            if(value.status==="REFUND_APPROVED")
                status="bg-success";
            else if(value.status==="REFUND_REJECTED")
                status="bg-danger";
            return (<tr className={status}>
                <td>{value.refund_amount}</td>
                <td>{value.type_con}</td>
                <td>{value.station}</td>
                <td>{value.refund_remark}</td>
                <td>{value.req_by}</td>
                <td>{value.app_by}</td>
                <td>{value.rej_by}</td>
                <td>{value.status}</td>
            </tr>)
        })
        let lst=null
        if(this.state.data.fuels!=null)
            lst=this.state.data.fuels.map(value=>{
                return <li>{value.station} ({value.fuel})</li>
            })
        return (<>
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-success ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Freight Void</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Truck #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.plate_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Driver Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.driver_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Trailer No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.trailer_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fright #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.fright_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Client</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Order #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.order_no}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cargo</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.cargo_name}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Distance</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.distance}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Volume</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.volume}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel (L)</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.total_fuel}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Start @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.start_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>ORG</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.org}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>DES</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.des}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>End @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.end_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel</label>
                                    <ul>
                                        {lst}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <table id="report" className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-1">Amount</th>
                                        <th className="col-md-2">Type</th>
                                        <th className="col-md-1">Station</th>
                                        <th className="col-md-3">Remark</th>
                                        <th className="col-md-1">REQ BY</th>
                                        <th className="col-md-1">APP BY</th>
                                        <th className="col-md-1">REJ BY</th>
                                        <th className="col-md-2">STATUS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-primary float-right m-3"
                                onClick={event =>{this.btn_void(event)}}>Freight Void
                        </button>
                        <button type="button" className="btn btn-danger float-right m-3"
                                onClick={event =>{
                                    event.preventDefault();
                                    this.setState({
                                        is_dis:false,
                                        wiz:0,
                                        fright_no:'',
                                    })
                                }}>Cancel
                        </button>

                    </div>
                </div>
            </div>

        </>)
    }
    wiz_2 = () => {
        let lst=null
        if(this.state.data.fuels!=null)
            lst=this.state.data.fuels.map(value=>{
                return <li>{value.station} ({value.fuel})</li>
            })
        let dis=null;
        if(this.state.is_dis){
            dis=
                <>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Station</label>
                            <Select
                                value={this.state.station_selected}
                                onChange={this.handelstation}
                                options={this.state.stations}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Fuel Rate</label>
                            <input disabled={true} type="text" className="form-control form-control-sm"
                                   value={this.state.txt_rate} name="txt_rate" onChange={this.inputmgt}/>
                        </div>
                    </div>
                    <div className="col-md-4">
                    </div>
                </>
        }
        return (<>
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-success ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">REQUEST FORM</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Truck #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.plate_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Driver Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.driver_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Trailer No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.trailer_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Amount</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.refund_amount} name="refund_amount" onChange={this.inputmgt}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Type</label>
                                    <select className="form-control form-control-sm"
                                            value={this.state.refund_type} name="refund_type" onChange={this.inputmgt_cbo}>
                                        <option value="-1">--Select Type--</option>
                                        <option value="1">Money</option>
                                        <option value="2">Fuel</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Remark</label>
                                    <textarea rows={5} className="form-control form-control-sm"
                                              value={this.state.refund_remark} name="refund_remark" onChange={this.inputmgt}></textarea>
                                </div>
                            </div>
                            {dis}
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fuel</label>
                                    <ul>
                                        {lst}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-primary btn-sm"
                                            onClick={this.btn_req}> {this.state.btn_text}
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-danger btn-sm"
                                            onClick={event => {
                                                event.preventDefault();
                                                this.setState({
                                                    wiz:1,
                                                })
                                            }}> CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="void_fo"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Fright Data</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Fright #</label>
                                                        <input type="text" className="form-control form-control-sm" name="fright_no"
                                                               value={this.state.fright_no} onChange={this.inputmgt}
                                                               placeholder="Fright #"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.get_fright()
                                                                }}> Get Fright
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Void_fo;
