import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import road_route from "../Assets/Road_route";
import {Modal} from "react-bootstrap";


let source = axios.CancelToken.source();

class Assign_own extends Component {

    constructor() {
        super();
        this.state = {
            is_exe:false,
            btn_text:"Create",
            datas: [],
            filtered: [],
            not_ret_documents: [],

            txtsearch: '',


            wiz: 1,
            plate_no: '',
            id: -1,

            drivers: [],
            driver_selected: null,

            trailers: [],
            trailer_selected: null,

            orders: [],
            order_selected: null,

            locations: [],
            ini_selected: null,
            final_selected: null,

            fuel_rates: [],
            fuel_rate_selected: null,

            road_routes: [],
            order_data: {},

            containers: [],
            prv_orders: [],
            container_selected: null,
            container_2_selected: null,

            documentshow: false,
            cushow: false,
            modal_type: -1,
            total_fuel_etb: 0,
            total_fuel_l: 0,
            expected_total_fuel_l: 0,
            fuel_datas: [],
            othercost_datas: [],
            re_fund_list_reasons: [],
            re_fund_datas: [],
            fright_no: '',
            ret_data: {},
            prv_data: {
                d_plate_no:'',
                d_driver_name:'',
                d_trailer_no:'',
                d_fright_no:'',
                d_org:'',
                d_des:'',
                d_client:'',
            },
            isextend:false,
            extendcreate:false,

        }
    }

    handelfuel_rates = (fuel_rate_selected) => {
        let temp=0;
        if(fuel_rate_selected!==null) {
            temp = this.state.fuel_rates.filter(v => {
                if (v.value == fuel_rate_selected.value) {
                    return v;
                }
            });
            if(temp.length>0)
                temp=temp[0].fuel_rate
            else
                temp=0;
        }

        this.setState({
            fuel_rate_selected: fuel_rate_selected,
            fuel_rate: temp,
        })
    }
    handelothercost = (othercost_selected) => {
        this.setState({
            othercost_selected: othercost_selected,
        })
    }

    handelchange_driver = (driver_selected) => {
        this.setState({
            driver_selected: driver_selected,
        })
    }
    handelchange_trailer = (trailer_selected) => {
        this.setState({
            trailer_selected: trailer_selected,
        })
    }
    handelchange_ini = (ini_selected) => {
        this.setState({
            ini_selected: ini_selected,
            road_routes: [],
            expected_total_fuel_l: 0,
            final_selected: null,
            order_selected: null,

        })
    }
    handelchange_final = (final_selected) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_final_route;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            order_id:this.state.order_selected.value,
            final_loc_id:final_selected.value,
            vehicle_id:this.state.id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(res.data.road_routes.length>0) {
                    let temp = this.state.road_routes;
                    if(temp.length>=2){
                        temp.length=2
                        temp.push({
                            route: res.data.road_routes[0].route,
                            status: res.data.road_routes[0].status,
                            km: res.data.road_routes[0].km,
                            allownce: res.data.road_routes[0].allownce,
                            fuel: res.data.road_routes[0].fuel,
                            parking: res.data.road_routes[0].parking,
                            other: res.data.road_routes[0].other,
                            is_found: res.data.road_routes[0].is_found,
                        })
                        let expected_total_fuel_l = 0
                        temp.map(v => {
                            if (!isNaN(v.fuel))
                                expected_total_fuel_l = (expected_total_fuel_l + v.fuel) * 1
                        })
                        this.setState({
                            road_routes: temp,
                            final_selected: final_selected,
                            expected_total_fuel_l: expected_total_fuel_l,
                        })
                    }

                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    handelchange_order = (order_selected) => {
        if (order_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.ini_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select initial location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.client_attach;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                ini_id: this.state.ini_selected.value,
                order_id: order_selected.value,
                vehicle_id: this.state.id,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let expected_total_fuel_l = 0
                    res.data.road_routes.map(v => {
                        if (!isNaN(v.fuel))
                            expected_total_fuel_l = (expected_total_fuel_l + v.fuel) * 1
                    })
                    this.setState({
                        road_routes: res.data.road_routes,
                        order_data: res.data.order_data,
                        containers: res.data.containers,
                        order_selected: order_selected,
                        expected_total_fuel_l: expected_total_fuel_l,
                        final_selected: null,
                        container_selected: null,
                        container_2_selected: null,
                        size: '',
                        actual_weight:400,
                        size_2: '',
                        volume_2: 400,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    handelchange_container = (container_selected) => {
        let temp = this.state.containers.filter(v=>  {
            if (v.value === container_selected.value)
                return v;
        })
        if (temp.length > 0)
            this.setState({
                container_selected: container_selected,
                size:temp[0].size,
                volume:temp[0].volume,
            })
    }
    handelchange_2_container = (container_2_selected) => {
        let temp = this.state.containers.filter(v=>  {
            if (v.value === container_2_selected.value)
                return v;
        })
        if (temp.length > 0)
            this.setState({
                container_2_selected: container_2_selected,
                size_2:temp[0].size,
                volume_2:temp[0].volume,
            })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_assets_for_assign_own;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    fuel_rates: res.data.fuel_rates,
                    extendcreate: res.data.extendcreate,
                },()=>{
                    let order_id = new URLSearchParams(this.props.location.search).get("order_id");
                    let vehicle_id = new URLSearchParams(this.props.location.search).get("vehicle_id");
                    let driver_id = new URLSearchParams(this.props.location.search).get("driver_id");
                    let trailer_id = new URLSearchParams(this.props.location.search).get("trailer_id");
                    if(vehicle_id!=null){
                        let vehicle=this.state.datas.filter(v=>{
                            if(v.id==vehicle_id)
                                return v
                        })
                        if(vehicle.length>0){
                            this.get_create_obj_2(vehicle[0].plate_no,vehicle_id,order_id,driver_id,trailer_id)
                        }

                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    get_create_obj = (plate_no,id,isextend ) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_create_obj;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(isextend){
                    this.setState({
                        wiz: 2,
                        id: id,
                        isextend: isextend,
                        plate_no: plate_no,
                        drivers: res.data.drivers,
                        trailers: res.data.trailers,
                        locations: res.data.locations,
                        orders: res.data.orders,
                        prv_orders: res.data.prv_orders,
                        driver_selected: res.data.driver_selected,
                        trailer_selected: res.data.trailer_selected,

                        order_selected: null,
                        final_selected: null,
                        road_routes: [],
                        order_data: {},
                        containers: [],
                        container_selected: null,
                        container_2_selected: null,

                        othercost_datas: [],
                        re_fund_datas: [],
                        fuel_rate_selected: null,
                        othercost_selected: null,
                        fright_no: '',
                        btn_text:"Assign with Additional FO",
                    })
                }
                else {
                    this.setState({
                        wiz: 2,
                        id: id,
                        isextend: isextend,
                        plate_no: plate_no,
                        drivers: res.data.drivers,
                        trailers: res.data.trailers,
                        locations: res.data.locations,
                        orders: res.data.orders,
                        prv_orders: res.data.prv_orders,
                        driver_selected: res.data.driver_selected,
                        trailer_selected: res.data.trailer_selected,

                        order_selected: null,
                        ini_selected: null,
                        final_selected: null,
                        road_routes: [],
                        order_data: {},
                        containers: [],
                        container_selected: null,
                        container_2_selected: null,
                        total_fuel: 0,
                        fuel_datas: [],
                        othercost_datas: [],
                        re_fund_datas: [],
                        fuel_rate_selected: null,
                        othercost_selected: null,
                        fright_no: '',
                        btn_text:"Assign",
                    })
                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    get_create_obj_2 = (plate_no,id,order_id,driver_id,trailer_id) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_create_obj;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                let driver_selected=res.data.driver_selected;
                let trailer_selected=res.data.trailer_selected;
                let driver=res.data.drivers.filter(v=>{
                    if(v.value==driver_id)
                        return v;
                })
                if(driver.length>0)
                    driver_selected={value:driver[0].value,label:driver[0].label}
                let trailer=res.data.trailers.filter(v=>{
                    if(v.value==trailer_id)
                        return v;
                })
                if(trailer.length>0)
                    trailer_selected={value:trailer[0].value,label:trailer[0].label}
                this.setState({
                    wiz: 2,
                    id: id,
                    isextend: false,
                    plate_no: plate_no,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                    locations: res.data.locations,
                    orders: res.data.orders,
                    prv_orders: res.data.prv_orders,
                    driver_selected: driver_selected,
                    trailer_selected: trailer_selected,
                    order_selected: null,
                    ini_selected: null,
                    final_selected: null,
                    road_routes: [],
                    order_data: {},
                    containers: [],
                    container_selected: null,
                    container_2_selected: null,
                    total_fuel: 0,
                    fuel_datas: [],
                    othercost_datas: [],
                    re_fund_datas: [],
                    fuel_rate_selected: null,
                    othercost_selected: null,
                    fright_no: '',
                    btn_text:"Assign",
                },()=>{
                    let order=this.state.orders.filter(v=>{
                        if(v.value==order_id)
                            return v;
                    })
                    if(order.length) {
                        let ini_selected = {value: 1, label: "DIRE DAWA"};
                        this.setState({
                            ini_selected: ini_selected,
                            road_routes: [],
                            expected_total_fuel_l: 0,
                            final_selected: null,
                            order_selected: null,
                        }, () => {
                            let order_selected = {value: order[0].value, label: order[0].label}
                            console.log(order_selected)
                            this.handelchange_order(order_selected)
                        })
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.plate_no.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt2 = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            re_fund_reason: value,
        })
    }

    btn_manage = (event,plate_no,id) => {
        event.preventDefault();
        this.get_create_obj(plate_no,id,false)
    }
    btn_extend = (event,plate_no,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_fright_extender;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            vehicle_id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 4,
                    id: id,
                    plate_no: plate_no,
                    prv_data:res.data.prv_data,
                    ini_selected:res.data.ini_selected,
                    fuel_datas:res.data.fuel_datas,
                    prv_assignment_id:res.data.assign_id,
                    btn_text:"Assign with Additional FO",
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_extend_next = (event) => {
        event.preventDefault();
        this.get_create_obj(this.state.plate_no,this.state.id,true)
    }
    btn_next_without_doc_rec = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to allocate vehicle without receiving the previous FO document?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                this.assign(false)
            }
        })
    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
        })
    }
    btnassign = (event) => {
        event.preventDefault();
        this.assign();
    }
    assign = (flag_document=true) => {
        if(!this.state.is_exe) {
            if (this.state.driver_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select driver",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else if (this.state.ini_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select initial location",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else if (this.state.order_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select order",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    is_exe:true,
                    btn_text:"Working...",
                })
                let trailer = this.state.trailer_selected == null ? null : this.state.trailer_selected.value;
                let fin_loc = this.state.final_selected == null ? null : this.state.final_selected.value;
                let container_1 = this.state.container_selected == null ? null : this.state.container_selected.value;
                let container_2 = this.state.container_2_selected == null ? null : this.state.container_2_selected.value;
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.vehicle_assign_own;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    flag_document:flag_document,
                    vehicle_id: this.state.id,
                    driver_id: this.state.driver_selected.value,
                    trailer_id: trailer,
                    ini_loc_id: this.state.ini_selected.value,
                    fin_loc_id: fin_loc,
                    order_id: this.state.order_selected.value,
                    volume: this.state.actual_weight,
                    fright_no: this.state.fright_no,
                    fuel_datas: this.state.fuel_datas,
                    othercost_datas: this.state.othercost_datas,
                    re_fund_datas: this.state.re_fund_datas,
                    container_1: container_1,
                    container_2: container_2,
                    allocated_date: this.state.allocated_date,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                            btn_text:"Assign"
                        })
                    } else {
                        if(res.data.warining){
                            Swal.fire({
                                title: 'Warning!',
                                text: res.data.msg,
                                icon: 'warning',
                                confirmButtonText: 'Okay'
                            }).then(v => {
                                this.setState({
                                    wiz: 5,
                                    not_ret_documents: res.data.not_ret_documents,
                                    is_exe: false,
                                    btn_text: "Assign"
                                })
                            })
                        }
                        else {
                            Swal.fire({
                                title: 'Success!',
                                text: res.data.msg,
                                icon: 'success',
                                confirmButtonText: 'Okay'
                            }).then(v => {
                                this.setState({
                                    wiz: 3,
                                    ret_data: res.data.ret_data,
                                    is_exe: false,
                                    btn_text: "Assign"
                                })
                            })
                        }
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        }
    }

    btn_doc_rec = (event) => {
        event.preventDefault()
        if (!this.state.is_exe) {
            this.setState({
                is_exe: true,
            })
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.document_mgt;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.a_id,
                remark: this.state.remark,
                volume: this.state.volume,
                unload_volume: this.state.unload_volume,
                allocation: this.state.allocation,
                arivalatloading: this.state.arivalatloading,
                loading: this.state.loading,
                departure: this.state.departure,
                arivalatunloading: this.state.arivalatunloading,
                unloading: this.state.unloading,
                finished: this.state.finished,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    this.setState({
                        is_exe: false,
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v=>{
                        if (res.data.not_ret_documents.length > 0) {
                            this.setState({
                                wiz: 5,
                                not_ret_documents: res.data.not_ret_documents,
                                is_exe: false,
                                btn_text: "Assign",
                                documentshow: false,
                            })
                        } else {
                            this.setState({
                                wiz: 2,
                                is_exe: false,
                                documentshow: false,
                            }, () => {
                                this.assign()
                            })
                        }
                    })

                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    btnassign_with_extender = (event) => {
        event.preventDefault();
        if(!this.state.is_exe) {
            if (this.state.driver_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select driver",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else if (this.state.ini_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select initial location",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else if (this.state.order_selected == null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select order",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    is_exe:true,
                    btn_text:"Working...",
                })
                let trailer = this.state.trailer_selected == null ? null : this.state.trailer_selected.value;
                let fin_loc = this.state.final_selected == null ? null : this.state.final_selected.value;
                let container_1 = this.state.container_selected == null ? null : this.state.container_selected.value;
                let container_2 = this.state.container_2_selected == null ? null : this.state.container_2_selected.value;
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.vehicle_assign_own_with_additional_fo;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    flag_document:false,
                    vehicle_id: this.state.id,
                    prv_assignment_id: this.state.prv_assignment_id,
                    driver_id: this.state.driver_selected.value,
                    trailer_id: trailer,
                    ini_loc_id: this.state.ini_selected.value,
                    fin_loc_id: fin_loc,
                    order_id: this.state.order_selected.value,
                    volume: this.state.actual_weight,
                    fright_no: this.state.fright_no,
                    fuel_datas: this.state.fuel_datas,
                    othercost_datas: this.state.othercost_datas,
                    re_fund_datas: this.state.re_fund_datas,
                    container_1: container_1,
                    container_2: container_2,
                    allocated_date: this.state.allocated_date,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                            btn_text:"Assign with Additional FO",
                        })

                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 3,
                                ret_data: res.data.ret_data,
                                is_exe:false,
                                btn_text:"Assign with Additional FO",
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        }
    }

    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filtered.map(value => {
            let btn=null;
            if(this.state.extendcreate)
                btn=<>
                    <hr/>
                    <a href="" onClick={event => this.btn_extend(event,value.plate_no,value.id)}>Additional FO</a>
                </>
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_manage(event,value.plate_no,value.id)}>Manage</a>
                    {btn}
                </td>
                <td>{value.plate_no}</td>
                <td>{value.capacity}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-3">Plate #</th>
                            <th className="col-md-3">Capacity</th>
                            <th className="col-md-3">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }

    wiz_2 = () => {
        let isdisabled_customer = true;
        if (this.state.ini_selected != null)
            isdisabled_customer = false;

        let isdisabled_final = true;
        let detail = null;
        let detail_2 = null;
        if (this.state.order_selected != null) {
            isdisabled_final = false;
            detail = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.order_no}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Orgin</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.org}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Destination</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.des}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Millage</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.distance}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Cargo</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.cargo}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Description of Good</label>
                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                  onChange={this.inputmgt} value={this.state.order_data.des_good ?? "NA"}></textarea>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Types of Load</label>
                        <input disabled={true} className="form-control form-control-sm text-capitalize"
                               value={this.state.order_data.tol}/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </>
            if (this.state.order_data.tol === "container") {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label># Container to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.no_container}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_1</label>
                            <Select
                                value={this.state.container_selected}
                                onChange={this.handelchange_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size"
                                   value={this.state.size}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="actual_weight"
                                   value={this.state.actual_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_2</label>
                            <Select
                                value={this.state.container_2_selected}
                                onChange={this.handelchange_2_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size_2"
                                   value={this.state.size_2}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="volume_2"
                                   value={this.state.volume_2}/>
                        </div>
                    </div>
                </>
            } else {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Left Weight (q) to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.avl_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Actual Weight (q)</label>
                            <input type="text" className="form-control form-control-sm" name="actual_weight"
                                   value={this.state.actual_weight} onChange={this.inputmgt}
                                   placeholder="Weight"/>
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">

                    </div>
                </>
            }
        }
        let km = 0;
        let allowence = 0;
        let fuel = 0;
        let parking = 0;
        let other = 0;
        let tdata = this.state.road_routes.map(v => {
            let bg = "bg-white"
            if (!v.is_found)
                bg = "bg-danger"
            if (v.is_found) {
                km = km + v.km
                allowence = allowence + v.allownce
                fuel = fuel + v.fuel
                parking = parking + v.parking
                other = other + v.other
            }
            return (<tr className={bg}>
                <td>{v.route}</td>
                <td>{v.status}</td>
                <td>{v.km}</td>
                <td>{v.allownce}</td>
                <td>{v.fuel}</td>
                <td>{v.parking}</td>
                <td>{v.other}</td>
            </tr>)
        })

        let tdata_2 = this.state.prv_orders.map(v => {
            return (<tr>
                <td>{v.client}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
                <td>{v.type}</td>
            </tr>)
        })

        let det_fuel=this.state.fuel_datas.map(v=>{
            return(<>
                <div className="col-md-2 text-sm"><a href=""
                                                     onClick={event => this.modal_remove_fuel(event,v.id)}>X</a></div>
                <div className="col-md-3 text-sm">{v.station}</div>
                <div className="col-md-3 text-sm">{v.fuel_rate}</div>
                <div className="col-md-2 text-sm">{(v.total_fuel_l*1).toFixed(2)}</div>
                <div className="col-md-2 text-sm">{(v.total_fuel_etb*1).toFixed(2)}</div>
            </>)
        })

        let total_fuel_l=0
        let total_fuel_etb=0
        this.state.fuel_datas.map(v=>{
            total_fuel_l=((total_fuel_l*1)+(v.total_fuel_l*1)).toFixed(2)
            total_fuel_etb=((total_fuel_etb*1)+(v.total_fuel_etb*1)).toFixed(2)
        })

        let det_other=this.state.othercost_datas.map(v=>{
            return(<>
                <div className="col-md-2 text-sm"><a href=""
                                                     onClick={event => this.modal_remove_othercost(event,v.id)}>X</a></div>
                <div className="col-md-5 text-sm">{v.othercost}</div>
                <div className="col-md-5 text-sm">{(v.cost*1).toFixed(2)}</div>
            </>)
        })
        let total_other_cost=0
        this.state.othercost_datas.map(v=>{
            total_other_cost=((total_other_cost*1)+(v.cost*1)*1).toFixed(2)
        })

        let det_re_fund=this.state.re_fund_datas.map(v=>{
            return(<>
                <div className="col-md-2 text-sm"><a href=""
                                                     onClick={event => this.modal_remove_refund(event,v.id)}>X</a></div>
                <div className="col-md-5 text-sm">{v.re_fund_reason}</div>
                <div className="col-md-5 text-sm">{(v.re_fund_cost*1).toFixed(2)}</div>
            </>)
        })
        let total_re_fund_cost=0
        this.state.re_fund_datas.map(v=>{
            total_re_fund_cost=((total_re_fund_cost*1)+(v.re_fund_cost*1)*1).toFixed(2)
        })
        let btn_assign;
        if(this.state.isextend)
            btn_assign= <button className="btn btn-block btn-outline-success btn-sm"
                                onClick={this.btnassign_with_extender}> {this.state.btn_text}
            </button>
        else
            btn_assign= <button className="btn btn-block btn-outline-success btn-sm"
                                onClick={this.btnassign}> {this.state.btn_text}
            </button>
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Assign Vehicle</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-danger ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Orders</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <table id="report" className="table table-head-fixed table-wrap">
                                                    <thead>
                                                    <tr>
                                                        <th className="col-md-4">Client</th>
                                                        <th className="col-md-2">Origin</th>
                                                        <th className="col-md-2">Destination</th>
                                                        <th className="col-md-4">Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {tdata_2}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}
                                               placeholder="Plate #"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.fright_no}
                                               name="fright_no"
                                               placeholder="Fright #"
                                               onChange={this.inputmgt}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input className="form-control form-control-sm"
                                               value={this.state.allocated_date}
                                               name="allocated_date"
                                               onChange={this.inputmgt}
                                               type="date"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.ini_selected}
                                            onChange={this.handelchange_ini}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            isDisabled={isdisabled_customer}
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <Select
                                            isDisabled={isdisabled_final}
                                            value={this.state.final_selected}
                                            onChange={this.handelchange_final}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 pb-2">
                                    <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Fuel Data </strong>
                                            <a className="text-sm" href=""
                                               onClick={event => {
                                                   event.preventDefault();
                                                   this.setState({
                                                       cushow:true,
                                                       modal_type:1,
                                                       fuel_rate_selected:null,
                                                       fuel_rate:'',
                                                       fuel:'',
                                                   })
                                               }}>(Add Fuel)
                                            </a>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-3 border"><strong>Station</strong></div>
                                        <div className="col-md-3 border"><strong>Fuel Rate</strong></div>
                                        <div className="col-md-2 border"><strong>Total Fuel(l)</strong></div>
                                        <div className="col-md-2 border"><strong>Total Fuel(ETB)</strong></div>
                                        {det_fuel}
                                        <div className="col-md-12 pt-3"><strong>Total Fuel(ETB): </strong>{ total_fuel_etb}</div>
                                        <div className="col-md-12 pt-3"><strong>Total Fuel(L): </strong>{total_fuel_l}</div>
                                        <div className="col-md-12 pt-3"><strong>Expected Fuel(L): </strong>{this.state.expected_total_fuel_l}</div>
                                    </div>
                                </div>
                                <div className="col-md-6 pb-2">
                                    {/* <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Other Cost </strong>
                                            <a className="text-sm" href=""
                                               onClick={event => {
                                                   event.preventDefault();
                                                   this.setState({
                                                       cushow:true,
                                                       modal_type:2,
                                                       fuel_rate_selected:null,
                                                       fuel_rate:'',
                                                       fuel:'',
                                                   })
                                               }}>(Add Other cost)
                                            </a>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5 border"><strong>Other Cost</strong></div>
                                        <div className="col-md-5 border"><strong>Cost</strong></div>
                                        {det_other}
                                        <div className="col-md-12 pt-3"><strong>Total Other Cost(ETB): </strong>{total_other_cost}</div>

                                    </div>*/}
                                </div>
                                {/* <div className="col-md-6 pb-2">
                                </div>
                                <div className="col-md-6 pb-2">
                                    <div className="row">
                                        <div className="col-12 border-bottom pb-2"><strong>Re-fund </strong>
                                            <a className="text-sm" href=""
                                               onClick={event => {
                                                   event.preventDefault();
                                                   this.setState({
                                                       cushow:true,
                                                       modal_type:3,
                                                       lstreason:"",
                                                       re_fund_reason:'',
                                                       re_fund_cost:'',
                                                   })
                                               }}>(Add Re-fund cost)
                                            </a>
                                        </div>
                                        <div className="col-md-2"></div>
                                        <div className="col-md-5 border"><strong>Re-fund Reason</strong></div>
                                        <div className="col-md-5 border"><strong>Cost</strong></div>
                                        {det_re_fund}
                                        <div className="col-md-12 pt-3"><strong>Total Refund Cost(ETB): </strong>{total_re_fund_cost}</div>

                                    </div>
                                </div>*/}
                                {detail}
                                {detail_2}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_assign}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-danger ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Mewecha Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr>
                                            <th className="col-md-2">Route</th>
                                            <th className="col-md-2">Status</th>
                                            <th className="col-md-1">KM</th>
                                            <th className="col-md-2">Allowance</th>
                                            <th className="col-md-2">Fuel</th>
                                            <th className="col-md-2">Parking</th>
                                            <th className="col-md-2">Others</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tdata}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let url=myconfig.redirecttofright_document+"?fright_no="+this.state.ret_data.fright_no;
        let url_2=myconfig.redirecttoopex_fuel_giver+"?fright_no="+this.state.ret_data.fright_no;
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="text-uppercase">Document Printer</h3>
                        <table id="report" className="table table-head-fixed">
                            <thead>
                            <tr>
                                <td className="col-md-6"><a href={url} target="_blank">Print FO Agreement Here</a></td>
                                <td className="col-md-6"></td>
                            </tr>
                            <tr>
                                <td className="col-md-6"><a href={url_2} target="_blank">Fuel Issue</a></td>
                                <td className="col-md-6"></td>
                            </tr>

                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer clearfix">
                    <button type="button" className="btn btn-danger float-right" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            wiz: 1,
                        },()=>this.fetchdata())
                    }}><i
                        className="fas fa-arrow-left"></i>
                    </button>
                </div>
            </div>
        </>)
    }
    wiz_4= () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Adding FO</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.prv_data.d_plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.prv_data.d_driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.prv_data.d_trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.prv_data.d_fright_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.prv_data.d_org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.prv_data.d_des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Customer</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.prv_data.d_client}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-primary float-right" onClick={event => {this.btn_extend_next(event)}}><i
                    className="fas fa-arrow-right"></i>
                </button>
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_5= () => {
        let tdata=this.state.not_ret_documents.map(v=>{
            return(
                <tr>
                    <td><a href="" onClick={event => this.modal_show_document(event,v.assign_own_id)}>Receive</a></td>
                    <td>{v.plate_no}</td>
                    <td>{v.driver_name}</td>
                    <td>{v.fright_no}</td>
                    <td>{v.client}</td>
                    <td>{v.org}</td>
                    <td>{v.des}</td>
                    <td>{v.allocate_date}</td>
                    <td>{v.unloading_date}</td>
                </tr>
            )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-2 py-2">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Not Returned Documents</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-striped">
                                        <tr>
                                            <th className="col-md-1">Action</th>
                                            <th className="col-md-1">Truck No.</th>
                                            <th className="col-md-1">Driver Name</th>
                                            <th className="col-md-1">Fright #</th>
                                            <th className="col-md-2">Client</th>
                                            <th className="col-md-1">Origin</th>
                                            <th className="col-md-1">Destination</th>
                                            <th className="col-md-2">Allocated @</th>
                                            <th className="col-md-2">Unloading @</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-primary float-right mx-2" onClick={event => {this.btn_next_without_doc_rec(event)}}><i
                    className="fas fa-arrow-right"></i>
                </button>
                <button type="button" className="btn btn-danger float-right mx-2" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 2,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }

    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        let dis=null;
        if(this.state.modal_type===1)
        {
            dis= <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Fuel Data</h3>
                            <div className="card-tools">
                                <button type="button" onClick={this.hidecu} className="btn btn-tool">
                                    <i className="fas fa-times"/>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Station</label>
                                            <Select
                                                value={this.state.fuel_rate_selected}
                                                onChange={this.handelfuel_rates}
                                                options={this.state.fuel_rates}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Fuel Rate</label>
                                            <input disabled={true} className="form-control" type="text"
                                                   name="fuel_rate" onChange={this.inputmgt}
                                                   value={this.state.fuel_rate} placeholder={"Rate"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Fuel</label>
                                            <input className="form-control" type="text"
                                                   name="fuel" onChange={this.inputmgt}
                                                   value={this.state.fuel} placeholder={"Fuel"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <button className="btn-primary form-control" onClick={this.modal_add_fuel}>Add Fuel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        else if(this.state.modal_type===2)
        {
            dis= <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Other Costs</h3>
                            <div className="card-tools">
                                <button type="button" onClick={this.hidecu} className="btn btn-tool">
                                    <i className="fas fa-times"/>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Other Cost</label>
                                            <Select
                                                value={this.state.othercost_selected}
                                                onChange={this.handelothercost}
                                                options={this.state.othercosts}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Costs</label>
                                            <input className="form-control" type="text"
                                                   name="cost" onChange={this.inputmgt}
                                                   value={this.state.cost} placeholder={"Cost"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <button className="btn-primary form-control" onClick={this.modal_add_othercost}>Add Other Cost</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        else if(this.state.modal_type===3)
        {
            let ops=this.state.re_fund_list_reasons.map(v=>{
                return ( <option value={v.reason}>{v.reason}</option>)
            })
            dis= <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Re-fund Costs</h3>
                            <div className="card-tools">
                                <button type="button" onClick={this.hidecu} className="btn btn-tool">
                                    <i className="fas fa-times"/>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Reasons</label>
                                            <select className="form-control"
                                                    name="lstreason" onChange={this.inputmgt2}
                                                    value={this.state.lstreason}>
                                                <option value=""></option>
                                                {ops}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Re-fund Reason</label>
                                            <input className="form-control" type="text"
                                                   name="re_fund_reason" onChange={this.inputmgt}
                                                   value={this.state.re_fund_reason} placeholder={"Re-Fund Reason"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <label>Re-fund Costs</label>
                                            <input className="form-control" type="text"
                                                   name="re_fund_cost" onChange={this.inputmgt}
                                                   value={this.state.re_fund_cost} placeholder={"Re-Fund Cost"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="form-group">
                                            <button className="btn-primary form-control" onClick={this.modal_add_re_fund}>Add Re-fund Cost</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Body>
                    {dis}
                </Modal.Body>
            </Modal>
        )
    }

    hide_document = () => {
        this.setState({
            documentshow:false,
        })
    }
    modaldocument=()=> {
        return (<Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.documentshow}
                onHide={this.hidecu}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                                    <h3 className="card-title">Document Return Managment</h3>
                                    <div className="card-tools">
                                        <button type="button" onClick={this.hide_document} className="btn btn-tool">
                                            <i className="fas fa-times"/>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Plate #</label>
                                                    <input disabled={true} className="form-control"
                                                           name="plate_no" readOnly={true}
                                                           value={this.state.plate_no}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Driver Name</label>
                                                    <input disabled={true} className="form-control"
                                                           name="driver_name" readOnly={true}
                                                           value={this.state.driver_name}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Fright #</label>
                                                    <input disabled={true} className="form-control"
                                                           name="prv_fright_no" readOnly={true}
                                                           value={this.state.prv_fright_no}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Allocated @</label>
                                                    <input disabled={true} className="form-control"
                                                           name="allocate_date" readOnly={true}
                                                           value={this.state.allocate_date}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Client</label>
                                                    <input disabled={true} className="form-control"
                                                           name="client" readOnly={true}
                                                           value={this.state.client}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Origin</label>
                                                    <input disabled={true} className="form-control"
                                                           name="org" readOnly={true}
                                                           value={this.state.org}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Destination</label>
                                                    <input disabled={true} className="form-control"
                                                           name="des" readOnly={true}
                                                           value={this.state.des}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Loaded Weight</label>
                                                    <input className="form-control" type="text"
                                                           name="volume" onChange={this.inputmgt}
                                                           value={this.state.volume} placeholder={"Loaded Weight"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Unloaded Weight</label>
                                                    <input className="form-control" type="text"
                                                           name="unload_volume" onChange={this.inputmgt}
                                                           value={this.state.unload_volume} placeholder={"Unloaded Weight"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Arrival @ Loading</label>
                                                    <input className="form-control" type="text"
                                                           name="arivalatloading" onChange={this.inputmgt}
                                                           value={this.state.arivalatloading} placeholder={"YYYY-MM-DD H:M:S"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Loading @</label>
                                                    <input className="form-control" type="text"
                                                           name="loading" onChange={this.inputmgt}
                                                           value={this.state.loading} placeholder={"YYYY-MM-DD H:M:S"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Departure @</label>
                                                    <input className="form-control" type="text"
                                                           name="departure" onChange={this.inputmgt}
                                                           value={this.state.departure} placeholder={"YYYY-MM-DD H:M:S"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Arrival @ Offloading</label>
                                                    <input className="form-control" type="text"
                                                           name="arivalatunloading" onChange={this.inputmgt}
                                                           value={this.state.arivalatunloading} placeholder={"YYYY-MM-DD H:M:S"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Offloading @</label>
                                                    <input className="form-control" type="text"
                                                           name="unloading" onChange={this.inputmgt}
                                                           value={this.state.unloading} placeholder={"YYYY-MM-DD H:M:S"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Back to Station @</label>
                                                    <input className="form-control" type="text"
                                                           name="finished" onChange={this.inputmgt}
                                                           value={this.state.finished} placeholder={"YYYY-MM-DD H:M:S"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <button className="btn-primary form-control" onClick={event => this.btn_doc_rec(event)}>Return Document</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    modal_show_document=(event,id)=> {
        event.preventDefault()
        let temp = this.state.not_ret_documents.filter(v => {
            if (v.assign_own_id === id) {
                return v;
            }
        });
        if (temp.length > 0) {
            this.setState({
                documentshow: true,
                a_id: id,
                plate_no:temp[0].plate_no,
                driver_name:temp[0].driver_name,
                prv_fright_no:temp[0].fright_no,
                allocate_date:temp[0].allocate_date,
                client:temp[0].client,
                org:temp[0].org,
                des:temp[0].des,
                volume:temp[0].volume,
                unload_volume:temp[0].unload_volume,
                arivalatloading:temp[0].arivalatloading,
                loading:temp[0].loading,
                departure:temp[0].departure,
                arivalatunloading:temp[0].arivalatunloading,
                unloading:temp[0].unloading,
                finished:temp[0].finished,
            })
        }
    }
    modal_add_fuel=(event)=> {
        event.preventDefault()
        if (this.state.fuel_rate_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select fuel station",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.fuel === "") {
            Swal.fire({
                title: 'Error!',
                text: "Fuel rate is required",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (isNaN(this.state.fuel)) {
            Swal.fire({
                title: 'Error!',
                text: "Fuel rate must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let fuel_datas = this.state.fuel_datas;
            let total_fuel_etb = 0;
            let total_fuel_l = 0;
            let temp = this.state.fuel_rates.filter(v => {
                if (v.value == this.state.fuel_rate_selected.value) {
                    return v;
                }
            });
            let id=fuel_datas.length+1
            if (temp.length > 0) {
                fuel_datas.push({
                    id: id,
                    fuel_rate_id: temp[0].value,
                    station: temp[0].label,
                    fuel_rate: temp[0].fuel_rate,
                    total_fuel_l: this.state.fuel,
                    total_fuel_etb: this.state.fuel*temp[0].fuel_rate,
                })
            }

            fuel_datas.map(v => {
                total_fuel_l = (total_fuel_l*1) + (v.total_fuel_l*1)
                total_fuel_etb = total_fuel_etb + v.total_fuel_etb
            })

            this.setState({
                fuel_datas: fuel_datas,
                total_fuel_l: total_fuel_l,
                total_fuel_etb: total_fuel_etb,
                cushow:false,
            })

        }
    }
    modal_remove_fuel=(event,id)=> {
        event.preventDefault()
        let fuel_datas=this.state.fuel_datas;
        fuel_datas=fuel_datas.filter(v=>{
            if(v.id!==id)
                return v;
        })
        this.setState({
            fuel_datas: fuel_datas,
        })
    }

    /*   modal_add_othercost=(event)=> {
           event.preventDefault()
           if (this.state.othercost_selected == null) {
               Swal.fire({
                   title: 'Error!',
                   text: "Select other cost",
                   icon: 'error',
                   confirmButtonText: 'Okay'
               })
           } else if (this.state.cost === "") {
               Swal.fire({
                   title: 'Error!',
                   text: "Other cost is required",
                   icon: 'error',
                   confirmButtonText: 'Okay'
               })
           } else if (isNaN(this.state.cost)) {
               Swal.fire({
                   title: 'Error!',
                   text: "Other cost must be numeric",
                   icon: 'error',
                   confirmButtonText: 'Okay'
               })
           } else {
               let othercost_datas = this.state.othercost_datas;
               let id=othercost_datas.length+1
               othercost_datas.push({
                   id: id,
                   cost: (this.state.cost*1).toFixed(2),
                   othercost: this.state.othercost_selected.label,
                   othercost_id: this.state.othercost_selected.value,
               })
               this.setState({
                   othercost_datas: othercost_datas,
                   cushow:false,
               })

           }
       }*/
    /* modal_remove_othercost=(event,id)=> {
         event.preventDefault()
         console.log(id)
         let othercosts=this.state.othercost_datas;
         othercosts=othercosts.filter(v=>{
             if(v.id!==id)
                 return v;
         })
         this.setState({
             othercost_datas: othercosts,
         })
     }
     modal_remove_refund=(event,id)=> {
         event.preventDefault()
         console.log(id)
         let re_fund_datas=this.state.re_fund_datas;
         re_fund_datas=re_fund_datas.filter(v=>{
             if(v.id!==id)
                 return v;
         })
         this.setState({
             re_fund_datas: re_fund_datas,
         })
     }
     modal_add_re_fund=(event)=> {
         event.preventDefault()
         if (this.state.re_fund_reason === "") {
             Swal.fire({
                 title: 'Error!',
                 text: "Re-fund reason is required",
                 icon: 'error',
                 confirmButtonText: 'Okay'
             })
         }
         else if (this.state.re_fund_cost === "") {
             Swal.fire({
                 title: 'Error!',
                 text: "Re-fund cost is required",
                 icon: 'error',
                 confirmButtonText: 'Okay'
             })
         }
         else if (isNaN(this.state.re_fund_cost)) {
             Swal.fire({
                 title: 'Error!',
                 text: "Re-fund cost must be numeric",
                 icon: 'error',
                 confirmButtonText: 'Okay'
             })
         } else {
             let re_fund_datas = this.state.re_fund_datas;
             let id=re_fund_datas.length+1
             re_fund_datas.push({
                 id: id,
                 re_fund_cost: (this.state.re_fund_cost*1).toFixed(2),
                 re_fund_reason: this.state.re_fund_reason,
             })
             this.setState({
                 re_fund_datas: re_fund_datas,
                 cushow:false,
             })

         }
     }*/
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        else if (this.state.wiz === 5)
            dis = this.wiz_5()
        return (
            <>
                <Nav type="assign_own"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Assign Own Vehicle</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
                {this.modaldocument()}
            </>
        )
    }
}

export default Assign_own;
