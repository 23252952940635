import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class G_status extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],

            txtsearch: '',
            wiz: 1,
        }
    }

    componentDidMount() {
        this.fetchdata2()
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.gets_today_g_status;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    wiz: 1,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata1 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.gets_current_maintance_g_status;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    wiz: 1,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id: -1,
            iscreate: true,
            coc: '',
        })
    }
    btnedit = (event, id) => {
        event.preventDefault();
        let temp = this.state.datas.filter(v => {
            if (v.id === id)
                return v;
        })
        if (temp.length > 0) {
            this.setState({
                wiz: 2,
                iscreate: false,
                id: id,
                plate_no: temp[0].plate_no,
                driver_name: temp[0].driver_name,
                trailer_no: temp[0].trailer_no,
                loc_name: temp[0].loc_name,
                reason_name: temp[0].reason_name,
                dt: temp[0].dt,
                ti: temp[0].ti,
                intime: temp[0].intime,
                in_req_by: temp[0].in_req_by,
                in_app_by: temp[0].in_app_by,
                in_remark: temp[0].in_remark,
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btn_reset = (event, id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to reset the request? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.reset_g_out_req;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata2()
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btnrequest = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.g_out_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            remark: this.state.remark,
            dt: this.state.dt,
            ti: this.state.ti,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.fetchdata2()
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.datas.map(value => {
            count = count + 1;
            return (<tr>
                <td>{count}</td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
                <td>{value.loc_name}</td>
                <td>{value.reason_name}</td>
                <td>{value.intime}</td>
                <td>{value.outtime}</td>
                <td>{value.in_req_by}</td>
                <td>{value.in_app_by}</td>
                <td>{value.out_req_by}</td>
                <td>{value.out_app_by}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                   onClick={this.btnexport_report}>Download .xlsx</a></div>
                    <table id="report" className="table table-head-fixed table-responsive">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Truck</th>
                            <th>Driver</th>
                            <th>Trailer</th>
                            <th>Location</th>
                            <th>Reason</th>
                            <th>Maintenance IN Time</th>
                            <th>Maintenance Out Time</th>
                            <th>IN REQ BY</th>
                            <th>IN APP BY</th>
                            <th>OUT REQ BY</th>
                            <th>OUT APP BY</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Out Request</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver </label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="driver_name"
                                               value={this.state.driver_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="trailer_no"
                                               value={this.state.trailer_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Location</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="loc_name"
                                               value={this.state.loc_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Reason</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="reason_name"
                                               value={this.state.reason_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea disabled={true} rows={3} className="form-control form-control-sm"
                                                  name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}
                                                  placeholder="Remark"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>In Time</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="intime"
                                               value={this.state.intime} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>In Req BY</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="in_req_by"
                                               value={this.state.in_req_by} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>In APP BY</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="in_app_by"
                                               value={this.state.in_app_by} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Out Remark</label>
                                        <textarea rows={3} className="form-control form-control-sm" name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}
                                                  placeholder="Remark"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Out Date</label>
                                        <input type="date" className="form-control form-control-sm" name="dt"
                                               value={this.state.dt} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Out Time</label>
                                        <input type="time" className="form-control form-control-sm" name="ti"
                                               value={this.state.ti} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btnrequest}> Request
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="g_status"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Garage Status</h3>
                                            <div className="card-tools">
                                                <ul className="nav nav-pills ml-auto">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" href=""
                                                           data-toggle="tab"
                                                           onClick={event => {
                                                               event.preventDefault();
                                                               this.fetchdata2()
                                                           }}
                                                        >Today Status</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" href=""
                                                           data-toggle="tab"
                                                           onClick={event => {
                                                               event.preventDefault();
                                                               this.fetchdata1()
                                                           }}
                                                        >Current Maintenance</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default G_status;
