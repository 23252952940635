import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import road_route from "../Assets/Road_route";


let source = axios.CancelToken.source();

class Update_own extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',


            wiz: 1,
            plate_no: '',
            id: -1,

            drivers: [],
            driver_selected: null,

            trailers: [],
            trailer_selected: null,

            company_name:'',
            order_no:'',
            tol:'',
            avl_weight:'',
            weight:'',
            no_container:'',
            containers: [],
            container_selected: null,
            container_2_selected: null,


            orders: [],
            order_selected: null,

            locations: [],
            ini_selected: null,
            final_selected: null,

            road_routes: [],
            order_data: {},


        }
    }

    handelchange_driver = (driver_selected) => {
        this.setState({
            driver_selected: driver_selected,
        })
    }
    handelchange_trailer = (trailer_selected) => {
        this.setState({
            trailer_selected: trailer_selected,
        })
    }
    handelchange_ini = (ini_selected) => {
        this.setState({
            ini_selected: ini_selected,
            road_routes: [],
            final_selected: null,
            order_selected: null,
        })
    }
    handelchange_final = (final_selected) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_final_route;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            order_id:this.state.order_selected.value,
            final_loc_id:final_selected.value,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                if(res.data.road_routes.length>0) {
                    let temp = this.state.road_routes;
                    if(temp.length>=2){
                        temp.length=2
                        temp.push({
                            route: res.data.road_routes[0].route,
                            status: res.data.road_routes[0].status,
                            km: res.data.road_routes[0].km,
                            allownce: res.data.road_routes[0].allownce,
                            fuel: res.data.road_routes[0].fuel,
                            parking: res.data.road_routes[0].parking,
                            other: res.data.road_routes[0].other,
                            is_found: res.data.road_routes[0].is_found,
                        })
                        this.setState({
                            road_routes: temp,
                            final_selected: final_selected,
                        })
                    }

                }
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    handelchange_final_2 = (final_selected) => {
        this.setState({
            final_selected: final_selected,
        })
    }
    handelchange_order = (order_selected) => {
        if (order_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.ini_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select initial location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_client_attach;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                ini_id: this.state.ini_selected.value,
                order_id: order_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        road_routes: res.data.road_routes,
                        order_data: res.data.order_data,
                        containers: res.data.containers,
                        order_selected: order_selected,
                        final_selected: null,
                        container_selected: null,
                        container_2_selected: null,
                        size:'',
                        volume:'',
                        size_2:'',
                        volume_2:'',
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    handelchange_container = (container_selected) => {
        let temp = this.state.containers.filter(v=>  {
            if (v.value === container_selected.value)
                return v;
        })
        if (temp.length > 0)
            this.setState({
                container_selected: container_selected,
                size:temp[0].size,
                volume:temp[0].volume,
            })
    }
    handelchange_2_container = (container_2_selected) => {
        let temp = this.state.containers.filter(v=>  {
            if (v.value === container_2_selected.value)
                return v;
        })
        if (temp.length > 0)
            this.setState({
                container_2_selected: container_2_selected,
                size_2:temp[0].size,
                volume_2:temp[0].volume,
            })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_get_assets_for_assign_own;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.plate_no.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btn_asset_view = (event,plate_no,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_get_asset;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id: id,
                    plate_no: plate_no,
                    driver_selected: res.data.driver_selected,
                    trailer_selected: res.data.trailer_selected,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
        this.setState({

        })
    }
    btn_asset_update = (event,orgin,destination) => {
        event.preventDefault();
        if (this.state.driver_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select driver",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let trailer = this.state.trailer_selected == null ? null : this.state.trailer_selected.value;
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_update_asset;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                driver_id: this.state.driver_selected.value,
                trailer_id: trailer,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }

    btn_volume_asset= (event,plate_no,id,orgin,destination) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_get_volume;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 3,
                    id: id,
                    plate_no: plate_no,
                    company_name:res.data.company_name,
                    order_no:res.data.order_no,
                    orgin:orgin,
                    destination:destination,
                    tol:res.data.tol,
                    avl_weight:res.data.avl_weight,
                    weight:res.data.weight,
                    no_container:res.data.no_container,
                    containers:res.data.containers,
                    container_selected: null,
                    container_2_selected: null,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_volume_update = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_update_volume;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            volume: this.state.weight,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_container_asset= (event,plate_no,id,orgin,destination) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_get_container;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 4,
                    id: id,
                    plate_no: plate_no,
                    company_name:res.data.company_name,
                    order_no:res.data.order_no,
                    orgin:orgin,
                    destination:destination,
                    no_container:res.data.no_container,
                    containers:res.data.containers,
                    container_selected: res.data.container_selected,
                    container_2_selected: res.data.container_2_selected,
                    size: res.data.size,
                    volume: res.data.volume,
                    size_2: res.data.size_2,
                    volume_2: res.data.volume_2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_container_update = (event) => {
        event.preventDefault();
        if (this.state.container_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select container",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let container_2 = this.state.container_2_selected == null ? null : this.state.container_2_selected.value;
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_update_container;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                container_1: this.state.container_selected.value,
                container_2: container_2,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btn_ini_loc= (event,plate_no,id,orgin,destination) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_get_start_loc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 5,
                    id: id,
                    plate_no: plate_no,
                    company_name:res.data.company_name,
                    order_no:res.data.order_no,
                    locations:res.data.locations,
                    ini_selected:res.data.ini_selected,
                    orgin:orgin,
                    destination:destination,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_ini_loc_update = (event) => {
        event.preventDefault();
        if (this.state.ini_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select container",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_update_start_loc;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                ini_loc_id: this.state.ini_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btn_fin_loc= (event,plate_no,id,orgin,destination) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_get_fin_loc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 6,
                    id: id,
                    plate_no: plate_no,
                    company_name:res.data.company_name,
                    order_no:res.data.order_no,
                    locations:res.data.locations,
                    final_selected:null,
                    orgin:orgin,
                    destination:destination,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_fin_loc_update = (event) => {
        event.preventDefault();
        if (this.state.final_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select final location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_update_fin_loc;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                fin_loc_id: this.state.final_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btn_client= (event,plate_no,id,orgin,destination) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_get_client;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 7,
                    id: id,
                    plate_no: plate_no,
                    company_name:res.data.company_name,
                    order_no:res.data.order_no,
                    locations:res.data.locations,
                    ini_selected:res.data.ini_selected,
                    orders:res.data.orders,
                    order_selected:null,
                    final_selected:null,
                    orgin:orgin,
                    destination:destination,
                    road_routes: [],
                    order_data:{},
                    containers: [],
                    container_selected: null,
                    container_2_selected: null,
                    actual_weight: '',
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_client_update = (event) => {
        if (this.state.ini_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select initial location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.order_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let fin_loc = this.state.final_selected == null ? null : this.state.final_selected.value;
            let container_1 = this.state.container_selected == null ? null : this.state.container_selected.value;
            let container_2 = this.state.container_2_selected == null ? null : this.state.container_2_selected.value;
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_update_client;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.id,
                ini_loc_id: this.state.ini_selected.value,
                fin_loc_id: fin_loc,
                order_id: this.state.order_selected.value,
                volume: this.state.actual_weight,
                container_1: container_1,
                container_2: container_2,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id: -1,
            iscreate: true,
            coc: '',
        })
    }
    btn_manage = (event,plate_no,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_create_obj;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id: id,
                    plate_no: plate_no,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                    locations: res.data.locations,
                    orders: res.data.orders,

                    driver_selected: null,
                    trailer_selected: null,
                    order_selected: null,
                    ini_selected: null,
                    final_selected: null,
                    road_routes: [],
                    order_data:{},
                    containers: [],
                    container_selected: null,
                    container_2_selected: null,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id: -1,
            iscreate: true,
            coc: '',
        })
    }
    btnassign = (event) => {
        event.preventDefault();

        if (this.state.driver_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select driver",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.ini_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select initial location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else if (this.state.order_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let trailer = this.state.trailer_selected == null ? null : this.state.trailer_selected.value;
            let fin_loc = this.state.final_selected == null ? null : this.state.final_selected.value;
            let container_1 = this.state.container_selected == null ? null : this.state.container_selected.value;
            let container_2 = this.state.container_2_selected == null ? null : this.state.container_2_selected.value;
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.vehicle_assign_own;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id: this.state.id,
                driver_id: this.state.driver_selected.value,
                trailer_id: trailer,
                ini_loc_id: this.state.ini_selected.value,
                fin_loc_id: fin_loc,
                order_id: this.state.order_selected.value,
                volume: this.state.actual_weight,
                container_1: container_1,
                container_2: container_2,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name: this.state.company_name,
            id: this.state.id,
            coc: this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    wiz_1 = () => {
        let count = 0;
        let tdata = this.state.filtered.map(value => {
            count = count + 1;
            let link_1=<a href="" onClick={event => this.btn_volume_asset(event,value.plate_no,value.assign_id,value.org,value.des)}>Volume</a>
            if(value.tol=="container")
                link_1=<a href="" onClick={event => this.btn_container_asset(event,value.plate_no,value.assign_id,value.org,value.des)}>Container</a>
            return (<tr valign="middle">
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_asset_view(event,value.plate_no,value.assign_id)}>Asset</a><hr/>
                    {link_1}<hr/>
                    <a href="" onClick={event => this.btn_ini_loc(event,value.plate_no,value.assign_id,value.org,value.des)}>Start Loc</a><hr/>
                    <a href="" onClick={event => this.btn_fin_loc(event,value.plate_no,value.assign_id,value.org,value.des)}>Final Loc</a><hr/>
                    <a href="" onClick={event => this.btn_client(event,value.plate_no,value.assign_id,value.org,value.des)}>Client</a><hr/>
                </td>
                <td style={{verticalAlign:"middle"}}>{value.plate_no}</td>
                <td style={{verticalAlign:"middle"}}>{value.company_name}</td>
                <td style={{verticalAlign:"middle"}}>{value.org}</td>
                <td style={{verticalAlign:"middle"}}>{value.des}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Change</th>
                            <th className="col-md-2">Plate #</th>
                            <th className="col-md-2">Company Name</th>
                            <th className="col-md-3">Orgin</th>
                            <th className="col-md-3">Destination</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update Assets</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}
                                               placeholder="Plate #"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer # <a href="" onClick={event => {
                                            event.preventDefault()
                                            this.setState({
                                                trailer_selected: null,
                                            })
                                        }}>(clear)</a> </label>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btn_asset_update}> Update Asset </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update Volume</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="order_no"
                                               value={this.state.order_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="orgin"
                                               value={this.state.orgin} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="destination"
                                               value={this.state.destination} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Left Weight (q) to load</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.avl_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Actual Weight (q)</label>
                                        <input type="text" className="form-control form-control-sm" name="weight"
                                               value={this.state.weight} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-3">

                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btn_volume_update}> Update Volume
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update Volume</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="order_no"
                                               value={this.state.order_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="orgin"
                                               value={this.state.orgin} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="destination"
                                               value={this.state.destination} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label># Container to load</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.no_container}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Container_1</label>
                                        <Select
                                            value={this.state.container_selected}
                                            onChange={this.handelchange_container}
                                            options={this.state.containers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Size</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="size"
                                               value={this.state.size}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Volume</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="volume"
                                               value={this.state.volume}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Container_2 <a href="" onClick={event => {
                                            event.preventDefault()
                                            this.setState({
                                                container_2_selected: null,
                                                size_2:'',
                                                volume_2:'',
                                            })
                                        }}>(clear)</a></label>
                                        <Select
                                            value={this.state.container_2_selected}
                                            onChange={this.handelchange_2_container}
                                            options={this.state.containers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Size</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="size_2"
                                               value={this.state.size_2}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Volume</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm" name="volume_2"
                                               value={this.state.volume_2}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btn_container_update}> Update Container
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_5 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update Start Location</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="order_no"
                                               value={this.state.order_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="orgin"
                                               value={this.state.orgin} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="destination"
                                               value={this.state.destination} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.ini_selected}
                                            onChange={this.handelchange_ini}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btn_ini_loc_update}> Update Start Location
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_6 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update End Location</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="order_no"
                                               value={this.state.order_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="orgin"
                                               value={this.state.orgin} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="destination"
                                               value={this.state.destination} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>End Location</label>
                                        <Select
                                            value={this.state.final_selected}
                                            onChange={this.handelchange_final_2}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btn_fin_loc_update}> Update End Location
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_7 = () => {
        let isdisabled_customer = true;
        if (this.state.ini_selected != null)
            isdisabled_customer = false;

        let isdisabled_final = true;
        let detail = null;
        let detail_2 = null;
        if (this.state.order_selected != null) {
            isdisabled_final = false;
            detail = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.order_no}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Orgin</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.orgin}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Destination</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.destination}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Millage</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.distance}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Cargo</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.cargo_name}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Description of Good</label>
                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                  onChange={this.inputmgt} value={this.state.order_data.des ?? "NA"}></textarea>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Types of Load</label>
                        <input disabled={true} className="form-control form-control-sm text-capitalize"
                               value={this.state.order_data.tol}/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </>
            if (this.state.order_data.tol === "container") {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label># Container to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.no_container}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_1</label>
                            <Select
                                value={this.state.container_selected}
                                onChange={this.handelchange_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size"
                                   value={this.state.size}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="volume"
                                   value={this.state.volume}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_2</label>
                            <Select
                                value={this.state.container_2_selected}
                                onChange={this.handelchange_2_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size_2"
                                   value={this.state.size_2}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="volume_2"
                                   value={this.state.volume_2}/>
                        </div>
                    </div>
                </>
            } else {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Left Weight (q) to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.avl_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Actual Weight (q)</label>
                            <input type="text" className="form-control form-control-sm" name="actual_weight"
                                   value={this.state.actual_weight} onChange={this.inputmgt}
                                   placeholder="Weight"/>
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">

                    </div>
                </>
            }
        }
        let km = 0;
        let allowence = 0;
        let fuel = 0;
        let parking = 0;
        let other = 0;
        let tdata = this.state.road_routes.map(v => {
            let bg = "bg-white"
            if (!v.is_found)
                bg = "bg-danger"
            if (v.is_found) {
                km = km + v.km
                allowence = allowence + v.allownce
                fuel = fuel + v.fuel
                parking = parking + v.parking
                other = other + v.other
            }
            return (<tr className={bg}>
                <td>{v.route}</td>
                <td>{v.status}</td>
                <td>{v.km}</td>
                <td>{v.allownce}</td>
                <td>{v.fuel}</td>
                <td>{v.parking}</td>
                <td>{v.other}</td>
            </tr>)
        })
        let tdata2 = null;
        if (this.state.road_routes.length > 0) {
            let total = km + allowence + fuel + parking + other
            tdata2 =
                <>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{km}</td>
                        <td>{allowence}</td>
                        <td>{fuel}</td>
                        <td>{parking}</td>
                        <td>{other}</td>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total</th>
                        <th>{total}</th>
                    </tr>
                </>
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update the customer</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="order_no"
                                               value={this.state.order_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="orgin"
                                               value={this.state.orgin} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="destination"
                                               value={this.state.destination} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.ini_selected}
                                            onChange={this.handelchange_ini}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            isDisabled={isdisabled_customer}
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <Select
                                            isDisabled={isdisabled_final}
                                            value={this.state.final_selected}
                                            onChange={this.handelchange_final}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                {detail}
                                {detail_2}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btn_client_update}> Update Client
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    wiz_3_1 = () => {
        let detail_2;
        if (this.state.tol === "container") {
            detail_2 = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label># Container to load</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.no_container}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Container_1</label>
                        <Select
                            value={this.state.container_selected}
                            onChange={this.handelchange_container}
                            options={this.state.containers}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Size</label>
                        <input disabled={true} type="text" className="form-control form-control-sm" name="size"
                               value={this.state.size}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Volume</label>
                        <input disabled={true} type="text" className="form-control form-control-sm" name="volume"
                               value={this.state.volume}/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Container_2</label>
                        <Select
                            value={this.state.container_2_selected}
                            onChange={this.handelchange_2_container}
                            options={this.state.containers}
                        />
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Size</label>
                        <input disabled={true} type="text" className="form-control form-control-sm" name="size_2"
                               value={this.state.size_2}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Volume</label>
                        <input disabled={true} type="text" className="form-control form-control-sm" name="volume_2"
                               value={this.state.volume_2}/>
                    </div>
                </div>
            </>
        }
        else
        {
            detail_2 = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Left Weight (q) to load</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.avl_weight}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Actual Weight (q)</label>
                        <input type="text" className="form-control form-control-sm" name="weight"
                               value={this.state.weight} onChange={this.inputmgt}/>
                    </div>
                </div>
                <div className="col-md-3">

                </div>
                <div className="col-md-3">

                </div>
            </>
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update Volume</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="order_no"
                                               value={this.state.order_no} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Orgin</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="orgin"
                                               value={this.state.orgin} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="destination"
                                               value={this.state.destination} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                {detail_2}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btn_asset_update}> Update Volume
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2_1 = () => {
        let isdisabled_customer = true;
        if (this.state.ini_selected != null)
            isdisabled_customer = false;

        let isdisabled_final = true;
        let detail = null;
        let detail_2 = null;
        if (this.state.order_selected != null) {
            isdisabled_final = false;
            detail = <>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Order #</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.order_no}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Orgin</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.orgin}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Destination</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.destination}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Millage</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.distance}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Cargo</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.order_data.cargo_name}/>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Description of Good</label>
                        <textarea disabled={true} rows={5} className="form-control form-control-sm"
                                  onChange={this.inputmgt} value={this.state.order_data.des ?? "NA"}></textarea>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label>Types of Load</label>
                        <input disabled={true} className="form-control form-control-sm text-capitalize"
                               value={this.state.order_data.tol}/>
                    </div>
                </div>
                <div className="col-md-3">
                </div>
            </>
            if (this.state.order_data.tol === "container") {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label># Container to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.no_container}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_1</label>
                            <Select
                                value={this.state.container_selected}
                                onChange={this.handelchange_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size"
                                   value={this.state.size}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="volume"
                                   value={this.state.volume}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Container_2</label>
                            <Select
                                value={this.state.container_2_selected}
                                onChange={this.handelchange_2_container}
                                options={this.state.containers}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Size</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="size_2"
                                   value={this.state.size_2}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Volume</label>
                            <input disabled={true} type="text" className="form-control form-control-sm" name="volume_2"
                                   value={this.state.volume_2}/>
                        </div>
                    </div>
                </>
            } else {
                detail_2 = <>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Left Weight (q) to load</label>
                            <input disabled={true} className="form-control form-control-sm"
                                   value={this.state.order_data.avl_weight}/>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label>Actual Weight (q)</label>
                            <input type="text" className="form-control form-control-sm" name="actual_weight"
                                   value={this.state.company_name} onChange={this.inputmgt}
                                   placeholder="Weight"/>
                        </div>
                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">

                    </div>
                </>
            }
        }
        let km = 0;
        let allowence = 0;
        let fuel = 0;
        let parking = 0;
        let other = 0;
        let tdata = this.state.road_routes.map(v => {
            let bg = "bg-white"
            if (!v.is_found)
                bg = "bg-danger"
            if (v.is_found) {
                km = km + v.km
                allowence = allowence + v.allownce
                fuel = fuel + v.fuel
                parking = parking + v.parking
                other = other + v.other
            }
            return (<tr className={bg}>
                <td>{v.route}</td>
                <td>{v.status}</td>
                <td>{v.km}</td>
                <td>{v.allownce}</td>
                <td>{v.fuel}</td>
                <td>{v.parking}</td>
                <td>{v.other}</td>
            </tr>)
        })
        let tdata2 = null;
        if (this.state.road_routes.length > 0) {
            let total = km + allowence + fuel + parking + other
            tdata2 =
                <>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{km}</td>
                        <td>{allowence}</td>
                        <td>{fuel}</td>
                        <td>{parking}</td>
                        <td>{other}</td>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>Total</th>
                        <th>{total}</th>
                    </tr>
                </>
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-info ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Update the customer</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               name="plate_no"
                                               value={this.state.plate_no} onChange={this.inputmgt}
                                               placeholder="Plate #"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Start Location</label>
                                        <Select
                                            value={this.state.ini_selected}
                                            onChange={this.handelchange_ini}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <Select
                                            isDisabled={isdisabled_customer}
                                            value={this.state.order_selected}
                                            onChange={this.handelchange_order}
                                            options={this.state.orders}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <Select
                                            isDisabled={isdisabled_final}
                                            value={this.state.final_selected}
                                            onChange={this.handelchange_final}
                                            options={this.state.locations}
                                        />
                                    </div>
                                </div>
                                {detail}
                                {detail_2}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm"
                                                onClick={this.btnassign}> Update Client
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-danger ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Mewecha Information</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-sm">
                                        <thead>
                                        <tr>
                                            <th className="col-md-2">Route</th>
                                            <th className="col-md-2">Status</th>
                                            <th className="col-md-1">KM</th>
                                            <th className="col-md-2">Allowance</th>
                                            <th className="col-md-2">Fuel</th>
                                            <th className="col-md-2">Parking</th>
                                            <th className="col-md-2">Others</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tdata}
                                        {tdata2}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        else if (this.state.wiz === 5)
            dis = this.wiz_5()
        else if (this.state.wiz === 6)
            dis = this.wiz_6()
        else if (this.state.wiz === 7)
            dis = this.wiz_7()
        return (
            <>
                <Nav type="update_own"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Update Own Vehicle</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Update_own;
