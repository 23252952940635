import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Cement_delivery extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',
            actual_weight:0,

        }
    }
    componentDidMount() {
        this.fetchdata()
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_datas_for_cement_delivery;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
            t_total_volume: 0,
            t_delivered_volume: 0,
            t_remain_volume: 0,
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                let actual_weight = (res.data.actual_weight * 1) / 400
                actual_weight = Math.round(actual_weight * 100) / 100
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    actual_weight: actual_weight,
                    t_total_volume: res.data.t_total_volume,
                    t_delivered_volume: res.data.t_delivered_volume,
                    t_remain_volume: res.data.t_remain_volume,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.label.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;

        let actual_weight=0;
        this.state.datas.map(v=>{
            if(v.active_weight!=="" && !isNaN(v.active_weight)){
                actual_weight=(actual_weight)+(1*v.active_weight);
            }
            return v;
        })
        this.setState({
            [name]: value,
        },()=>{
            this.setState({
                actual_weight: (actual_weight/400).toFixed(2),
            })
        })
    }
    inputmgt_no_truck = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let active_no_truck="";
        let active_weight="";
        if(value!=="" && !isNaN(value)){
            active_weight=(value*1)*400;
            active_no_truck=value;
        }
        let filtered=this.state.filtered.map(v=>{
            if(v.id==name){
                v.active_weight=active_weight
                v.active_no_truck=active_no_truck
            }
            return v;
        })
        let actual_weight=0;
        let datas=this.state.datas.map(v=>{
            if(v.id==name){
                v.active_weight=active_weight
                v.active_no_truck=active_no_truck
            }
            if(v.active_weight!=="" && !isNaN(v.active_weight)){
                actual_weight=(actual_weight*1)+(1*v.active_weight)
            }
            return v;
        })

        this.setState({
            datas: datas,
            filtered: filtered,
            actual_weight: Math.round(actual_weight/400 * 100) / 100,
        },()=>{
            console.log(this.state.filtered)
        })
    }
    inputmgt_weight = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let active_no_truck="";
        let active_weight="";
        if(value!=="" && !isNaN(value)){
            active_no_truck=(value*1)/400;
            active_weight=value;
        }
        let filtered=this.state.filtered.map(v=>{
            if(v.id==name){
                v.active_weight=active_weight
                v.active_no_truck=active_no_truck
            }
            return v;
        })
        let actual_weight=0;
        let datas=this.state.datas.map(v=>{

            if(v.id==name){
                v.active_weight=active_weight
                v.active_no_truck=active_no_truck
            }
            if(v.active_weight!=="" && !isNaN(v.active_weight)){
                actual_weight=(actual_weight*1)+(1*v.active_weight);

            }
            return v;
        })
        this.setState({
            datas: datas,
            filtered: filtered,
            actual_weight:Math.round(actual_weight/400 * 100) / 100,
        },()=>{
            console.log(this.state.filtered)
        })
    }

    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_delivery;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            datas:this.state.datas,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td>{value.label}</td>
                <td>{value.po_no}</td>
                <td>{value.total_weight}</td>
                <td>{value.delivered_volume}</td>
                <td>{value.remaining}</td>
                <td>  <div className="form-group">
                    <input type="text" className="form-control form-control-sm" name={value.id}
                           value={value.active_no_truck}
                           onChange={this.inputmgt_no_truck}
                           placeholder="# of truck"/>
                </div></td>
                <td><div className="form-group">
                    <input type="text" className="form-control form-control-sm" name={value.id}
                           value={value.active_weight}
                           onChange={this.inputmgt_weight}
                           placeholder="Weight (q)"/>
                </div></td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 table-responsive" style={{height: '500px'}}>
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-3">Client</th>
                            <th className="col-md-2">PO #</th>
                            <th className="col-md-1">Total Volume (q)</th>
                            <th className="col-md-1">Delivered Volume (q)</th>
                            <th className="col-md-1">Remaining Volume (q)</th>
                            <th className="col-md-2"># of Truck</th>
                            <th className="col-md-2">Weight (q)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2">Total Volume (q)</th>
                            <th className="col-md-2">{this.state.t_total_volume}</th>
                        </tr>
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2">Total Delivered Volume (q)</th>
                            <th className="col-md-2">{this.state.t_delivered_volume}</th>
                        </tr>
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2">Total Remaining Volume (q)</th>
                            <th className="col-md-2">{this.state.t_remain_volume}</th>
                        </tr>
                        <tr>
                            <th className="col-md-3"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2">Current Allocation (# of Truck)</th>
                            <th className="col-md-2">{this.state.actual_weight}</th>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-primary float-right" onClick={this.btncreate}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="cement_delivery"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Today Allocation</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Actual Weight (# Truck)</label>
                                                        <input readOnly={true} type="text" className="form-control form-control-sm" name="actual_weight"
                                                               value={this.state.actual_weight}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={this.btncreate}> Allocate Vehicle
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Delivery Allocated Weight ({this.state.actual_weight} (# Truck)) </h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cement_delivery;
