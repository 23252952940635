import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Kpi_data extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            datas2: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,

            kpi_uoms: [],
            kpi_uom_selected: null,

        }
    }
    handelchange_kpi_uom = (kpi_uom_selected) => {
        this.setState({
            kpi_uom_selected:kpi_uom_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_kpi_datas;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    kpi_uoms: res.data.kpi_uoms,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_kpi_data;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            kpi_description_id:this.state.kpi_description_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas2: res.data.datas,
                    wiz: 2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 3,
            id:-1,
            iscreate: true,
            st:'',
            ed:'',
            kvalue:'',
            avg_value:'',
            dep:'Marketing',
            remark:'',
        })
    }

    btnmore = (event,value) => {
        event.preventDefault();
        this.setState({
            kpi_description_id: value.id,
            des: value.des,
            name: value.name,
            avg_value: value.avg_value,
            dep: value.dep,
        }, () => this.fetchdata2())
    }
    btnedit = (event,value) => {
        event.preventDefault();
        this.setState({
            wiz: 3,
            iscreate: false,
            id:value.id,
            dt:value.dt,
            kvalue:value.value,
            remark:value.remark,
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.create_kpi_data;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st: this.state.st,
            ed: this.state.ed,
            kvalue: this.state.kvalue,
            remark: this.state.remark,
            avg_value: this.state.avg_value,
            dep: this.state.dep,
            kpi_description_id: this.state.kpi_description_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 2,
                    }, () => {
                        this.fetchdata2()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_kpi_data;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.id,
            kvalue: this.state.kvalue,
            remark: this.state.remark,
            avg_value: this.state.avg_value,
            dep: this.state.dep,
            kpi_description_id: this.state.kpi_description_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 2,
                    }, () => {
                        this.fetchdata2()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnmore(event,value)}>More</a>
                </td>
                <td>{value.des}</td>
                <td>{value.name}</td>
                <td>{value.remark}</td>
            </tr>)
        })
        return (<>

            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed table-responsive">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-3">KPI Description</th>
                            <th className="col-md-3">UoM</th>
                            <th className="col-md-3">Remark</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let count=0;
        let tdata = this.state.datas2.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value)}><i className="fas fa-edit px-1"></i></a>
                </td>
                <td>{value.des}</td>
                <td>{value.dt}</td>
                <td>{value.value}</td>
                <td>{value.avg_value}</td>
                <td>{value.dep}</td>
                <td>{value.remark}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Data</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">KPI: <strong>{this.state.des}</strong></h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <table id="report" className="table table-head-fixed table-responsive">
                                    <thead>
                                    <tr>
                                        <th className="col-md-1">#</th>
                                        <th className="col-md-2">Action</th>
                                        <th className="col-md-2">KPI Description</th>
                                        <th className="col-md-2">Date</th>
                                        <th className="col-md-1">Value</th>
                                        <th className="col-md-1">AvG Value</th>
                                        <th className="col-md-2">Department</th>
                                        <th className="col-md-1">Remark</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_3 = () => {
        let header_1 = "Add KPI Data"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
        let dt=<>
            <div className="col-md-4">
                <div className="form-group">
                    <label>Start Date</label>
                    <input type="date" className="form-control form-control-sm" name="st"
                           value={this.state.st} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label>End Date</label>
                    <input type="date" className="form-control form-control-sm" name="ed"
                           value={this.state.ed} onChange={this.inputmgt}/>
                </div>
            </div>
            <div className="col-md-4">
            </div>
        </>
        if (!this.state.iscreate) {
            header_1 = "Edit KPI Data"
            btn_1 =
                <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Edit </button>
            dt = null;
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>KPI Description</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>KPI UoM</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                {dt}
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>KPI Value</label>
                                        <input type="text" className="form-control form-control-sm" name="kvalue"
                                               value={this.state.kvalue} onChange={this.inputmgt}
                                               placeholder="KPI Value"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Average KPI Value</label>
                                        <input type="text" className="form-control form-control-sm" name="avg_value"
                                               value={this.state.avg_value} onChange={this.inputmgt}
                                               placeholder="AvG KPI Value"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Department</label>
                                        <select className="form-control form-control-sm" name="dep"
                                                value={this.state.dep} onChange={this.inputmgt}>
                                            <option value="-1">-- Select Department--</option>
                                            <option value="Marketing">Marketing</option>
                                            <option value="Operation">Operation</option>
                                            <option value="Finance">Finance</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={5} className="form-control form-control-sm" name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="kpi_data"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">KPI Data</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Kpi_data;
