import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Sub_contractors extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            datas: [],
            filtered: [],
            txtsearch: '',

            wiz: 1,
            id:-1,

        }
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.sub_contractors;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
            wiz: 1,
            id:-1,

        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st:this.state.st,
            ed:this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas:res.data.datas,
                    filtered:res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btnshowmore = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                coc: temp[0].coc,
                company_name: temp[0].company_name,
                plate_no: temp[0].plate_no,
                driver_name: temp[0].driver_name,
                trailer_no: temp[0].trailer_no,
                order_label: temp[0].order_label,
                fright_no: temp[0].fright_no,
                loaded_weight: temp[0].loaded_weight,
                transport_rate: temp[0].transport_rate,
                allocate_date: temp[0].allocate_date,
                order: temp[0].order,
                order_no: temp[0].order_no,
                remark: temp[0].remark,
                status: temp[0].status,
                req_by: temp[0].req_by,
                req_at: temp[0].req_at,
                app_by: temp[0].app_by,
                app_at: temp[0].app_at,
                rej_by: temp[0].rej_by,
                rej_at: temp[0].rej_at,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }

    wiz_1 = () => {
        let count=0;
        let redirecttofright_document=myconfig.redirecttofright_document;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            let color="bg-warning"
            if(value.status==="APPROVED")
                color="bg-success"
            else if(value.status==="REJECTED")
                color="bg-danger"

            let url=null;
            if(value.print) {
                let url2=redirecttofright_document+"?type=os&fright_no="+value.fright_no
                url = <> |
                    <a href={url2} target="_blank"> FO </a></>
            }
            return (<tr >
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnshowmore(event,value.id)}>More </a> {url}
                </td>
                <td className={color}>{value.fright_no}</td>
                <td className={color}>{value.order_no}</td>
                <td className={color}>{value.order_label}</td>
                <td className={color}>{value.company_name}</td>
                <td className={color}>{value.plate_no}</td>
                <td className={color}>{value.allocate_date}</td>
                <td className={color}>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Fright # </th>
                            <th className="col-md-1">Order # </th>
                            <th className="col-md-2">Order </th>
                            <th className="col-md-2">Sub-contractor</th>
                            <th className="col-md-1">Truck # </th>
                            <th className="col-md-1">Allocated Date</th>
                            <th className="col-md-1">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Sub-Contractor List</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Company</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.company_name}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Vehicle</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Driver</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sub-Contract Trailer</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.trailer_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.fright_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Allocated Date</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.allocate_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Order</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order_label}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea readOnly={true} rows={5} className="form-control form-control-sm"
                                                  value={this.state.order.des_good}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Types of Load</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.tol}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Available Weight</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.avl_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.loaded_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>


                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order Rate</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.order.order_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea readOnly={true} rows={5} className="form-control form-control-sm"
                                                  name="remark" value={this.state.remark}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>STATUS</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.status}/>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQUESTED BY</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.req_by}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>REQUESTED AT</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.req_at}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>APPROVED BY</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.app_by}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Approved AT</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.app_at}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Rejected BY</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.rej_by}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Rejected AT</label>
                                        <input readOnly={true} className="form-control form-control-sm"
                                               value={this.state.rej_at}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-danger btn-sm"
                                                onClick={this.btncancel}> CANCEL
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="sub_contractors"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Date</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Start Date</label>
                                                        <input type="date" className="form-control form-control-sm" name="st"
                                                               value={this.state.st} onChange={this.inputmgt}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>End Date</label>
                                                        <input type="date" className="form-control form-control-sm" name="ed"
                                                               value={this.state.ed} onChange={this.inputmgt}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.fetchdata()
                                                                }}> Get Data
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12"></div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Sub-Contractors</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Sub_contractors;
