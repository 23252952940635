import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Recipe_mgt extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            filtered:[],
            objsload: false,

            wiz:1,

            recipe_lists:[],
            menu_name:'',
            menu_id:'',

            stock_item_main:[],
            stock_items: [],
            stock_item_selected: null,

            cushow:false,
            siunit:'',
            cateory:'',
            qty:'',
            gross_qty:'',

        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_menus_for_recipe;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered:res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
        baseurl = myconfig.get_stock_items_for_recipe;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    stock_items:res.data.datas2,
                    stock_item_main:res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu = (event) => {
        event.preventDefault()
        this.setState({
            cushow:true,
            stock_item_selected: null,
            siunit:'',
            cateory:'',
            qty:'',
            gross_qty:'',
        })
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {

        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                                    <h3 className="card-title">Recipe Item Add</h3>
                                    <div className="card-tools">
                                        <button type="button" onClick={this.hidecu} className="btn btn-tool">
                                            <i className="fas fa-times"/>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Stock Item</label>
                                                    <Select
                                                        value={this.state.stock_item_selected}
                                                        onChange={this.handelchange_stock_item}
                                                        options={this.state.stock_items}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>UOM</label>
                                                    <input disabled={true} className="form-control" type="text"
                                                           name="siunit" onChange={this.inputmgt}
                                                           value={this.state.siunit}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Category</label>
                                                    <input disabled={true} className="form-control" type="text"
                                                           name="cateory" onChange={this.inputmgt}
                                                           value={this.state.cateory}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Quantity</label>
                                                    <input className="form-control" type="text"
                                                           name="qty" onChange={this.inputmgt}
                                                           value={this.state.qty}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Gross Quantity</label>
                                                    <input className="form-control" type="text"
                                                           name="gross_qty" onChange={this.inputmgt}
                                                           value={this.state.gross_qty}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        if(this.state.stock_item_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select stock item.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(isNaN(this.state.qty)){
            Swal.fire({
                title: 'Error!',
                text: "Quantity must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.qty<=0){
            Swal.fire({
                title: 'Error!',
                text: "Quantity must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(isNaN(this.state.gross_qty)){
            Swal.fire({
                title: 'Error!',
                text: "Gross Quantity must be numeric",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.gross_qty<=0){
            Swal.fire({
                title: 'Error!',
                text: "Gross Quantity must be greater than zero",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.qty>this.state.gross_qty){
            Swal.fire({
                title: 'Error!',
                text: "Quantity can not greater than Gross Quantity",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {
            let temp=this.state.recipe_lists;
            let isfound=false
            let temp2=temp.map(v=>{
                if(v.stock_item_id===this.state.stock_item_selected.value)
                {
                    v.qty=this.state.qty
                    v.gross_qty=this.state.gross_qty
                    isfound=true;
                }
                return v;
            })
            if(isfound===false){
                temp2.push({
                    menu_id:this.state.menu_name,
                    stock_item_id:this.state.stock_item_selected.value,
                    qty:this.state.qty,
                    gross_qty:this.state.gross_qty,
                    name:this.state.stock_item_selected.label,
                    siunit:this.state.siunit,
                    cateory:this.state.cateory,
                });
            }

            this.setState({
                recipe_lists:temp2,
                cushow:false,
            })
        }
    }

    handelchange_stock_item = (stock_item_selected) => {
        this.setState({
            siunit:'',
            cateory:'',
        }, () => {
            let temp=this.state.stock_item_main.filter(v=>{
                if(v.id===stock_item_selected.value)
                    return v;
            })
            if(temp.length>0){
                let temp2=this.state.recipe_lists.filter(v=>{
                    if(v.stock_item_id===stock_item_selected.value)
                        return v;
                })
                let t_qty='';
                let t_qty_gross='';
                if(temp2.length>0){
                    t_qty=temp2[0].qty;
                    t_qty_gross=temp2[0].gross_qty;
                }
                this.setState({
                    siunit:temp[0].siunit,
                    cateory:temp[0].cateory,
                    stock_item_selected:stock_item_selected,
                    qty:t_qty,
                    gross_qty:t_qty_gross,
                })
            }
        })
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    btn_save = (event) => {
        event.preventDefault();
        if (this.state.recipe_lists.length === 0) {
            Swal.fire({
                title: 'No Item is added to the recipe. Are you sure you want to save?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                   this.save()
                }
            })
        } else {
            this.save()
        }
    }
    btn_remove = (event,id) => {
        event.preventDefault();
        let temp=this.state.recipe_lists.filter(v=>{
            if(v.stock_item_id!==id)
                return v;
        })
        this.setState({
            recipe_lists:temp,
        })
    }
    save = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.create_recipe_by_menu;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
            recipe_lists: this.state.recipe_lists,
            menu_id:this.state.menu_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(result=>{
                    this.setState({
                        wiz:1,
                        recipe_lists:[],
                        menu_name:'',
                        menu_id:'',
                        stock_item_main:[],
                        stock_items: [],
                        stock_item_selected: null,
                        cushow:false,
                        siunit:'',
                        cateory:'',
                        qty:'',
                        gross_qty:'',
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name_en.toUpperCase().includes(value.toUpperCase()) ||
                    v.name_am.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            retdata = this.state.filtered.map(value => {
                return (<tr>
                    <td><a href="" onClick={(event => {this.manage_recipe(event,value.id,value.name_en)})}>Manage Recipe</a></td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.price}</td>
                    <td>{value.type}</td>
                    <td>{value.contents}</td>
                    <td>{value.isfound}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    manage_recipe = (event,id,menu_name) => {
        event.preventDefault()
        let baseurl = myconfig.get_recipe_by_menu;
        let redirecttologin = myconfig.redirecttologin;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                console.log(res.data)
                this.setState({
                    recipe_lists:res.data.datas,
                    menu_name:menu_name,
                    menu_id:id,
                    wiz:2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_cancel = (event,id,menu_name) => {
        event.preventDefault()
        this.setState({
            recipe_lists:[],
            menu_name:'',
            menu_id:-1,
            wiz:1,
        })
    }
    btn_refresh = (event) => {
        event.preventDefault()
        this.fetcheddata()
    }

    wiz_1=()=> {
        return (<div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}>
                                <input type="text" name="txtsearch"
                                       className="form-control float-right"
                                       value={this.state.txtsearch} onChange={this.inputsearch}
                                       placeholder="Search"/>
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header">
                        <div className="card-tools">
                            <a
                                className="text-right"
                                href=""
                                onClick={this.btn_refresh}
                            >Refresh</a>
                        </div>
                    </div>
                    <div className="card-header">
                        <h3 className="card-title"></h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                        <table className="table table-head-fixed text-nowrap">
                            <thead>
                            <tr>
                                <th className="col-md-1">Action</th>
                                <th className="col-md-2">Name English</th>
                                <th className="col-md-2">Name Amharic</th>
                                <th className="col-md-2">Price</th>
                                <th className="col-md-1">Type</th>
                                <th className="col-md-2">Contents</th>
                                <th className="col-md-2">Recipe Found</th>

                            </tr>
                            </thead>
                            <tbody>
                            {this.tabledata()}
                            </tbody>
                        </table>
                    </div>
                    {/* /.card-body */}
                </div>
            </div>
        </div>)
    }
    wiz_2=()=> {
        let count=0;
        let data=this.state.recipe_lists.map(v=>{
            count=count+1;
            return(<tr>
                <td>{count}</td>
                <td><a href="" onClick={event => {this.btn_remove(event,v.stock_item_id)}}>Remove</a></td>
                <td>{v.name}</td>
                <td>{v.siunit}</td>
                <td>{v.qty}</td>
                <td>{v.gross_qty}</td>
            </tr>)
        })
        return (<div className="row">
            <section className="col-lg-7 connectedSortable ui-sortable">
                <div className="card">
                    <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                        <h3 className="card-title">
                            <i className="ion ion-clipboard mr-1" />
                            Menu Recipe for {this.state.menu_name}
                        </h3>
                    </div>
                    <div className="card-body">
                        <div className="card-body table-responsive p-0" style={{height: '250px'}}>
                            <table className="table table-head-fixed">
                                <thead>
                                <tr>
                                    <th className="col-md-1">#</th>
                                    <th className="col-md-1">Action</th>
                                    <th className="col-md-2">Item</th>
                                    <th className="col-md-2">UOM</th>
                                    <th className="col-md-3">QTY</th>
                                    <th className="col-md-3">Gross QTY</th>
                                </tr>

                                </thead>
                                <tbody>
                                {data}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-primary float-right m-2" onClick={(event)=>this.showcu(event)}><i className="fas fa-plus" /> Add item</button>
                        <button type="button" className="btn btn-success float-right m-2" onClick={this.btn_save}><i className="fas fa-save" /> Save item</button>
                        <button type="button" className="btn btn-danger float-right m-2" onClick={this.btn_cancel}><i className="fas fa-power-off" /> Cancel </button>
                    </div>
                </div>
            </section>
        </div>)
    }

    render() {
        let display=null;
        if(this.state.wiz===1)
            display=this.wiz_1();
        else if(this.state.wiz===2)
            display=this.wiz_2();
        return (
            <>
                <Nav type="recipe_mgt"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Recipe Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Recipe</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            {display}
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Recipe_mgt;
