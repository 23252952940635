import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class D_sale_per_user extends Component {

    constructor() {
        super();
        this.state = {
            reports:[],
            filtered:[],
            txtsearch:'',
        }
    }
    componentDidMount() {
        this.fetchdata()
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.d_sale_per_user;
        this.setState({
            reports:[],
            filtered:[],

        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    reports: res.data.reports,
                    filtered: res.data.reports,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.reports;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.order_no.toUpperCase().includes(value.toUpperCase()) ||
                    v.servedby.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,

        },()=>this.fetcheddata2())
    }
    btnexport_report= (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    wiz_1 = () => {
        let total_price = 0;
        let tdata = this.state.filtered.map(value => {
            total_price = parseFloat(total_price) + parseFloat(value.price);
            return (<tr>
                <td>{value.order_no}</td>
                <td>{value.at}</td>
                <td>{value.by}</td>
                <td>{value.isserved}</td>
                <td>{value.servedby}</td>
                <td>{value.order}</td>
                <td>{value.price}</td>
            </tr>)
        })
        let tdata_2 = null;
        if (this.state.filtered.length > 0) {
            tdata_2 = <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <th className="text-right">Total Sales:-</th>
                <th>{total_price}</th>
            </tr>
        }
        return (<>
            <div className="px-3 py-1 text-left"><a href="#" onClick={this.btnexport_report}>.xlsx</a></div>
            <table id="report" className="table table-head-fixed">
                <thead>
                <tr>
                    <th className="col-md-1">Order #</th>
                    <th className="col-md-2">Order At</th>
                    <th className="col-md-2">Cashier Name</th>
                    <th className="col-md-1">Is served</th>
                    <th className="col-md-2">Served By</th>
                    <th className="col-md-3">Orders</th>
                    <th className="col-md-1">Price</th>
                </tr>
                </thead>
                <tbody>
                {tdata}
                {tdata_2}
                </tbody>
            </table>
        </>)
    }
    render() {
        let dis=this.wiz_1();
        return (
            <>
                <Nav type="d_sale_per_user"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Served Daily</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Served</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Daily Report</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {dis}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default D_sale_per_user;
