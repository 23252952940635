import {Component} from 'react';
import axios from "axios"
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import myconfig from "../../myconfig";
import logimg from "../../img/logo.png";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { StickyContainer, Sticky } from 'react-sticky';
import Select from "react-select";

let source = axios.CancelToken.source();

class D_marketing extends Component {
    constructor() {
        super();
        this.state = {
            wiz: 2,
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            kpi_plans:[],
            tpt_services_1:[],
            tpt_services_2:[],
            cmt_services_1:[],
            cmt_services_2:[],
            tpt_deliverys:[],
            cmt_deliverys:[],
            is_dis:false,
        };
    }

    componentDidMount() {
        //this.fetchdata()
    }

    fetchdata_dashboard = () => {
        let baseurl = myconfig.d_marketing;
        let redirecttologin = myconfig.redirecttologin;
        /* let token = new URLSearchParams(this.props.location.search).get("token");*/
        this.setState({
            wiz:0,
            is_dis:false,
        },()=>{
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                st:this.state.st,
                ed:this.state.ed,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    }).then(v=>{
                        this.setState({
                            wiz: 2,
                            is_dis:false,
                        })
                    })
                } else {
                    this.setState({
                        wiz: 2,
                        kpi_plans: res.data.kpi_plans,
                        tpt_services_1: res.data.tpt_services_1,
                        tpt_services_2: res.data.tpt_services_2,
                        tpt_deliverys: res.data.tpt_deliverys,
                        cmt_services_1: res.data.cmt_services_1,
                        cmt_services_2: res.data.cmt_services_2,
                        cmt_deliverys: res.data.cmt_deliverys,
                        is_dis:true,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })

    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_0 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Loading...</h5>
            </div>
        )
    }
    wiz_1 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Invalid session token. Please contact your admin.</h5>
            </div>
        )
    }

    wiz_2 = () => {
        let t_data_kpi_plans=this.state.kpi_plans.map(value=>{
            return(
                <tr>
                    <td>{value.kpi}</td>
                    <td>{value.umo}</td>
                    <td>{value.avg_value}</td>
                    <td>{value.plan_value}</td>
                    <td>{value.plan_etb}</td>
                </tr>)
        })

        let i=0
        let t_data_tpt_services_1=this.state.tpt_services_1.map(value=>{
            i=i+1;
            if(i===1)
                return(
                    <tr>
                        <td rowSpan={8} style={{verticalAlign:"middle"}}>Transport service</td>
                        <td>{value.kpi}</td>
                        <td>{value.plan_ton}</td>
                        <td>{value.plan_etb}</td>
                        <td>{value.actual_ton}</td>
                        <td>{value.actual_etb}</td>
                        <td>{value.percentile}%</td>
                        <td>{value.remark}</td>
                    </tr>
                )
            else
                return(
                    <tr>
                        <td>{value.kpi}</td>
                        <td>{value.plan_ton}</td>
                        <td>{value.plan_etb}</td>
                        <td>{value.actual_ton}</td>
                        <td>{value.actual_etb}</td>
                        <td>{value.percentile}%</td>
                        <td>{value.remark}</td>
                    </tr>
                )
        })

        let t_data_tpt_services_2=this.state.tpt_services_2.map(value=>{
            return(
                <tr>
                    <td>{value.kpi}</td>
                    <td>{value.plan}</td>
                    <td></td>
                    <td>{value.actual}</td>
                    <td></td>
                    <td>{value.percentile}%</td>
                    <td>{value.remark}</td>
                </tr>
            )
        })

        i=0
        let t_data_cmt_deliverys=this.state.cmt_deliverys.map(value=>{
            i=i+1;
            if(i===1)
                return(
                    <tr>
                        <td rowSpan={3} style={{verticalAlign:"middle"}}>Cement sales Delivery</td>
                        <td>{value.kpi}</td>
                        <td>{value.ton}</td>
                        <td>{value.truck}</td>
                        <td>{value.etb}</td>
                        <td></td>
                        <td></td>
                        <td>{value.remark}</td>
                    </tr>
                )
            else
                return(
                    <tr>
                        <td>{value.kpi}</td>
                        <td>{value.ton}</td>
                        <td>{value.truck}</td>
                        <td>{value.etb}</td>
                        <td></td>
                        <td></td>
                        <td>{value.remark}</td>
                    </tr>
                )
        })


        i=0
        let t_data_cmt_services_1=this.state.cmt_services_1.map(value=>{
            i=i+1;
            if(i===1)
                return(
                    <tr>
                        <td rowSpan={4} style={{verticalAlign:"middle"}}>Cement retail/Wholesale sales</td>
                        <td>{value.kpi}</td>
                        <td>{value.plan_ton}</td>
                        <td>{value.plan_etb}</td>
                        <td>{value.actual_ton}</td>
                        <td>{value.actual_etb}</td>
                        <td>{value.percentile}%</td>
                        <td>{value.remark}</td>
                    </tr>
                )
            else
                return(
                    <tr>
                        <td>{value.kpi}</td>
                        <td>{value.plan_ton}</td>
                        <td>{value.plan_etb}</td>
                        <td>{value.actual_ton}</td>
                        <td>{value.actual_etb}</td>
                        <td>{value.percentile}%</td>
                        <td>{value.remark}</td>
                    </tr>
                )
        })

        let t_data_cmt_services_2=this.state.cmt_services_2.map(value=>{
            return(
                <tr>
                    <td>{value.kpi}</td>
                    <td>{value.plan}</td>
                    <td></td>
                    <td>{value.actual}</td>
                    <td></td>
                    <td>{value.percentile}%</td>
                    <td>{value.remark}</td>
                </tr>
            )
        })

        i=0
        let t_data_tpt_deliverys=this.state.tpt_deliverys.map(value=>{
            i=i+1;
            if(i===1)
                return(
                    <tr>
                        <td rowSpan={3} style={{verticalAlign:"middle"}}>Transport sales Delivery</td>
                        <td>{value.kpi}</td>
                        <td>{value.ton}</td>
                        <td>{value.truck}</td>
                        <td>{value.etb}</td>
                        <td></td>
                        <td></td>
                        <td>{value.remark}</td>
                    </tr>
                )
            else
                return(
                    <tr>
                        <td>{value.kpi}</td>
                        <td>{value.ton}</td>
                        <td>{value.truck}</td>
                        <td>{value.etb}</td>
                        <td></td>
                        <td></td>
                        <td>{value.remark}</td>
                    </tr>
                )
        })

        let dis=null;
        if(this.state.is_dis) {
            dis = <>
                <div className="col-md-12 pb-2">
                    <table className="table table-responsive-lg">
                        <tr style={{backgroundColor: "#00008B", color: "#FFFFFF"}}>
                            <th className="col-md-2" colSpan={9}>Plan</th>
                        </tr>
                        <tr style={{backgroundColor: "#FF0000", color: "#FFFFFF"}}>
                            <th className="col-md-3">KPI</th>
                            <th className="col-md-3">UOM</th>
                            <th className="col-md-2">Avg Price</th>
                            <th className="col-md-2">Plan-ton</th>
                            <th className="col-md-2">Plan-ETB</th>
                        </tr>
                        {t_data_kpi_plans}
                    </table>
                </div>
                <div className="col-md-12">

                    <table className="table table-responsive-lg">
                        <tr style={{backgroundColor: "#00008B", color: "#FFFFFF"}}>
                            <th className="col-md-2" colSpan={8}>KPI Data</th>
                        </tr>
                        <tr style={{backgroundColor: "#FF0000", color: "#FFFFFF"}}>
                            <th className="col-md-2">Transport Collection</th>
                            <th className="col-md-2">Transport sales</th>
                            <th className="col-md-1">Plan</th>
                            <th className="col-md-1">Plan (ETB)</th>
                            <th className="col-md-1">Actual Ton</th>
                            <th className="col-md-1">Actual (ETB)</th>
                            <th className="col-md-2">%tage</th>
                            <th className="col-md-1">Remarks</th>
                        </tr>
                        {t_data_tpt_services_1}
                        <tr style={{backgroundColor: "#00008B", color: "#FFFFFF"}}>
                            <th className="col-md-2">Transport sales</th>
                            <th className="col-md-1">Plan</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1">Actual</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2">%tage</th>
                            <th className="col-md-1">Remarks</th>
                        </tr>
                        {t_data_tpt_services_2}
                        <tr style={{backgroundColor: "#FF0000", color: "#FFFFFF"}}>
                            <th className="col-md-2">Transport Delivery</th>
                            <th className="col-md-2">Shipment Status</th>
                            <th className="col-md-1">Ton</th>
                            <th className="col-md-1">Trucks</th>
                            <th className="col-md-1">ETB</th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1">Remarks</th>
                        </tr>
                        {t_data_tpt_deliverys}
                        <tr style={{backgroundColor: "#00008B", color: "#FFFFFF"}}>
                            <th className="col-md-2">Cement Collection</th>
                            <th className="col-md-2">Cements sales</th>
                            <th className="col-md-1">Plan</th>
                            <th className="col-md-1">Plan (ETB)</th>
                            <th className="col-md-1">Actual Ton</th>
                            <th className="col-md-1">Actual (ETB)</th>
                            <th className="col-md-2">%tage</th>
                            <th className="col-md-1">Remarks</th>
                        </tr>
                        {t_data_cmt_services_1}
                        <tr style={{backgroundColor: "#FF0000", color: "#FFFFFF"}}>
                            <th className="col-md-2">Cement sales</th>
                            <th className="col-md-1">Plan</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1">Actual</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2">%tage</th>
                            <th className="col-md-1">Remarks</th>
                        </tr>
                        {t_data_cmt_services_2}
                        <tr style={{backgroundColor: "#00008B", color: "#FFFFFF"}}>
                            <th className="col-md-2">Cement Delivery</th>
                            <th className="col-md-2">Shipment Status</th>
                            <th className="col-md-1">Ton</th>
                            <th className="col-md-1">Trucks</th>
                            <th className="col-md-1">ETB</th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-1">Remarks</th>
                        </tr>
                        {t_data_cmt_deliverys}

                        <tr style={{backgroundColor: "#FF0000", color: "#FFFFFF"}}>
                            <th className="col-md-2" colSpan={8}>Challenges / opportunities encountered</th>
                        </tr>
                        <tr>
                            <td className="col-md-1" rowSpan={1} style={{verticalAlign: "middle"}}></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-2"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-2">Lack of manpower</td>
                        </tr>
                        <tr style={{backgroundColor: "#00008B", color: "#FFFFFF"}}>
                            <th className="col-md-2" colSpan={8}>Strategic Issues</th>
                        </tr>
                        <tr>
                            <td className="col-md-1" rowSpan={2} style={{verticalAlign: "middle"}}></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-2"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-1"></td>
                            <td className="col-md-2">Ensure maximum utilization of market opportunities in Sister
                                companies, (Cement, Local coal.) that mobilizes 100% of available trucks
                            </td>
                        </tr>

                    </table>
                </div>
            </>
        }
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                <h3 className="card-title">Daily Sales & Marketing</h3>
                            </div>
                            <div className="card-body table-responsive  table-bordered p-0">
                                <div className="row">
                                    <div className="col-md-4 mt-3 mx-3">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input type="date" className="form-control" name="st"
                                                   value={this.state.st} onChange={this.inputmgt}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3 mx-3">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input type="date" className="form-control" name="ed"
                                                   value={this.state.ed} onChange={this.inputmgt}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mx-3">
                                        <div className="form-group">
                                            <label> </label>
                                            <button className="btn btn-outline-primary" onClick={event => {
                                                event.preventDefault();
                                                this.fetchdata_dashboard()
                                            }}>Get Data</button>
                                        </div>
                                    </div>
                                    {dis}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let dis=null;
        if(this.state.wiz===0)
            dis=this.wiz_0();
        else if(this.state.wiz===1)
            dis=this.wiz_1();
        else if(this.state.wiz===2)
            dis=this.wiz_2();

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-2" style={{width: "100%"}}>
                            <div className="card">
                                <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                    <h2 className="card-title">Dashboard</h2>
                                    <div className="card-tools">
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default D_marketing;
