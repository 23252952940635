import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Order_accept extends Component {

    constructor() {
        super();
        this.state = {
            is_exe:false,
            datas: [],
            data: {},
            urls: [],
            len:0,
            wiz: 1,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_open_order_for_order_accept;
        this.setState({
            datas: [],
        })

        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btn_accept = (event) => {
        event.preventDefault();
        if(!this.state.is_exe) {
            Swal.fire({
                title: 'Are you sure you want to approve the order? You can not undone the operation',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        is_exe:true,
                    })
                    let redirecttologin = myconfig.redirecttologin;
                    let baseurl = myconfig.order_accept;
                    axios.post(baseurl, {
                        sid: window.localStorage.getItem("logmgtsys_sid"),
                        id: this.state.id,
                    }, {cancelToken: source.token}).then(res => {
                        console.log(res.data)
                        if (res.data.auth == false) {
                            window.localStorage.setItem("logmgtsys_sid", null);
                            window.localStorage.setItem("logmgtsys_username", null);
                            window.localStorage.setItem("logmgtsys_name", null);
                            var win = window.open(redirecttologin, "_self");
                        } else if (res.data.success == false) {
                            Swal.fire({
                                title: 'Error!',
                                text: res.data.msg,
                                icon: 'error',
                                confirmButtonText: 'Okay'
                            })
                            this.setState({
                                is_exe:false,
                            })
                        } else {
                            Swal.fire({
                                title: 'Success!',
                                text: res.data.msg,
                                icon: 'success',
                                confirmButtonText: 'Okay'
                            }).then(v => {
                                this.setState({
                                    wiz:1,
                                },()=>{
                                    this.fetchdata()
                                })
                            })
                        }
                    }).catch((e) => {
                        console.log(e.message);
                    });
                }
            })
        }
        else{
            Swal.fire({
                title: 'Information!',
                text: "Wait until the first operation is done",
                icon: 'info',
                confirmButtonText: 'Okay'
            })
        }
    }
    btn_manage = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_order_accept;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    id:id,
                    data:res.data.data,
                    urls:res.data.urls,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    wiz_1 = () => {
        let tdata = this.state.datas.map(value => {
            return (<tr>
                <td>
                    <a href="" onClick={event =>{
                        this.btn_manage(event,value.id)
                    }}>Manage</a>
                </td>
                <td>{value.order_no}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.cargo}</td>
                <td>{value.transport_rate}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Order #</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-2">Origin</th>
                            <th className="col-md-2">Destination</th>
                            <th className="col-md-2">Cargo</th>
                            <th className="col-md-2">Transport Rate</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let len=this.state.len;
        let temp=[];
        for(let i=1; i<=len;i++){
            let va="img_"+i.toString();
            temp.push({
                state_name:va
            })
        }
       let imgs=this.state.urls.map(v=>{
            return (<div className="col-md-11 text-center">
                <img src={v.url}/>
            </div> )
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Order Accept</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Order #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.order_no}/>
                                    </div>
                                </div>
                                <div className="col-md-9">

                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.client}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Millage</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.distance}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Description of Good</label>
                                        <textarea rows={5} className="form-control form-control-sm"
                                                  onChange={this.inputmgt} readOnly={true}>{this.state.data.des_good}</textarea>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cargo</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Modal Of Transport</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.mode_of_transport}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>PO #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.purchase_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Start Time</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.st_date}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>End Date</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ed_date}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Transport Rate</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.transport_rate}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.weight}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Transport Type</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.transport_type}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Cement Order</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ce_order}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cement PO #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ce_purchase_no}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cement Order Date</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ce_order_date}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cement Available Weight</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ce_weight}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Cement Type</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.data.ce_cargo}/>
                                    </div>
                                </div>
                                {imgs}
                                <div className="col-md-3 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_accept}> Accept Order </button>
                                    </div>
                                </div>
                                <div className="col-md-3 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="order_accept"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Order Accept</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_accept;
