import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class R_load_factor extends Component {

    constructor() {
        super();
        this.state = {
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            reports: [],
            filter: "1",
            ret_data:{
                st:new Date().toISOString().slice(0, 10),
                ed:new Date().toISOString().slice(0, 10),
                t_volume:0,
                t_loaded_distance:0,
                t_empty_distance:0,
                t_total_distance:0,
                t_ton_km:0,
                t_load_factor:0,
            }
        }
    }

    componentDidMount() {
        // this.getreport();
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    btnget_report = (event) => {
        event.preventDefault();
        this.getreport();
    }
    getreport = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.r_load_factor;
        this.setState({
            reports: [],
            ret_data:{
                st:new Date().toISOString().slice(0, 10),
                ed:new Date().toISOString().slice(0, 10),
                t_volume:0,
                t_loaded_distance:0,
                t_empty_distance:0,
                t_total_distance:0,
                t_ton_km:0,
                t_load_factor:0,
            }
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            st: this.state.st,
            ed: this.state.ed,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    reports: res.data.reports,
                    ret_data: res.data.ret_data,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            reports: [],
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    tabledata=()=> {
        let retdata = this.state.reports.map(value => {
            return (<tr>
                <td>{value.plate_no}</td>
                <td>{value.fright_no}</td>
                <td>{value.loading}</td>
                <td>{value.unloading}</td>
                <td>{value.company_name}</td>
                <td>{value.name}</td>
                <td>{value.volume}</td>
                <td>{value.loaded_distance}</td>
                <td>{value.ton_km}</td>
                <td>{value.empty_distance}</td>
                <td>{value.total_distance}</td>
                <td>{value.load_factor} %</td>
            </tr>)
        });
        return (<>
            {retdata}
        </>)
    }
    render() {
        return (
            <>
                <Nav type="r_load_factor"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Load Factor Report</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Report</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="st" value={this.state.st}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <input className="form-control" type="date"
                                                                   name="ed" value={this.state.ed}
                                                                   onChange={this.inputmgt}/>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Filter</label>
                                                            <select className="form-control"
                                                                    name="filter" value={this.state.filter}
                                                                    onChange={this.inputmgt}>
                                                                <option value="1">All</option>
                                                                <option value="2">Completed</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <button type="button" className="btn btn-primary float-right"
                                                    onClick={this.btnget_report}><i className="fas fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">

                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Report Result</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                                               onClick={this.btnexport_report}>Download .xlsx</a></div>
                                                <div className="table" id="section_to_print">
                                                    <table id="report" className="table table-responsive">
                                                        <tr>
                                                            <th colSpan={12}>Summary Load Factor</th>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><strong>Start Time: </strong>{this.state.ret_data.st}</td>
                                                            <td colSpan={3}><strong>End Time: </strong>{this.state.ret_data.ed}</td>
                                                            <td colSpan={3}><strong>Total Loaded Millage: </strong>{this.state.ret_data.t_loaded_distance}</td>
                                                            <td colSpan={3}><strong>Total Empty Millage: </strong>{this.state.ret_data.t_empty_distance}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={3}><strong>Total Millage: </strong>{this.state.ret_data.t_total_distance}</td>
                                                            <td colSpan={3}><strong>Total Loaded Volume(t): </strong>{this.state.ret_data.t_volume}</td>
                                                            <td colSpan={3}><strong>Total Ton Per KM: </strong>{this.state.ret_data.t_ton_km}</td>
                                                            <td colSpan={3}><strong>Total Load Factor: </strong>{this.state.ret_data.t_load_factor} %</td>
                                                        </tr>
                                                        <tr>
                                                            <th>TRUCK No.</th>
                                                            <th>FREIGHT ORDER #</th>
                                                            <th>ORIGIN</th>
                                                            <th>DESTINATION</th>
                                                            <th>CUSTOMER</th>
                                                            <th>CARGO</th>
                                                            <th>LOAD WEIGHT</th>
                                                            <th>LOAD MILLAGE</th>
                                                            <th>TON-KM</th>
                                                            <th>EMPTY MILLAGE</th>
                                                            <th>TOTAL MILLAGE</th>
                                                            <th>LOAD FACTOR</th>
                                                        </tr>
                                                        {this.tabledata()}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default R_load_factor;
