import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class G_in_req extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,

            vehicles:[],
            drivers:[],
            trailers:[],
            reason_locs:[],
            reason_lists:[],
            vehicle_selected: null,
            driver_selected: null,
            trailer_selected: null,
            reason_loc_selected: null,
            reason_list_selected: null,

            dt: '',
            ti: '',
            remark: '',
        }
    }

    handelchange_vehicle = (vehicle_selected) => {
        this.setState({
            vehicle_selected: vehicle_selected,
        })
    }
    handelchange_driver = (driver_selected) => {
        this.setState({
            driver_selected: driver_selected,
        })
    }
    handelchange_trailer = (trailer_selected) => {
        this.setState({
            trailer_selected: trailer_selected,
        })
    }
    handelchange_reason_loc = (reason_loc_selected) => {
        this.setState({
            reason_loc_selected: reason_loc_selected,
        })
    }
    handelchange_reason_list = (reason_list_selected) => {
        this.setState({
            reason_list_selected: reason_list_selected,
        })
    }
    componentDidMount() {
        this.fetchdata()
        this.fetchdata2()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_g_in_req;
        /*this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })*/
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    vehicles: res.data.vehicles,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                    reason_locs: res.data.reason_locs,
                    reason_lists: res.data.reason_lists,
                    dt: res.data.dt,
                    ti: res.data.ti,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.gets_g_in_req;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.datas.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            let driver_selected=null;
            if(temp[0].driver_id!=null)
                driver_selected={value:temp[0].driver_id,label:temp[0].driver_name}
            let trailer_selected=null;
            if(temp[0].trailer_id!=null)
                trailer_selected={value:temp[0].trailer_id,label:temp[0].trailer_no}
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                vehicle_selected: {value:temp[0].vehicle_id,label:temp[0].plate_no},
                driver_selected: driver_selected,
                trailer_selected: trailer_selected,
                reason_loc_selected: {value:temp[0].reason_loc_id,label:temp[0].loc_name},
                reason_list_selected: {value:temp[0].reason_list_id,label:temp[0].reason_name},
                dt: temp[0].dt,
                ti: temp[0].ti,
                remark: temp[0].in_remark,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btndelete = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to delete the record? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.remove_g_in_req;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id:id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.fetchdata2()
                        })

                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.reason_loc_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select Location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.reason_list_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select Reason",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.g_in_req;
            let driver_id=this.state.driver_selected===null?null:this.state.driver_selected.value
            let trailer_id=this.state.trailer_selected===null?null:this.state.trailer_selected.value
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                vehicle_id:this.state.vehicle_selected.value,
                driver_id:driver_id,
                trailer_id:trailer_id,
                reason_loc_id:this.state.reason_loc_selected.value,
                reason_list_id:this.state.reason_list_selected.value,
                remark:this.state.remark,
                dt:this.state.dt,
                ti:this.state.ti,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata2()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    btnupdate = (event) => {
        event.preventDefault();
        if (this.state.vehicle_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.reason_loc_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select Location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if (this.state.reason_list_selected == null) {
            Swal.fire({
                title: 'Error!',
                text: "Select Reason",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.update_g_in_req;
            let driver_id=this.state.driver_selected===null?null:this.state.driver_selected.value
            let trailer_id=this.state.trailer_selected===null?null:this.state.trailer_selected.value
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id:this.state.id,
                vehicle_id:this.state.vehicle_selected.value,
                driver_id:driver_id,
                trailer_id:trailer_id,
                reason_loc_id:this.state.reason_loc_selected.value,
                reason_list_id:this.state.reason_list_selected.value,
                remark:this.state.remark,
                dt:this.state.dt,
                ti:this.state.ti,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata2()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value.id)}><i className="fas fa-edit px-1"></i></a>
                    <a href="" onClick={event => this.btndelete(event,value.id)}><i className="fas fa-trash px-1 text-danger"></i></a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.trailer_no}</td>
                <td>{value.loc_name}</td>
                <td>{value.reason_name}</td>
                <td>{value.intime}</td>
                <td>{value.in_req_by}</td>
                <td>{value.in_req_at}</td>
                <td>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Request</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Action</th>
                            <th>Truck</th>
                            <th>Driver</th>
                            <th>Trailer</th>
                            <th>Location</th>
                            <th>Reason</th>
                            <th>Maintenance IN Time</th>
                            <th>REQ BY</th>
                            <th>REQ AT</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let header_1 = "New Request"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Request </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Request"
            btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Edit </button>
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Truck</label>
                                        <Select
                                            value={this.state.vehicle_selected}
                                            onChange={this.handelchange_vehicle}
                                            options={this.state.vehicles}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver </label><a href="" onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            driver_selected: null,
                                        })
                                    }}>(Reset)</a>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer</label><a href="" onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            trailer_selected:null,
                                        })
                                    }}>(Reset)</a>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Location</label>
                                        <Select
                                            value={this.state.reason_loc_selected}
                                            onChange={this.handelchange_reason_loc}
                                            options={this.state.reason_locs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Reason</label>
                                        <Select
                                            value={this.state.reason_list_selected}
                                            onChange={this.handelchange_reason_list}
                                            options={this.state.reason_lists}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={3} className="form-control form-control-sm" name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}
                                                  placeholder="Remark"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Date</label>
                                        <input type="date" className="form-control form-control-sm" name="dt"
                                               value={this.state.dt} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Time</label>
                                        <input type="time" className="form-control form-control-sm" name="ti"
                                               value={this.state.ti} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }


    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="g_in_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Garage In Request</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default G_in_req;
