import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Order_status extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            wiz: 1,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_order_for_order_status;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    type: "2",
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    change_type = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let filtered=[];
        if(value==="1")
            filtered=this.state.datas_q
        else if(value==="2")
            filtered=this.state.datas_no_truck
        this.setState({
            [name]: value,
            filtered: filtered,
            txtsearch:'',
        })
    }

    wiz_1 = () => {
        let count=0;


        let thead=null;
        let tdata=null;
        if(this.state.type==="1"){
            tdata = this.state.datas.map(value => {
                count=count+1;
                let url_detail=myconfig.redirecttoorder_detail+"?order_no="+value.order_no
                let total_loaded=(value.assign_own*1)+(value.assign_sub_contractor*1)
                let left_over_order_q=(value.total_weight*1)-(total_loaded*1)
                return (<tr>
                    <td>{count}</td>
                    <td>
                        <a href={url_detail} target="_blank">Detail</a>
                    </td>
                    <td>{value.order_no}</td>
                    <td>{value.label}</td>
                    <td>{value.org}</td>
                    <td>{value.des}</td>
                    <td>{value.total_weight}</td>
                    <td>{total_loaded}</td>
                    <td>{left_over_order_q}</td>
                    <td>{value.assign_own}</td>
                    <td>{value.assign_sub_contractor}</td>
                </tr>)
            })
            thead= <tr>
                <th>#</th>
                <th>Action</th>
                <th>Order #</th>
                <th>Client</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Weight (q)</th>
                <th>Total Loaded (q)</th>
                <th>Left Over (q)</th>
                <th>Loaded by Own Truck (q)</th>
                <th>Loaded by Outsource (q)</th>
            </tr>
        }
        else if(this.state.type==="2")
        {
            tdata = this.state.datas.map(value => {
                count=count+1;
                let url_detail=myconfig.redirecttoorder_detail+"?order_no="+value.order_no
                let total_loaded=(value.assign_owns_truck*1)+(value.assign_sub_contractor_truck*1)
                let left_over_order_q=(value.total_weight_truck*1)-(total_loaded*1)
                return (<tr>
                    <td>{count}</td>
                    <td>
                        <a href={url_detail} target="_blank">Detail</a>
                    </td>
                    <td>{value.order_no}</td>
                    <td>{value.label}</td>
                    <td>{value.org}</td>
                    <td>{value.des}</td>
                    <td>{value.total_weight_truck}</td>
                    <td>{total_loaded}</td>
                    <td>{left_over_order_q}</td>
                    <td>{value.assign_owns_truck}</td>
                    <td>{value.assign_sub_contractor_truck}</td>
                </tr>)
            })
            thead= <tr>
                <th>#</th>
                <th>Action</th>
                <th>Order #</th>
                <th>Client</th>
                <th>Origin</th>
                <th>Destination</th>
                <th># of Trucks</th>
                <th>Total Loaded (Truck)</th>
                <th>Left Over (Truck)</th>
                <th>Loaded by Own Truck (Truck)</th>
                <th>Loaded by Outsource (Truck)</th>
            </tr>
        }
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-responsive table-head-fixed">
                        <thead>
                        {thead}
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="order_status"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Order Status</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <select name="type"
                                                            className="form-control float-right"
                                                            value={this.state.type} onChange={this.change_type}>
                                                        <option value="-1">-- Select Type--</option>
                                                        <option value="1">By Weight</option>
                                                        <option value="2">By # of Truck</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_status;
