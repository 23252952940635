import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            follow_ups:[],

            txtsearch: '',

            wiz: 1,
            id: -1,
            coc: '',
            eid: null,
            sums: [],
            labels: [],
            isload:false,

            allocation: '',
            arivalatloading: '',
            loading: '',
            departure: '',
            arivalatunloading: '',
            unloading: '',
            finished: '',


            type: '',
            time: '',
            time_con: '',
            short: '',
            long: '',
            millage: '',
            msg:null,

            client:'',
            loading_at:'',
            unloading_at:'',
            starting_at:'',
            ending_at:'',
            plate_no:'',
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_follow_up;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            eid:this.state.eid,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    eid: res.data.eid,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.vehicle.toUpperCase().includes(value.toUpperCase())||
                    v.driver.toUpperCase().includes(value.toUpperCase())||
                    v.route.toUpperCase().includes(value.toUpperCase())||
                    v.status.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btn_manage = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_date_follow_up;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
            eid: this.state.eid
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    id: id,
                    isload: true,
                    type: '',
                    time: '',
                    time_con: '',
                    short: '',
                    long: '',
                    millage: '',
                    msg: null,
                    wiz: 2,
                    eid: res.data.eid,
                    dt: res.data.dt,
                    sums: res.data.sums,
                    labels: res.data.labels,
                    allocation: res.data.date_obj.allocation??'',
                    arivalatloading: res.data.date_obj.arivalatloading??'',
                    loading: res.data.date_obj.loading??'',
                    departure: res.data.date_obj.departure??'',
                    arivalatunloading: res.data.date_obj.arivalatunloading??'',
                    unloading: res.data.date_obj.unloading??'',
                    finished: res.data.date_obj.finished??'',
                    loading_at: res.data.loading_at,
                    unloading_at: res.data.unloading_at,
                    starting_at: res.data.starting_at,
                    ending_at: res.data.ending_at,
                    plate_no: res.data.plate_no,
                    client: res.data.client,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_history = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_follow_up_hisotry;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    follow_ups:res.data.datas,
                    wiz:3,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    btncreate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.assign_date;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            allocation:this.state.allocation,
            arivalatloading:this.state.arivalatloading,
            loading:this.state.loading,
            departure:this.state.departure,
            arivalatunloading:this.state.arivalatunloading,
            unloading:this.state.unloading,
            finished:this.state.finished,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    let datas=this.state.datas;
                    datas=this.state.datas.filter(v=>{
                        if(v.id===this.state.id) {
                            if (res.data.status !== "WAITING_FOR_ORDER") {
                                v.status = res.data.status
                                return v;
                            }
                        }
                        else
                        {
                            return v;
                        }
                    })
                    this.setState({
                        wiz: 1,
                        datas:datas,
                        filtered:datas,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_get_report = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_mov_for_follow_up;
        this.setState({
            isload:false,
            allocation: '',
            arivalatloading: '',
            loading: '',
            departure: '',
            arivalatunloading: '',
            unloading: '',
            finished: '',
            type: '',
            time: '',
            time_con: '',
            short: '',
            long: '',
            millage: '',
            msg: null,
            sums:[],
            labels:[],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:this.state.id,
            dt:this.state.dt,
            eid:this.state.eid,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    eid: res.data.eid,
                    sums: res.data.sums,
                    labels: res.data.labels,
                    isload:true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_select_data = (event,type,time,time_con,short,long,millage) => {
        this.setState({
            [type]: time,
            time: time,
            time_con: time_con,
            short: short,
            long: long,
            millage: millage,
        })
    }

    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td style={{verticalAlign:"middle"}}>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_manage(event,value.id)}>Manage</a> <hr/>
                    <a href="" onClick={event => this.btn_history(event,value.id)}>History</a>
                </td>
                <td style={{verticalAlign:"middle"}}>{value.driver}</td>
                <td style={{verticalAlign:"middle"}}>{value.vehicle}</td>
                <td style={{verticalAlign:"middle"}}>{value.route}</td>
                <td style={{verticalAlign:"middle"}}>{value.status}</td>
                <td style={{verticalAlign:"middle"}}><p title={value.long_name}>{value.short_name}</p></td>
                <td style={{verticalAlign:"middle"}}>{value.avl_date}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed text-wrap">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Driver</th>
                            <th className="col-md-2">Vehicle</th>
                            <th className="col-md-1">Route</th>
                            <th className="col-md-2">Status</th>
                            <th className="col-md-2">Current loc</th>
                            <th className="col-md-2">Update Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }

    wiz_2 = () => {
        let datas=[];
        let msg=null;
        if(this.state.isload){
            if(this.state.allocation===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="allocation")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                this.state.sums.map(v=>{
                    datas.push(
                        {
                            type:'allocation',
                            time: v.st_time,
                            time_con: v.st_time_con,
                            short: v.st_short,
                            long: v.st_long,
                            millage: v.millage,
                        }
                    )
                })
            }
            else if(this.state.arivalatloading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="arivalatloading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                if(this.state.time_con!==''){
                    datas.push(
                        {
                            type:'arivalatloading',
                            time: this.state.time,
                            time_con: this.state.time_con,
                            short: this.state.short,
                            long: this.state.long,
                            millage: this.state.millage,
                        }
                    )
                }

                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'arivalatloading',
                                time: v.ed_time,
                                time_con: v.ed_time_con,
                                short: v.ed_short,
                                long: v.ed_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.loading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="loading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                if(this.state.time_con!=='') {
                    datas.push(
                        {
                            type: 'loading',
                            time: this.state.time,
                            time_con: this.state.time_con,
                            short: this.state.short,
                            long: this.state.long,
                            millage: this.state.millage,
                        }
                    )
                }
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'loading',
                                time: v.st_time,
                                time_con: v.st_time_con,
                                short: v.st_short,
                                long: v.st_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.departure===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="departure")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                if(this.state.time_con!=='') {
                    datas.push(
                        {
                            type: 'departure',
                            time: this.state.time,
                            time_con: this.state.time_con,
                            short: this.state.short,
                            long: this.state.long,
                            millage: this.state.millage,
                        }
                    )
                }
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'departure',
                                time: v.st_time,
                                time_con: v.st_time_con,
                                short: v.st_short,
                                long: v.st_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.arivalatunloading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="arivalatunloading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                if(this.state.time_con!=='') {
                    datas.push(
                        {
                            type: 'arivalatunloading',
                            time: this.state.time,
                            time_con: this.state.time_con,
                            short: this.state.short,
                            long: this.state.long,
                            millage: this.state.millage,
                        }
                    )
                }
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'arivalatunloading',
                                time: v.ed_time,
                                time_con: v.ed_time_con,
                                short: v.ed_short,
                                long: v.ed_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.unloading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="unloading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                if(this.state.time_con!=='') {
                    datas.push(
                        {
                            type: 'unloading',
                            time: this.state.time,
                            time_con: this.state.time_con,
                            short: this.state.short,
                            long: this.state.long,
                            millage: this.state.millage,
                        }
                    )
                }
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'unloading',
                                time: v.st_time,
                                time_con: v.st_time_con,
                                short: v.st_short,
                                long: v.st_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.finished===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="finished")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                if(this.state.time_con!=='') {
                    datas.push(
                        {
                            type: 'finished',
                            time: this.state.time,
                            time_con: this.state.time_con,
                            short: this.state.short,
                            long: this.state.long,
                            millage: this.state.millage,
                        }
                    )
                }
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'finished',
                                time: v.ed_time,
                                time_con: v.ed_time_con,
                                short: v.ed_short,
                                long: v.ed_long,
                                millage: v.millage,
                            }
                        )
                })
            }
        }
        let tdata=datas.map(v=>{
            return(<tr>
                <td><a href="" onClick={event => {
                    event.preventDefault()
                    this.btn_select_data(event,v.type,v.time,v.time_con,v.short,v.long,v.millage)
                }}>Select</a></td>
                <td>{v.time}</td>
                <td><p title={v.long}>{v.short}</p></td>
                <td>{v.millage}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">{this.state.plate_no}</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Report Date</label>
                                            <input type="date" className="form-control form-control-sm" name="dt"
                                                   value={this.state.dt} onChange={this.inputmgt}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_get_report}> Get Report </button>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-outline-danger btn-sm"
                                                    onClick={event => {
                                                        event.preventDefault()
                                                        this.setState({
                                                            wiz: 1,
                                                        })
                                                    }}> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header bg-warning ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Routing</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                {msg}
                                <div className="row">
                                    <table id="report" className="table table-head-fixed table-wrap">
                                        <thead>
                                        <tr>
                                            <th className="col-md-4">Client</th>
                                            <th className="col-md-2">Start at</th>
                                            <th className="col-md-2">Loading at</th>
                                            <th className="col-md-2">Unloading at</th>
                                            <th className="col-md-2">Finished at</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><strong>{this.state.client}</strong></td>
                                            <td><strong>{this.state.loading_at}</strong></td>
                                            <td><strong>{this.state.unloading_at}</strong></td>
                                            <td><strong>{this.state.starting_at}</strong></td>
                                            <td><strong>{this.state.ending_at}</strong></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-black ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Date Management</h3>
                                <div className="card-tools">
                                    <a href="" onClick={event => {
                                        event.preventDefault();
                                        this.setState({
                                            allocation: '',
                                            arivalatloading: '',
                                            loading: '',
                                            departure: '',
                                            arivalatunloading: '',
                                            unloading: '',
                                            finished: '',
                                            type: '',
                                            time: '',
                                            time_con: '',
                                            short: '',
                                            long: '',
                                            millage: '',
                                            msg:null,
                                        })
                                    }}>Reset</a>
                                </div>

                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Allocation</label>
                                                <input disabled={true} type="text" className="form-control" name="allocation"
                                                       value={this.state.allocation} placeholder="Allocation"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Arival @ loading</label>
                                                <input disabled={true} type="text" className="form-control" name="arivalatloading"
                                                       value={this.state.arivalatloading} placeholder="Arival @ loading"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Loading</label>
                                                <input disabled={true} type="text" className="form-control" name="arivalatloading"
                                                       value={this.state.loading} placeholder="Loading"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Departure</label>
                                                <input disabled={true} type="text" className="form-control" name="departure"
                                                       value={this.state.departure} placeholder="Departure"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Arrival @ Unloading</label>
                                                <input disabled={true} type="text" className="form-control" name="arivalatunloading"
                                                       value={this.state.arivalatunloading} placeholder="Arrival @ Unloading"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Unloading</label>
                                                <input disabled={true} type="text" className="form-control" name="unloading"
                                                       value={this.state.unloading} placeholder="Unloading"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Back to Station</label>
                                                <input disabled={true} type="text" className="form-control" name="finished"
                                                       value={this.state.finished} placeholder="Back to Station"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Update Date </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-fuchsia ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Reports</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                {msg}
                                <div className="row">
                                    <table id="report" className="table table-head-fixed table-wrap">
                                        <thead>
                                        <tr>
                                            <th className="col-md-2">Action</th>
                                            <th className="col-md-4">Time</th>
                                            <th className="col-md-4">Location</th>
                                            <th className="col-md-2">Millage</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tdata}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>)
    }
    wiz_3 = () => {
        let count=0;
        let tdata = this.state.follow_ups.map(v => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{v.driver}</td>
                <td>{v.vehicle}</td>
                <td>{v.route}</td>
                <td>{v.clientname}</td>
                <td>{v.allocation}</td>
                <td>{v.arivalatloading}</td>
                <td>{v.loading}</td>
                <td>{v.departure}</td>
                <td>{v.arivalatunloading}</td>
                <td>{v.unloading}</td>
                <td>{v.finished}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed table-responsive">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Driver</th>
                            <th>Vehicle</th>
                            <th>Route</th>
                            <th>Company</th>
                            <th>Allocation</th>
                            <th>Arrive @ load</th>
                            <th>Loading</th>
                            <th>Departure</th>
                            <th>Arrive @ offload</th>
                            <th>Unloading</th>
                            <th>Finished</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_2_2 = () => {
        let datas=[];
        let msg=null;
        if(this.state.isload){
            if(this.state.allocation===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="allocation")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                this.state.sums.map(v=>{
                    datas.push(
                        {
                            type:'allocation',
                            time: v.st_time,
                            time_con: v.st_time_con,
                            short: v.st_short,
                            long: v.st_long,
                            millage: v.millage,
                        }
                    )
                })
            }
            else if(this.state.arivalatloading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="arivalatloading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                datas.push(
                    {
                        type:'arivalatloading',
                        time: this.state.time,
                        time_con: this.state.time_con,
                        short: this.state.short,
                        long: this.state.long,
                        millage: this.state.millage,
                    }
                )
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'arivalatloading',
                                time: v.ed_time,
                                time_con: v.ed_time_con,
                                short: v.ed_short,
                                long: v.ed_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.loading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="loading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                datas.push(
                    {
                        type:'loading',
                        time: this.state.time,
                        time_con: this.state.time_con,
                        short: this.state.short,
                        long: this.state.long,
                        millage: this.state.millage,
                    }
                )
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'loading',
                                time: v.st_time,
                                time_con: v.st_time_con,
                                short: v.st_short,
                                long: v.st_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.departure===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="departure")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                datas.push(
                    {
                        type:'departure',
                        time: this.state.time,
                        time_con: this.state.time_con,
                        short: this.state.short,
                        long: this.state.long,
                        millage: this.state.millage,
                    }
                )
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'departure',
                                time: v.st_time,
                                time_con: v.st_time_con,
                                short: v.st_short,
                                long: v.st_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.arivalatunloading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="arivalatunloading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                datas.push(
                    {
                        type:'arivalatunloading',
                        time: this.state.time,
                        time_con: this.state.time_con,
                        short: this.state.short,
                        long: this.state.long,
                        millage: this.state.millage,
                    }
                )
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'arivalatunloading',
                                time: v.ed_time,
                                time_con: v.ed_time_con,
                                short: v.ed_short,
                                long: v.ed_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.unloading===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="unloading")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                datas.push(
                    {
                        type:'unloading',
                        time: this.state.time,
                        time_con: this.state.time_con,
                        short: this.state.short,
                        long: this.state.long,
                        millage: this.state.millage,
                    }
                )
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'unloading',
                                time: v.st_time,
                                time_con: v.st_time_con,
                                short: v.st_short,
                                long: v.st_long,
                                millage: v.millage,
                            }
                        )
                })
            }
            else if(this.state.finished===''){
                let temp=this.state.labels.filter(v=>{
                    if(v.type==="finished")
                        return v;
                })
                if(temp.length>0)
                    msg=<h6>{temp[0].text}</h6>
                datas.push(
                    {
                        type:'finished',
                        time: this.state.time,
                        time_con: this.state.time_con,
                        short: this.state.short,
                        long: this.state.long,
                        millage: this.state.millage,
                    }
                )
                this.state.sums.map(v=>{
                    if(v.st_time_con>this.state.time_con)
                        datas.push(
                            {
                                type:'finished',
                                time: v.ed_time,
                                time_con: v.ed_time_con,
                                short: v.ed_short,
                                long: v.ed_long,
                                millage: v.millage,
                            }
                        )
                })
            }
        }
        let tdata=datas.map(v=>{
            return(<tr>
                <td><a href="" onClick={event => {
                    event.preventDefault()
                    this.btn_select_data(event,v.type,v.time,v.time_con,v.short,v.long,v.millage)
                }}>Select</a></td>
                <td>{v.time}</td>
                <td><p title={v.long}>{v.short}</p></td>
                <td>{v.millage}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">3-23232</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Report Date</label>
                                            <input type="date" className="form-control form-control-sm" name="dt"
                                                   value={this.state.dt} onChange={this.inputmgt}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_get_report}> Get Report </button>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-outline-danger btn-sm"
                                                    onClick={event => {
                                                        event.preventDefault()
                                                        this.setState({
                                                            wiz: 1,
                                                        })
                                                    }}> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-black ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Date Management</h3>
                                <div className="card-tools">
                                    <a href="">Reset</a>
                                </div>

                            </div>
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="form-group">
                                                <label>Report Date</label>
                                                <input disabled={true} type="text" className="form-control form-control-sm" name="allocation"
                                                       value={this.state.allocation}/>
                                            </div>

                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="allocation"
                                                       onChange={this.inputmgt} value={this.state.allocation} placeholder="Allocation"
                                                />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="fas fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Arival @ loading</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="arivalatloading"
                                                       onChange={this.inputmgt} value={this.state.arivalatloading} placeholder="Arival @ loading"
                                                />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="fas fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Loading</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="loading"
                                                       onChange={this.inputmgt} value={this.state.loading} placeholder="Loading"
                                                />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="fas fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Departure</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="departure"
                                                       onChange={this.inputmgt} value={this.state.departure} placeholder="Departure"
                                                />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="fas fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Arrival @ Unloading</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="arivalatunloading"
                                                       onChange={this.inputmgt} value={this.state.arivalatunloading} placeholder="Arrival @ Unloading"
                                                />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="fas fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Unloading</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="unloading"
                                                       onChange={this.inputmgt} value={this.state.unloading} placeholder="Unloading"
                                                />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="fas fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Back to Station</label>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" name="finished"
                                                       onChange={this.inputmgt} value={this.state.finished} placeholder="Back to Station"
                                                />
                                                <div className="input-group-append">
                                                <span className="input-group-text"><i
                                                    className="fas fa-trash"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Update Date </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header bg-fuchsia ui-sortable-handle" style={{cursor: 'move'}}>
                                <h3 className="card-title">Reports</h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            <div className="card-body">
                                {msg}
                                <div className="row">
                                    <table id="report" className="table table-head-fixed table-wrap">
                                        <thead>
                                        <tr>
                                            <th className="col-md-2">Action</th>
                                            <th className="col-md-4">Time</th>
                                            <th className="col-md-4">Location</th>
                                            <th className="col-md-2">Millage</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tdata}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="follow_up"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Vehicle Follow_up</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up;
