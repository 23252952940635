import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Document_mgt extends Component {

    constructor() {
        super();
        this.state = {
            drivers: [],
            filtered: [],

            txtsearch: '',

            details:[],
            wiz:1,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let fright_no = new URLSearchParams(this.props.location.search).get("fright_no");
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_driver_for_document_mgt;
        this.setState({
            wiz:1,
            filtered: [],
            drivers:[],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no:fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    drivers: res.data.drivers,
                    filtered: res.data.drivers,
                },()=>{
                    if(res.data.isfound){
                        this.setState({
                            data:res.data.data,
                            driver_name:res.data.data.driver_name,
                            rec_by:res.data.data.rec_by,
                            rec_at:res.data.data.rec_at,
                            volume:res.data.data.volume,
                            unload_volume:res.data.data.unload_volume,
                            assign_own_id:res.data.data.id,
                            wiz:3,
                        })
                    }
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.drivers;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.driver_name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btncreate = (event) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.document_mgt;
        event.preventDefault();
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: this.state.assign_own_id,
            volume: this.state.volume,
            unload_volume: this.state.unload_volume,
            rec_at: this.state.rec_at,
            remark: this.state.remark,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        wiz:1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_manage = (event,id) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_document_mgt;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id:id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    details: res.data.datas,
                    driver_name: res.data.driver_name,
                    rec_doc: res.data.rec_doc,
                    not_rec_doc: res.data.not_rec_doc,
                    wiz:2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_manage_2 = (event,data) => {
        event.preventDefault();
        this.manage_2(data)
    }
    manage_2(data){
        let temp=this.state.details.filter(v=>{
            if(v.assign_own_id===data.assign_own_id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                assign_own_id: data.assign_own_id,
                volume: temp[0].volume,
                unload_volume: temp[0].unload_volume,
                rec_at: temp[0].rec_at,
                remark: temp[0].remark,
                wiz:3,
                data:data,
            })
        }
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btn_manage(event,value.id)}>Manage</a>
                </td>
                <td>{value.driver_name}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-9">Driver Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata = this.state.details.map(value => {
            let isdoc_rec=value.isdoc_rec?"Yes":"No";
            return (<tr>
                <td>
                    <a href="" onClick={event => this.btn_manage_2(event,value)}>Manage</a>
                </td>
                <td>{value.fright_no}</td>
                <td>{value.plate_no}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.allocate_date}</td>
                <td>{value.unloading_date}</td>
                <td>{isdoc_rec}</td>
                <td>{value.rec_at}</td>
                <td>{value.rec_by}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Summery</h3>
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <table id="report" className="table table-head-fixed">
                                <tr>
                                    <th className="col-md-3">Driver Name</th>
                                    <td className="col-md-3">{this.state.driver_name}</td>
                                </tr>
                                <tr>
                                    <th className="col-md-3">Total Return Document</th>
                                    <td className="col-md-3">{this.state.rec_doc}</td>
                                </tr>
                                <tr>
                                    <th className="col-md-3">Total Not-Return Document</th>
                                    <td className="col-md-3">{this.state.not_rec_doc}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-danger float-right" onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 1,
                            },()=>this.fetchdata())
                        }}><i
                            className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Detail</h3>
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <table id="report" className="table table-head-fixed">
                                <thead>
                                <tr>
                                    <th className="col-md-1">Action</th>
                                    <th className="col-md-1">Fright #</th>
                                    <th className="col-md-1">Plate #</th>
                                    <th className="col-md-2">Client</th>
                                    <th className="col-md-1">Org</th>
                                    <th className="col-md-1">Des</th>
                                    <th className="col-md-1">Allocated Date</th>
                                    <th className="col-md-1">Unloading Date</th>
                                    <th className="col-md-1">Is Doc Return</th>
                                    <th className="col-md-1">Doc Return Date</th>
                                    <th className="col-md-1">Doc Receive By</th>

                                </tr>
                                </thead>
                                <tbody>
                                {tdata}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-danger float-right" onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 1,
                            },()=>this.fetchdata())
                        }}><i
                            className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2_2 = () => {
        let tdata=this.state.aops.map(v=>{
            return(<tr>
                <td>{v.amount}</td>
                <td>{v.req_by}</td>
                <td>{v.req_at}</td>
                <td>{v.app_by}</td>
                <td>{v.app_at}</td>
                <td>{v.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="row">
                        <div className="col-md-5">
                            <h3><strong>Payment Summary</strong></h3>
                            <table id="report" className="table table-head-fixed">
                                <tr>
                                    <th className="col-md-6">Driver Name</th>
                                    <td className="col-md-6">{this.state.data.driver}</td>
                                </tr>
                                <tr>
                                    <th className="col-md-6">Plate # </th>
                                    <td className="col-md-6">{this.state.data.plate_no}</td>
                                </tr>
                                <tr>
                                    <th className="col-md-6">Description </th>
                                    <td className="col-md-6">{this.state.data.route}</td>
                                </tr>
                                <tr>
                                    <th className="col-md-6">Total Payment </th>
                                    <td className="col-md-6">{this.state.data.total}</td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>PV #</label>
                                        <input type="text" className="form-control form-control-sm" name="pv_no"
                                               value={this.state.pv_no} onChange={this.inputmgt}
                                               placeholder="PV #"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Cheque #</label>
                                        <input type="text" className="form-control form-control-sm" name="chk_no"
                                               value={this.state.chk_no} onChange={this.inputmgt}
                                               placeholder="Cheque #"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Bank</label>
                                        <input type="text" className="form-control form-control-sm" name="bank"
                                               value={this.state.bank} onChange={this.inputmgt}
                                               placeholder="Bank"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_paid}> Paid </button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event => {
                                                    event.preventDefault()
                                                    this.setState({
                                                        wiz: 1,
                                                    });
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <table id="report" className="table table-head-fixed pt-3">
                                <thead>
                                <tr>
                                    <th className="col-md-2">Amount</th>
                                    <th className="col-md-2">Req By</th>
                                    <th className="col-md-2">Req At</th>
                                    <th className="col-md-2">App By</th>
                                    <th className="col-md-2">App At</th>
                                    <th className="col-md-2">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {tdata}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Document Management</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver Name</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.driver_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Plate #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.plate_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Fright #</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.fright_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Org</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.org}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Des</label>
                                        <input disabled={true} type="text" className="form-control form-control-sm"
                                               value={this.state.data.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Received Date</label>
                                        <input type="date" className="form-control form-control-sm" name="rec_at"
                                               value={this.state.rec_at} onChange={this.inputmgt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Load Weight</label>
                                        <input type="text" className="form-control form-control-sm" name="volume"
                                               value={this.state.volume} onChange={this.inputmgt}
                                               placeholder="LOAD WEIGHT"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Unload Weight</label>
                                        <input type="text" className="form-control form-control-sm" name="unload_volume"
                                               value={this.state.unload_volume} onChange={this.inputmgt}
                                               placeholder="UNLOAD WEIGHT"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea className="form-control form-control-sm" name="remark"
                                                  value={this.state.remark} onChange={this.inputmgt}
                                                  rows={5}>
                                        </textarea>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Received </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={event =>{
                                                    event.preventDefault();
                                                    this.setState({
                                                        wiz:1,
                                                    })
                                                }}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="document_mgt"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Document Management</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <form onSubmit={event => {
                                                        event.preventDefault();
                                                        if(this.state.fo_no!==""){
                                                            let redirecttodocument_mgt=myconfig.redirecttodocument_mgt+"?fright_no="+this.state.fo_no
                                                            window.open(redirecttodocument_mgt, "_self");
                                                        }

                                                    }}>
                                                        <input type="text" name="fo_no"
                                                               className="form-control float-right"
                                                               value={this.state.fo_no} onChange={this.inputmgt}
                                                               placeholder="Fright #"/>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Document_mgt;
