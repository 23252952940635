import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class R_daily_stoppage extends Component {

    constructor() {
        super();
        this.state = {
            sums: [],
            details: [],
            filtered_details: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',

        }
    }
    componentDidMount() {
        this.fetchdata()
    }
    fetchdata2 = () => {
        let datas={
            "sums": [{
                "vehicle_id": 131,
                "veh_id": "2203",
                "plate_no": "3-68233",
                "con_time_at_loc": 34111,
                "time_at_loc": "09:28:31",
                "dt": "2023-05-29 10:19:04",
                "type": "IN_TRANSIT_TO_LOAD_POINT",
                "org": "TJR",
                "des": "DD",
                "st": "2023-05-30 06:00:00",
                "ed": "2023-05-30 20:00:00"
            },
                {
                    "vehicle_id": 126,
                    "veh_id": "403",
                    "plate_no": "3-89681",
                    "con_time_at_loc": 34107,
                    "time_at_loc": "09:28:27",
                    "dt": "2023-05-28 17:06:31",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 125,
                    "veh_id": "2230",
                    "plate_no": "3-71763",
                    "con_time_at_loc": 34105,
                    "time_at_loc": "09:28:25",
                    "dt": "2023-05-29 12:43:52",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 117,
                    "veh_id": "287",
                    "plate_no": "3-89680",
                    "con_time_at_loc": 34104,
                    "time_at_loc": "09:28:24",
                    "dt": "2023-05-29 04:12:54",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 116,
                    "veh_id": "2268",
                    "plate_no": "3-71728",
                    "con_time_at_loc": 34102,
                    "time_at_loc": "09:28:22",
                    "dt": "2023-05-29 07:34:25",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 114,
                    "veh_id": "2171",
                    "plate_no": "3-71749",
                    "con_time_at_loc": 34101,
                    "time_at_loc": "09:28:21",
                    "dt": "2023-05-29 09:31:53",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 110,
                    "veh_id": "2204",
                    "plate_no": "3-71715",
                    "con_time_at_loc": 34097,
                    "time_at_loc": "09:28:17",
                    "dt": "2023-05-29 14:16:32",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 109,
                    "veh_id": "2215",
                    "plate_no": "3-68240",
                    "con_time_at_loc": 34095,
                    "time_at_loc": "09:28:15",
                    "dt": "2023-05-28 18:24:23",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 108,
                    "veh_id": "3783",
                    "plate_no": "3-68219",
                    "con_time_at_loc": 34094,
                    "time_at_loc": "09:28:14",
                    "dt": "2023-05-29 10:07:55",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 106,
                    "veh_id": "2221",
                    "plate_no": "3-68220",
                    "con_time_at_loc": 34090,
                    "time_at_loc": "09:28:10",
                    "dt": "2023-05-29 05:20:00",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 101,
                    "veh_id": "2160",
                    "plate_no": "3-71711",
                    "con_time_at_loc": 34080,
                    "time_at_loc": "09:28:00",
                    "dt": "2023-05-29 14:13:22",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 100,
                    "veh_id": "2147",
                    "plate_no": "3-71740",
                    "con_time_at_loc": 34078,
                    "time_at_loc": "09:27:58",
                    "dt": "2023-05-29 09:34:37",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 97,
                    "veh_id": "1397",
                    "plate_no": "3-66711",
                    "con_time_at_loc": 34077,
                    "time_at_loc": "09:27:57",
                    "dt": "2023-05-24 19:53:22",
                    "type": "HEADING_TO_STATION",
                    "org": "DD",
                    "des": "AA",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 94,
                    "veh_id": "2212",
                    "plate_no": "3-68245",
                    "con_time_at_loc": 34071,
                    "time_at_loc": "09:27:51",
                    "dt": "2023-05-29 07:36:43",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 92,
                    "veh_id": "2150",
                    "plate_no": "3-71729",
                    "con_time_at_loc": 34070,
                    "time_at_loc": "09:27:50",
                    "dt": "2023-05-28 21:59:43",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 91,
                    "veh_id": "3778",
                    "plate_no": "3-71764",
                    "con_time_at_loc": 34068,
                    "time_at_loc": "09:27:48",
                    "dt": "2023-05-29 04:27:07",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 89,
                    "veh_id": "420",
                    "plate_no": "3-89687",
                    "con_time_at_loc": 34065,
                    "time_at_loc": "09:27:45",
                    "dt": "2023-05-29 04:05:39",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 86,
                    "veh_id": "1850",
                    "plate_no": "3-66654",
                    "con_time_at_loc": 34063,
                    "time_at_loc": "09:27:43",
                    "dt": "2023-05-27 16:21:17",
                    "type": "HEADING_TO_STATION",
                    "org": "DD",
                    "des": "LEMI",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 85,
                    "veh_id": "2227",
                    "plate_no": "3-71717",
                    "con_time_at_loc": 34061,
                    "time_at_loc": "09:27:41",
                    "dt": "2023-05-29 04:18:03",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 83,
                    "veh_id": "416",
                    "plate_no": "3-89692",
                    "con_time_at_loc": 34057,
                    "time_at_loc": "09:27:37",
                    "dt": "2023-05-28 19:29:07",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 79,
                    "veh_id": "2201",
                    "plate_no": "3-71775",
                    "con_time_at_loc": 34055,
                    "time_at_loc": "09:27:35",
                    "dt": "2023-05-28 23:12:13",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 74,
                    "veh_id": "286",
                    "plate_no": "3-89825",
                    "con_time_at_loc": 34050,
                    "time_at_loc": "09:27:30",
                    "dt": "2023-05-28 19:25:17",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 71,
                    "veh_id": "2228",
                    "plate_no": "3-71706",
                    "con_time_at_loc": 34046,
                    "time_at_loc": "09:27:26",
                    "dt": "2023-05-29 13:09:21",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 64,
                    "veh_id": "393",
                    "plate_no": "3-89682",
                    "con_time_at_loc": 34041,
                    "time_at_loc": "09:27:21",
                    "dt": "2023-05-29 15:56:16",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 62,
                    "veh_id": "3780",
                    "plate_no": "3-71727",
                    "con_time_at_loc": 34039,
                    "time_at_loc": "09:27:19",
                    "dt": "2023-05-21 13:30:24",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 61,
                    "veh_id": "2180",
                    "plate_no": "3-71710",
                    "con_time_at_loc": 34038,
                    "time_at_loc": "09:27:18",
                    "dt": "2023-05-29 14:15:26",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 59,
                    "veh_id": "2194",
                    "plate_no": "3-68237",
                    "con_time_at_loc": 34036,
                    "time_at_loc": "09:27:16",
                    "dt": "2023-05-29 05:15:39",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 56,
                    "veh_id": "2242",
                    "plate_no": "3-68249",
                    "con_time_at_loc": 34031,
                    "time_at_loc": "09:27:11",
                    "dt": "2023-05-29 06:13:16",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 55,
                    "veh_id": "2187",
                    "plate_no": "3-71773",
                    "con_time_at_loc": 34029,
                    "time_at_loc": "09:27:09",
                    "dt": "2023-05-29 08:24:51",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 54,
                    "veh_id": "2202",
                    "plate_no": "3-68231",
                    "con_time_at_loc": 34027,
                    "time_at_loc": "09:27:07",
                    "dt": "2023-05-28 16:15:15",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 53,
                    "veh_id": "2238",
                    "plate_no": "3-68244",
                    "con_time_at_loc": 34026,
                    "time_at_loc": "09:27:06",
                    "dt": "2023-05-29 11:15:24",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 51,
                    "veh_id": "1858",
                    "plate_no": "3-66562",
                    "con_time_at_loc": 34024,
                    "time_at_loc": "09:27:04",
                    "dt": "2023-05-10 15:06:09",
                    "type": "HEADING_TO_STATION",
                    "org": "DD",
                    "des": "LEMI",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 50,
                    "veh_id": "2157",
                    "plate_no": "3-71731",
                    "con_time_at_loc": 34023,
                    "time_at_loc": "09:27:03",
                    "dt": "2023-05-29 08:38:01",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 49,
                    "veh_id": "2158",
                    "plate_no": "3-71718",
                    "con_time_at_loc": 34021,
                    "time_at_loc": "09:27:01",
                    "dt": "2023-05-29 17:44:47",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 47,
                    "veh_id": "2210",
                    "plate_no": "3-71742",
                    "con_time_at_loc": 34019,
                    "time_at_loc": "09:26:59",
                    "dt": "2023-05-29 19:20:44",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 46,
                    "veh_id": "2159",
                    "plate_no": "3-71765",
                    "con_time_at_loc": 34018,
                    "time_at_loc": "09:26:58",
                    "dt": "2023-05-28 17:23:30",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 43,
                    "veh_id": "3779",
                    "plate_no": "3-71738",
                    "con_time_at_loc": 34012,
                    "time_at_loc": "09:26:52",
                    "dt": "2023-05-28 21:29:27",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 40,
                    "veh_id": "2161",
                    "plate_no": "3-68223",
                    "con_time_at_loc": 34011,
                    "time_at_loc": "09:26:51",
                    "dt": "2023-05-29 08:26:18",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 39,
                    "veh_id": "2196",
                    "plate_no": "3-71712",
                    "con_time_at_loc": 34009,
                    "time_at_loc": "09:26:49",
                    "dt": "2023-05-29 11:19:53",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 37,
                    "veh_id": "2164",
                    "plate_no": "3-68226",
                    "con_time_at_loc": 34005,
                    "time_at_loc": "09:26:45",
                    "dt": "2023-05-29 13:28:10",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 36,
                    "veh_id": "2243",
                    "plate_no": "3-71734",
                    "con_time_at_loc": 34004,
                    "time_at_loc": "09:26:44",
                    "dt": "2023-05-28 12:23:15",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 35,
                    "veh_id": "2200",
                    "plate_no": "3-71774",
                    "con_time_at_loc": 34002,
                    "time_at_loc": "09:26:42",
                    "dt": "2023-05-29 15:49:27",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 33,
                    "veh_id": "2206",
                    "plate_no": "3-71709",
                    "con_time_at_loc": 34001,
                    "time_at_loc": "09:26:41",
                    "dt": "2023-05-29 16:36:21",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 29,
                    "veh_id": "2240",
                    "plate_no": "3-68217",
                    "con_time_at_loc": 33993,
                    "time_at_loc": "09:26:33",
                    "dt": "2023-05-29 06:10:26",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 24,
                    "veh_id": "2153",
                    "plate_no": "3-71776",
                    "con_time_at_loc": 33989,
                    "time_at_loc": "09:26:29",
                    "dt": "2023-05-29 15:13:49",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 22,
                    "veh_id": "426",
                    "plate_no": "3-88067",
                    "con_time_at_loc": 33988,
                    "time_at_loc": "09:26:28",
                    "dt": "2023-05-29 10:22:27",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 15,
                    "veh_id": "3781",
                    "plate_no": "3-71713",
                    "con_time_at_loc": 33980,
                    "time_at_loc": "09:26:20",
                    "dt": "2023-05-29 04:34:36",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 12,
                    "veh_id": "2195",
                    "plate_no": "3-71743",
                    "con_time_at_loc": 33976,
                    "time_at_loc": "09:26:16",
                    "dt": "2023-05-29 08:42:36",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 10,
                    "veh_id": "2235",
                    "plate_no": "3-71771",
                    "con_time_at_loc": 33975,
                    "time_at_loc": "09:26:15",
                    "dt": "2023-05-29 05:39:03",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 9,
                    "veh_id": "2197",
                    "plate_no": "3-71750",
                    "con_time_at_loc": 33973,
                    "time_at_loc": "09:26:13",
                    "dt": "2023-05-29 13:59:08",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 8,
                    "veh_id": "2175",
                    "plate_no": "3-68225",
                    "con_time_at_loc": 33971,
                    "time_at_loc": "09:26:11",
                    "dt": "2023-05-29 11:40:34",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 3,
                    "veh_id": "400",
                    "plate_no": "3-89695",
                    "con_time_at_loc": 33966,
                    "time_at_loc": "09:26:06",
                    "dt": "2023-05-29 13:53:56",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 44,
                    "veh_id": "428",
                    "plate_no": "3-89685",
                    "con_time_at_loc": 19586,
                    "time_at_loc": "05:26:26",
                    "dt": "2023-05-20 11:39:32",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 27,
                    "veh_id": "2148",
                    "plate_no": "3-71934",
                    "con_time_at_loc": 17343,
                    "time_at_loc": "04:49:03",
                    "dt": "2023-05-29 13:34:19",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 57,
                    "veh_id": "2223",
                    "plate_no": "3-71790",
                    "con_time_at_loc": 16401,
                    "time_at_loc": "04:33:21",
                    "dt": "2023-05-29 17:12:29",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 65,
                    "veh_id": "281",
                    "plate_no": "3-89696",
                    "con_time_at_loc": 12942,
                    "time_at_loc": "03:35:42",
                    "dt": "2023-05-29 12:08:18",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "DUK",
                    "des": "LEMI",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 32,
                    "veh_id": "2241",
                    "plate_no": "3-71735",
                    "con_time_at_loc": 10630,
                    "time_at_loc": "02:57:10",
                    "dt": "2023-05-29 05:42:59",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 7,
                    "veh_id": "2190",
                    "plate_no": "3-71767",
                    "con_time_at_loc": 9053,
                    "time_at_loc": "02:30:53",
                    "dt": "2023-05-29 19:39:36",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 17,
                    "veh_id": "2185",
                    "plate_no": "3-68229",
                    "con_time_at_loc": 8989,
                    "time_at_loc": "02:29:49",
                    "dt": "2023-05-29 19:10:05",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 107,
                    "veh_id": "2216",
                    "plate_no": "3-71770",
                    "con_time_at_loc": 8255,
                    "time_at_loc": "02:17:35",
                    "dt": "2023-05-29 19:53:11",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 78,
                    "veh_id": "2218",
                    "plate_no": "3-71724",
                    "con_time_at_loc": 8095,
                    "time_at_loc": "02:14:55",
                    "dt": "2023-05-29 18:35:24",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 77,
                    "veh_id": "2172",
                    "plate_no": "3-68227",
                    "con_time_at_loc": 8027,
                    "time_at_loc": "02:13:47",
                    "dt": "2023-05-29 11:51:18",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 103,
                    "veh_id": "2226",
                    "plate_no": "3-68234",
                    "con_time_at_loc": 7387,
                    "time_at_loc": "02:03:07",
                    "dt": "2023-05-29 18:25:04",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 38,
                    "veh_id": "275",
                    "plate_no": "3-89698",
                    "con_time_at_loc": 7041,
                    "time_at_loc": "01:57:21",
                    "dt": "2023-05-29 22:44:38",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 84,
                    "veh_id": "2155",
                    "plate_no": "3-71744",
                    "con_time_at_loc": 7006,
                    "time_at_loc": "01:56:46",
                    "dt": "2023-05-30 05:50:21",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 19,
                    "veh_id": "2199",
                    "plate_no": "3-71741",
                    "con_time_at_loc": 6915,
                    "time_at_loc": "01:55:15",
                    "dt": "2023-05-30 05:01:33",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 129,
                    "veh_id": "3356",
                    "plate_no": "3-71720",
                    "con_time_at_loc": 6833,
                    "time_at_loc": "01:53:53",
                    "dt": "2023-05-30 05:20:16",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 90,
                    "veh_id": "2193",
                    "plate_no": "3-68246",
                    "con_time_at_loc": 6653,
                    "time_at_loc": "01:50:53",
                    "dt": "2023-05-29 17:41:50",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 14,
                    "veh_id": "2229",
                    "plate_no": "3-71732",
                    "con_time_at_loc": 6612,
                    "time_at_loc": "01:50:12",
                    "dt": "2023-05-30 04:49:07",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 45,
                    "veh_id": "2170",
                    "plate_no": "3-68243",
                    "con_time_at_loc": 5981,
                    "time_at_loc": "01:39:41",
                    "dt": "2023-05-30 05:03:55",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 5,
                    "veh_id": "2169",
                    "plate_no": "3-68242",
                    "con_time_at_loc": 5603,
                    "time_at_loc": "01:33:23",
                    "dt": "2023-05-30 05:25:53",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 72,
                    "veh_id": "2205",
                    "plate_no": "3-71739",
                    "con_time_at_loc": 5484,
                    "time_at_loc": "01:31:24",
                    "dt": "2023-05-29 18:10:38",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 96,
                    "veh_id": "2167",
                    "plate_no": "3-71748",
                    "con_time_at_loc": 4833,
                    "time_at_loc": "01:20:33",
                    "dt": "2023-05-29 04:21:38",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 58,
                    "veh_id": "2236",
                    "plate_no": "3-68239",
                    "con_time_at_loc": 4655,
                    "time_at_loc": "01:17:35",
                    "dt": "2023-05-29 03:38:13",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "DD",
                    "des": "HAJMA CHACHA",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 30,
                    "veh_id": "2211",
                    "plate_no": "3-68221",
                    "con_time_at_loc": 4645,
                    "time_at_loc": "01:17:25",
                    "dt": "2023-05-29 04:21:07",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 102,
                    "veh_id": "3697",
                    "plate_no": "3-89684",
                    "con_time_at_loc": 4066,
                    "time_at_loc": "01:07:46",
                    "dt": "2023-05-29 14:37:58",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 2,
                    "veh_id": "2177",
                    "plate_no": "3-68247",
                    "con_time_at_loc": 3504,
                    "time_at_loc": "00:58:24",
                    "dt": "2023-05-30 05:54:06",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 104,
                    "veh_id": "2186",
                    "plate_no": "3-68215",
                    "con_time_at_loc": 3332,
                    "time_at_loc": "00:55:32",
                    "dt": "2023-05-30 07:39:52",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 133,
                    "veh_id": "398",
                    "plate_no": "3-66656",
                    "con_time_at_loc": 3100,
                    "time_at_loc": "00:51:40",
                    "dt": "2023-05-29 15:37:13",
                    "type": "HEADING_TO_STATION",
                    "org": "DD",
                    "des": "LEMI",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 95,
                    "veh_id": "2173",
                    "plate_no": "3-68224",
                    "con_time_at_loc": 2672,
                    "time_at_loc": "00:44:32",
                    "dt": "2023-05-29 10:17:32",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "DD",
                    "des": "AA",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 31,
                    "veh_id": "2214",
                    "plate_no": "3-71716",
                    "con_time_at_loc": 2250,
                    "time_at_loc": "00:37:30",
                    "dt": "2023-05-30 09:39:07",
                    "type": "IN_TRANSIT_TO_LOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 09:39:07",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 112,
                    "veh_id": "1840",
                    "plate_no": "3-66706",
                    "con_time_at_loc": 1757,
                    "time_at_loc": "00:29:17",
                    "dt": "2023-05-29 16:34:44",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "DD",
                    "des": "HAWASA",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                },
                {
                    "vehicle_id": 21,
                    "veh_id": "2174",
                    "plate_no": "3-71725",
                    "con_time_at_loc": 1047,
                    "time_at_loc": "00:17:27",
                    "dt": "2023-05-30 05:46:46",
                    "type": "IN_TRANSIT_TO_OFFLOAD_POINT",
                    "org": "TJR",
                    "des": "DD",
                    "st": "2023-05-30 06:00:00",
                    "ed": "2023-05-30 20:00:00"
                }
            ],
            "details": [{
                "vehicle_id": 131,
                "veh_id": "2203",
                "plate_no": "3-68233",
                "location": "No Data",
                "time": "No Data",
                "con_time_at_loc": 34111,
                "time_at_loc": "09:28:31"
            },
                {
                    "vehicle_id": 126,
                    "veh_id": "403",
                    "plate_no": "3-89681",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34107,
                    "time_at_loc": "09:28:27"
                },
                {
                    "vehicle_id": 125,
                    "veh_id": "2230",
                    "plate_no": "3-71763",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34105,
                    "time_at_loc": "09:28:25"
                },
                {
                    "vehicle_id": 117,
                    "veh_id": "287",
                    "plate_no": "3-89680",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34104,
                    "time_at_loc": "09:28:24"
                },
                {
                    "vehicle_id": 116,
                    "veh_id": "2268",
                    "plate_no": "3-71728",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34102,
                    "time_at_loc": "09:28:22"
                },
                {
                    "vehicle_id": 114,
                    "veh_id": "2171",
                    "plate_no": "3-71749",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34101,
                    "time_at_loc": "09:28:21"
                },
                {
                    "vehicle_id": 110,
                    "veh_id": "2204",
                    "plate_no": "3-71715",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34097,
                    "time_at_loc": "09:28:17"
                },
                {
                    "vehicle_id": 109,
                    "veh_id": "2215",
                    "plate_no": "3-68240",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34095,
                    "time_at_loc": "09:28:15"
                },
                {
                    "vehicle_id": 108,
                    "veh_id": "3783",
                    "plate_no": "3-68219",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34094,
                    "time_at_loc": "09:28:14"
                },
                {
                    "vehicle_id": 106,
                    "veh_id": "2221",
                    "plate_no": "3-68220",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34090,
                    "time_at_loc": "09:28:10"
                },
                {
                    "vehicle_id": 101,
                    "veh_id": "2160",
                    "plate_no": "3-71711",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34080,
                    "time_at_loc": "09:28:00"
                },
                {
                    "vehicle_id": 100,
                    "veh_id": "2147",
                    "plate_no": "3-71740",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34078,
                    "time_at_loc": "09:27:58"
                },
                {
                    "vehicle_id": 97,
                    "veh_id": "1397",
                    "plate_no": "3-66711",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34077,
                    "time_at_loc": "09:27:57"
                },
                {
                    "vehicle_id": 94,
                    "veh_id": "2212",
                    "plate_no": "3-68245",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34071,
                    "time_at_loc": "09:27:51"
                },
                {
                    "vehicle_id": 92,
                    "veh_id": "2150",
                    "plate_no": "3-71729",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34070,
                    "time_at_loc": "09:27:50"
                },
                {
                    "vehicle_id": 91,
                    "veh_id": "3778",
                    "plate_no": "3-71764",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34068,
                    "time_at_loc": "09:27:48"
                },
                {
                    "vehicle_id": 89,
                    "veh_id": "420",
                    "plate_no": "3-89687",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34065,
                    "time_at_loc": "09:27:45"
                },
                {
                    "vehicle_id": 86,
                    "veh_id": "1850",
                    "plate_no": "3-66654",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34063,
                    "time_at_loc": "09:27:43"
                },
                {
                    "vehicle_id": 85,
                    "veh_id": "2227",
                    "plate_no": "3-71717",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34061,
                    "time_at_loc": "09:27:41"
                },
                {
                    "vehicle_id": 83,
                    "veh_id": "416",
                    "plate_no": "3-89692",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34057,
                    "time_at_loc": "09:27:37"
                },
                {
                    "vehicle_id": 79,
                    "veh_id": "2201",
                    "plate_no": "3-71775",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34055,
                    "time_at_loc": "09:27:35"
                },
                {
                    "vehicle_id": 74,
                    "veh_id": "286",
                    "plate_no": "3-89825",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34050,
                    "time_at_loc": "09:27:30"
                },
                {
                    "vehicle_id": 71,
                    "veh_id": "2228",
                    "plate_no": "3-71706",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34046,
                    "time_at_loc": "09:27:26"
                },
                {
                    "vehicle_id": 64,
                    "veh_id": "393",
                    "plate_no": "3-89682",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34041,
                    "time_at_loc": "09:27:21"
                },
                {
                    "vehicle_id": 62,
                    "veh_id": "3780",
                    "plate_no": "3-71727",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34039,
                    "time_at_loc": "09:27:19"
                },
                {
                    "vehicle_id": 61,
                    "veh_id": "2180",
                    "plate_no": "3-71710",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34038,
                    "time_at_loc": "09:27:18"
                },
                {
                    "vehicle_id": 59,
                    "veh_id": "2194",
                    "plate_no": "3-68237",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34036,
                    "time_at_loc": "09:27:16"
                },
                {
                    "vehicle_id": 56,
                    "veh_id": "2242",
                    "plate_no": "3-68249",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34031,
                    "time_at_loc": "09:27:11"
                },
                {
                    "vehicle_id": 55,
                    "veh_id": "2187",
                    "plate_no": "3-71773",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34029,
                    "time_at_loc": "09:27:09"
                },
                {
                    "vehicle_id": 54,
                    "veh_id": "2202",
                    "plate_no": "3-68231",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34027,
                    "time_at_loc": "09:27:07"
                },
                {
                    "vehicle_id": 53,
                    "veh_id": "2238",
                    "plate_no": "3-68244",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34026,
                    "time_at_loc": "09:27:06"
                },
                {
                    "vehicle_id": 51,
                    "veh_id": "1858",
                    "plate_no": "3-66562",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34024,
                    "time_at_loc": "09:27:04"
                },
                {
                    "vehicle_id": 50,
                    "veh_id": "2157",
                    "plate_no": "3-71731",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34023,
                    "time_at_loc": "09:27:03"
                },
                {
                    "vehicle_id": 49,
                    "veh_id": "2158",
                    "plate_no": "3-71718",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34021,
                    "time_at_loc": "09:27:01"
                },
                {
                    "vehicle_id": 47,
                    "veh_id": "2210",
                    "plate_no": "3-71742",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34019,
                    "time_at_loc": "09:26:59"
                },
                {
                    "vehicle_id": 46,
                    "veh_id": "2159",
                    "plate_no": "3-71765",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34018,
                    "time_at_loc": "09:26:58"
                },
                {
                    "vehicle_id": 43,
                    "veh_id": "3779",
                    "plate_no": "3-71738",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34012,
                    "time_at_loc": "09:26:52"
                },
                {
                    "vehicle_id": 40,
                    "veh_id": "2161",
                    "plate_no": "3-68223",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34011,
                    "time_at_loc": "09:26:51"
                },
                {
                    "vehicle_id": 39,
                    "veh_id": "2196",
                    "plate_no": "3-71712",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34009,
                    "time_at_loc": "09:26:49"
                },
                {
                    "vehicle_id": 37,
                    "veh_id": "2164",
                    "plate_no": "3-68226",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34005,
                    "time_at_loc": "09:26:45"
                },
                {
                    "vehicle_id": 36,
                    "veh_id": "2243",
                    "plate_no": "3-71734",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34004,
                    "time_at_loc": "09:26:44"
                },
                {
                    "vehicle_id": 35,
                    "veh_id": "2200",
                    "plate_no": "3-71774",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34002,
                    "time_at_loc": "09:26:42"
                },
                {
                    "vehicle_id": 33,
                    "veh_id": "2206",
                    "plate_no": "3-71709",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 34001,
                    "time_at_loc": "09:26:41"
                },
                {
                    "vehicle_id": 29,
                    "veh_id": "2240",
                    "plate_no": "3-68217",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33993,
                    "time_at_loc": "09:26:33"
                },
                {
                    "vehicle_id": 24,
                    "veh_id": "2153",
                    "plate_no": "3-71776",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33989,
                    "time_at_loc": "09:26:29"
                },
                {
                    "vehicle_id": 22,
                    "veh_id": "426",
                    "plate_no": "3-88067",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33988,
                    "time_at_loc": "09:26:28"
                },
                {
                    "vehicle_id": 15,
                    "veh_id": "3781",
                    "plate_no": "3-71713",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33980,
                    "time_at_loc": "09:26:20"
                },
                {
                    "vehicle_id": 12,
                    "veh_id": "2195",
                    "plate_no": "3-71743",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33976,
                    "time_at_loc": "09:26:16"
                },
                {
                    "vehicle_id": 10,
                    "veh_id": "2235",
                    "plate_no": "3-71771",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33975,
                    "time_at_loc": "09:26:15"
                },
                {
                    "vehicle_id": 9,
                    "veh_id": "2197",
                    "plate_no": "3-71750",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33973,
                    "time_at_loc": "09:26:13"
                },
                {
                    "vehicle_id": 8,
                    "veh_id": "2175",
                    "plate_no": "3-68225",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33971,
                    "time_at_loc": "09:26:11"
                },
                {
                    "vehicle_id": 3,
                    "veh_id": "400",
                    "plate_no": "3-89695",
                    "location": "No Data",
                    "time": "No Data",
                    "con_time_at_loc": 33966,
                    "time_at_loc": "09:26:06"
                },
                {
                    "vehicle_id": 44,
                    "veh_id": "428",
                    "plate_no": "3-89685",
                    "location": "NCSC DIRA DAWA FACTORY",
                    "time": "2023-05-30 10:26:18",
                    "con_time_at_loc": 15978,
                    "time_at_loc": "04:26:18"
                },
                {
                    "vehicle_id": 44,
                    "veh_id": "428",
                    "plate_no": "3-89685",
                    "location": "NCSC DIRA DAWA FACTORY",
                    "time": "2023-05-30 10:47:38",
                    "con_time_at_loc": 702,
                    "time_at_loc": "0:11:42"
                },
                {
                    "vehicle_id": 44,
                    "veh_id": "428",
                    "plate_no": "3-89685",
                    "location": "NCSC DIRA DAWA FACTORY",
                    "time": "2023-05-30 11:04:11",
                    "con_time_at_loc": 664,
                    "time_at_loc": "0:11:04"
                },
                {
                    "vehicle_id": 44,
                    "veh_id": "428",
                    "plate_no": "3-89685",
                    "location": "NCSC DIRA DAWA FACTORY",
                    "time": "2023-05-30 11:18:44",
                    "con_time_at_loc": 2242,
                    "time_at_loc": "0:37:22"
                },
                {
                    "vehicle_id": 27,
                    "veh_id": "2148",
                    "plate_no": "3-71934",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 07:11:44",
                    "con_time_at_loc": 4304,
                    "time_at_loc": "01:11:44"
                },
                {
                    "vehicle_id": 27,
                    "veh_id": "2148",
                    "plate_no": "3-71934",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 07:21:03",
                    "con_time_at_loc": 1925,
                    "time_at_loc": "0:32:05"
                },
                {
                    "vehicle_id": 27,
                    "veh_id": "2148",
                    "plate_no": "3-71934",
                    "location": "Dewele, Somali Region, Ethiopia",
                    "time": "2023-05-30 08:56:11",
                    "con_time_at_loc": 4710,
                    "time_at_loc": "1:18:30"
                },
                {
                    "vehicle_id": 27,
                    "veh_id": "2148",
                    "plate_no": "3-71934",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 3.59 km from Dewele",
                    "time": "2023-05-30 10:25:19",
                    "con_time_at_loc": 6404,
                    "time_at_loc": "1:46:44"
                },
                {
                    "vehicle_id": 57,
                    "veh_id": "2223",
                    "plate_no": "3-71790",
                    "location": "Dewele, Somali Region, Ethiopia",
                    "time": "2023-05-30 06:31:47",
                    "con_time_at_loc": 13678,
                    "time_at_loc": "3:47:58"
                },
                {
                    "vehicle_id": 57,
                    "veh_id": "2223",
                    "plate_no": "3-71790",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Ali Sabieh, Djibouti, Guelile غليل",
                    "time": "2023-05-30 10:35:44",
                    "con_time_at_loc": 2723,
                    "time_at_loc": "0:45:23"
                },
                {
                    "vehicle_id": 65,
                    "veh_id": "281",
                    "plate_no": "3-89696",
                    "location": "Addis Ababa - Bahir Dar Road, Oromia Region, Ethiopia, 17.07 km from Addis Ababa",
                    "time": "2023-05-30 06:26:18",
                    "con_time_at_loc": 1578,
                    "time_at_loc": "00:26:18"
                },
                {
                    "vehicle_id": 65,
                    "veh_id": "281",
                    "plate_no": "3-89696",
                    "location": "Addis Ababa - Bahir Dar Road, Oromia Region, Ethiopia, ሙከጡሪ",
                    "time": "2023-05-30 08:44:35",
                    "con_time_at_loc": 2466,
                    "time_at_loc": "0:41:06"
                },
                {
                    "vehicle_id": 65,
                    "veh_id": "281",
                    "plate_no": "3-89696",
                    "location": "Addis Ababa - Bahir Dar Road, Oromia Region, Ethiopia, ሙከጡሪ",
                    "time": "2023-05-30 09:37:22",
                    "con_time_at_loc": 8898,
                    "time_at_loc": "2:28:18"
                },
                {
                    "vehicle_id": 32,
                    "veh_id": "2241",
                    "plate_no": "3-71735",
                    "location": "Route Nationale الطريق الوطني رقم 9, Arta, Djibouti, 5.14 km from شركة الملح للاستثمار",
                    "time": "2023-05-30 06:49:53",
                    "con_time_at_loc": 2993,
                    "time_at_loc": "00:49:53"
                },
                {
                    "vehicle_id": 32,
                    "veh_id": "2241",
                    "plate_no": "3-71735",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Ali Sabieh, Djibouti, 1.66 km from Ali Sabieh علي صبيح",
                    "time": "2023-05-30 09:13:39",
                    "con_time_at_loc": 1009,
                    "time_at_loc": "0:16:49"
                },
                {
                    "vehicle_id": 32,
                    "veh_id": "2241",
                    "plate_no": "3-71735",
                    "location": "أطلال الجسر القديم, Somali Region, Ethiopia, Dewele",
                    "time": "2023-05-30 10:07:29",
                    "con_time_at_loc": 6628,
                    "time_at_loc": "1:50:28"
                },
                {
                    "vehicle_id": 7,
                    "veh_id": "2190",
                    "plate_no": "3-71767",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 06:36:52",
                    "con_time_at_loc": 1960,
                    "time_at_loc": "0:32:40"
                },
                {
                    "vehicle_id": 7,
                    "veh_id": "2190",
                    "plate_no": "3-71767",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 07:56:44",
                    "con_time_at_loc": 6352,
                    "time_at_loc": "1:45:52"
                },
                {
                    "vehicle_id": 7,
                    "veh_id": "2190",
                    "plate_no": "3-71767",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 10:27:22",
                    "con_time_at_loc": 741,
                    "time_at_loc": "0:12:21"
                },
                {
                    "vehicle_id": 17,
                    "veh_id": "2185",
                    "plate_no": "3-68229",
                    "location": "Route Nationale الطريق الوطني رقم 9, Arta, Djibouti, 11.73 km from شركة الملح للاستثمار",
                    "time": "2023-05-30 06:15:09",
                    "con_time_at_loc": 909,
                    "time_at_loc": "00:15:09"
                },
                {
                    "vehicle_id": 17,
                    "veh_id": "2185",
                    "plate_no": "3-68229",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 09:52:40",
                    "con_time_at_loc": 8080,
                    "time_at_loc": "2:14:40"
                },
                {
                    "vehicle_id": 107,
                    "veh_id": "2216",
                    "plate_no": "3-71770",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 08:10:50",
                    "con_time_at_loc": 3738,
                    "time_at_loc": "1:02:18"
                },
                {
                    "vehicle_id": 107,
                    "veh_id": "2216",
                    "plate_no": "3-71770",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 5.60 km from Dewele",
                    "time": "2023-05-30 10:14:01",
                    "con_time_at_loc": 1637,
                    "time_at_loc": "0:27:17"
                },
                {
                    "vehicle_id": 107,
                    "veh_id": "2216",
                    "plate_no": "3-71770",
                    "location": "Ali Sabih, Djibouti",
                    "time": "2023-05-30 11:04:45",
                    "con_time_at_loc": 2880,
                    "time_at_loc": "0:48:00"
                },
                {
                    "vehicle_id": 78,
                    "veh_id": "2218",
                    "plate_no": "3-71724",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 06:16:20",
                    "con_time_at_loc": 980,
                    "time_at_loc": "00:16:20"
                },
                {
                    "vehicle_id": 78,
                    "veh_id": "2218",
                    "plate_no": "3-71724",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 07:57:31",
                    "con_time_at_loc": 6366,
                    "time_at_loc": "1:46:06"
                },
                {
                    "vehicle_id": 78,
                    "veh_id": "2218",
                    "plate_no": "3-71724",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 10:26:32",
                    "con_time_at_loc": 749,
                    "time_at_loc": "0:12:29"
                },
                {
                    "vehicle_id": 77,
                    "veh_id": "2172",
                    "plate_no": "3-68227",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 07:57:14",
                    "con_time_at_loc": 7034,
                    "time_at_loc": "01:57:14"
                },
                {
                    "vehicle_id": 77,
                    "veh_id": "2172",
                    "plate_no": "3-68227",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 11:34:19",
                    "con_time_at_loc": 993,
                    "time_at_loc": "0:16:33"
                },
                {
                    "vehicle_id": 103,
                    "veh_id": "2226",
                    "plate_no": "3-68234",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 06:18:40",
                    "con_time_at_loc": 1120,
                    "time_at_loc": "00:18:40"
                },
                {
                    "vehicle_id": 103,
                    "veh_id": "2226",
                    "plate_no": "3-68234",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 07:56:00",
                    "con_time_at_loc": 6267,
                    "time_at_loc": "1:44:27"
                },
                {
                    "vehicle_id": 38,
                    "veh_id": "275",
                    "plate_no": "3-89698",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 07:57:21",
                    "con_time_at_loc": 7041,
                    "time_at_loc": "01:57:21"
                },
                {
                    "vehicle_id": 84,
                    "veh_id": "2155",
                    "plate_no": "3-71744",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Ali Sabieh, Djibouti, Guelile غليل",
                    "time": "2023-05-30 08:48:23",
                    "con_time_at_loc": 1473,
                    "time_at_loc": "0:24:33"
                },
                {
                    "vehicle_id": 84,
                    "veh_id": "2155",
                    "plate_no": "3-71744",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 10:32:25",
                    "con_time_at_loc": 4166,
                    "time_at_loc": "1:09:26"
                },
                {
                    "vehicle_id": 84,
                    "veh_id": "2155",
                    "plate_no": "3-71744",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 11:45:35",
                    "con_time_at_loc": 1367,
                    "time_at_loc": "0:22:47"
                },
                {
                    "vehicle_id": 19,
                    "veh_id": "2199",
                    "plate_no": "3-71741",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Ali Sabieh, Djibouti, Guelile غليل",
                    "time": "2023-05-30 07:24:53",
                    "con_time_at_loc": 941,
                    "time_at_loc": "0:15:41"
                },
                {
                    "vehicle_id": 19,
                    "veh_id": "2199",
                    "plate_no": "3-71741",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 6.10 km from Dewele",
                    "time": "2023-05-30 08:19:05",
                    "con_time_at_loc": 642,
                    "time_at_loc": "0:10:42"
                },
                {
                    "vehicle_id": 19,
                    "veh_id": "2199",
                    "plate_no": "3-71741",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 10:37:11",
                    "con_time_at_loc": 5332,
                    "time_at_loc": "1:28:52"
                },
                {
                    "vehicle_id": 129,
                    "veh_id": "3356",
                    "plate_no": "3-71720",
                    "location": "Ali Sabih, Djibouti",
                    "time": "2023-05-30 09:21:15",
                    "con_time_at_loc": 941,
                    "time_at_loc": "0:15:41"
                },
                {
                    "vehicle_id": 129,
                    "veh_id": "3356",
                    "plate_no": "3-71720",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 10:32:57",
                    "con_time_at_loc": 5892,
                    "time_at_loc": "1:38:12"
                },
                {
                    "vehicle_id": 90,
                    "veh_id": "2193",
                    "plate_no": "3-68246",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 4.90 km from Dewele",
                    "time": "2023-05-30 06:25:41",
                    "con_time_at_loc": 1541,
                    "time_at_loc": "00:25:41"
                },
                {
                    "vehicle_id": 90,
                    "veh_id": "2193",
                    "plate_no": "3-68246",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 4.92 km from Dewele",
                    "time": "2023-05-30 06:27:44",
                    "con_time_at_loc": 2280,
                    "time_at_loc": "0:38:00"
                },
                {
                    "vehicle_id": 90,
                    "veh_id": "2193",
                    "plate_no": "3-68246",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 5.01 km from Dewele",
                    "time": "2023-05-30 07:11:08",
                    "con_time_at_loc": 977,
                    "time_at_loc": "0:16:17"
                },
                {
                    "vehicle_id": 90,
                    "veh_id": "2193",
                    "plate_no": "3-68246",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 5.30 km from Dewele",
                    "time": "2023-05-30 07:30:40",
                    "con_time_at_loc": 626,
                    "time_at_loc": "0:10:26"
                },
                {
                    "vehicle_id": 90,
                    "veh_id": "2193",
                    "plate_no": "3-68246",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 5.66 km from Dewele",
                    "time": "2023-05-30 08:11:11",
                    "con_time_at_loc": 1229,
                    "time_at_loc": "0:20:29"
                },
                {
                    "vehicle_id": 14,
                    "veh_id": "2229",
                    "plate_no": "3-71732",
                    "location": "Route Nationale الطريق الوطني رقم 9, Arta, Djibouti, 15.55 km from Arta عرتا",
                    "time": "2023-05-30 06:08:10",
                    "con_time_at_loc": 715,
                    "time_at_loc": "0:11:55"
                },
                {
                    "vehicle_id": 14,
                    "veh_id": "2229",
                    "plate_no": "3-71732",
                    "location": "Ali Sabih, Djibouti",
                    "time": "2023-05-30 08:14:04",
                    "con_time_at_loc": 770,
                    "time_at_loc": "0:12:50"
                },
                {
                    "vehicle_id": 14,
                    "veh_id": "2229",
                    "plate_no": "3-71732",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 09:36:47",
                    "con_time_at_loc": 2660,
                    "time_at_loc": "0:44:20"
                },
                {
                    "vehicle_id": 14,
                    "veh_id": "2229",
                    "plate_no": "3-71732",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 11:36:44",
                    "con_time_at_loc": 2467,
                    "time_at_loc": "0:41:07"
                },
                {
                    "vehicle_id": 45,
                    "veh_id": "2170",
                    "plate_no": "3-68243",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 07:30:42",
                    "con_time_at_loc": 1524,
                    "time_at_loc": "0:25:24"
                },
                {
                    "vehicle_id": 45,
                    "veh_id": "2170",
                    "plate_no": "3-68243",
                    "location": "Dewele, Somali Region, Ethiopia",
                    "time": "2023-05-30 10:05:03",
                    "con_time_at_loc": 665,
                    "time_at_loc": "0:11:05"
                },
                {
                    "vehicle_id": 45,
                    "veh_id": "2170",
                    "plate_no": "3-68243",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 5.77 km from Dewele",
                    "time": "2023-05-30 10:41:42",
                    "con_time_at_loc": 899,
                    "time_at_loc": "0:14:59"
                },
                {
                    "vehicle_id": 45,
                    "veh_id": "2170",
                    "plate_no": "3-68243",
                    "location": "Ali Sabih, Djibouti",
                    "time": "2023-05-30 11:15:25",
                    "con_time_at_loc": 2893,
                    "time_at_loc": "0:48:13"
                },
                {
                    "vehicle_id": 5,
                    "veh_id": "2169",
                    "plate_no": "3-68242",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Somali Region, Ethiopia, 5.75 km from Dewele",
                    "time": "2023-05-30 10:22:44",
                    "con_time_at_loc": 5603,
                    "time_at_loc": "1:33:23"
                },
                {
                    "vehicle_id": 72,
                    "veh_id": "2205",
                    "plate_no": "3-71739",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 06:46:36",
                    "con_time_at_loc": 3148,
                    "time_at_loc": "0:52:28"
                },
                {
                    "vehicle_id": 72,
                    "veh_id": "2205",
                    "plate_no": "3-71739",
                    "location": "Djibouti Road, Dire Dawa, Ethiopia",
                    "time": "2023-05-30 11:06:35",
                    "con_time_at_loc": 2336,
                    "time_at_loc": "0:38:56"
                },
                {
                    "vehicle_id": 96,
                    "veh_id": "2167",
                    "plate_no": "3-71748",
                    "location": "Ali Sabih, Djibouti",
                    "time": "2023-05-30 08:13:50",
                    "con_time_at_loc": 757,
                    "time_at_loc": "0:12:37"
                },
                {
                    "vehicle_id": 96,
                    "veh_id": "2167",
                    "plate_no": "3-71748",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 09:28:56",
                    "con_time_at_loc": 3236,
                    "time_at_loc": "0:53:56"
                },
                {
                    "vehicle_id": 96,
                    "veh_id": "2167",
                    "plate_no": "3-71748",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 11:47:37",
                    "con_time_at_loc": 840,
                    "time_at_loc": "0:14:00"
                },
                {
                    "vehicle_id": 58,
                    "veh_id": "2236",
                    "plate_no": "3-68239",
                    "location": "Welenchete",
                    "time": "2023-05-30 06:54:21",
                    "con_time_at_loc": 4655,
                    "time_at_loc": "1:17:35"
                },
                {
                    "vehicle_id": 30,
                    "veh_id": "2211",
                    "plate_no": "3-68221",
                    "location": "Route Nationale الطريق الوطني رقم 9, Arta, Djibouti, 15.58 km from Arta عرتا",
                    "time": "2023-05-30 06:10:00",
                    "con_time_at_loc": 608,
                    "time_at_loc": "0:10:08"
                },
                {
                    "vehicle_id": 30,
                    "veh_id": "2211",
                    "plate_no": "3-68221",
                    "location": "Ali Sabih, Djibouti",
                    "time": "2023-05-30 08:14:01",
                    "con_time_at_loc": 755,
                    "time_at_loc": "0:12:35"
                },
                {
                    "vehicle_id": 30,
                    "veh_id": "2211",
                    "plate_no": "3-68221",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 09:29:03",
                    "con_time_at_loc": 3282,
                    "time_at_loc": "0:54:42"
                },
                {
                    "vehicle_id": 102,
                    "veh_id": "3697",
                    "plate_no": "3-89684",
                    "location": "Ali Sabih, Djibouti",
                    "time": "2023-05-30 06:37:03",
                    "con_time_at_loc": 2223,
                    "time_at_loc": "00:37:03"
                },
                {
                    "vehicle_id": 102,
                    "veh_id": "3697",
                    "plate_no": "3-89684",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Ali Sabieh, Djibouti, Guelile غليل",
                    "time": "2023-05-30 06:49:40",
                    "con_time_at_loc": 1843,
                    "time_at_loc": "0:30:43"
                },
                {
                    "vehicle_id": 2,
                    "veh_id": "2177",
                    "plate_no": "3-68247",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 09:34:20",
                    "con_time_at_loc": 2831,
                    "time_at_loc": "0:47:11"
                },
                {
                    "vehicle_id": 2,
                    "veh_id": "2177",
                    "plate_no": "3-68247",
                    "location": "Ayesha, Somali Region, Ethiopia",
                    "time": "2023-05-30 10:26:50",
                    "con_time_at_loc": 673,
                    "time_at_loc": "0:11:13"
                },
                {
                    "vehicle_id": 104,
                    "veh_id": "2186",
                    "plate_no": "3-68215",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Ali Sabieh, Djibouti, Guelile غليل",
                    "time": "2023-05-30 07:28:42",
                    "con_time_at_loc": 670,
                    "time_at_loc": "0:11:10"
                },
                {
                    "vehicle_id": 104,
                    "veh_id": "2186",
                    "plate_no": "3-68215",
                    "location": "Harerge, Ethiopia",
                    "time": "2023-05-30 11:02:56",
                    "con_time_at_loc": 2662,
                    "time_at_loc": "0:44:22"
                },
                {
                    "vehicle_id": 133,
                    "veh_id": "398",
                    "plate_no": "3-66656",
                    "location": "4, Oromia Region, Ethiopia, Metahara",
                    "time": "2023-05-30 07:47:30",
                    "con_time_at_loc": 3100,
                    "time_at_loc": "0:51:40"
                },
                {
                    "vehicle_id": 95,
                    "veh_id": "2173",
                    "plate_no": "3-68224",
                    "location": "Awash - Assab Highway, Afar Region, Ethiopia, 2.16 km from አዋሽ / Awash",
                    "time": "2023-05-30 07:12:20",
                    "con_time_at_loc": 886,
                    "time_at_loc": "0:14:46"
                },
                {
                    "vehicle_id": 95,
                    "veh_id": "2173",
                    "plate_no": "3-68224",
                    "location": "4, Oromia Region, Ethiopia, Metahara",
                    "time": "2023-05-30 08:45:14",
                    "con_time_at_loc": 1786,
                    "time_at_loc": "0:29:46"
                },
                {
                    "vehicle_id": 31,
                    "veh_id": "2214",
                    "plate_no": "3-71716",
                    "location": "Main East West Road, Dire Dawa, Ethiopia",
                    "time": "2023-05-30 11:24:22",
                    "con_time_at_loc": 1121,
                    "time_at_loc": "0:18:41"
                },
                {
                    "vehicle_id": 31,
                    "veh_id": "2214",
                    "plate_no": "3-71716",
                    "location": "Main East West Road, Dire Dawa, Ethiopia",
                    "time": "2023-05-30 12:07:12",
                    "con_time_at_loc": 1129,
                    "time_at_loc": "0:18:49"
                },
                {
                    "vehicle_id": 112,
                    "veh_id": "1840",
                    "plate_no": "3-66706",
                    "location": "4, Oromia Region, Ethiopia, 2.15 km from Asebot",
                    "time": "2023-05-30 07:24:49",
                    "con_time_at_loc": 1757,
                    "time_at_loc": "0:29:17"
                },
                {
                    "vehicle_id": 21,
                    "veh_id": "2174",
                    "plate_no": "3-71725",
                    "location": "Route Ali Sabieh-Frontière Éthiopienne طريق الحدود علي صبيح - إثيوبيا, Ali Sabieh, Djibouti, Guelile غليل",
                    "time": "2023-05-30 07:39:57",
                    "con_time_at_loc": 1047,
                    "time_at_loc": "0:17:27"
                }
            ]
        }
        this.setState({
            sums: datas.sums,
            details: datas.details,
        })

    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.stoppage_1;
        Swal.fire('Loading...');
        Swal.showLoading()
        await axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            }
            else{
                console.log(res.data.datas)
                let datas=res.data.datas;
                baseurl = myconfig.stoppage_2;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    datas: datas,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    }
                    else{
                        console.log(res.data.datas)
                        let datas=res.data.datas;
                        baseurl = myconfig.stoppage_3;
                        axios.post(baseurl, {
                            sid: window.localStorage.getItem("logmgtsys_sid"),
                            datas: datas,
                        }, {cancelToken: source.token}).then(res => {
                            console.log(res.data)
                            if (res.data.auth == false) {
                                window.localStorage.setItem("logmgtsys_sid", null);
                                window.localStorage.setItem("logmgtsys_username", null);
                                window.localStorage.setItem("logmgtsys_name", null);
                                var win = window.open(redirecttologin, "_self");
                            } else if (res.data.success == false) {
                                Swal.fire({
                                    title: 'Error!',
                                    text: res.data.msg,
                                    icon: 'error',
                                    confirmButtonText: 'Okay'
                                })
                            }
                            else{
                                console.log(res.data.datas)
                                let datas=res.data.datas;
                                this.setState({
                                    sums: datas.sums,
                                    details: datas.details,
                                    filtered_details: [],
                                    wiz:1,
                                },()=>{
                                    Swal.close();
                                })
                            }
                        }).catch((e) => {
                            console.log(e.message);
                        });
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        }).catch((e) => {
            console.log(e.message);
        });

    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btnfilter = (event,id) => {
        event.preventDefault();
        let filtered_details = this.state.details.filter(v => {
            if (v.vehicle_id === id)
                return v;
        })
        this.setState({
            filtered_details: filtered_details,
            wiz:2,
            id:id,
        })
    }
    btnremove = (event,id) => {
        event.preventDefault();
        let sums = this.state.sums.filter(v => {
            if (v.vehicle_id !== id)
                return v;
        })
        let details = this.state.details.filter(v => {
            if (v.vehicle_id !== id)
                return v;
        })
        this.setState({
            sums: sums,
            details: details,
            wiz:1,
        })
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    btnexport_report_2 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report2");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report2'));
        wb.Sheets["report2"] = wb2;
        XLSX.writeFile(wb, "report2.xlsx");
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.sums.map(value => {
            count=count+1;
            let url_follow_up_history=myconfig.redirecttofollowuphistory+"?id="+value.vehicle_id
            let url_redirecttomovement=myconfig.redirecttomovement+"?vehicle_id="+value.vehicle_id
            return (<tr>
                <td style={{verticalAlign:"middle"}}>{count}</td>
                <td style={{verticalAlign:"middle"}}>{value.plate_no}</td>
                <td style={{verticalAlign:"middle"}}>{value.type}</td>
                <td style={{verticalAlign:"middle"}}>{value.org}</td>
                <td style={{verticalAlign:"middle"}}>{value.des}</td>
                <td style={{verticalAlign:"middle"}}>{value.loc}</td>
                <td style={{verticalAlign:"middle"}}>{value.avltime}</td>
                <td style={{verticalAlign:"middle"}}>{value.time_at_loc}</td>
                <td style={{verticalAlign:"middle"}}></td>
                <td>
                    <a href="" onClick={event => this.btnfilter(event,value.vehicle_id)}>More</a><hr/>
                    <a href={url_follow_up_history} target="_blank">Load History</a><hr/>
                    <a href={url_redirecttomovement} target="_blank">Movement History</a><hr/>
                    <a href="" onClick={event => this.btnremove(event,value.vehicle_id)}>Remove</a>
                </td>
            </tr>)
        })
        let tdata2 = this.state.details.map(value => {
            return (<tr>
                <td>{value.plate_no}</td>
                <td>{value.location}</td>
                <td>{value.time}</td>
                <td>{value.time_at_loc}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <h4>Summary Report</h4>
                    <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                   onClick={this.btnexport_report}>Download .xlsx</a></div>
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">PLATE #</th>
                            <th className="col-md-2">STATUS</th>
                            <th className="col-md-1">ORG</th>
                            <th className="col-md-1">DES</th>
                            <th className="col-md-1">Current Loc</th>
                            <th className="col-md-1">Avl Time</th>
                            <th className="col-md-1">TIME AT LOC</th>
                            <th className="col-md-1">Action Taken</th>
                            <th className="col-md-2">REMOVE</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                    <h4>Details Report</h4>
                    <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                   onClick={this.btnexport_report_2}>Download .xlsx</a></div>

                    <table id="report2" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-3">PLATE #</th>
                            <th className="col-md-4">Location</th>
                            <th className="col-md-3">Time</th>
                            <th className="col-md-2">TIME AT LOC</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata2}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata2 = this.state.filtered_details.map(value => {
            return (<tr>
                <td>{value.plate_no}</td>
                <td>{value.location}</td>
                <td>{value.time}</td>
                <td>{value.time_at_loc}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <h4>Details Report</h4> <a href="" onClick={event => this.btnremove(event,this.state.id)}>(Remove)</a>
                    <table id="report2" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-3">PLATE #</th>
                            <th className="col-md-4">Location</th>
                            <th className="col-md-3">Time</th>
                            <th className="col-md-2">TIME AT LOC</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata2}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="r_daily_stoppage"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Daily Stoppage</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default R_daily_stoppage;
