import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import Order_loading_adv_req from "./Order_loading_adv_req";
import nt from "../../img/ntlog.png";


let source = axios.CancelToken.source();

class Order_loading_adv extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filter_datas: [],
            unique_clients: [],
            dt: '',
            wiz: 1,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_datas_for_order_loading_adv;
        this.setState({
            datas: [],
            filter_datas: [],
            unique_clients: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.loading_ads,
                    filter_datas: res.data.loading_ads,
                    unique_clients: res.data.unique_clients,
                    dt: res.data.dt,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt_cbo_box = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let filter_datas=this.state.datas.filter(v=>{
            if(v.client_id==value)
                return v;
        })
        if(filter_datas.length==0)
        {
            if(value=="-1")
                filter_datas=this.state.datas;
        }

        this.setState({
            [name]: value,
            filter_datas:filter_datas,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let  datas=this.state.datas.map(v=>{
            v.fs_no=value;
            return v;
        })
        let  filter_datas=this.state.filter_datas.map(v=>{
            v.fs_no=value;
            return v;
        })
        this.setState({
            [name]: value,
            datas:datas,
            filter_datas:filter_datas,
        })
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filter_datas.map(value => {
            count=count+1;
            let cla="";
            if(value.is_warining)
                cla="bg-warning";
            return (<tr className={cla}>
                <td>{count}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.quanity}</td>
                <td>{value.fs_no}</td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.driver_phone}</td>
                <td>{value.type}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row" id="section_to_print_4">
                    <div className="col-md-4 no-print">
                        <div className="form-group">
                            <label>SO/FS #</label>
                            <input type="text" className="form-control form-control-sm" name="fs_no"
                                   value={this.state.fs_no} placeholder="SO/FS #" onChange={this.inputmgt}/>
                        </div>
                    </div>
                    <div className="col-md-12 no-print">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            window.print()
                        }}>Print</a>
                    </div>
                    <div className="col-md-3 pb-5 my-3">
                            <img className="img w-50" src={nt}></img>
                    </div>
                    <div className="col-md-9">
                    </div>
                    <div className="col-md-12 text-center">
                        <h5>NATIONAL TRANSPORT PLC</h5>
                    </div>
                    <div className="col-md-12 text-center">
                        <h6>Truck allocation</h6>
                    </div>
                    <div className="col-md-12 text-right">
                        <h6>Date:- {this.state.dt}</h6><br/>
                    </div>
                    <div className="col-md-12">
                        <table id="report" className="table table-sm table-bordered " style={{border:"2px solid black" , borderCollapse: "collapse"}}>
                            <thead>
                            <tr>
                                <th className="col-md-1">S/N</th>
                                <th className="col-md-2">CUSTOMER NAME</th>
                                <th className="col-md-1">ORIGIN</th>
                                <th className="col-md-1">DESTINATION</th>
                                <th className="col-md-1">QUANTITY LOADED</th>
                                <th className="col-md-1">SO/FS #</th>
                                <th className="col-md-1">TRUCK PLATE NO</th>
                                <th className="col-md-1">DRIVER NAME</th>
                                <th className="col-md-1">DRIVER phone</th>
                                <th className="col-md-2">TRANSPORTER TYPE</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-6 text-left">
                        <p><strong>Order by: </strong>-------------------------------------------</p>
                    </div>
                    <div className="col-6 text-right">
                        <p><strong>Prepared by: </strong>NT Fleet Operation Department</p>
                    </div>
                    <div className="col-12 text-left">
                        <p><strong>Order date: </strong>-------------------------------------------</p>
                    </div>
                    <div className="col-12  text-left">
                        <p><strong>Signature: </strong>-------------------------------------------</p>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        let op=this.state.unique_clients.map(v=>{
            return (<option value={v.client_id}>{v.client}</option>)
        })
        return (
            <>
                <Nav type="order_loading_adv"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle no-print" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Loading Advice Print</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <select name="client"
                                                            className="form-control float-right"
                                                            value={this.state.client} onChange={this.inputmgt_cbo_box}>
                                                        {op}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_loading_adv;
