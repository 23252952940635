import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Status_cement_detail extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            urls: [],
            purchase: {},
            wiz: 1,
        }
    }

    componentDidMount() {
        let no = new URLSearchParams(this.props.location.search).get("no");
        console.log(no)
        if (no !== undefined && no !== "" && no!==null) {
            this.setState({
                no: no,
            }, () => this.fetchdata())
        }
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.status_cement_detail;
        this.setState({
            datas: [],
            urls: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            no: this.state.no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        datas: res.data.cements,
                        urls: res.data.urls,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_1 = () => {
        let imgs=this.state.urls.map(v=>{
            let sty="rotate("+v.deg+"deg)"
            return (<>
                    <div className="col-md-12 text-center my-2 border">
                        <img style={{transform:sty}} src={v.url}/>
                    </div>
                    <div className="col-md-12 text-center my-2 border">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let urls=this.state.urls.map(v2=>{
                                if(v2.id===v.id){
                                    v2.deg=v2.deg+90
                                    if(v2.deg>270)
                                        v2.deg=0
                                }
                                return v2
                            });
                            this.setState({
                                urls:urls
                            })
                        }}>Image Rotate</a>
                    </div>
                </>
            )
        })
        let tdata=this.state.datas.map(v=>{
            return(<tr>
                <td>{v.client}</td>
                <td>{v.order_date}</td>
                <td>{v.cargo}</td>
                <td>{v.allocate_date}</td>
                <td>{v.volume}</td>
            </tr>)
        })

        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-3">Client</th>
                            <th className="col-md-3">Order Date</th>
                            <th className="col-md-2">Cement Type</th>
                            <th className="col-md-2">Allocated Date</th>
                            <th className="col-md-2">Delivered Volume</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                    </div>
                    <div className="col-md-12">
                        {imgs}
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="status_cement_detail"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Balance Detail</h3>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Cement #</label>
                                                                <input type="text" className="form-control form-control-sm" name="no"
                                                                       value={this.state.no} onChange={this.inputmgt}
                                                                       placeholder="Cement #"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <button className="btn btn-block btn-outline-primary btn-sm"
                                                                        onClick={event => {
                                                                            event.preventDefault();
                                                                            this.fetchdata()
                                                                        }}> Get Cement
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Status_cement_detail;
