import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Admin_script extends Component {

    constructor() {
        super();
        this.state = {
            datas1: [],
            datas2: [],
            datas3: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            coc:'',

        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_cocs;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnscript_execute = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.script_1;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            input1:this.state.input1,
            input2:this.state.input2,
            input3:this.state.input3,
            input4:this.state.input4,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        datas1:res.data.datas1,
                        datas2:res.data.datas2,
                        datas3:res.data.datas3,
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_check_fo_status = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.check_fo_status;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            input1:this.state.input1,
            input2:this.state.input2,
            input3:this.state.input3,
            input4:this.state.input4,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        output_1:res.data.output_1,
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    btnexport_report2 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report2");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report2'));
        wb.Sheets["report2"] = wb2;
        XLSX.writeFile(wb, "report2.xlsx");
    }
    btnexport_report3 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report3");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report3'));
        wb.Sheets["report3"] = wb2;
        XLSX.writeFile(wb, "report3.xlsx");
    }
    wiz_1 = () => {
        let count=0;
        let datas1 = this.state.datas1.map(value => {
            return (<tr>
                <td>{value.d_1}</td>
                <td>{value.d_2}</td>
                <td>{value.d_3}</td>
                <td>{value.d_4}</td>
                <td>{value.d_5}</td>
                <td>{value.d_6}</td>
                <td>{value.d_7}</td>
                <td>{value.d_8}</td>
                <td>{value.d_9}</td>
                <td>{value.d_10}</td>
                <td>{value.d_11}</td>
                <td>{value.d_12}</td>
                <td>{value.d_13}</td>
                <td>{value.d_14}</td>
                <td>{value.d_15}</td>
                <td>{value.d_16}</td>
                <td>{value.d_17}</td>
                <td>{value.d_18}</td>
                <td>{value.d_19}</td>
                <td>{value.d_20}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Input-1</label>
                            <textarea className="form-control form-control-sm" name="input1"
                                   value={this.state.input1} onChange={this.inputmgt}
                                   rows={10}>
                            </textarea>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Input-2</label>
                            <textarea className="form-control form-control-sm" name="input2"
                                   value={this.state.input2} onChange={this.inputmgt}
                                   rows={10}>
                            </textarea>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Input-3</label>
                            <textarea className="form-control form-control-sm" name="input3"
                                   value={this.state.input3} onChange={this.inputmgt}
                                   rows={10}>
                            </textarea>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Output-1</label>
                            <textarea className="form-control form-control-sm" name="output_1"
                                   value={this.state.output_1} onChange={this.inputmgt}
                                   rows={10}>
                            </textarea>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnscript_execute}> Run Script </button>
                        </div>
                    </div><
                    div className="col-md-3">
                        <div className="form-group">
                            <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_check_fo_status}> Check FO </button>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                       onClick={this.btnexport_report}>Download .xlsx</a></div>
                        <h3>Result-1</h3>
                        <table id="report" className="table table-head-fixed">
                            {datas1}
                        </table>
                    </div>
                    <div className="col-12">
                        <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                       onClick={this.btnexport_report2}>Download .xlsx</a></div>

                        <h3>Result-2</h3>
                        <table id="report2" className="table table-head-fixed">
                            <thead>
                            <tr>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {datas1}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12">
                        <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                       onClick={this.btnexport_report3}>Download .xlsx</a></div>

                        <h3>Result-3</h3>
                        <table id="report3" className="table table-head-fixed">
                            <thead>
                            <tr>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {datas1}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="admin_script"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Admin Script</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Admin_script;
