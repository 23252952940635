import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Status_purchase_detail extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            purchase: {},
            wiz: 1,
        }
    }

    componentDidMount() {
        let no = new URLSearchParams(this.props.location.search).get("no");
        console.log(no)
        if (no !== undefined && no !== "" && no!==null) {
            this.setState({
                no: no,
            }, () => this.fetchdata())
        }
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.status_purchase_detail;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            no: this.state.no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success',
                    text: "Report fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        datas: res.data.datas,
                        purchase: res.data.purchase,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_1 = () => {
        let tdata1=null
        let tdata2=null
        if(this.state.datas.length>0){
            let t_2 = this.state.datas.map(value => {
                return (<tr>
                    <td>
                        {value.cement_no}
                    </td>
                    <td>{value.client}</td>
                    <td>{value.cargo}</td>
                    <td>{value.cement_rate_s}</td>
                    <td>{value.order_date}</td>
                    <td>{value.bank_tt}</td>
                    <td>{value.total_weight}</td>
                    <td>{value.order_own}</td>
                    <td>{value.assign_supplier_loaded}</td>
                    <td>{value.weight}</td>
                </tr>)
            })

            tdata1=  <>
                <tr>
                    <th className="col-md-1">Purchase #</th>
                    <th className="col-md-3">Product Type</th>
                    <th className="col-md-1">Purchase Volume (q)</th>
                    <th className="col-md-1">Sale Volume (q)</th>
                    <th className="col-md-1">Avaliable Volume (q)</th>
                    <th className="col-md-1">Unit Price</th>
                    <th className="col-md-1">Purchase #</th>
                    <th className="col-md-1">FS #</th>
                    <th className="col-md-1">-</th>
                    <th className="col-md-1">-</th>
                </tr>
                <tr>
                    <td>{this.state.purchase.purchase_no}</td>
                    <td>{this.state.purchase.product_type}</td>
                    <td>{this.state.purchase.volume}</td>
                    <td>{this.state.purchase.sale_volume}</td>
                    <td>{this.state.purchase.avl_volume}</td>
                    <td>{this.state.purchase.unit_price}</td>
                    <td>{this.state.purchase.po_no}</td>
                    <td>{this.state.purchase.fs_no}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </>
            tdata2=  <>
                <tr>
                    <th className="col-md-1">Cement #</th>
                    <th className="col-md-3">Client</th>
                    <th className="col-md-1">Cargo</th>
                    <th className="col-md-1">Cement Sale Rate</th>
                    <th className="col-md-1">Order Date</th>
                    <th className="col-md-1">Bank TT</th>
                    <th className="col-md-1">Total Weight</th>
                    <th className="col-md-1">Allocated By NT</th>
                    <th className="col-md-1">Allocated By Supplier</th>
                    <th className="col-md-1">Un-allocated</th>
                </tr>
                {t_2}
            </>
        }

        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        {tdata1}
                        {tdata2}
                    </table>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="status_purchase_detail"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Purchase Balance Detail</h3>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>Purchase #</label>
                                                                <input type="text" className="form-control form-control-sm" name="no"
                                                                       value={this.state.no} onChange={this.inputmgt}
                                                                       placeholder="Purchase #"/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <button className="btn btn-block btn-outline-primary btn-sm"
                                                                        onClick={event => {
                                                                            event.preventDefault();
                                                                            this.fetchdata()
                                                                        }}> Get Purchase
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Status_purchase_detail;
