import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Client_mgt extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            txtsearch: '',


            wiz: 1,
            iscreate: false,
            id:-1,
            company_name: '',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',

            client_types: [],
            client_type_selected: null,
        }
    }

    handelchange_client_type = (client_type_selected) => {
        this.setState({
            client_type_selected:client_type_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
        this.fetchdata2()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_clients;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch: '',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata2 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_client_type_for_client_mgt;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    client_types: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.company_name.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            company_name: '',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            am_company_name: '',
            am_address: '',
            client_type_selected: null,
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                client_type_selected:{value:temp[0].client_type_id,label:temp[0].type},
                company_name: temp[0].company_name,
                contact_person: temp[0].contact_person,
                id:id,
                tin: temp[0].tin,
                city: temp[0].city,
                address: temp[0].address,
                phone: temp[0].phone,
                email: temp[0].email,
                am_company_name: temp[0].am_company_name,
                am_address: temp[0].am_address,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            company_name: '',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            client_type_selected: null,
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.client_type_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select client type.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.create_client;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                company_name: this.state.company_name,
                contact_person: this.state.contact_person,
                tin: this.state.tin,
                city: this.state.city,
                address: this.state.address,
                phone: this.state.phone,
                email: this.state.email,
                am_company_name: this.state.am_company_name,
                am_address: this.state.am_address,
                client_type: this.state.client_type_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnupdate = (event) => {
        event.preventDefault();
        if (this.state.client_type_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select client type",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.edit_client;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                company_name: this.state.company_name,
                id: this.state.id,
                contact_person: this.state.contact_person,
                tin: this.state.tin,
                city: this.state.city,
                address: this.state.address,
                phone: this.state.phone,
                email: this.state.email,
                client_type: this.state.client_type_selected.value,
                am_company_name: this.state.am_company_name,
                am_address: this.state.am_address,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value.id)}><i className="fas fa-edit px-1"></i></a>
                    <a href=""><i className="fas fa-trash px-1 text-danger"></i></a>
                </td>
                <td>{value.type}</td>
                <td>{value.company_name}</td>
                <td>{value.am_company_name}</td>
                <td>{value.contact_person}</td>
                <td>{value.tin}</td>
                <td>{value.city}</td>
                <td>{value.address}</td>
                <td>{value.phone}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Client</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Type</th>
                            <th className="col-md-2">Company Name</th>
                            <th className="col-md-2">የድርጅት ስም</th>
                            <th className="col-md-1">Contact Person</th>
                            <th className="col-md-1">TIN</th>
                            <th className="col-md-1">City</th>
                            <th className="col-md-1">Address</th>
                            <th className="col-md-1">Phone</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let header_1 = "Add Client"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Client"
            btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Edit </button>
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Client Type</label>
                                        <Select
                                            value={this.state.client_type_selected}
                                            onChange={this.handelchange_client_type}
                                            options={this.state.client_types}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Company Name</label>
                                        <input type="text" className="form-control form-control-sm" name="company_name"
                                               value={this.state.company_name} onChange={this.inputmgt}
                                               placeholder="Company name"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Contact person</label>
                                        <input type="text" className="form-control form-control-sm"
                                               name="contact_person" value={this.state.contact_person}
                                               onChange={this.inputmgt} placeholder="Contact person"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>TIN</label>
                                        <input type="text" className="form-control form-control-sm" name="tin"
                                               value={this.state.tin} onChange={this.inputmgt} placeholder="TIN"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>City</label>
                                        <input type="text" className="form-control form-control-sm" name="city"
                                               value={this.state.city} onChange={this.inputmgt} placeholder="City"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input type="text" className="form-control form-control-sm" name="address"
                                               value={this.state.address} onChange={this.inputmgt}
                                               placeholder="Address"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Phone #</label>
                                        <input type="text" className="form-control form-control-sm" name="phone"
                                               value={this.state.phone} onChange={this.inputmgt} placeholder="Phone #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input type="email" className="form-control form-control-sm" name="email"
                                               value={this.state.email} onChange={this.inputmgt} placeholder="Email"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>የድርጅት ስም</label>
                                        <input type="text" className="form-control form-control-sm" name="am_company_name"
                                               value={this.state.am_company_name} onChange={this.inputmgt} placeholder="የድርጅት ስም"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>አድራሻ</label>
                                        <input type="text" className="form-control form-control-sm" name="am_address"
                                               value={this.state.am_address} onChange={this.inputmgt} placeholder="አድራሻ"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="client_mgt"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Client Managements</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Client_mgt;
