import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Status_cement_allocation extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filter_datas: [],
            wiz: 1,
            fil: "All",
            is_exe: false,

            client_selected: null,
            clients: [],
            dt: new Date().toISOString().slice(0, 10),
        }
    }
    handelchange_client = (client_selected) => {
        let filter_datas=this.state.datas.filter(v=>{
            if(v.client==client_selected.label)
                return v;
        });
        this.setState({
            client_selected:client_selected,
            filter_datas:filter_datas,
        })
    }
    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    fetchdata = () => {
        if (!this.state.is_exe) {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.status_cement_allocation;
            this.setState({
                datas: [],
                filter_datas: [],
                clients: [],
                is_exe: true,
            })
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                dt: this.state.dt,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            datas: res.data.datas,
                            filter_datas: res.data.datas,
                            is_exe: false,
                        })
                    })
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    inputmgt_filter_by_status = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let filter_datas=this.state.datas;
        if(value=="Open")
            filter_datas=this.state.datas.filter(v=>{
                if(v.isfound)
                    return v;
            });
        else if(value=="Closed")
            filter_datas=this.state.datas.filter(v=>{
                if(!v.isfound)
                    return v;
            });
        this.setState({
            [name]: value,
            filter_datas: filter_datas,
        })
    }
    inputmgt_filter_by_key_word = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        },()=>this.filter_by_key())
    }
    filter_by_key(){
        let filter_datas=this.state.datas;
        if(this.state.key_word!="" && this.state.key_word!=null) {
            filter_datas = this.state.datas.filter(v => {

                if (
                    v.label.toUpperCase().includes(this.state.key_word.toUpperCase())
                ){
                    return v;
                }

            });
        }
        this.setState({
            filter_datas: filter_datas,
        })
    }
    wiz_1 = () => {
        let count=0;
        let tshipment=0;
        let tshipment_truck=0;
        let tabsenet=0;
        let tabsenet_truck=0;
        let tdata = this.state.filter_datas.map(value => {
            count=count+1;
            tshipment=tshipment+(value.actual_volume*1);
            tabsenet=tabsenet+(value.canceled_volume*1);
            return (<tr>
                <td>{count}</td>
                <td>
                    {value.delivery_no}
                </td>
                <td>{value.label}</td>
                <td>{value.total_weight}</td>
                <td>{value.delivered_volume}</td>
                <td>{value.remaining}</td>
                <td>{value.actual_volume}</td>
                <td>{value.remark}</td>
            </tr>)
        })
        tshipment_truck=(tshipment/400).toFixed(2)
        tabsenet_truck=(tabsenet/400).toFixed(2)
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Delivery #</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-2">Total Sale Collection (q)</th>
                            <th className="col-md-2">Total Shipped to Customer (q)</th>
                            <th className="col-md-2">Unshipped to Customer (q)</th>
                            <th className="col-md-1">Current Shipment (q)</th>
                            <th className="col-md-1">Remark</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2">Total Current Shipment (q)</th>
                            <th className="col-md-2">{tshipment}</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                        </tr>
                        <tr>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2">Total Current Shipment (#)</th>
                            <th className="col-md-2">{tshipment_truck}</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                        </tr>
                        <tr>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2">Total Absent Shipment (q)</th>
                            <th className="col-md-2">{tabsenet}</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                        </tr>
                        <tr>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2"></th>
                            <th className="col-md-2">Total Absent Shipment (#)</th>
                            <th className="col-md-2">{tabsenet_truck}</th>
                            <th className="col-md-1"></th>
                            <th className="col-md-1"></th>
                        </tr>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="status_cement_allocation"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Search Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-11 m-1">
                                                    <div className="form-group">
                                                        <label>Date</label>
                                                        <input type="date" name="dt"
                                                               className="form-control float-right"
                                                               value={this.state.dt} onChange={this.inputmgt}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-11 m-1">
                                                    <div className="form-group">
                                                        <label>Key Word</label>
                                                        <input type="text" name="key_word"
                                                               className="form-control float-right"
                                                               value={this.state.key_word} onChange={this.inputmgt_filter_by_key_word} placeholder="Key Word"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-11 m-1">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.fetchdata()
                                                                }}> Get Allocation
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Balance</h3>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Status_cement_allocation;

