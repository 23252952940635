import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Follow_up_eta extends Component {

    constructor() {
        super();
        this.state = {
            emptys: [],
            loads: [],
            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            msg_status:'',

            locs: [],
            loc_selected: null,
            isremovable: false,
        }
    }

    componentDidMount() {
        this.fetchdata()
    }
    handelchange_loc = (loc_selected) => {
        this.setState({
            loc_selected:loc_selected,
        })
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_loc_for_follow_up_eta;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    locs: res.data.datas,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }


    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btn_next = (event) => {
        event.preventDefault();
        if(this.state.loc_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select location",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.get_vehicles_follow_up_eta;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                loc_id:this.state.loc_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        wiz: 2,
                        emptys:res.data.emptys,
                        loads:res.data.loads,
                        isremovable:true,
                    },()=>{
                        this.fetchdata2()
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name:this.state.company_name,
            id:this.state.id,
            coc:this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    wiz_2 = () => {
        let count=0;
        let shortname=null;
        let tdata1=this.state.emptys.map(value => {
            count=count+1;
            shortname=value.short_name;
            return (<tr>
                <td>{count}</td>
                <td>{value.plate_no}</td>
                <td>{value.type}</td>
                <td><p title={value.cur_loc_long}>{value.cur_loc_short}</p></td>
                <td>{value.avltime}</td>
                <td>{value.km_1}</td>
            </tr>)
        });
        count=0;
        let tdata2=this.state.loads.map(value => {
            count=count+1;
            shortname=value.short_name;
            return (<tr>
                <td>{count}</td>
                <td>{value.plate_no}</td>
                <td>{value.type}</td>
                <td><p title={value.cur_loc_long}>{value.cur_loc_short}</p></td>
                <td>{value.avltime}</td>
                <td>{value.km_1}</td>
            </tr>)
        });
        return (<>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Information Status</label>
                            <textarea rows={7} className="form-control form-control-sm" name="msg_status"
                                      value={this.state.msg_status} onChange={this.inputmgt}></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                                       onClick={this.btnexport_report}>Download .xlsx</a></div>
                        <table id="report" className="table table-head-fixed">
                            <tr>
                                <th colSpan={6}>Heading To: <strong>{shortname}</strong></th>
                            </tr>
                            <tr>
                                <th colSpan={6}><strong>Heading Empty</strong></th>
                            </tr>
                            <tr>
                                <th className="col-md-1">#</th>
                                <th className="col-md-2">Plate #</th>
                                <th className="col-md-3">Type</th>
                                <th className="col-md-2">Current Location</th>
                                <th className="col-md-1">Update Time</th>
                                <th className="col-md-1">Left Km</th>
                            </tr>
                            {tdata1}
                            <tr>
                                <th colSpan={6}><strong>Heading Loaded</strong></th>
                            </tr>
                            <tr>
                                <th className="col-md-1">#</th>
                                <th className="col-md-2">Plate #</th>
                                <th className="col-md-3">Type</th>
                                <th className="col-md-2">Current Location</th>
                                <th className="col-md-1">Update Time</th>
                                <th className="col-md-1">Left Km</th>
                            </tr>
                            {tdata2}
                        </table>
                    </div>

                </div>
            </div>
            <div className="card-footer clearfix">
                <button type="button" className="btn btn-danger float-right" onClick={event => {
                    event.preventDefault()
                    this.setState({
                        wiz: 1,
                    })
                }}><i
                    className="fas fa-arrow-left"></i>
                </button>
            </div>
        </>)
    }
    wiz_1 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Heading Status</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Heading To</label>
                                        <Select
                                            value={this.state.loc_selected}
                                            onChange={this.handelchange_loc}
                                            options={this.state.locs}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-4">
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_next}> Next </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    fetchdata2 = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_km_follow_up_eta;
        for (let i = 0; i < this.state.emptys.length; i++) {
            let msg_status = this.state.msg_status
            msg_status = msg_status + "Executing vehicle " + this.state.emptys[i].plate_no + "....\n"
            this.setState({
                msg_status: msg_status,
            })
            await axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                eid: this.state.eid,
                veh_id: this.state.emptys[i].veh_id,
                lat: this.state.emptys[i].lat,
                lon: this.state.emptys[i].lon,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    msg_status = msg_status + "Executing vehicle " + this.state.emptys[i].plate_no + " finished\n"
                    let temp = this.state.emptys.map(v => {
                        if (v.veh_id === this.state.emptys[i].veh_id) {
                            v.cur_loc_short = res.data.data.short_name
                            v.cur_loc_long = res.data.data.long_name
                            v.avltime = res.data.data.avl_date
                            v.km_1 = res.data.data.km_1
                            v.km_2 = res.data.data.km_2
                            v.sort = res.data.sort
                        }
                        return v;
                    })
                    this.setState({

                        msg_status: msg_status,
                        eid: res.data.eid,
                        emptys: temp,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
        for (let i = 0; i < this.state.loads.length; i++) {
            let msg_status = this.state.msg_status
            msg_status = msg_status + "Executing vehicle " + this.state.loads[i].plate_no + "....\n"
            this.setState({
                msg_status: msg_status,
            })
            await axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                eid: this.state.eid,
                veh_id: this.state.loads[i].veh_id,
                lat: this.state.loads[i].lat,
                lon: this.state.loads[i].lon,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    msg_status = msg_status + "Executing vehicle " + this.state.loads[i].plate_no + " finished\n"
                    let temp = this.state.loads.map(v => {
                        if (v.veh_id === this.state.loads[i].veh_id) {
                            v.cur_loc_short = res.data.data.short_name
                            v.cur_loc_long = res.data.data.long_name
                            v.avltime = res.data.data.avl_date
                            v.km_1 = res.data.data.km_1
                            v.km_2 = res.data.data.km_2
                            v.sort = res.data.sort
                        }
                        return v;
                    })
                    this.setState({
                        msg_status: msg_status,
                        eid: res.data.eid,
                        loads: temp,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
        ///let temp = this.state.datas.sort((a, b) => a.km_2 < b.km_2 ? 1 : -1)
        let msg_status = this.state.msg_status
        msg_status = msg_status + "Executing finished \n"
        Swal.fire({
            title: 'Success!',
            text: "Executed successfully",
            icon: 'success',
            confirmButtonText: 'Okay'
        }).then(v => {
            let emptys = this.state.emptys.sort(({sort: a}, {sort: b}) => a - b)
            let loads = this.state.loads.sort(({sort: a}, {sort: b}) => a - b)
            this.setState({
                msg_status: msg_status,
                emptys: emptys,
                loads: loads,
                /*datas: temp,*/
            })
        })
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        let btn=null

        if(this.state.isremovable)
            btn=<a href="" onClick={event => {
                event.preventDefault();
                this.setState({
                    isremovable:false,
                },()=>this.fetchdata2())
            }}> (Get data)</a>
        return (
            <>
                <Nav type="follow_up_eta"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-cyan ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Follow-Up ETA {btn}</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_eta;
