import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import Order_loading_adv_req from "./Order_loading_adv_req";


let source = axios.CancelToken.source();

class Order_loading_adv_app extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            vehicles:[],
            drivers:[],
            trailers:[],
            sub_contractor_vehicles:[],
            sub_contractor_drivers:[],
            sub_contractor_trailers:[],
            current_vehicles:[],
            current_drivers:[],
            current_trailers:[],
            vehicle_selected: null,
            driver_selected: null,
            trailer_selected: null,
            is_sub:false,
            vehicle_sub_company_name:'',
            driver_sub_company_name:'',
            trailer_sub_company_name:'',
            order_data:{},
            orders: [],
            sums: [],
            order_selected: null,

            wiz: 1,
            iscreate: false,
        }
    }
    /*handelchange_vehicle = (vehicle_selected) => {
        if (order_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select order",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else
        {

    }*/

    handelchange_vehicle = (vehicle_selected) => {
        let temp = this.state.current_vehicles.filter(v => {
            if (v.value === vehicle_selected.value)
                return v;
        })
        if (temp.length > 0) {
            let driver_selected=null;
            this.state.current_drivers.filter(v=>{
                if(v.value===temp[0].driver_id)
                {
                    driver_selected={value:v.value,label:v.label}
                }
            })

            let trailer_selected=null;
            this.state.current_trailers.filter(v=>{
                if(v.value===temp[0].trailer_id)
                {
                    trailer_selected={value:v.value,label:v.label}
                }
            })
            this.setState({
                vehicle_selected: vehicle_selected,
                vehicle_sub_company_name:temp[0].company_name,
                driver_sub_company_name:driver_selected==null?"":temp[0].company_name,
                trailer_sub_company_name:trailer_selected==null?"":temp[0].company_name,
                driver_selected:driver_selected,
                trailer_selected:trailer_selected,
            })
        }
    }
    handelchange_driver = (driver_selected) => {
        let temp = this.state.current_drivers.filter(v => {
            if (v.value === driver_selected.value)
                return v;
        })
        if (temp.length > 0) {
            this.setState({
                driver_selected: driver_selected,
                driver_sub_company_name:temp[0].company_name,
            })
        }
    }
    handelchange_trailer = (trailer_selected) => {
        let temp = this.state.current_trailers.filter(v => {
            if (v.value === trailer_selected.value)
                return v;
        })
        if (temp.length > 0) {
            this.setState({
                trailer_selected: trailer_selected,
                trailer_sub_company_name:temp[0].company_name,
            })
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_datas_for_order_loading_adv_app;
        this.setState({
            datas: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.loading_ads,
                    sums: res.data.sums,
                    vehicles: res.data.vehicles,
                    drivers: res.data.drivers,
                    trailers: res.data.trailers,
                    sub_contractor_vehicles: res.data.sub_contractor_vehicles,
                    sub_contractor_drivers: res.data.sub_contractor_drivers,
                    sub_contractor_trailers: res.data.sub_contractor_trailers,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputmgt_transporter = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        var vehicle_selected = null;
        var driver_selected = null;
        var current_vehicles = []
        var current_drivers = []
        var current_trailers = []
        var is_sub = false
        if (value === "own") {
            current_vehicles = this.state.vehicles
            current_drivers = this.state.drivers
            current_trailers = this.state.trailers
        } else if (value === "sub") {
            current_vehicles = this.state.sub_contractor_vehicles
            current_drivers = this.state.sub_contractor_drivers
            current_trailers = this.state.sub_contractor_trailers
            is_sub = true
        }
        this.setState({
            [name]: value,
            is_sub: is_sub,
            vehicle_selected: vehicle_selected,
            driver_selected: driver_selected,
            current_vehicles: current_vehicles,
            current_drivers: current_drivers,
            current_trailers: current_trailers,
            vehicle_sub_company_name:'',
            driver_sub_company_name:'',
            trailer_sub_company_name:'',
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            coc: '',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.vehicle_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        if(this.state.driver_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select vehicle.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else{
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.order_loading_adv_app;
            let trailer_selected=this.state.trailer_selected==null?null:this.state.trailer_selected.value;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id:this.state.id,
                type:this.state.t_type,
                vehicle_id:this.state.vehicle_selected.value,
                driver_id:this.state.driver_selected.value,
                trailer_selected:trailer_selected,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    wiz_1 = () => {
        let count=0;
        let tdata = this.state.datas.map(value => {
            count=count+1;
            let url_detail=myconfig.redirecttostatus_delivery_detail
            let order_no="Order_"+value.order_id;
            url_detail=url_detail+"?no="+order_no
            let cla="";
            if(value.is_warining)
                cla="bg-warning";
            let link=null
            let td_class="col-md-1 bg-yellow";
            if(value.status==="APPROVED")
                td_class="col-md-1 bg-green";
            if(value.type==="OWN") {
                if(value.vehicle_id!=null){
                    if(value.status==="APPROVED") {
                        let l = myconfig.redirecttoassign_own + "?order_id=" + value.order_id + "&driver_id=" + value.driver_id + "&vehicle_id=" + value.vehicle_id + "&trailer_id=" + value.trailer_id;
                        link = <> |
                            <a href={l} target="_blank">Manage</a>
                        </>
                    }
                }
            }

            else {
                if(value.vehicle_id!=null) {
                    let l = myconfig.redirecttoassign_sub_contractor_req + "?order_id=" + value.order_id + "&driver_id=" + value.driver_id + "&vehicle_id=" + value.vehicle_id + "&trailer_id=" + value.trailer_id;
                    link = <> |
                        <a href={l} target="_blank">Manage</a>
                    </>
                }
            }
            return (<tr className={cla}>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => {this.btn_allocate(event,value.id)}}>A</a> |
                    <a href="" onClick={event => {this.btn_remove(event,value.id)}}>U</a>
                    {link}
                </td>
                <td>
                    <a href={url_detail} target="_blank">Show</a>
                </td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.plate_no}</td>
                <td>{value.driver_name}</td>
                <td>{value.driver_phone}</td>
                <td>{value.type}</td>
                <td className={td_class}>{value.status}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            wiz:3,
                        })
                    }}>Show Summary</a>
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">S/N</th>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">AGREEMENT</th>
                            <th className="col-md-2">CUSTOMER NAME</th>
                            <th className="col-md-1">ORIGIN</th>
                            <th className="col-md-1">DESTINATION</th>
                            <th className="col-md-1">TRUCK PLATE NO</th>
                            <th className="col-md-1">DRIVER NAME</th>
                            <th className="col-md-1">DRIVER phone</th>
                            <th className="col-md-1">TRANSPORTER TYPE</th>
                            <th className="col-md-1">AGREEMENT STATUS</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let dis=null;
        if(this.state.is_sub){
            dis=<>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Vehicle Sub-contractor Company</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.vehicle_sub_company_name}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Driver Sub-contractor Company</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.driver_sub_company_name}/>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label>Trailer Sub-contractor Company</label>
                        <input disabled={true} className="form-control form-control-sm"
                               value={this.state.trailer_sub_company_name}/>
                    </div>
                </div>
            </>
        }
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Loading Advice Populate</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Customer Name</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.client}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Origin</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.org}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Destination</label>
                                        <input disabled={true} className="form-control form-control-sm"
                                               value={this.state.des}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Transporter Type</label>
                                        <select onChange={this.inputmgt_transporter} name="t_type" className="form-control form-control-sm"
                                                value={this.state.t_type}>
                                            <option value="-1">-- Select Transporter Type --</option>
                                            <option value="own">Own</option>
                                            <option value="sub">Sub-contractor</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Vehicle</label>
                                        <Select
                                            value={this.state.vehicle_selected}
                                            onChange={this.handelchange_vehicle}
                                            options={this.state.current_vehicles}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver</label>
                                        <Select
                                            value={this.state.driver_selected}
                                            onChange={this.handelchange_driver}
                                            options={this.state.current_drivers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer</label> <a href="" onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            trailer_selected:null,
                                        })
                                    }}>(Reset)</a>
                                        <Select
                                            value={this.state.trailer_selected}
                                            onChange={this.handelchange_trailer}
                                            options={this.state.current_trailers}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8">
                                </div>
                                {dis}
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let count=0;
        let tdata = this.state.sums.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.count}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>Add Allocation</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            wiz:1,
                        })
                    }}>Show Detail</a>
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">S/N</th>
                            <th className="col-md-3">CUSTOMER NAME</th>
                            <th className="col-md-3">ORIGIN</th>
                            <th className="col-md-3">DESTINATION</th>
                            <th className="col-md-2">Quantity</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }

    btn_remove = (event,id) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to unset the allocation?',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.order_loading_adv_app_del;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                            }, () => {
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    btn_allocate = (event,id) => {
        event.preventDefault();
        let temp = this.state.datas.filter(v => {
            if (v.id === id)
                return v;
        })
        if (temp.length > 0) {
            this.setState({
                client:temp[0].client,
                org:temp[0].org,
                des:temp[0].des,
                id:id,
                wiz:2,
                is_sub: false,
                t_type: "-1",
                vehicle_selected: null,
                driver_selected: null,
                current_vehicles: [],
                current_drivers: [],
                current_trailers: [],
                vehicle_sub_company_name:'',
                driver_sub_company_name:'',
                trailer_sub_company_name:'',
            })
        }
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        return (
            <>
                <Nav type="order_loading_adv_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Loading Advice Populate</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Order_loading_adv_app;
