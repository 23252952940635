import {Component} from 'react';
import axios from "axios"
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import myconfig from "../../myconfig";
import logimg from "../../img/logo.png";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { StickyContainer, Sticky } from 'react-sticky';
import Select from "react-select";

let source = axios.CancelToken.source();

class D_operation extends Component {
    constructor() {
        super();
        this.state = {
            d_type: 1,

            wiz: 0,
            status: [],
            vehicles: [],
            vehicles_fitler: [],
            series: [],
            sum_datas: [],
            datas: [],
            det_datas: [],
            yesterday_unloads: [],
            options: {},

            sums: [],
            stoppages: [],
            filter_stoppages: [],

            cus_datas: [],
            cus_filter_datas: [],
            cus_sums: [],

            filter_status: [],
            filter_customers: [],
            filter_cargos: [],
            filter_orgs: [],
            filter_dess: [],
            location_selected: null,
            locations: [],
            aud: 0,
            wfo: 0,
            itlp: 0,
            wtl: 0,
            itop: 0,
            wto: 0,
            hts: 0,

            aud_datas: [],
            wfo_datas: [],
            itlp_datas: [],
            wtl_datas: [],
            itop_datas: [],
            wto_datas: [],
            hts_datas: [],

            series2: [],
            options2: {},

            mains: [],
            status_text:'',
        };
    }

    componentDidMount() {
        this.fetchdata_dashboard()
    }

    fetchdata_dashboard = () => {
        let baseurl = myconfig.d_operation;
        let redirecttologin = myconfig.redirecttologin;
       /* let token = new URLSearchParams(this.props.location.search).get("token");*/
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    status: res.data.status,
                    vehicles: res.data.vehicles,
                    series: res.data.series,
                    sum_datas: res.data.sum_datas,
                    datas: res.data.datas,
                    mains: res.data.mains,

                    sums: res.data.sums,
                    stoppages: res.data.stoppages,
                    yesterday_unloads: res.data.yesterday_unloads,
                    filter_stoppages: [],


                    options: {
                        chart: {
                            width: 350,
                            type: 'pie',
                        },
                        legend: {
                            position: 'bottom',
                            show: true,
                        },
                        labels: res.data.lables,
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                            }
                        }]
                    },

                    series2: [{
                        name: "Availability",
                        data: res.data.avl_values
                    }],
                    options2: {
                        chart: {
                            height: 350,
                            type: 'line',
                            zoom: {
                                enabled: false
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        stroke: {
                            curve: 'straight'
                        },
                        title: {
                            text: 'Vehicle availability by day',
                            align: 'left'
                        },
                        grid: {
                            row: {
                                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                opacity: 0.5
                            },
                        },
                        xaxis: {
                            categories: res.data.avl_days,
                        },
                        yaxis: {
                            min:90,
                            max:150,
                        }
                    },
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    more_status = (event, ty) => {
        event.preventDefault();
        let temp = this.state.vehicles;
        if (ty !== "All") {
            temp = this.state.vehicles.filter(v => {
                if (v.status === ty.type)
                    return v;
            })
        }
        this.setState({
            vehicles_fitler: temp,
            status_text: "(" + ty.type + ")",

        })
    }

    wiz_0 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Loading...</h5>
            </div>
        )
    }
    wiz_1 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Invalid session token. Please contact your admin.</h5>
            </div>
        )
    }
    wiz_2 = () => {
        let t_data_status_sum = this.state.status.map(v => {
            return (<tr>
                <td>{v.type}</td>
                <td><a href="" onClick={event => this.more_status(event, v)}>{v.count}</a></td>
            </tr>)
        })
        let t_data_status_det = this.state.vehicles_fitler.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.remark}</td>
            </tr>)
        })
        let t_data_yesterday_unload = this.state.yesterday_unloads.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.client_name}</td>
                <td>{v.org}</td>
                <td>{v.des}</td>
                <td>{v.remark}</td>
            </tr>)
        })

        let t_main = this.state.mains.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.cur_loc}</td>
                <td>{v.remark}</td>
            </tr>)
        })

        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                <h3 className="card-title">Vehicles Status Pi Chart</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <div id="chart">
                                    <Chart options={this.state.options} series={this.state.series} type="pie"
                                           width={350}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header" style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                <h3 className="card-title">Yesterday Unloading</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-3">Plate #</th>
                                        <th className="col-md-3">Client Name</th>
                                        <th className="col-md-2">Origin</th>
                                        <th className="col-md-2">Destination</th>
                                        <th className="col-md-2">Stoppage</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_yesterday_unload}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header" style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                <h3 className="card-title">Vehicles Status Summary</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Status</th>
                                        <th className="col-md-5">Count</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_status_sum}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-primary">
                            <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                <h3 className="card-title">Vehicles Status Detail {this.state.status_text}</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Plate #</th>
                                        <th className="col-md-5">Stoppage</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_status_det}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                <h3 className="card-title">Vehicle Availability</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <Chart options={this.state.options2} series={this.state.series2} type="line" height={300} />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header" style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                <h3 className="card-title">Vehicle Availability Detail</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-4">Plate #</th>
                                        <th className="col-md-4">Current Location</th>
                                        <th className="col-md-4">Remark</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_main}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    btnexport_report_1 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Report 1");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report_1'));
        wb.Sheets["Report 1"] = wb2;
        XLSX.writeFile(wb, "report 1.xlsx");
    }
    btnexport_report_2 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Report 2");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report_2'));
        wb.Sheets["Report 2"] = wb2;
        XLSX.writeFile(wb, "report 2.xlsx");
    }

    render() {
        let dis=null;
        if(this.state.wiz===0)
            dis=this.wiz_0();
        else if(this.state.wiz===1)
            dis=this.wiz_1();
        else if(this.state.wiz===2)
            dis=this.wiz_2();
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-2" style={{width: "100%"}}>
                            <div className="card">
                                <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                    <h2 className="card-title">Operation Dashboard</h2>
                                    <div className="card-tools">
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default D_operation;
