import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Road_route_open extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],
            txtsearch: '',

            wiz: 1,
            iscreate: false,
            id:-1,
            fin_loc:'',
            distance:'',
            empty_allowance:'',
            empty_fuel_cost:'',
            empty_paring:'',
            empty_other_cost:'',
            load_allowance:'',
            load_fuel_cost:'',
            load_paring:'',
            load_other_cost:'',

            ini_locs: [],
            ini_loc_selected: null,

            fin_locs: [],
            fin_loc_selected: null,
        }
    }
    handelchange_ini_loc = (ini_loc_selected) => {
        this.setState({
            ini_loc_selected:ini_loc_selected,
        })
    }
    handelchange_fin_loc = (fin_loc_selected) => {
        this.setState({
            fin_loc_selected:fin_loc_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
    }
    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_obj_for_road_route_open;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    ini_locs: res.data.locations,
                    fin_locs: res.data.locations,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.ini_loc_con.toUpperCase().includes(value.toUpperCase()) ||
                    v.fin_loc_con.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            distance:'',
            empty_allowance:'',
            empty_fuel_cost:'',
            empty_paring:'',
            empty_other_cost:'',
            load_allowance:'',
            load_fuel_cost:'',
            load_paring:'',
            load_other_cost:'',
        })
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            distance:'',
            empty_allowance:'',
            empty_fuel_cost:'',
            empty_paring:'',
            empty_other_cost:'',
            load_allowance:'',
            load_fuel_cost:'',
            load_paring:'',
            load_other_cost:'',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if(this.state.ini_loc_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select initial location.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else if(this.state.fin_loc_selected===null){
            Swal.fire({
                title: 'Error!',
                text: "Select final location type.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }
        else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.road_route_open;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                coc:this.state.coc,
                ini_loc:this.state.ini_loc_selected.value,
                fin_loc:this.state.fin_loc_selected.value,
                distance:this.state.distance,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }

    wiz_1 = () => {
        let tdata = this.state.filtered.map(value => {
            return (<tr>
                <td>{value.ini_loc_con}</td>
                <td>{value.fin_loc_con}</td>
                <td>{value.distance}</td>
                <td>{value.empty_allowance}</td>
                <td>{value.empty_fuel_cost}</td>
                <td>{value.empty_paring}</td>
                <td>{value.empty_other_cost}</td>
                <td>{value.load_allowance}</td>
                <td>{value.load_fuel_cost}</td>
                <td>{value.load_paring}</td>
                <td>{value.load_other_cost}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Route</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed table-responsive">
                        <thead>
                        <tr>
                            <th className="col-md-1">Initial Location</th>
                            <th className="col-md-1">Final Location</th>
                            <th className="col-md-2">Distance</th>
                            <th className="col-md-1">Allowance (empty)</th>
                            <th className="col-md-1">Fuel (empty)</th>
                            <th className="col-md-1">Parking (empty)</th>
                            <th className="col-md-1">Other (empty)</th>
                            <th className="col-md-1">Allowance (loaded)</th>
                            <th className="col-md-1">Fuel (loaded)</th>
                            <th className="col-md-1">Parking (loaded)</th>
                            <th className="col-md-1">Other (loaded)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">New Route</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Initial Location</label>
                                        <Select
                                            value={this.state.ini_loc_selected}
                                            onChange={this.handelchange_ini_loc}
                                            options={this.state.ini_locs}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Final Location</label>
                                        <Select
                                            value={this.state.fin_loc_selected}
                                            onChange={this.handelchange_fin_loc}
                                            options={this.state.fin_locs}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Distance</label>
                                        <input type="text" className="form-control form-control-sm" name="distance"
                                               value={this.state.distance} onChange={this.inputmgt}
                                               placeholder="Distance"/>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="road_route_open"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Road Route</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Road_route_open;
