import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Cement_app extends Component {

    constructor() {
        super();
        this.state = {
            no_img:1,
            imgs:[],

            order_date:new Date().toISOString().slice(0, 10),
            purchase_no:'',
            cement_type:'',
            weight:'',
            cement_rate_b:'',
            cement_rate_s:'',
            bank_tt:'',

            is_exe:false,
            btn_text:"Create",

            datas: [],
            products: [],
            txtsearch: '',

            clients: [],
            clients_main: [],
            cargos: [],
            urls: [],

            wiz: 1,
            client_selected: null,
            cargo_selected: null,
            iscreate: true,
            id: -1,

            Amount:'',
            invoice_no:'',
            payment_date:new Date().toISOString().slice(0, 10),
            type:'',
            remark:'',
        }
    }
    onfilechange = (event,id) => {
        event.preventDefault()
        if(event.target.files[0]!==undefined){
            let isfound=false;
            let imgs = this.state.imgs.filter(v=>{
                if(v.name==="img_" + id){
                    isfound=true;
                    v.img=event.target.files[0]
                }
                return v;
            })
            if(!isfound)
                imgs.push({
                    name: "img_" + id,
                    img: event.target.files[0],
                })
            this.setState({
                imgs: imgs,
            });
        }

    }

    handelchange_client = (client_selected) => {
        this.setState({
            client_selected:client_selected,
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
        },()=>{
            let temp=this.state.clients_main.filter(v=>{
                console.log(client_selected.value)
                console.log(v.id)
                if(v.id===client_selected.value)
                    return v;
            })
            if(temp.length>0) {
                this.setState({
                    company_name: temp[0].company_name==null?"NA":temp[0].company_name,
                    client_type: temp[0].type==null?"NA":temp[0].type,
                    contact_person: temp[0].contact_person==null?"NA":temp[0].contact_person,
                    tin: temp[0].tin==null?"NA":temp[0].tin,
                    city: temp[0].city==null?"NA":temp[0].city,
                    address: temp[0].address==null?"NA":temp[0].address,
                    phone: temp[0].phone==null?"NA":temp[0].phone,
                    email: temp[0].email==null?"NA":temp[0].email,
                })
            }
        })
    }
    handelchange_cargo = (cargo_selected) => {
        this.setState({
            cargo_selected:cargo_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl =myconfig.get_open_cement_for_cement_app;
        this.setState({
            datas: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    clients: res.data.clients,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.company_name.toUpperCase().includes(value.toUpperCase()
                    )
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            no_img:1,
            imgs:[],
            order_date:new Date().toISOString().slice(0, 10),
            purchase_no:'',
            cement_type:'',
            weight:'',
            cement_rate_b:'',
            cement_rate_s:'',
            bank_tt:'',
            datas: [],
            txtsearch: '',
            wiz: 2,
            client_selected: null,
            cargo_selected: null,
            iscreate: true,
            id: -1,
        })
    }
    btnshow = (event,id) => {
        event.preventDefault();
        this.getorder(id,4)
    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            cargo_selected: null,
            org_selected: null,
            des_selected: null,
            client_selected: null,
            iscreate: true,
            id:-1,
            des:'',
            weight:'',
            mode_of_transport:'',
            company_name: '',
            client_type:'',
            contact_person: '',
            tin: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            millage: 'NA',
            tol: '-1',
            container_no:'',
            volume:'',
            transport_rate:'',
            st_date:'',
            ed_date:'',
        })
    }
    btncreate = (event) => {
        event.preventDefault();
        if (!this.state.is_exe) {
            if (this.state.client_selected === null) {
                Swal.fire({
                    title: 'Error!',
                    text: "Select client.",
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    is_exe: true,
                    btn_text: "Working...",
                })
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.cement_open;
                const formData = new FormData();
                formData.append(
                    "sid", window.localStorage.getItem("logmgtsys_sid")
                );
                formData.append(
                    "client_id", this.state.client_selected.value
                );
                formData.append(
                    "order_date", this.state.order_date
                );
                formData.append(
                    "purchase_no", this.state.purchase_no
                );
                formData.append(
                    "cement_type", this.state.cement_type
                );
                formData.append(
                    "weight", this.state.weight
                );
                formData.append(
                    "cement_rate_b", this.state.cement_rate_b
                );
                formData.append(
                    "cement_rate_s", this.state.cement_rate_s
                );
                formData.append(
                    "bank_tt", this.state.bank_tt
                );
                let len = this.state.imgs.length
                formData.append(
                    "img_length",
                    len,
                );
                for (let i = 1; i <= len; i++) {
                    formData.append(
                        "img_" + i.toString(),
                        this.state.imgs[i - 1].img,
                    );
                }
                axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe: false,
                            btn_text: "Create",
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz: 1,
                                is_exe: false,
                                btn_text: "Create",
                            }, () => {
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        }
    }
    getorder = (id,wiz) => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_single_open_order_for_cement_app;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            id: id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                let cement_type=res.data.data.cargo==="PPC"?"ppc":"opc";
                this.setState({
                    wiz: wiz,
                    client_selected: {value:res.data.data.client_id,label:res.data.data.client},
                    iscreate: false,
                    btn_text:"Edit",
                    id: id,
                    order_date: res.data.data.order_date,
                    purchase_no: res.data.data.purchase_no,
                    client: res.data.data.client,
                    cargo: res.data.data.cargo,
                    cement_type: cement_type,
                    cement_rate_b: res.data.data.cement_rate_b,
                    cement_rate_s: res.data.data.cement_rate_s,
                    weight: res.data.data.weight,
                    bank_tt: res.data.data.bank_tt,
                    fs_no: res.data.data.fs_no,
                    expected_amount: res.data.data.expected_amount,
                    agreement_no: res.data.data.agreement_no,
                    urls:res.data.urls,
                    products:res.data.products,
                    t_volume: res.data.data.t_volume,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_show = (event,id) => {
        event.preventDefault();
        this.getorder(id,2)
    }

    wiz_1 = () => {
        let tdata = this.state.datas.map(value => {
            let cls=null;
            if(value.isfound)
                cls="bg-success";
            return (<tr>
                <td style={{verticalAlign:"middle"}}>
                    <a href="" onClick={event =>{
                        this.btn_show(event,value.id)
                    }}>Manage</a>
                </td>
                <td className={cls} style={{verticalAlign:"middle"}}>{value.order_date}</td>
                <td className={cls} style={{verticalAlign:"middle"}}>{value.client}</td>
                <td className={cls} style={{verticalAlign:"middle"}}>{value.cargo}</td>
                <td className={cls} style={{verticalAlign:"middle"}}>{value.cement_rate_s}</td>
                <td className={cls} style={{verticalAlign:"middle"}}>{value.weight}</td>
                <td className={cls} style={{verticalAlign:"middle"}}>{value.bank_tt}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">Action</th>
                            <th className="col-md-1">Date</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-1">Cargo</th>
                            <th className="col-md-2">Sale Rate</th>
                            <th className="col-md-1">Weight</th>
                            <th className="col-md-1">Bank TT</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let imgs=this.state.urls.map(v=>{
            let sty="rotate("+v.deg+"deg)"
            return (<>
                    <div className="col-md-12 text-center my-2 border">
                        <img style={{transform:sty}} src={v.url}/>
                    </div>
                    <div className="col-md-12 text-center my-2 border">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            let urls=this.state.urls.map(v2=>{
                                if(v2.id===v.id){
                                    v2.deg=v2.deg+90
                                    if(v2.deg>270)
                                        v2.deg=0
                                }
                                return v2
                            });
                            this.setState({
                                urls:urls
                            })
                        }}>Image Rotate</a>
                    </div>
                </>
            )
        })
        let tdata=this.state.products.map(v=>{
            return (<tr>
                <td>{v.product_type}</td>
                <td>{v.volume}</td>
                <td>{v.unit_price}</td>
                <td>{v.po_no}</td>
                <td>{v.fs_no}</td>
            </tr> )
        })
        let history=myconfig.redirecttostatus_cement+"?key_word="+this.state.client;
        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">Cement Order</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Client</label> <a href={history} target="_blank"> (History)</a>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.client}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Order Date</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.order_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Cement Type</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.cargo}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Sale Rate</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.cement_rate_s}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Bank TT #</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.bank_tt}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Weight (q)</label>
                                        <input readOnly={true} type="text" className="form-control form-control-sm"
                                               value={this.state.t_volume}/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h4>Product Data</h4>
                                    <table className="table table-bordered table-sm">
                                        <tr>
                                            <th className="col-md-3">Product Type</th>
                                            <th className="col-md-3">Weight (q)</th>
                                            <th className="col-md-2">Unit Price</th>
                                            <th className="col-md-2">PO #</th>
                                            <th className="col-md-2">FS #</th>
                                        </tr>
                                        {tdata}
                                    </table>
                                </div>
                                <div className="col-md-4  pb-3">
                                    <div className="form-group">
                                        <label>Expected Amount</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.expected_amount}/>
                                    </div>
                                </div>
                                <div className="col-md-4  pb-3">
                                    <div className="form-group">
                                        <label>Agreement #</label>
                                        <input readOnly={true}
                                               className="form-control form-control-sm"
                                               value={this.state.agreement_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4  pb-3">
                                </div>
                                {imgs}

                         {/*       <div className="col-md-4 pt-2">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="text"
                                               className="form-control form-control-sm"
                                               name="amount"
                                               onChange={this.inputmgt}
                                               value={this.state.amount}/>
                                    </div>
                                </div>
                                <div className="col-md-4 pt-2">
                                    <div className="form-group">
                                        <label>Invoice #</label>
                                        <input type="text"
                                               className="form-control form-control-sm"
                                               name="invoice_no"
                                               onChange={this.inputmgt}
                                               value={this.state.invoice_no}/>
                                    </div>
                                </div>
                                <div className="col-md-4 pt-2">
                                    <div className="form-group">
                                        <label>Invoice Date</label>
                                        <input type="date"
                                               className="form-control form-control-sm"
                                               name="payment_date"
                                               onChange={this.inputmgt}
                                               value={this.state.payment_date}/>
                                    </div>
                                </div>
                                <div className="col-md-4 pt-2">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select
                                            className="form-control form-control-sm"
                                            name="type"
                                            onChange={this.inputmgt}
                                            value={this.state.type}>
                                            <option value="-1">-- Select Type --</option>
                                            <option value="cash">Cash</option>
                                            <option value="credit">Credit</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-4 pt-2">
                                    <div className="form-group">
                                        <label>Remark</label>
                                        <textarea rows={5} className="form-control form-control-sm"
                                                  name="remark"  onChange={this.inputmgt}>{this.state.remark}
                                        </textarea>
                                    </div>
                                </div>*/}
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btn_accept}> Accept Order</button>
                                    </div>
                                </div>
                                <div className="col-md-4 my-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    btn_accept = (event) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to approve the order? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({
                    is_exe:true,
                })
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.cement_app;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    id: this.state.id,
                    amount: this.state.amount,
                    invoice_no: this.state.invoice_no,
                    type: this.state.type,
                    payment_date: this.state.payment_date,
                    remark: this.state.remark,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:1,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="cement_app"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Open</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cement_app;
