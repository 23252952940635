import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Opex_cost_bank_tt extends Component {

    constructor() {
        super();
        this.state = {
            bank_tt:'',
            letter_no:'',
            total:'0',
            drivers: [],
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    get_letter = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_opex_cost_bank_tt;
        this.setState({
            wiz:0,
            total:'0',
            drivers: [],
            bank_tt: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            letter_no:this.state.letter_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        total:res.data.total,
                        drivers: res.data.drivers,
                        wiz:1,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btn_submit = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.opex_cost_bank_tt;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            letter_no:this.state.letter_no,
            bank_tt:this.state.bank_tt,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz:0,
                        total:'0',
                        drivers: [],
                        bank_tt: '',
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    wiz_1 = () => {
        let count=0
        let tdata=this.state.drivers.map(v=>{
            count=count+1;
            return(
                <tr>
                    <td>{count}</td>
                    <td>{v.driver_name}</td>
                    <td>{v.acct_no}</td>
                    <td>{v.amount}</td>
                </tr>
            )
        })
        return (<>
            <div className="col-md-8"></div>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header bg-success ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Bank TT Information</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Bank TT #</label>
                                    <input type="text" className="form-control form-control-sm" name="bank_tt"
                                           value={this.state.bank_tt} onChange={this.inputmgt}
                                           placeholder="Bank #"/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-primary btn-sm"
                                            onClick={this.btn_submit}> Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-success ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Payment Information</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 px-3 py-1 text-left">
                                <table className="table table-bordered table-striped">
                                    <tr>
                                        <th className="col-md-1">#</th>
                                        <th className="col-md-4">Driver Name</th>
                                        <th className="col-md-4">Account #</th>
                                        <th className="col-md-3">Amount (ETB)</th>
                                    </tr>
                                    {tdata}
                                </table>
                            </div>
                            <div className="col-12 px-3 py-1 text-right">
                                <h6>Total Payment: <strong>{this.state.total} (ETB)</strong></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="opex_cost_bank_tt"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Letter #</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Letter #</label>
                                                        <input type="text" className="form-control form-control-sm" name="letter_no"
                                                               value={this.state.letter_no} onChange={this.inputmgt}
                                                               placeholder="Letter #"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.get_letter()
                                                                }}> Get Letter
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Opex_cost_bank_tt;
