import {Component} from 'react';
import axios from "axios"
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import myconfig from "../../myconfig";
import logimg from "../../img/logo.png";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { StickyContainer, Sticky } from 'react-sticky';
import Select from "react-select";

let source = axios.CancelToken.source();

class D_status extends Component {
    constructor() {
        super();
        this.state = {
            d_type: 1,

            wiz: 4,
            status: [],
            vehicles: [],
            vehicles_fitler: [],
            series: [],
            sum_datas: [],
            datas: [],
            det_datas: [],
            options: {},

            sums: [],
            stoppages: [],
            filter_stoppages: [],

            cus_datas: [],
            cus_filter_datas: [],
            cus_sums: [],

            filter_status: [],
            filter_customers: [],
            filter_cargos: [],
            filter_orgs: [],
            filter_dess: [],
            location_selected: null,
            locations: [],
            aud: 0,
            wfo: 0,
            itlp: 0,
            wtl: 0,
            itop: 0,
            wto: 0,
            hts: 0,

            aud_datas: [],
            wfo_datas: [],
            itlp_datas: [],
            wtl_datas: [],
            itop_datas: [],
            wto_datas: [],
            hts_datas: [],

            series2: [{
                name: "Availability",
                data: [85, 90, 95, 83, 80, 82, 92]
            }],
            options2: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: 'Vehicle availability by day',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: ['19', '20', '21', '22', '23', '24', '25'],
                }
            },


        };
    }

    componentDidMount() {
        //this.fetchdata_dashboard_1()
    }

    fetchdata_dashboard_1 = () => {
        let baseurl = myconfig.pub_dashboard_1;
        let token = new URLSearchParams(this.props.location.search).get("token");
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ntfms_sid"),
            token: token,
        }, {cancelToken: source.token}).then(res => {
            if (!res.data.auth) {
                this.setState({
                    wiz: 1,
                })
            } else if (!res.data.success) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz: 2,
                    status: res.data.status,
                    vehicles: res.data.vehicles,
                    series: res.data.series,
                    sum_datas: res.data.sum_datas,
                    datas: res.data.datas,

                    sums: res.data.sums,
                    stoppages: res.data.stoppages,
                    filter_stoppages: [],


                    options: {
                        chart: {
                            width: 350,
                            type: 'pie',
                        },
                        legend: {
                            position: 'bottom',
                            show: true,
                        },
                        labels: res.data.lables,
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                            }
                        }]
                    },
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata_dashboard_2 = () => {
        this.setState({
            wiz: 3,
            series: [
                {
                    name: "CEMENT",
                    data: [1500, 3200, 2000, 500, 6000, 1000, 4000]
                },
                {
                    name: "DELIVERY",
                    data: [500,3000, 1000, 1000, 4000, 1000, 0]
                }
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#77B6EA', '#545454'],
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'CEMENT VS DELIVERY',
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                xaxis: {
                    categories: ['Aug-2', 'Aug-3', 'Aug-4', 'Aug-5', 'Aug-6', 'Aug-7', 'Aug-8'],
                    title: {
                        text: 'Month'
                    }
                },
                yaxis: {
                    title: {
                        text: 'Weight (q)'
                    },
                    min: 0,
                    max: 6000
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                }
            },
        })
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    more_status = (event, ty) => {
        event.preventDefault();
        let temp = this.state.vehicles;
        if (ty !== "All") {
            temp = this.state.vehicles.filter(v => {
                if (v.status === ty)
                    return v;
            })
        }
        this.setState({
            vehicles_fitler: temp
        })
    }
    more_datas = (event, ty, value) => {
        event.preventDefault();
        let temp = this.state.stoppages.filter(v => {
            if (v.status === ty && v.type === value)
                return v;
        })
        this.setState({
            filter_stoppages: temp
        })
    }

    wiz_0 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Loading...</h5>
            </div>
        )
    }
    wiz_1 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Invalid session token. Please contact your admin.</h5>
            </div>
        )
    }

    wiz_2 = () => {
        let t_data_status_sum = this.state.status.map(v => {
            return (<tr>
                <td>{v.type}</td>
                <td><a href="" onClick={event => this.more_status(event, v.type)}>{v.count}</a></td>
            </tr>)
        })
        let t_data_status_det = this.state.vehicles_fitler.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.status}</td>
            </tr>)
        })
        let t_data_sum_datas_sum = this.state.sums.map(v => {
            return (<tr>
                <td>{v.type}</td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 1)}>{v.type_1}</a></td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 2)}>{v.type_2}</a></td>
                <td><a href="" onClick={event => this.more_datas(event, v.type, 3)}>{v.type_3}</a></td>
            </tr>)
        })
        let t_data_sum_datas_det = this.state.filter_stoppages.map(v => {
            return (<tr>
                <td>{v.plate_no}</td>
                <td>{v.status}</td>
                <td>{v.client}</td>
                <td>{v.st_time}</td>
                <td>{v.dif_time_con}</td>
            </tr>)
        })

        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Vehicles Status Pi Chart </h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <div id="chart">
                                    <Chart options={this.state.options} series={this.state.series} type="pie"
                                           width={350}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Vehicles Status Summary</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Status</th>
                                        <th className="col-md-5">Count</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_status_sum}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Vehicles Status Detail</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <table className="table table-head-fixed">
                                    <thead>
                                    <tr>
                                        <th className="col-md-6">Plate #</th>
                                        <th className="col-md-5">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {t_data_status_det}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">Vehicle Availability</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                                <Chart options={this.state.options2} series={this.state.series2} type="line" height={300} />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">Vehicle Availability Detail</h3>
                            </div>
                            <div className="card-body table-responsive p-0" style={{height: '350px'}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    wiz_3 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-8">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Cement Stock Balance</h3>
                            </div>
                            <div className="card-body table-responsive p-0">
                                <table className="table table-responsive-lg">
                                    <tr>
                                        <th className="col-md-3">
                                            Product Type
                                        </th>
                                        <th className="col-md-3">
                                            Current Stock (q)
                                        </th>
                                        <th className="col-md-3">
                                            Minimum Stock
                                        </th>
                                        <th className="col-md-3">
                                            Status
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>PPC</td>
                                        <td>2000</td>
                                        <td>10000</td>
                                        <td className="bg-danger"></td>
                                    </tr>
                                    <tr>
                                        <td>OPC</td>
                                        <td>30000</td>
                                        <td>10000</td>
                                        <td></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-12">
                        <div className="card card-warning">
                            <div className="card-header">
                                <h3 className="card-title">CEMENT VS DELIVERY DATA SET</h3>
                            </div>
                            <div className="card-body table-responsive p-0">
                                <table className="table table-responsive-lg">
                                    <tr>
                                        <th className="col-md-5">
                                            Type
                                        </th>
                                        <th className="col-md-1">Aug-2</th>
                                        <th className="col-md-1">Aug-3</th>
                                        <th className="col-md-1">Aug-4</th>
                                        <th className="col-md-1">Aug-5</th>
                                        <th className="col-md-1">Aug-6</th>
                                        <th className="col-md-1">Aug-7</th>
                                        <th className="col-md-1">Aug-8</th>
                                    </tr>
                                    <tr>
                                        <td>CEMENT</td>
                                        <td>1500</td>
                                        <td>3200</td>
                                        <td>2000</td>
                                        <td>500</td>
                                        <td>6000</td>
                                        <td>1000</td>
                                        <td>4000</td>
                                    </tr>
                                    <tr>
                                        <td>Delivery</td>
                                        <td>500</td>
                                        <td>3000</td>
                                        <td>1000</td>
                                        <td>1000</td>
                                        <td>4000</td>
                                        <td>1000</td>
                                        <td>0</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card card-warning">
                            <div className="card-body table-responsive p-0"  style={{height: '450px'}}>
                                <div id="chart">
                                    <Chart options={this.state.options} series={this.state.series} type="line" height={450} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    wiz_4 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                <h3 className="card-title">Daily Sales & Marketing</h3>
                            </div>
                            <div className="card-body table-responsive  table-bordered p-0">
                                <div className="row">
                                    <div className="col-md-4 mt-3 mx-3">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input type="date" className="form-control" name="st"
                                                   value={this.state.st} onChange={this.inputmgt}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3 mx-3">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input type="date" className="form-control" name="ed"
                                                   value={this.state.ed} onChange={this.inputmgt}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mx-3">
                                        <div className="form-group">
                                            <label> </label>
                                            <button className="btn btn-outline-primary">Get Data</button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 pb-2">
                                        <table className="table table-responsive-lg">
                                            <tr style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                                <th className="col-md-2" colSpan={8}>Plan</th>
                                            </tr>
                                            <tr style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                                <th className="col-md-3">KPI</th>
                                                <th className="col-md-1">Avg Price</th>
                                                <th className="col-md-1">Plan-ton</th>
                                                <th className="col-md-1">Actual-ton</th>
                                                <th className="col-md-2">%</th>
                                                <th className="col-md-1">Plan-ETB</th>
                                                <th className="col-md-1">Actual-ETB</th>
                                                <th className="col-md-2">%</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-3">Transport Cement</td>
                                                <td className="col-md-1">MT</td>
                                                <td className="col-md-1">3600</td>
                                                <td className="col-md-1">13562</td>
                                                <td className="col-md-2">%</td>
                                                <td className="col-md-1">47,468,456</td>
                                                <td className="col-md-1"></td>
                                                <td className="col-md-2">%</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="col-md-12">

                                        <table className="table table-responsive-lg">
                                            <tr style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                                <th className="col-md-2" colSpan={6}>KPI Data</th>
                                            </tr>
                                            <tr style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                                <th className="col-md-2"></th>
                                                <th className="col-md-2">Biweekly-Transport sales</th>
                                                <th className="col-md-2">Actual Ton</th>
                                                <th className="col-md-2">Actual (ETB)</th>
                                                <th className="col-md-2">%tage</th>
                                                <th className="col-md-2">Remarks</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2" rowSpan={8} style={{verticalAlign:"middle"}}>Transport service</td>
                                                <td className="col-md-2">Daily  Collection Cement transport</td>
                                                <td className="col-md-2">640</td>
                                                <td className="col-md-2">2672000</td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Daily Collection Local Coal transport (BL)</td>
                                                <td className="col-md-2">0</td>
                                                <td className="col-md-2">0</td>
                                                <td className="col-md-2">0%</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Daily Collection  Import Coal transport</td>
                                                <td className="col-md-2">0</td>
                                                <td className="col-md-2">0</td>
                                                <td className="col-md-2">161%</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Daily Collection General cargo transport</td>
                                                <td className="col-md-2">0</td>
                                                <td className="col-md-2">0</td>
                                                <td className="col-md-2">53%</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Total Daily transport Collection</td>
                                                <td className="col-md-2">640</td>
                                                <td className="col-md-2">2672000</td>
                                                <td className="col-md-2">126%</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                                <th className="col-md-2">To-date Transport sales</th>
                                                <th className="col-md-2">Plan</th>
                                                <th className="col-md-2">Actual</th>
                                                <th className="col-md-2">%tage</th>
                                                <th className="col-md-2"></th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">To-date transport sales collection ETB</td>
                                                <td className="col-md-2">49,878,527</td>
                                                <td className="col-md-2">55,480,000</td>
                                                <td className="col-md-2">111.2%</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">To-date transport sales collection ton</td>
                                                <td className="col-md-2">49,878,527</td>
                                                <td className="col-md-2">55,480,000</td>
                                                <td className="col-md-2">111.2%</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                                <th className="col-md-2">Transport Delivery</th>
                                                <th className="col-md-2">Shipment Status Biweekly & Todate</th>
                                                <th className="col-md-2">Ton</th>
                                                <th className="col-md-2">Trucks</th>
                                                <th className="col-md-2">ETB</th>
                                                <th className="col-md-2">Remark</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2" rowSpan={3} style={{verticalAlign:"middle"}}>Transport sales Delivery</td>
                                                <td className="col-md-2">Daily Shipped transport </td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>                                        <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month Todate Shipped/delivered transport</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month Todate Unshipped transport</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>
                                            <tr style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                                <th className="col-md-2"></th>
                                                <th className="col-md-2">Biweekly & todate Cement sales</th>
                                                <th className="col-md-2">Plan</th>
                                                <th className="col-md-2">Actual</th>
                                                <th className="col-md-2">%%tage</th>
                                                <th className="col-md-2">Remarks</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2" rowSpan={4} style={{verticalAlign:"middle"}}>Cement retail/Wholesale sales</td>
                                                <td className="col-md-2">Daily cement sales Collection ETB</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Daily cement sales Collection  ton</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month to-date cement sales Collection ETB</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month to-date cement sales Collection ton</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>

                                            <tr style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                                <th className="col-md-2">Cement Delivery</th>
                                                <th className="col-md-2">Biweekly & todate Shipment Status</th>
                                                <th className="col-md-2">Quintal</th>
                                                <th className="col-md-2">Trucks</th>
                                                <th className="col-md-2">Birr</th>
                                                <th className="col-md-2">Remarks</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2" rowSpan={3} style={{verticalAlign:"middle"}}>Cement sales Delivery</td>
                                                <td className="col-md-2">Daily Shipped Cement</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month to-date Shipped/deliverd cement</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month todate Unshipped cement  (Unearned)</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>

                                            <tr style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                                <th className="col-md-2">Subcontracting</th>
                                                <th className="col-md-2">Biweekly & todate Subcontrating status</th>
                                                <th className="col-md-2">Quintal</th>
                                                <th className="col-md-2">Trucks</th>
                                                <th className="col-md-2">Birr</th>
                                                <th className="col-md-2">Remarks</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2" rowSpan={3} style={{verticalAlign:"middle"}}>Subcontructing</td>
                                                <td className="col-md-2">Daily Cement-Subcontract</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2"></td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month todate Cement-Subcontract</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2">Month todate Import coal-Subcontract</td>
                                                <td className="col-md-2">720</td>
                                                <td className="col-md-2">18</td>
                                                <td className="col-md-2">2,520,000</td>
                                                <td className="col-md-2">97%</td>
                                            </tr>

                                            <tr style={{backgroundColor:"#FF0000",color:"#FFFFFF"}}>
                                                <th className="col-md-2" colSpan={6}>Challenges / opportunities encountered</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2" rowSpan={2} style={{verticalAlign:"middle"}}></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2">Lack of manpower</td>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2">Workload, (exessive working hours)</td>
                                            </tr>

                                            <tr style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                                <th className="col-md-2" colSpan={6}>Strategic Issues</th>
                                            </tr>
                                            <tr>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2"></td>
                                                <td className="col-md-2">Ensure maximum utilization of market opportunities in Sister companies, (Cement, Local coal.) that mobilizes 100% of available trucks</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    btnexport_report_1 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Report 1");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report_1'));
        wb.Sheets["Report 1"] = wb2;
        XLSX.writeFile(wb, "report 1.xlsx");
    }
    btnexport_report_2 = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("Report 2");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report_2'));
        wb.Sheets["Report 2"] = wb2;
        XLSX.writeFile(wb, "report 2.xlsx");
    }
    change_dashboard_report = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
            wiz:0,
        },()=>{
            if(value==1)
                this.fetchdata_dashboard_1();
            else if(value==2)
                this.fetchdata_dashboard_2()
        })
    }
    render() {
        let dis=null;
        if(this.state.wiz===0)
            dis=this.wiz_0();
        else if(this.state.wiz===1)
            dis=this.wiz_1();
        else if(this.state.wiz===2)
            dis=this.wiz_2();
        else if(this.state.wiz===3)
            dis=this.wiz_3();
        else if(this.state.wiz===4)
            dis=this.wiz_4();

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-2" style={{width: "100%"}}>
                            <div className="card">
                                <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                    <h2 className="card-title">Dashboard</h2>
                                    <div className="card-tools">
                                        <div className="input-group input-group-sm" style={{width: '150px'}}>
                                            <select name="d_type"
                                                    className="form-control float-right"
                                                    value={this.state.d_type} onChange={this.change_dashboard_report}>
                                                <option value={1}>Vehicle Status</option>
                                                <option value={2}>Operation Status</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default D_status;
