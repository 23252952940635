import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";


let source = axios.CancelToken.source();

class Follow_up_wizard extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            rows_1: [],
            rows_2: [],
            count: 0,
            filter: '',

            wiz: 0,
            msg_status: '',
            isexe: false,

            ids:[],
            found_datas:[],
            results:[],
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    fetchdata0 = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_ids_follow_up_wizard;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            filter: this.state.filter,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    ids: res.data.ids,
                }, () => {
                    this.fetchdata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_vehicle_follow_up_wizard;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            ids: this.state.ids,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    ids:[],
                    wiz:1,
                }, () => {
                    this.fetchdata2()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnexport_report = (event) => {
        event.preventDefault();
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push("report");
        let wb2 = XLSX.utils.table_to_sheet(document.getElementById('report'));
        wb.Sheets["report"] = wb2;
        XLSX.writeFile(wb, "report.xlsx");
    }
    fetchdata2 = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.execute_follow_up_wizard;
        let msg_status = this.state.msg_status
        this.setState({
            isexe: true,
        })
        msg_status = msg_status + "Total of " + this.state.datas.length + " data found. \n"
        this.setState({
            msg_status: msg_status,
        })
        let len = this.state.datas.length
        for (let i = 0; i < len; i++) {
            console.log(this.state.datas[i])
            msg_status = msg_status + "Executing " + (i + 1) + " of " + len + "\n"
            this.setState({
                msg_status: msg_status,
            })
            await axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                eid: this.state.eid,
                i: this.state.count,
                st: this.state.datas[i].st,
                ed: this.state.datas[i].ed,
                assign_own_id: this.state.datas[i].assign_own_id,
                status: this.state.datas[i].status,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    let rows_1 = this.state.rows_1
                    let rows_2 = this.state.rows_2

                    res.data.rows_1.map(v => {
                        rows_1.push(
                            {
                                id: v.id
                            }
                        )
                    })
                    res.data.rows_2.map(v => {
                        rows_2.push(
                            {
                                id: v.id,
                                type: v.type,
                                data: v.data,
                            }
                        )
                    })
                    msg_status = msg_status + "Execute finished \n"
                    let ids = this.state.ids;
                    let found_datas = this.state.found_datas;
                    if (res.data.isfound) {
                        ids.push(res.data.vehicle_id);
                        found_datas.push(this.state.datas[i].assign_own_id)
                    }
                    this.setState({
                        rows_1: rows_1,
                        rows_2: rows_2,
                        msg_status: msg_status,
                        eid: res.data.eid,
                        count: res.data.i,
                        ids: ids,
                        found_datas: found_datas,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
        if (this.state.ids.length > 0) {
            this.setState({
                isexe: false,
                datas: [],
                rows_1: [],
                rows_2: [],
                count: 0,
                filter: '',
                wiz: 0,
                msg_status: '',
            },()=>{
                this.fetchdata()
            })
        } else
            this.setState({
                isexe: false,
            },()=>{
                this.fetchdata3()
            })
    }
    fetchdata3 = async () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_result_follow_up_wizard;
        await axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            found_datas: this.state.found_datas,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    results: res.data.results,
                    wiz:2,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    wiz_0 = () => {
        return (<>
            <div className="col-md-4  px-3 py-1">
                <div className="form-group">
                    <label>Filter</label>
                    <textarea rows={7} className="form-control form-control-sm" name="filter"
                              value={this.state.filter} onChange={this.inputmgt}></textarea>
                </div>
            </div>
            <div className="col-md-8">
            </div>
            <div className="col-md-4  px-3 py-1">
                <div className="form-group">
                    <button className="btn btn-block btn-outline-primary" onClick={event => {
                        event.preventDefault();
                        this.fetchdata0()
                    }}>Next</button>
                </div>
            </div>
            <div className="col-md-8">
            </div>
        </>)
    }
    wiz_1 = () => {
        let tdata = this.state.rows_1.map(v => {
            let temps = this.state.rows_2.filter(v2 => {
                if (v2.id === v.id)
                    return v2;
            })
            let rows = temps.map(v3 => {
                if (v3.type == 1)
                    return (<th>{v3.data}</th>)
                else {
                    return (<td>{v3.data}</td>)
                }
            })
            return (<tr>
                {rows}
            </tr>)
        })
        return (<>
            <div className="col-md-4  px-3 py-1">
                <div className="form-group">
                    <label>Information Status</label>
                    <textarea rows={7} className="form-control form-control-sm" name="msg_status"
                              value={this.state.msg_status} onChange={this.inputmgt}></textarea>
                </div>
            </div>
            <div className="col-md-8">
            </div>
            <div className="col-12 px-3 py-1 text-left"><a href="#"
                                                           onClick={this.btnexport_report}>Download .xlsx</a></div>
            <div className="col-12 px-3 py-1">
                <div className="table" id="section_to_print">
                    <table id="report" className="table table-sm table-bordered table-responsive">
                        {tdata}
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let count=0;

        let tdata = this.state.results.map(value => {
            count=count+1;
            let url=myconfig.redirecttofollow_up_movement+"?vehicle_id="+value.vehicle_id+"&st="+value.st+"&ed="+value.ed
            return (<tr>
                <td>
                    <a href={url} target="_blank">More</a>
                </td>
                <td>{value.plate_no}</td>
                <td>{value.client}</td>
                <td>{value.org}</td>
                <td>{value.des}</td>
                <td>{value.allocation}</td>
                <td>{value.arivalatloading}</td>
                <td>{value.loading}</td>
                <td>{value.departure}</td>
                <td>{value.arivalatunloading}</td>
                <td>{value.unloading}</td>
                <td>{value.finished}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-sm table-responsive">
                        <thead>
                        <tr>
                            <th>Action</th>
                            <th>Plate #</th>
                            <th>Client</th>
                            <th>Origin</th>
                            <th>Destination</th>
                            <th>Allocation</th>
                            <th>Arrival @ Loading</th>
                            <th>Loading</th>
                            <th>Departure</th>
                            <th>Arrival @ Unloading</th>
                            <th>Unloading</th>
                            <th>Finished</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 0)
            dis = this.wiz_0()
        else if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()

        return (
            <>
                <Nav type="follow_up_wizard"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Follow-up Wizard</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {dis}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Follow_up_wizard;
