import {Component} from 'react';
import axios from "axios"
import Swal from "sweetalert2";
import Chart from "react-apexcharts";
import myconfig from "../../myconfig";
import logimg from "../../img/logo.png";
import $ from 'jquery';
import * as XLSX from "xlsx";
import { StickyContainer, Sticky } from 'react-sticky';
import Select from "react-select";

let source = axios.CancelToken.source();

class D_operation_2 extends Component {
    constructor() {
        super();
        this.state = {
            wiz: 2,
            st: new Date().toISOString().slice(0, 10),
            ed: new Date().toISOString().slice(0, 10),
            activitys:[],
            services:[],
            is_dis:false,
        };
    }

    componentDidMount() {
        //this.fetchdata()
    }

    fetchdata_dashboard = () => {
        let baseurl = myconfig.d_operation_2;
        let redirecttologin = myconfig.redirecttologin;
        /* let token = new URLSearchParams(this.props.location.search).get("token");*/
        this.setState({
            wiz:0,
            is_dis:false,
        },()=>{
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                st:this.state.st,
                ed:this.state.ed,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    }).then(v=>{
                        this.setState({
                            wiz: 2,
                            is_dis:false,
                        })
                    })
                } else {
                    this.setState({
                        wiz: 2,
                        activitys: res.data.activitys,
                        services: res.data.services,
                        is_dis:true,
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        })

    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    wiz_0 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Loading...</h5>
            </div>
        )
    }
    wiz_1 = () => {
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <h5>Invalid session token. Please contact your admin.</h5>
            </div>
        )
    }

    wiz_2 = () => {
        let dis=null;
        let t_data_activitys=this.state.activitys.map(value=>{
            return (<tr>
                <td>{value.activity}</td>
                <td>{value.particulars}</td>
                <td>{value.remark}</td>
            </tr>)
        })

        let t_data_services=this.state.services.map(value=>{
            return (<tr>
                <td>{value.service}</td>
                <td>{value.truck}</td>
                <td>{value.ton}</td>
                <td>{value.tkm}</td>
                <td>{value.remark}</td>
            </tr>)
        })
        if(this.state.is_dis) {
            dis = <>
                <div className="col-md-12 pb-2">
                    <table className="table table-responsive-lg">
                        <tr style={{backgroundColor: "#00008B", color: "#FFFFFF"}}>
                            <th className="col-md-4">Activity/Issue 1</th>
                            <th className="col-md-4">Particulars</th>
                            <th className="col-md-4">Remark</th>
                        </tr>
                        {t_data_activitys}
                    </table>
                    <table className="table table-responsive-lg">
                        <tr style={{backgroundColor: "#FF0000", color: "#FFFFFF"}}>
                            <th className="col-md-4">Service Description</th>
                            <th className="col-md-2">No Trucks</th>
                            <th className="col-md-2">In Ton</th>
                            <th className="col-md-2">TKM</th>
                            <th className="col-md-2">Remark</th>
                        </tr>
                        {t_data_services}
                    </table>
                </div>
            </>
        }
        return (
            <div className="card-body" style={{minHeight: "100vh"}}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                <h3 className="card-title">Daily Operation-2</h3>
                            </div>
                            <div className="card-body table-responsive  table-bordered p-0">
                                <div className="row">
                                    <div className="col-md-4 mt-3 mx-3">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input type="date" className="form-control" name="st"
                                                   value={this.state.st} onChange={this.inputmgt}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3 mx-3">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input type="date" className="form-control" name="ed"
                                                   value={this.state.ed} onChange={this.inputmgt}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mx-3">
                                        <div className="form-group">
                                            <label> </label>
                                            <button className="btn btn-outline-primary" onClick={event => {
                                                event.preventDefault();
                                                this.fetchdata_dashboard()
                                            }}>Get Data</button>
                                        </div>
                                    </div>
                                    {dis}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let dis=null;
        if(this.state.wiz===0)
            dis=this.wiz_0();
        else if(this.state.wiz===1)
            dis=this.wiz_1();
        else if(this.state.wiz===2)
            dis=this.wiz_2();

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-12 m-2" style={{width: "100%"}}>
                            <div className="card">
                                <div className="card-header" style={{backgroundColor:"#00008B",color:"#FFFFFF"}}>
                                    <h2 className="card-title">Dashboard</h2>
                                    <div className="card-tools">
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default D_operation_2;
