import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import Order_loading_adv_req from "./Order_loading_adv_req";
import style2 from "../../style2.css"
import nt from "../../img/ntlog.png";


let source = axios.CancelToken.source();

class Cement_delivery_report extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            wiz: 1,
            dt: new Date().toISOString().slice(0, 10),
        }
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_delivery_report;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            dt:this.state.dt
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: "Data fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        datas: res.data.datas,
                        dt: res.data.dt,
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    wiz_1 = () => {
        let total=0;
        let tdata = this.state.datas.map(value => {
            total=total+(value.active_weight*1);
            return (<tr>
                <td>{value.client}</td>
                <td>{value.agreement_no}</td>
                <td>{value.po_no}</td>
                <td>{value.so_no}</td>
                <td>{value.active_weight}</td>
                <td>{value.active_weight_truck}</td>
            </tr>)
        })
        let total_truck=total/400;
        return (<>
            <div className="card-body">
                <div className="row" id="section_to_print_4">
                    <div className="col-md-12 no-print">
                        <a href="" onClick={event => {
                            event.preventDefault()
                            window.print()
                        }}>Print</a>
                    </div>
                    <div className="col-md-3 pb-5 my-3">
                        <img className="img w-50" src={nt}></img>
                    </div>
                    <div className="col-md-9">
                    </div>
                    <div className="col-md-12 text-center">
                        <h5>NATIONAL TRANSPORT PLC</h5>
                    </div>
                    <div className="col-md-12 text-center">
                        <h6>Cement allocation</h6>
                    </div>
                    <div className="col-md-12 text-right">
                        <h6>Date:- {this.state.dt}</h6><br/>
                    </div>
                    <div className="col-md-12">
                        <table id="report" className="table table-sm table-bordered">
                            <thead>
                            <tr>
                                <th className="col-md-2">Client</th>
                                <th className="col-md-2">Agreement #</th>
                                <th className="col-md-2">PO #</th>
                                <th className="col-md-2">SO #</th>
                                <th className="col-md-2">Weight (q)</th>
                                <th className="col-md-2">Weight (#)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {tdata}
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Total</th>
                                <th>{total}</th>
                                <th>{total_truck}</th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="cement_delivery_report"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Allocated Information</h3>

                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Allocated Date</label>
                                                        <input type="date" className="form-control form-control-sm" name="dt"
                                                               value={this.state.dt} onChange={this.inputmgt}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.fetchdata()
                                                                }}> Get Allocation
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle no-print"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Loading Advice Print</h3>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cement_delivery_report;
