import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Os_trailer extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filtered: [],

            txtsearch: '',



            wiz: 1,
            iscreate: false,
            id:-1,
            trailer_no:'',
            chassis_no:'',

            sub_contractor_companys: [],
            sub_contractor_company_selected: null,
        }
    }
    handelchange_sub_contractor_company = (sub_contractor_company_selected) => {
        this.setState({
            sub_contractor_company_selected:sub_contractor_company_selected,
        })
    }

    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_os_trailer;
        this.setState({
            datas: [],
            filtered: [],
            txtsearch:'',
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    datas: res.data.datas,
                    filtered: res.data.datas,
                    sub_contractor_companys: res.data.sub_contractor_companys,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.trailer_no.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id:-1,
            iscreate: true,
            trailer_no:'',
            chassis_no:'',
        })
    }
    btnedit = (event,id) => {
        event.preventDefault();
        let temp=this.state.filtered.filter(v=>{
            if(v.id===id)
                return v;
        })
        if(temp.length>0){
            this.setState({
                wiz: 2,
                iscreate: false,
                id:id,
                sub_contractor_company_selected:{value:temp[0].sub_contractor_company_id,label:temp[0].company_name},
                trailer_no: temp[0].trailer_no,
                chassis_no: temp[0].chassis_no,
            })
        }
        else
        {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }
    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id:-1,
            iscreate: true,
            trailer_no:'',
            chassis_no:'',
        })
    }
    btncreate = (event) => {
        if (this.state.sub_contractor_company_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor company.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            event.preventDefault();
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.create_os_trailer;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                trailer_no: this.state.trailer_no,
                chassis_no: this.state.chassis_no,
                sub_contractor_company_id: this.state.sub_contractor_company_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    btnupdate = (event) => {
        event.preventDefault();
        if (this.state.sub_contractor_company_selected === null) {
            Swal.fire({
                title: 'Error!',
                text: "Select subcontractor company.",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        } else {
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.edit_os_trailer;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                company_name: this.state.company_name,
                id: this.state.id,
                trailer_no: this.state.trailer_no,
                chassis_no: this.state.chassis_no,
                sub_contractor_company_id: this.state.sub_contractor_company_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                        }, () => {
                            this.fetchdata()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filtered.map(value => {
            count=count+1;
            return (<tr>
                <td>{count}</td>
                <td>
                    <a href="" onClick={event => this.btnedit(event,value.id)}><i className="fas fa-edit px-1"></i></a>
                    <a href=""><i className="fas fa-trash px-1 text-danger"></i></a>
                </td>
                <td>{value.company_name}</td>
                <td>{value.trailer_no}</td>
                <td>{value.chassis_no}</td>
            </tr>)
        })
        return (<>
            <div className="card-header">
                <div className="card-tools">
                    <div className="input-group input-group-sm" style={{width: '150px'}}><a
                        href="" className="btn btn-xs btn-outline-success btn-block" onClick={this.btnnew}>New
                        Trailer</a></div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-2">Action</th>
                            <th className="col-md-3">Company Name</th>
                            <th className="col-md-3">Trailer #</th>
                            <th className="col-md-3">Chassis #</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let header_1 = "Add Trailer"
        let btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btncreate}> Create </button>
        if (!this.state.iscreate) {
            header_1 = "Edit Trailer"
            btn_1 = <button className="btn btn-block btn-outline-success btn-sm" onClick={this.btnupdate}> Edit </button>
        }

        return (<>
            <div className="card-body">
                <div className="col-md-12 px-5 py-3">
                    <div className="card">
                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                            <h3 className="card-title">{header_1}</h3>
                            <div className="card-tools">
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Subcontractor Company</label>
                                        <Select
                                            value={this.state.sub_contractor_company_selected}
                                            onChange={this.handelchange_sub_contractor_company}
                                            options={this.state.sub_contractor_companys}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8"></div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Trailer #</label>
                                        <input type="text" className="form-control form-control-sm" name="trailer_no"
                                               value={this.state.trailer_no} onChange={this.inputmgt}
                                               placeholder="Trailer #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Chassis #</label>
                                        <input type="text" className="form-control form-control-sm" name="chassis_no"
                                               value={this.state.chassis_no} onChange={this.inputmgt}
                                               placeholder="Chassis #"/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        {btn_1}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <button className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={this.btncancel}> Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="os_trailer"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Outsource Trailer</h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Os_trailer;
