import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import Select from "react-select";


let source = axios.CancelToken.source();

class Op_trip_extend_req extends Component {

    constructor() {
        super();
        this.state = {
            fright_no: '',
            data: {},
            is_exe:false,
            btn_text:"Request",


            datas: [],
            filtered: [],

            txtsearch: '',


            wiz: 0,
            iscreate: false,
            id: -1,
            coc: '',

            stations: [],
            station_selected: null,

            is_dis: false,
            txt_rate: '',
        }
    }

    handelstation = (station_selected) => {
        this.setState({
            station_selected: station_selected,
        })
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    inputmgt_cbo = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let is_dis = false
        if (value == "2")
            is_dis = true;
        this.setState({
            [name]: value,
            is_dis: is_dis,
        })
    }

    get_fright = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_fright_for_op_trip_extend_req;
        this.setState({
            wiz: 0,
            data: {},
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            fright_no: this.state.fright_no,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                        data: res.data.data,
                        stations: res.data.stations,
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    btnnew = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 2,
            id: -1,
            iscreate: true,
            fuel: '',
            pre_diem: '',
            parking: '',
            other: '',
            revenue: '',
            millage: '',
            extended_loc: '',
            remark: '',
        })
    }
    btn_req = (event) => {
        event.preventDefault();
        if(!this.state.is_exe) {
            this.setState({
                is_exe:true,
                btn_text:"Working...",
            })
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.op_trip_extend_req;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                id: this.state.data.id,
                fuel: this.state.fuel,
                pre_diem: this.state.pre_diem,
                parking: this.state.parking,
                other: this.state.other,
                revenue: this.state.revenue,
                millage: this.state.millage,
                extended_loc: this.state.extended_loc,
                remark: this.state.remark,
                fule_rate_id: this.state.station_selected == null ? null : this.state.station_selected.value,
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                    this.setState({
                        is_exe:false,
                        btn_text:"Request",
                    })
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.msg,
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    }).then(v => {
                        this.setState({
                            wiz: 1,
                            is_exe:false,
                            btn_text:"Request",
                        }, () => {
                            this.get_fright()
                        })
                    })
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }

    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.datas;
        if (value !== "") {
            temp = temp.filter(v => {
                if (
                    v.coc.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }


    btnedit = (event, id) => {
        event.preventDefault();
        let temp = this.state.filtered.filter(v => {
            if (v.id === id)
                return v;
        })
        if (temp.length > 0) {
            this.setState({
                wiz: 2,
                iscreate: false,
                id: id,
                coc: temp[0].coc,
            })
        } else {
            Swal.fire({
                title: 'Error!',
                text: "No data is found for given client",
                icon: 'error',
                confirmButtonText: 'Okay'
            })
        }

    }

    btncancel = (event) => {
        event.preventDefault();
        this.setState({
            wiz: 1,
            id: -1,
            iscreate: true,
            coc: '',
        })
    }

    btnupdate = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.edit_coc;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            company_name: this.state.company_name,
            id: this.state.id,
            coc: this.state.coc,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    wiz_1 = () => {
        let tdata = this.state.data.assign_own_trip_extenders.map(value => {
            let status = "bg-yellow"
            if (value.status === "EXTENDER_APPROVED")
                status = "bg-success";
            else if (value.status === "EXTENDER_REJECTED")
                status = "bg-danger";
            return (
                <tr className={status}>
                    <td>{value.fuel}</td>
                    <td>{value.station}</td>
                    <td>{value.pre_diem}</td>
                    <td>{value.parking}</td>
                    <td>{value.other}</td>
                    <td>{value.revenue}</td>
                    <td>{value.extended_loc}</td>
                    <td>{value.millage}</td>
                    <td>{value.remark}</td>
                    <td>{value.req_by}</td>
                    <td>{value.app_by}</td>
                    <td>{value.rej_by}</td>
                    <td>{value.status}</td>
                </tr>
            )
        })
        let lst = null
        if (this.state.data.fuels != null)
            lst = this.state.data.fuels.map(value => {
                return <li>{value.station} ({value.fuel})</li>
            })
        return (<>
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-success ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Operation Trip Extender REQ</h3>
                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{width: '150px'}}>
                                <a
                                    href="" className="btn btn-xs btn-primary btn-block" onClick={this.btnnew}>REQ
                                    TRIP EXTENDER</a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Truck #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.plate_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Driver Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.driver_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Trailer No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.trailer_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fright #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.fright_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Client</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Order #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.order_no}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cargo</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.cargo_name}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Distance</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.distance}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Volume</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.volume}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel (L)</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.total_fuel}/>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Start @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.start_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>ORG</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.org}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>DES</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.des}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>End @</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.end_loc}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Fuel</label>
                                    <ul>
                                        {lst}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12">
                                <table id="report" className="table table-head-fixed table-responsive">
                                    <thead>
                                    <tr>
                                        <th>Fuel</th>
                                        <th>Station</th>
                                        <th>Pre-diem</th>
                                        <th>Handling Fee</th>
                                        <th>Other</th>
                                        <th>Revenue</th>
                                        <th>Extended Location</th>
                                        <th>Millage</th>
                                        <th>REMARK</th>
                                        <th>REQ BY</th>
                                        <th>APP BY</th>
                                        <th>REJ BY</th>
                                        <th>STATUS</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }

    wiz_2 = () => {
        let lst = null
        if (this.state.data.fuels != null)
            lst = this.state.data.fuels.map(value => {
                return <li>{value.station} ({value.fuel})</li>
            })
        return (<>
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-success ui-sortable-handle"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">REQUEST FORM</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Truck #</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.plate_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Driver Name</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.driver_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Trailer No</label>
                                    <input disabled={true} type="text" className="form-control form-control-sm"
                                           value={this.state.data.trailer_no}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fuel</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.fuel} name="fuel" onChange={this.inputmgt}
                                    placeholder="Fuel"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Station</label>
                                    <Select
                                        value={this.state.station_selected}
                                        onChange={this.handelstation}
                                        options={this.state.stations}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Pre-diem</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.pre_diem} name="pre_diem"
                                           onChange={this.inputmgt}
                                           placeholder="Pre-diem"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Handling</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.parking} name="parking"
                                           onChange={this.inputmgt}
                                           placeholder="Handling"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Other</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.other} name="other" onChange={this.inputmgt}
                                           placeholder="Other"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Revenue</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.revenue} name="revenue" onChange={this.inputmgt}
                                           placeholder="Revenue"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Extended Location</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.extended_loc} name="extended_loc" onChange={this.inputmgt}
                                           placeholder="Extended Location"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Millage</label>
                                    <input type="text" className="form-control form-control-sm"
                                           value={this.state.millage} name="millage" onChange={this.inputmgt}
                                           placeholder="Millage"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Remark</label>
                                    <textarea rows={5} className="form-control form-control-sm"
                                              value={this.state.remark} name="remark"
                                              onChange={this.inputmgt}></textarea>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Fuel</label>
                                    <ul>
                                        {lst}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-primary btn-sm"
                                            onClick={this.btn_req}> {this.state.btn_text}
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-danger btn-sm"
                                            onClick={event => {
                                                event.preventDefault();
                                                this.setState({
                                                    wiz: 1,
                                                })
                                            }}> CANCEL
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>)
    }

    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        return (
            <>
                <Nav type="op_trip_extend_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Fright Data</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Fright #</label>
                                                        <input type="text" className="form-control form-control-sm"
                                                               name="fright_no"
                                                               value={this.state.fright_no} onChange={this.inputmgt}
                                                               placeholder="Fright #"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <button className="btn btn-block btn-outline-primary btn-sm"
                                                                onClick={event => {
                                                                    event.preventDefault();
                                                                    this.get_fright()
                                                                }}> Get Fright
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Op_trip_extend_req;
