import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";
import Order_loading_adv_req from "./Order_loading_adv_req";
import nt from "../../img/ntlog.png";


let source = axios.CancelToken.source();

class Cement_delivery_cancel_req extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            cancled_datas: [],
            imgs: [],
            urls: [],
            wiz: 1,
            no_img: 1,
            dt: new Date().toISOString().slice(0, 10),
            is_create:true,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }
    onfilechange = (event,id) => {
        event.preventDefault()
        if(event.target.files[0]!==undefined){
            let isfound=false;
            let imgs = this.state.imgs.filter(v=>{
                if(v.name==="img_" + id){
                    isfound=true;
                    v.img=event.target.files[0]
                }
                return v;
            })
            if(!isfound)
                imgs.push({
                    name: "img_" + id,
                    img: event.target.files[0],
                })
            this.setState({
                imgs: imgs,
            });
        }

    }
    fetchdata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_cement_delivery_for_cement_delivery_cancel_req;
        this.setState({
            datas: [],
        })
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            dt:this.state.dt
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: "Data fetched successfully",
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v=>{
                    this.setState({
                        datas: res.data.datas,
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    manage = (event,value) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_cement_delivery_cancel_for_cement_delivery_cancel_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            cement_delivery_id:value.cement_delivery_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    cancled_datas:res.data.datas,
                    cement_no:"Cement_"+value.id,
                    cement_delivery_id:value.cement_delivery_id,
                    client_name:value.label,
                    total_weight:value.total_weight,
                    delivered_volume:value.delivered_volume,
                    remaining:value.remaining,
                    weight:value.active_weight,
                    fs_no:value.fs_no,
                    po_no:value.po_no,
                    wiz:2,
                    is_creatable:res.data.is_creatable,
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    show = (event,value) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_delivery_cancel_req_imgs;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            cement_delivery_cancel_id:value.id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    wiz:4,
                    cement_delivery_cancel_id:value.cement_delivery_cancel_id,
                    cancel_weight:value.volume,
                    urls:res.data.urls,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    edit = (event,value) => {
        event.preventDefault();
        this.setState({
            wiz:3,
            cement_delivery_cancel_id:value.cement_delivery_cancel_id,
            cancel_weight:value.volume,
            is_create:false,
        })
      /*  let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_cement_delivery_cancel_for_cement_delivery_cancel_req;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            cement_delivery_id:value.cement_delivery_id,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {


            }
        }).catch((e) => {
            console.log(e.message);
        });*/
    }
    btn_delete = (event,value) => {
        event.preventDefault();
        Swal.fire({
            title: 'Are you sure you want to remove this order? You can not undone the operation',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                let redirecttologin = myconfig.redirecttologin;
                let baseurl = myconfig.cement_delivery_cancel_req_delete;
                axios.post(baseurl, {
                    sid: window.localStorage.getItem("logmgtsys_sid"),
                    cement_delivery_cancel_id:value.id,
                }, {cancelToken: source.token}).then(res => {
                    console.log(res.data)
                    if (res.data.auth == false) {
                        window.localStorage.setItem("logmgtsys_sid", null);
                        window.localStorage.setItem("logmgtsys_username", null);
                        window.localStorage.setItem("logmgtsys_name", null);
                        var win = window.open(redirecttologin, "_self");
                    } else if (res.data.success == false) {
                        Swal.fire({
                            title: 'Error!',
                            text: res.data.msg,
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                        this.setState({
                            is_exe:false,
                        })
                    } else {
                        Swal.fire({
                            title: 'Success!',
                            text: res.data.msg,
                            icon: 'success',
                            confirmButtonText: 'Okay'
                        }).then(v => {
                            this.setState({
                                wiz:1,
                                is_exe:false,
                            },()=>{
                                this.fetchdata()
                            })
                        })
                    }
                }).catch((e) => {
                    console.log(e.message);
                });
            }
        })
    }
    cancel_request = (event) => {
        event.preventDefault();
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_delivery_cancel_req;
        const formData = new FormData();
        formData.append(
            "sid", window.localStorage.getItem("logmgtsys_sid")
        );
        formData.append(
            "cement_delivery_id", this.state.cement_delivery_id
        );
        formData.append(
            "cancel_weight", this.state.cancel_weight
        );
        let len = this.state.imgs.length
        formData.append(
            "img_length",
            len,
        );
        for (let i = 1; i <= len; i++) {
            formData.append(
                "img_" + i.toString(),
                this.state.imgs[i - 1].img,
            );
        }
        axios.post(baseurl, formData, {cancelToken: source.token}).then(res => {
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
                this.setState({
                    is_exe: false,
                    btn_text: "Edit",
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                         this.setState({
                             wiz:1,
                         }, () => {
                             this.fetchdata()
                         })
                     })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    update_request = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.cement_delivery_cancel_req_edit;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("logmgtsys_sid"),
            cement_delivery_cancel_id:this.state.cement_delivery_cancel_id,
            cancel_weight:this.state.cancel_weight,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("logmgtsys_sid", null);
                window.localStorage.setItem("logmgtsys_username", null);
                window.localStorage.setItem("logmgtsys_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(v => {
                    this.setState({
                        wiz: 1,
                    }, () => {
                        this.fetchdata()
                    })
                })

            }
        }).catch((e) => {
            console.log(e.message);
        });
    }
    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }

    wiz_1 = () => {
        let total=0;
        let tdata = this.state.datas.map(value => {
            total=total+(value.active_weight*1);
            return (<tr>
                <td><a href="" onClick={event => {this.manage(event,value)}}>Manage</a></td>
                <td>{value.client}</td>
                <td>Cement_{value.id}</td>
                <td>{value.po_no}</td>
                <td>{value.fs_no}</td>
                <td>{value.active_weight}</td>
            </tr>)
        })
        return (<>
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                        <h3 className="card-title">Allocated Information</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Allocated Date</label>
                                    <input type="date" className="form-control form-control-sm" name="dt"
                                           value={this.state.dt} onChange={this.inputmgt}/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-primary btn-sm"
                                            onClick={event => {
                                                event.preventDefault();
                                                this.fetchdata()
                                            }}> Get Allocation
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle no-print"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Cement Allocation Cancel</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <table id="report" className="table table-sm table-bordered">
                                    <thead>
                                    <tr>
                                        <th className="col-md-2">Action</th>
                                        <th className="col-md-2">Client</th>
                                        <th className="col-md-2">Cement #</th>
                                        <th className="col-md-2">PO #</th>
                                        <th className="col-md-2">FS #</th>
                                        <th className="col-md-2">Weight (q)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {tdata}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_2 = () => {
        let tdata=this.state.cancled_datas.map(v=>{
            return(<tr>
                <td style={{verticalAlign:"middle"}}>
                    <a href="" onClick={event => this.show(event,v)}>Show</a><hr/>
                    <a href="" onClick={event => this.edit(event,v)}>Edit</a><hr/>
                    <a href="" onClick={event => this.btn_delete(event,v)}>Remove</a><hr/>
                </td>
                <td style={{verticalAlign:"middle"}}>{v.volume}</td>
                <td style={{verticalAlign:"middle"}}>{v.status}</td>
            </tr>)
        })
        let is_creatable=null;
        if(this.state.is_creatable){
            is_creatable=<>
                <div className="col-md-3">
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            wiz:3,
                            is_create:true,
                        })
                    }}> Cancel </a><br/><br/>
                </div>
                <div className="col-md-9">
                </div>
            </>
        }
        return (<>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle no-print"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Cement Allocation Cancel</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cement #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.cement_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>PO #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.po_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>FS #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fs_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Weight (q)</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.weight}/>
                                </div>
                            </div>
                            {is_creatable}
                            <div className="col-md-12">
                                <h6>Previous History</h6>
                            </div>
                            <div className="col-md-12">
                                <table className="table table-bordered">
                                    <tr>
                                        <th>Action</th>
                                        <th>Cancel Weight (q)</th>
                                        <th>Status</th>
                                    </tr>
                                    {tdata}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-danger float-right" onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 1,
                            })
                        }}>
                            <i
                                className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_3 = () => {
        let files=null;
        let btn= <button className="btn btn-block btn-outline-success btn-sm" onClick={this.update_request}> Update </button>
        if(this.state.is_create){
            let no_img = [];
            for (let i = 1; i <= this.state.no_img; i++) {
                no_img.push(i)
            }
            let t = no_img.map(v => {
                return (
                    <div className="col-md-12">
                        <input className="m-2" type="file" onChange={event => {
                            this.onfilechange(event, v)
                        }}/>
                    </div>
                )
            });
            files =<>
                <div className="col-md-6">
                    <label htmlFor="formFileSm" className="form-label">Agreements</label><p>(Agreement and other
                    file must be in image format)</p>
                    <a href="" onClick={event => {
                        event.preventDefault()
                        this.setState({
                            no_img: this.state.no_img + 1,
                        })
                    }}>(Add Additional File) <br/></a>
                </div>
                <div className="col-md-6">
                </div>
                {t}
            </>
            btn=   <button className="btn btn-block btn-outline-success btn-sm" onClick={this.cancel_request}> Execute </button>
        }
        return (<>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle no-print"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Cement Allocation Cancel</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cement #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.cement_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>PO #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.po_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>FS #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fs_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Weight (q)</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.weight}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Cancel Weight (q)</label>
                                    <input type="text" className="form-control form-control-sm" name="cancel_weight"
                                           value={this.state.cancel_weight} onChange={this.inputmgt} placeholder="Cancel Weight (q)"/>
                                </div>
                            </div>
                            <div className="col-md-8">
                            </div>
                            {files}
                            <div className="col-md-4">
                                <div className="form-group">
                                    {btn}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <button className="btn btn-block btn-outline-danger btn-sm" onClick={event => {
                                        event.preventDefault()
                                        this.setState({
                                            wiz:2,
                                        })
                                    }}> Cancel </button>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-danger float-right" onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 2,
                            })
                        }}>
                            <i
                                className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
    wiz_4 = () => {
        let imgs=this.state.urls.map(v=>{
            return (<div className="col-md-12 text-center my-2 border">
                <img src={v.url}/>
            </div> )
        })
        return (<>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-green ui-sortable-handle no-print"
                         style={{cursor: 'move'}}>
                        <h3 className="card-title">Cement Allocation Cancel</h3>
                        <div className="card-tools">
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.client_name}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Cement #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.cement_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>PO #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.po_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>FS #</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.fs_no}/>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Weight (q)</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm"
                                           value={this.state.weight}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Cancel Weight (q)</label>
                                    <input readOnly={true} type="text" className="form-control form-control-sm" name="cancel_weight"
                                           value={this.state.cancel_weight} onChange={this.inputmgt} placeholder="Cancel Weight (q)"/>
                                </div>
                            </div>
                            <div className="col-md-8">
                            </div>
                            {imgs}
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        <button type="button" className="btn btn-danger float-right" onClick={event => {
                            event.preventDefault()
                            this.setState({
                                wiz: 2,
                            })
                        }}>
                            <i
                                className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        else if (this.state.wiz === 2)
            dis = this.wiz_2()
        else if (this.state.wiz === 3)
            dis = this.wiz_3()
        else if (this.state.wiz === 4)
            dis = this.wiz_4()
        return (
            <>
                <Nav type="cement_delivery_cancel_req"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {dis}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Cement_delivery_cancel_req;
