import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import { Modal,Button } from 'react-bootstrap';
import Select from 'react-select'
let source = axios.CancelToken.source();

class Menu extends Component {

    constructor() {
        super();
        this.state = {
            objs: [],
            filtered:[],
            objsload: false,

            id:-1,
            cushow:false,
            iscreate:true,
            txtsearch:'',

            name_en:'',
            name_am:'',
            price:'',
            contents:'',
            type:'',
        }
    }
    componentDidMount() {
        this.fetcheddata()
    }
    fetcheddata = () => {
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.get_menus;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                this.setState({
                    objs: res.data,
                    filtered:res.data.datas,
                    objsload: true,
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    showcu = (event,iscreate,id=-1) => {
        event.preventDefault()
        if(iscreate) {
            this.setState({
                cushow: true,
                id: id,
                iscreate: iscreate,
                name_en:'',
                name_am:'',
                price:'',
                contents:'',
                type:'',
            })
        }
        else {
            let baseurl = myconfig.get_menu;
            let redirecttologin = myconfig.redirecttologin;
            axios.post(baseurl, {
                sid: window.localStorage.getItem("ordermgt_sid"),
                id: id,
            }, {cancelToken: source.token}).then(res => {
                if (res.data.auth == false) {
                    window.localStorage.setItem("ordermgt_sid", null);
                    window.localStorage.setItem("ordermgt_username", null);
                    window.localStorage.setItem("ordermgt_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    if (res.data.data == null) {
                        Swal.fire({
                            title: 'Error!',
                            text: "No data found",
                            icon: 'error',
                            confirmButtonText: 'Okay'
                        })
                    } else {
                        console.log(res.data)
                        this.setState({
                            cushow: true,
                            id:id,
                            iscreate: iscreate,
                            name_en:res.data.data.name_en,
                            name_am:res.data.data.name_am,
                            price:res.data.data.price,
                            contents:res.data.data.contents,
                            type:res.data.data.type,
                        })
                    }
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }
    }
    hidecu = () => {
        this.setState({
            cushow:false,
        })
    }
    modalcu=()=> {
        return (<Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.cushow}
                onHide={this.hidecu}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-cyan ui-sortable-handle" style={{cursor: 'move'}}>
                                    <h3 className="card-title">Menu Management</h3>
                                    <div className="card-tools">
                                        <button type="button" onClick={this.hidecu} className="btn btn-tool">
                                            <i className="fas fa-times"/>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Name English</label>
                                                    <input className="form-control" type="text"
                                                           name="name_en" onChange={this.inputmgt}
                                                           value={this.state.name_en}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Name Amharic</label>
                                                    <input className="form-control" type="text"
                                                           name="name_am" onChange={this.inputmgt}
                                                           value={this.state.name_am}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Price</label>
                                                    <input className="form-control" type="text"
                                                           name="price" onChange={this.inputmgt}
                                                           value={this.state.price}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <select className="form-control"
                                                            name="type" onChange={this.inputmgt}
                                                            value={this.state.type}>
                                                        <option value="-1">-- Select Type --</option>
                                                        <option value="Food">Food</option>
                                                        <option value="Beverage">Beverage</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label>Contents</label>
                                                    <textarea className="form-control" type="text"
                                                              name="contents" onChange={this.inputmgt} rows="5">
                                                            {this.state.contents}
                                                        </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 px-3">
                                            <div className="form-group d-flex justify-content-around">
                                                <button className="btn btn-outline-primary" onClick={this.submitcu}> Save </button>
                                                <button className="btn btn-outline-danger" onClick={this.hidecu}> Cancel </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    submitcu = (event) => {
        event.preventDefault()
        let redirecttologin = myconfig.redirecttologin;
        let baseurl = myconfig.update_menu;
        if (this.state.iscreate)
            baseurl = myconfig.create_menu;
        axios.post(baseurl, {
            sid: window.localStorage.getItem("ordermgt_sid"),
            id: this.state.id,
            name_en:this.state.name_en,
            name_am:this.state.name_am,
            price:this.state.price,
            contents:this.state.contents,
            type:this.state.type,
        }, {cancelToken: source.token}).then(res => {
            console.log(res.data)
            if (res.data.auth == false) {
                window.localStorage.setItem("ordermgt_sid", null);
                window.localStorage.setItem("ordermgt_username", null);
                window.localStorage.setItem("ordermgt_name", null);
                var win = window.open(redirecttologin, "_self");
            } else if (res.data.success == false) {
                Swal.fire({
                    title: 'Error!',
                    text: res.data.msg,
                    icon: 'error',
                    confirmButtonText: 'Okay'
                })
            } else {
                Swal.fire({
                    title: 'success!',
                    text: res.data.msg,
                    icon: 'success',
                    confirmButtonText: 'Okay'
                }).then(value => {
                    this.setState({
                        cushow:false,
                    })
                    this.fetcheddata()
                })
            }
        }).catch((e) => {
            console.log(e.message);
        });
    }

    tabledata=()=>{
        let retdata=null;
        if(this.state.objsload) {
            retdata = this.state.filtered.map(value => {
                return (<tr>
                    <td><a href="" onClick={(event => {this.showcu(event,false,value.id)})}>Edit</a></td>
                    <td>{value.name_en}</td>
                    <td>{value.name_am}</td>
                    <td>{value.price}</td>
                    <td>{value.type}</td>
                    <td>{value.contents}</td>
                </tr>)
            })
        }
        return (<>{retdata}</>)
    }

    inputmgt = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        })
    }
    inputsearch = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let temp = this.state.objs.datas;
        if (value != "") {
            temp = temp.filter(v => {
                if (
                    v.name_en.toUpperCase().includes(value.toUpperCase()) ||
                    v.name_am.toUpperCase().includes(value.toUpperCase())
                )
                    return value;
            })
        }
        this.setState({
            [name]: value,
            filtered: temp,
        })
    }

    render() {
        return (
            <>
                <Nav type="menu"/>
                <div className="content-wrapper">
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Menu Management</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href={myconfig.urlwelcome}>Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Menu</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <input type="text" name="txtsearch"
                                                           className="form-control float-right"
                                                           value={this.state.txtsearch} onChange={this.inputsearch}
                                                           placeholder="Search"/>
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-default">
                                                            <i className="fas fa-search"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-header">
                                            <h3 className="card-title"></h3>
                                            <div className="card-tools">
                                                <div className="input-group input-group-sm" style={{width: '150px'}}>
                                                    <button className="btn btn-outline-primary btn-block"
                                                            onClick={(event => this.showcu(event, true))}>New Menu
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body table-responsive p-0" style={{height: '500px'}}>
                                            <table className="table table-head-fixed">
                                                <thead>
                                                <tr>
                                                    <th className="col-md-1">Action</th>
                                                    <th className="col-md-2">Name English</th>
                                                    <th className="col-md-2">Name Amharic</th>
                                                    <th className="col-md-2">Price</th>
                                                    <th className="col-md-2">Type</th>
                                                    <th className="col-md-3">Contents</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.tabledata()}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {this.modalcu()}
            </>
        )
    }
}

export default Menu;
