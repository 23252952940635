import {Component} from 'react';
import axios from "axios"
import Nav from "./../Nav";
import Swal from "sweetalert2";
import myconfig from "../../myconfig";
import * as XLSX from "xlsx";
import Select from "react-select";


let source = axios.CancelToken.source();

class Status_cement extends Component {

    constructor() {
        super();
        this.state = {
            datas: [],
            filter_datas: [],
            wiz: 1,
            fil: "All",
            is_exe: false,

            client_selected: null,
            clients: [],
        }
    }
    handelchange_client = (client_selected) => {
        let filter_datas=this.state.datas.filter(v=>{
            if(v.client==client_selected.label)
                return v;
        });
        this.setState({
            client_selected:client_selected,
            filter_datas:filter_datas,
        })
    }
    componentDidMount() {
        this.fetchdata()
    }

    componentWillUnmount() {
        try {
            if (source) {
                source.cancel("Landing Component got unmounted");
            }
        } catch (e) {

        }
    }

    fetchdata = () => {
        if(!this.state.is_exe){
            let redirecttologin = myconfig.redirecttologin;
            let baseurl = myconfig.status_cement;

            this.setState({
                datas: [],
                filter_datas: [],
                clients: [],
                is_exe: true,
            })
            let key_word = new URLSearchParams(this.props.location.search).get("key_word");
            axios.post(baseurl, {
                sid: window.localStorage.getItem("logmgtsys_sid"),
                fil:"All",
            }, {cancelToken: source.token}).then(res => {
                console.log(res.data)
                if (res.data.auth == false) {
                    window.localStorage.setItem("logmgtsys_sid", null);
                    window.localStorage.setItem("logmgtsys_username", null);
                    window.localStorage.setItem("logmgtsys_name", null);
                    var win = window.open(redirecttologin, "_self");
                } else if (res.data.success == false) {
                    Swal.fire({
                        title: 'Error!',
                        text: res.data.msg,
                        icon: 'error',
                        confirmButtonText: 'Okay'
                    })
                } else {
                    this.setState({
                        datas: res.data.datas,
                        filter_datas: res.data.datas,
                        clients: res.data.clients,
                        key_word: key_word,
                        is_exe:false,
                    },()=>this.filter_by_key())
                }
            }).catch((e) => {
                console.log(e.message);
            });
        }

    }
    inputmgt_filter_by_status = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        let filter_datas=this.state.datas;
        if(value=="Open")
            filter_datas=this.state.datas.filter(v=>{
                if(v.isfound)
                    return v;
            });
        else if(value=="Closed")
            filter_datas=this.state.datas.filter(v=>{
                if(!v.isfound)
                    return v;
            });
        this.setState({
            [name]: value,
            filter_datas: filter_datas,
        })
    }
    inputmgt_filter_by_key_word = (event) => {
        event.preventDefault();
        var name = event.target.name;
        var value = event.target.value;
        this.setState({
            [name]: value,
        },()=>this.filter_by_key())
    }
    filter_by_key(){
        let filter_datas=this.state.datas;
        if(this.state.key_word!="" && this.state.key_word!=null) {
            filter_datas = this.state.datas.filter(v => {

                if (
                    v.label.toUpperCase().includes(this.state.key_word.toUpperCase())
                ){
                    console.log(v.label)
                    console.log(this.state.key_word)
                    return v;
                }

            });
        }
        this.setState({
            filter_datas: filter_datas,
        })
    }
    wiz_1 = () => {
        let count=0;
        let tdata = this.state.filter_datas.map(value => {
            let cl="bg-danger";
            if(value.isfound)
                cl=""
            let url_detail=myconfig.redirecttostatus_cement_detail
            url_detail=url_detail+"?no="+value.cement_no
            count=count+1;
            return (<tr className={cl}>
                <td>{count}</td>
                <td>
                    <a href={url_detail} target="_blank">{value.cement_no}</a>
                </td>
                <td>{value.label}</td>
                <td>{value.cargo}</td>
                <td>{value.order_date}</td>
                <td>{value.total_weight}</td>
                <td>{value.delivered_volume}</td>
                <td>{value.remaining}</td>
            </tr>)
        })
        return (<>
            <div className="card-body">
                <div className="row">
                    <table id="report" className="table table-head-fixed">
                        <thead>
                        <tr>
                            <th className="col-md-1">#</th>
                            <th className="col-md-1">Cement #</th>
                            <th className="col-md-2">Client</th>
                            <th className="col-md-1">Cargo</th>
                            <th className="col-md-1">Order Date</th>
                            <th className="col-md-2">Total Sale Collection (q)</th>
                            <th className="col-md-2">Total Shipped to Customer (q)</th>
                            <th className="col-md-2">Unshipped to Customer (q)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdata}
                        </tbody>
                    </table>
                </div>
            </div>
        </>)
    }
    render() {
        let dis = null;
        if (this.state.wiz === 1)
            dis = this.wiz_1()
        return (
            <>
                <Nav type="status_cement"/>
                <div className="content-wrapper">
                    <div className="content-header">
                    </div>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle" style={{cursor: 'move'}}>
                                            <h3 className="card-title">Search Criteria</h3>
                                            <div className="card-tools">
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-11 m-1">
                                                    <div className="form-group">
                                                        <label>By Status</label>
                                                        <select  name="fil"
                                                                 className="form-control float-right"
                                                                 value={this.state.fil} onChange={this.inputmgt_filter_by_status}>
                                                            <option value="Open">Open</option>
                                                            <option value="Closed">Closed</option>
                                                            <option value="All">All</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-11 m-1">
                                                    <div className="form-group">
                                                        <label>Client<a href="" onClick={event => {
                                                            event.preventDefault()
                                                            this.setState({
                                                                fil:"All",
                                                                filter_datas:this.state.datas,
                                                                client_selected:null,
                                                            })
                                                        }}>(Reset)</a></label>
                                                        <Select
                                                            value={this.state.client_selected}
                                                            onChange={this.handelchange_client}
                                                            options={this.state.clients}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-11 m-1">
                                                    <div className="form-group">
                                                        <label>Key Word</label>
                                                        <input type="text" name="key_word"
                                                               className="form-control float-right"
                                                               value={this.state.key_word} onChange={this.inputmgt_filter_by_key_word} placeholder="Key Word"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header bg-green ui-sortable-handle"
                                             style={{cursor: 'move'}}>
                                            <h3 className="card-title">Cement Balance</h3>
                                        </div>
                                        {dis}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Status_cement;
